define('m0601/controllers/optionen', ['exports', 'ember', 'ember-validations', 'ember-i18n'], function (exports, Ember, EmberValidations, ember_i18n) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {

    i18n: Ember['default'].inject.service(),

    debug: true,

    mindestanzahlVerbinderNebeneinander: "-1", // n90min
    maximaleAnzahlVerbinderNebeneinander: "-1", // n90max
    geradeAnzahlVerbinderNebeneinander: false, // anzahlVmNebeneinanderGerade
    mindestanzahlVerbinderJeFeldhaelfte: "-1", // mindestanzahlSchubverbinderLinks, mindestanzahlSchubverbinderRechts

    validations: {
      mindestanzahlVerbinderNebeneinander: {
        numericality: {
          greaterThanOrEqualTo: 1,
          lessThanOrEqualTo: 99,
          allowBlank: true
        }
      },
      maximaleAnzahlVerbinderNebeneinander: {
        numericality: {
          greaterThanOrEqualTo: 1,
          lessThanOrEqualTo: 99,
          allowBlank: true
        }
      },
      mindestanzahlVerbinderJeFeldhaelfte: {
        numericality: {
          greaterThanOrEqualTo: 1,
          lessThanOrEqualTo: 99,
          allowBlank: true
        }
      }
    },

    ttMindestanzahlVerbinderNebeneinander: Ember['default'].computed('i18n.locale', {
      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 1 - 99";
      },
      set: function set(key, value) {
        return value;
      }
    }),
    ttMaximaleAnzahlVerbinderNebeneinander: Ember['default'].computed('i18n.locale', {
      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 1 - 99";
      },
      set: function set(key, value) {
        return value;
      }
    }),
    ttMindestanzahlVerbinderJeFeldhaelfte: Ember['default'].computed('i18n.locale', {
      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 1 - 99";
      },
      set: function set(key, value) {
        return value;
      }
    }),

    init: function init() {
      this._super();

      this.set('mindestanzahlVerbinderNebeneinander', "");
      this.set('maximaleAnzahlVerbinderNebeneinander', "");
      this.set('mindestanzahlVerbinderJeFeldhaelfte', "");

      this.send('validation', -1, { target: { name: 'foobar' } });
    },

    setValues: function setValues(values) {

      var self = this;

      if (values.mindestanzahlVerbinderNebeneinander) {
        self.set('mindestanzahlVerbinderNebeneinander', values.mindestanzahlVerbinderNebeneinander);
      }
      if (values.maximaleAnzahlVerbinderNebeneinander) {
        self.set('maximaleAnzahlVerbinderNebeneinander', values.maximaleAnzahlVerbinderNebeneinander);
      }
      if (values.geradeAnzahlVerbinderNebeneinander) {
        self.set('geradeAnzahlVerbinderNebeneinander', values.geradeAnzahlVerbinderNebeneinander);
      }
      if (values.mindestanzahlSchubverbinderLinks) {
        self.set('mindestanzahlVerbinderJeFeldhaelfte', values.mindestanzahlSchubverbinderLinks);
      }

      this.send('validation', -1, { target: { name: 'foobar' } });
    },

    watchMindestanzahlVerbinderNebeneinander: (function () {

      var self = this;

      if (this.get('mindestanzahlVerbinderNebeneinander').indexOf(",") !== -1) {
        this.set('mindestanzahlVerbinderNebeneinander', this.get('mindestanzahlVerbinderNebeneinander').replace(",", "."));
      }

      self.set('validations.maximaleAnzahlVerbinderNebeneinander.numericality.greaterThanOrEqualTo', Number(self.get('mindestanzahlVerbinderNebeneinander')));
      self.set('ttMaximaleAnzahlVerbinderNebeneinander', self.get('i18n').t('wertebereich') + " " + (Number(self.get('mindestanzahlVerbinderNebeneinander')) === 0 ? 1 : Number(self.get('mindestanzahlVerbinderNebeneinander'))) + " - 99");

      this.send('validation', -1, { target: { name: 'foobar' } });
    }).observes('mindestanzahlVerbinderNebeneinander'),

    watchMaximalanzahlVerbinderNebeneinander: (function () {

      var self = this;

      // console.log('mindestanzahlVerbinderNebeneinander: ', self.get('mindestanzahlVerbinderNebeneinander'), Number(self.get('mindestanzahlVerbinderNebeneinander')))
      // console.log('validations.mindestanzahlVerbinderNebeneinander.numericality.allowBlank', self.get('validations.mindestanzahlVerbinderNebeneinander.numericality.allowBlank'))

      if (this.get('maximaleAnzahlVerbinderNebeneinander').indexOf(",") !== -1) {
        this.set('maximaleAnzahlVerbinderNebeneinander', this.get('maximaleAnzahlVerbinderNebeneinander').replace(",", "."));
      }

      // if (self.get('maximaleAnzahlVerbinderNebeneinander') === "" ){
      //   self.set('validations.mindestanzahlVerbinderNebeneinander.numericality.allowBlank', true);
      // }else{
      //   self.set('validations.mindestanzahlVerbinderNebeneinander.numericality.allowBlank', false);
      // }

      // console.log('validations.mindestanzahlVerbinderNebeneinander.numericality.allowBlank', self.get('validations.mindestanzahlVerbinderNebeneinander.numericality.allowBlank'))
      // self.send('validation', -1, { target: { name: "foobar" } });
      // console.log('validated')
    }).observes('maximaleAnzahlVerbinderNebeneinander'),

    watchAnzahlVmNebeneinanderGerade: (function () {

      var self = this;

      var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      applicationdata.set('treeLoaded', false);
      applicationdata.set('initialized', false);
      applicationdata.set('pdfErstellt', false);
      applicationdata.set('dxfErstellt', false);

      var downloadcenter = self.controllerFor('downloadcenter');
      downloadcenter.set('pdfErstellt', false);
      downloadcenter.set('dxfErstellt', false);

      var optionendata = self.controllerFor('application').get('model').optionen.objectAt(0);
      optionendata.set('anzahlVmNebeneinanderGerade', self.get('anzahlVmNebeneinanderGerade'));
    }).observes('anzahlVmNebeneinanderGerade'),

    watchNumericalInputs: (function () {

      if (!this.openFromFile) {

        if (this.get('mindestanzahlVerbinderJeFeldhaelfte').indexOf(",") !== -1) {
          this.set('mindestanzahlVerbinderJeFeldhaelfte', this.get('mindestanzahlVerbinderJeFeldhaelfte').replace(",", "."));
        }
      }
    }).observes('mindestanzahlVerbinderJeFeldhaelfte'),

    actions: {

      validation: function validation(value, event) {

        var self = this;

        if (!self.openFromFile) {

          // this.setX3D(value, event);

        }

        self.validate().then(function () {
          // all validati0ons pass

        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');
          if (validation === true) {
            self.set('displayErrors', false);
            application.set('optionenInvalid', false);

            var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
            applicationdata.set('treeLoaded', false);
            applicationdata.set('initialized', false);
            applicationdata.set('pdfErstellt', false);
            applicationdata.set('dxfErstellt', false);

            var downloadcenter = self.controllerFor('downloadcenter');
            downloadcenter.set('pdfErstellt', false);
            downloadcenter.set('dxfErstellt', false);

            var optionendata = self.controllerFor('application').get('model').optionen.objectAt(0);

            optionendata.set('n90min', self.get('mindestanzahlVerbinderNebeneinander'));
            optionendata.set('n90max', self.get('maximaleAnzahlVerbinderNebeneinander'));
            optionendata.set('mindestanzahlSchubverbinderLinks', self.get('mindestanzahlVerbinderJeFeldhaelfte'));
            optionendata.set('mindestanzahlSchubverbinderRechts', self.get('mindestanzahlVerbinderJeFeldhaelfte'));
          } else {
            self.set('displayErrors', true);
            application.set('optionenInvalid', true);
          }
        });
      },

      mindestanzahlVerbinderNebeneinanderIsSelected: function mindestanzahlVerbinderNebeneinanderIsSelected() {
        if (this.get('mindestanzahlVerbinderNebeneinander') !== "") {
          var valuework = parseFloat(this.get('mindestanzahlVerbinderNebeneinander').replace(",", "."));
          var valuerounded = valuework.toFixed(0);
          this.set('mindestanzahlVerbinderNebeneinander', valuerounded);
        }
        document.getElementsByName('mindestanzahlVerbinderNebeneinander')[0].setSelectionRange(0, this.get('mindestanzahlVerbinderNebeneinander').length);
      },

      maximaleAnzahlVerbinderNebeneinanderIsSelected: function maximaleAnzahlVerbinderNebeneinanderIsSelected() {
        if (this.get('maximaleAnzahlVerbinderNebeneinander') !== "") {
          var valuework = parseFloat(this.get('maximaleAnzahlVerbinderNebeneinander').replace(",", "."));
          var valuerounded = valuework.toFixed(0);
          this.set('maximaleAnzahlVerbinderNebeneinander', valuerounded);
        }
        document.getElementsByName('maximaleAnzahlVerbinderNebeneinander')[0].setSelectionRange(0, this.get('maximaleAnzahlVerbinderNebeneinander').length);
      },

      mindestanzahlVerbinderJeFeldhaelfteIsSelected: function mindestanzahlVerbinderJeFeldhaelfteIsSelected() {
        if (this.get('mindestanzahlVerbinderJeFeldhaelfte') !== "") {
          var valuework = parseFloat(this.get('mindestanzahlVerbinderJeFeldhaelfte').replace(",", "."));
          var valuerounded = valuework.toFixed(0);
          this.set('mindestanzahlVerbinderJeFeldhaelfte', valuerounded);
        }
        document.getElementsByName('mindestanzahlVerbinderJeFeldhaelfte')[0].setSelectionRange(0, this.get('mindestanzahlVerbinderJeFeldhaelfte').length);
      }

    }

  });

});