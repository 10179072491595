define('m0601/models/lasteinwirkung', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	var lasteinwirkung = DS['default'].Model.extend({

		NKL_ID: DS['default'].attr('string'),
		NKL_Text: DS['default'].attr('string'),
		KLED_ID: DS['default'].attr('string'),
		LED_Text: DS['default'].attr('string'),
		V_d: DS['default'].attr('string'),

		//--

		dacheindeckung: DS['default'].attr('string'),
		beschreibung: DS['default'].attr('string'),
		g: DS['default'].attr('string'),

		SLZ_ID: DS['default'].attr('string'),
		sk: DS['default'].attr('string'),
		HuNN: DS['default'].attr('string'),
		NDTE: DS['default'].attr('boolean'),
		Schneefanggitter: DS['default'].attr('boolean'),
		geoDatenManuell: DS['default'].attr('boolean'),

		WLZ_ID: DS['default'].attr('string'),
		q: DS['default'].attr('string')

	});

	lasteinwirkung.reopenClass({
		FIXTURES: [{
			id: 1,
			LED_Text: "sehr kurz",
			NKL_ID: 1,
			KLED_ID: 4,
			NKL_Text: "Nutzungsklasse 1",
			V_d: "1.0",

			//--

			dacheindeckung: 1,
			beschreibung: "",
			g: 0,

			SLZ_ID: 1,
			sk: 0,
			HuNN: 0,
			NDTE: false,
			Schneefanggitter: false,
			geoDatenManuell: false,

			WLZ_ID: 1,
			q: 0

		}]
	});

	exports['default'] = lasteinwirkung;

});