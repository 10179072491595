define('m0601/controllers/daemmung', ['exports', 'ember', 'ember-validations'], function (exports, Ember, EmberValidations) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {

    bauder: false,
    zweilagig: false,
    freieEingabeGL: false,
    osbSelected: false,

    daemmstoffart_1: "",
    daemmstoffartnr_1: "",
    daemmstoffdicken_1: "",
    daemmstoffdruckfest_1: false,
    daemmstoffemod_1: "0",
    daemmstoffgew_1: 0,
    daemmstoffhersteller_1: "",
    daemmstoffind_1: 0,
    daemmstoffs_10_1: "",
    daemmstofftext_1: "",

    openFromFile: false,
    displayErrors: true,

    traufkonstruktion: false,
    befestigungsvariante: 0,
    verklebt: false,

    validations: {
      glHoehe: {
        numericality: {
          greaterThanOrEqualTo: 30,
          lessThanOrEqualTo: 240
        }
      },
      glBreite: {
        numericality: {
          greaterThanOrEqualTo: 50,
          lessThanOrEqualTo: 1250
        }
      },
      glLaenge: {
        numericality: {
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 9999.0
        }
      },
      daemmstoffdicken_1: {
        numericality: {
          greaterThanOrEqualTo: 15.0,
          lessThanOrEqualTo: 400.0
        }
      },
      daemmstoffemod_1: {
        numericality: {
          greaterThanOrEqualTo: 0.5,
          lessThanOrEqualTo: 15000.0
        }
      },
      daemmstoffs_10_1: {
        numericality: {
          greaterThanOrEqualTo: 0.05,
          lessThanOrEqualTo: 9999.0
        }
      },

      sAbstand: {
        numericality: {
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 250
        }
      }
    },

    ttDicke: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 15 - 400 [mm]";
    }),
    ttEmod: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0.5 - 15000 [N/mm²]";
    }),
    ttSigma10: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0.05 - 9999.0 [N/mm²]";
    }),
    ttGLHoehe: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 30 - 240 [mm]";
    }),
    ttGLBreite: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 50 - 1250 [mm]";
    }),
    ttGLLaenge: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0 - 9999.0 [m]";
    }),
    ttMaxAbstand: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0 - 250 [cm]";
    }),
    i18n: Ember['default'].inject.service(),

    bvariante: [{
      name: "Kontinuierliche Verschraubung",
      ind: 0
    }, {
      name: "Traufkonstruktion",
      ind: 1
    }],

    verlegung: [{
      name: "Einlagige Verlegung",
      value: false
    }, {
      name: "Zweilagige Verlegung",
      value: true
    }],

    glMaterial: [{
      name: "NH, C24",
      ind: 0
    }, {
      name: "OSB/3",
      ind: 1
    }],

    glVerklebt: [{
      name: "aufkaschiert",
      value: false
    }, {
      name: "verklebt",
      value: true
    }],

    glAbmessungen: [{
      name: "30 mm x 50 mm",
      ind: 0
    }, {
      name: "40 mm x 60 mm",
      ind: 1
    }, {
      name: "40 mm x 80 mm",
      ind: 2
    }, {
      name: "60 mm x 60 mm",
      ind: 3
    }, {
      name: "40 mm x 100 mm",
      ind: 4
    }, {
      name: "freie Eingabe h x b",
      ind: 5
    }],

    glHoehen: {
      0: "30",
      1: "40",
      2: "40",
      3: "60",
      4: "40"
    },
    glBreiten: {
      0: "50",
      1: "60",
      2: "80",
      3: "60",
      4: "100"
    },

    dsDruckfest: [{
      name: "druckweicher Dämmstoff",
      value: false
    }, {
      name: "druckfester Dämmstoff",
      value: true
    }],

    selectedNodesLage1: "",
    selectedNodesLage2: "",
    loadingLage1: false,
    loadingLage2: false,

    glBreite: "50.0",
    glHoehe: "30.0",
    glLaenge: "5.0",
    sAbstand: "0.0",

    watchBefestigungsvariante: (function () {

      var self = this;

      var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var grundmasse = x3d.get('bauteile').findBy('id', 'dachgeometrie').get('grundmasse').objectAt(0);

      if (self.get('befestigungsvariante') === 0) {
        self.set('traufkonstruktion', false);
        grundmasse.set('traufkonstruktion', false);
        if (self.get('daemmstoffdruckfest_1') === true) {
          x3d.set('aktuellerSchraubenTyp', 'schraube2');
        } else {
          x3d.set('aktuellerSchraubenTyp', 'schraube1');
        }
      } else {
        self.set('traufkonstruktion', true);
        grundmasse.set('traufkonstruktion', true);
        this.setSchraubenEigenschaften();
        x3d.set('aktuellerSchraubenTyp', 'schraube3');
      }

      x3d.set('transformHelper', !x3d.get('transformHelper'));
      x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));

      var daemmungdata = self.controllerFor('application').get('model').daemmung.objectAt(0);
      daemmungdata.set('befestigungsVariante', self.get('befestigungsvariante'));
    }).observes('befestigungsvariante'),

    watchVerlegung: (function () {

      var self = this;

      if (self.get('verlegungLagen') === true) {
        self.set('zweilagig', true);
      } else {
        self.set('zweilagig', false);
      }
    }).observes('verlegungLagen'),

    watchGrundlattenGrenzwertmodus: (function () {

      var self = this;

      if (self.get('befestigungsvariante') === 1 && self.get('daemmstoffdruckfest_1') === false) {
        self.validations.glHoehe.numericality.greaterThanOrEqualTo = 50;
        self.set('ttGLHoehe', self.get('i18n').t('wertebereich') + " 50 - 240 [mm]");
      } else {
        self.validations.glHoehe.numericality.greaterThanOrEqualTo = 30;
        self.set('ttGLHoehe', self.get('i18n').t('wertebereich') + " 30 - 240 [mm]");
      }

      self.send('validation');
    }).observes('druckfest', 'befestigungsvariante'),

    watchDruckfest: (function () {

      var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var self = this;

      if (self.get('daemmstoffdruckfest_1') === true) {
        self.set('daemmstoffdruckfest_1', true);
        if (self.get('befestigungsvariante') === 0) {
          x3d.set('aktuellerSchraubenTyp', 'schraube2');
        }
      } else {
        self.set('daemmstoffdruckfest_1', false);
        if (self.get('befestigungsvariante') === 0) {
          x3d.set('aktuellerSchraubenTyp', 'schraube1');
        }
      }

      var daemmungdata = self.controllerFor('application').get('model').daemmung.objectAt(0);
      daemmungdata.set('Dae_Druckfest', self.get('daemmstoffdruckfest_1'));
      x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));
    }).observes('daemmstoffdruckfest_1'),

    watchGLMaterial: (function () {

      var self = this;

      if (self.get('grundlatteMaterial') === 1) {
        self.set('osbSelected', true);
        //self.validations.glHoehe.numericality.greaterThanOrEqualTo = 35;
      } else {
          self.set('osbSelected', false);
          //self.validations.glHoehe.numericality.greaterThanOrEqualTo = 30;
        }
    }).observes('grundlatteMaterial'),

    watchOSB: (function () {

      var self = this;

      if (self.get('osbVerklebt') === true) {
        self.set('verklebt', true);
      } else {
        self.set('verklebt', false);
      }

      var daemmungdata = self.controllerFor('application').get('model').daemmung.objectAt(0);

      daemmungdata.set('verklebt', self.get('osbVerklebt'));
    }).observes('osbVerklebt'),

    watchGrundlatteAbmessungen: (function () {

      var self = this;

      var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (this.get('grundlatteAbmessungen') === 0) {
        this.set('freieEingabeGL', false);
        bauteile.findBy('id', 'grundlatte1').get('boxsizes').objectAt(0).set('y', 3);
        bauteile.findBy('id', 'grundlatte2').get('boxsizes').objectAt(0).set('y', 3);
        bauteile.findBy('id', 'grundlatte1').get('boxsizes').objectAt(0).set('z', 5);
        bauteile.findBy('id', 'grundlatte2').get('boxsizes').objectAt(0).set('z', 5);
        // name: "40 mm x 60 mm",
      } else if (this.get('grundlatteAbmessungen') === 1) {
          this.set('freieEingabeGL', false);
          bauteile.findBy('id', 'grundlatte1').get('boxsizes').objectAt(0).set('y', 4);
          bauteile.findBy('id', 'grundlatte2').get('boxsizes').objectAt(0).set('y', 4);
          bauteile.findBy('id', 'grundlatte1').get('boxsizes').objectAt(0).set('z', 6);
          bauteile.findBy('id', 'grundlatte2').get('boxsizes').objectAt(0).set('z', 6);
          // name: "40 mm x 60 mm",
        } else if (this.get('grundlatteAbmessungen') === 2) {
            this.set('freieEingabeGL', false);
            bauteile.findBy('id', 'grundlatte1').get('boxsizes').objectAt(0).set('y', 4);
            bauteile.findBy('id', 'grundlatte2').get('boxsizes').objectAt(0).set('y', 4);
            bauteile.findBy('id', 'grundlatte1').get('boxsizes').objectAt(0).set('z', 8);
            bauteile.findBy('id', 'grundlatte2').get('boxsizes').objectAt(0).set('z', 8);
            // name: "40 mm x 80 mm",
          } else if (this.get('grundlatteAbmessungen') === 3) {
              this.set('freieEingabeGL', false);
              bauteile.findBy('id', 'grundlatte1').get('boxsizes').objectAt(0).set('y', 6);
              bauteile.findBy('id', 'grundlatte2').get('boxsizes').objectAt(0).set('y', 6);
              bauteile.findBy('id', 'grundlatte1').get('boxsizes').objectAt(0).set('z', 6);
              bauteile.findBy('id', 'grundlatte2').get('boxsizes').objectAt(0).set('z', 6);
              // name: "60 mm x 60 mm",
            } else if (this.get('grundlatteAbmessungen') === 4) {
                this.set('freieEingabeGL', false);
                bauteile.findBy('id', 'grundlatte1').get('boxsizes').objectAt(0).set('y', 4);
                bauteile.findBy('id', 'grundlatte2').get('boxsizes').objectAt(0).set('y', 4);
                bauteile.findBy('id', 'grundlatte1').get('boxsizes').objectAt(0).set('z', 10);
                bauteile.findBy('id', 'grundlatte2').get('boxsizes').objectAt(0).set('z', 10);
                // name: "40 mm x 100 mm",
              } else if (this.get('grundlatteAbmessungen') === 5) {
                  this.set('freieEingabeGL', true);
                }
      x3d.set('transformHelper', !x3d.get('transformHelper'));

      var daemmungdata = self.controllerFor('application').get('model').daemmung.objectAt(0);

      daemmungdata.set('glHoehe', self.glHoehen[self.get('grundlatteAbmessungen')]);
      daemmungdata.set('glBreite', self.glBreiten[self.get('grundlatteAbmessungen')]);
    }).observes('grundlatteAbmessungen'),

    watchSelectedNodes: (function () {

      var self = this;

      console.log(self.selectedNodesLage1[0].data.hersteller);

      self.set('daemmstoffart_1', self.selectedNodesLage1[0].data.art);
      self.set('daemmstoffartnr_1', self.selectedNodesLage1[0].data.artnr);
      self.set('daemmstoffdicken_1', self.selectedNodesLage1[0].data.dicken);
      self.set('daemmstoffdruckfest_1', self.selectedNodesLage1[0].data.druckfest);
      self.set('daemmstoffemod_1', self.selectedNodesLage1[0].data.emod);
      self.set('daemmstoffgew_1', self.selectedNodesLage1[0].data.gew);
      self.set('daemmstoffhersteller_1', self.selectedNodesLage1[0].data.hersteller);
      self.set('daemmstoffind_1', self.selectedNodesLage1[0].data.ind);
      self.set('daemmstoffs_10_1', self.selectedNodesLage1[0].data.s_10);
      self.set('daemmstofftext_1', self.selectedNodesLage1[0].data.text);

      var daemmungdata = self.controllerFor('application').get('model').daemmung.objectAt(0);

      //$("#daemmstoffdicken_1").val(self.selectedNodesLage1[0].data.dicken);
      // $("#daemmstoffart_1").val(self.selectedNodesLage1[0].data.art);
      // $("#daemmstoffs_10_1").val(self.selectedNodesLage1[0].data.s_10);
      // $("#daemmstoffemod_1").val(self.selectedNodesLage1[0].data.emod);
      // $("#druckfest").val(self.selectedNodesLage1[0].data.druckfest);

      daemmungdata.set('NameDae1', self.get('daemmstoffart_1'));
      daemmungdata.set('ArtNr1', self.get('daemmstoffartnr_1'));
      daemmungdata.set('d_Dae1', self.get('daemmstoffdicken_1'));
      daemmungdata.set('E_Dae', self.get('daemmstoffemod_1'));
      daemmungdata.set('Sima10', self.get('daemmstoffs_10_1'));
      daemmungdata.set('Dae_Druckfest', self.get('daemmstoffdruckfest_1'));
    }).observes('selectedNodesLage1'),

    watchNumericalInputs: (function () {
      if (!this.openFromFile) {

        console.log('watchNumericalInputs');

        if (toString(this.get('daemmstoffdicken_1')).indexOf(",") !== -1) {
          this.set('daemmstoffdicken_1', this.get('daemmstoffdicken_1').replace(",", "."));
        }

        if (this.get('glHoehe').indexOf(",") !== -1) {
          this.set('glHoehe', this.get('glHoehe').replace(",", "."));
        }

        if (this.get('glBreite').indexOf(",") !== -1) {
          this.set('glBreite', this.get('glBreite').replace(",", "."));
        }

        if (this.get('glLaenge').indexOf(",") !== -1) {
          this.set('glLaenge', this.get('glLaenge').replace(",", "."));
        }

        if (this.get('sAbstand').indexOf(",") !== -1) {
          this.set('sAbstand', this.get('sAbstand').replace(",", "."));
        }
      }
    }).observes('daemmstoffdicken_1', 'glHoehe', 'glBreite', 'glLaenge', 'sAbstand'),

    actions: {

      getDaemmstoffbaum: function getDaemmstoffbaum(event) {

        var self = this;

        $.ajax({
          type: "POST",
          dataType: "json",
          url: "assets/data/daemmstoffliste.json"
        }).done(function (data) {
          var core = tools.buildTree(data);
          console.log(core);
          self.set('daemmungLage1', core);
        });
      },

      bauderAction: function bauderAction(event) {
        var bauder = this.get('bauder');
        if (bauder) {
          this.set('bauder', false);
        } else {
          this.set('bauder', true);
        }
      },

      validation: function validation(value, event) {
        console.log("validation()");
        console.log(event);

        var self = this;

        if (!self.openFromFile) {
          this.setX3D(value, event);
        }
        console.log("nach x3d()");
        self.validate().then(function () {
          // all validations pass

        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');
          if (validation === true) {

            if (self.get('daemmstoffdicken_1') > 0) {
              console.log("Validation!");
              self.set('displayErrors', false);
              application.set('daemmungInvalid', false);

              var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
              applicationdata.set('treeLoaded', false);
              applicationdata.set('initialized', false);
              applicationdata.set('pdfErstellt', false);
              applicationdata.set('dxfErstellt', false);

              application.set('verbindungsmittelInvalid', true);

              var daemmungdata = self.controllerFor('application').get('model').daemmung.objectAt(0);

              daemmungdata.set('glHoehe', parseFloat(self.get('glHoehe')).toFixed(1));
              daemmungdata.set('glBreite', parseFloat(self.get('glBreite')).toFixed(1));

              daemmungdata.set('glLaenge', parseFloat(self.get('glLaenge')).toFixed(1));
              daemmungdata.set('schraubenabstaende', parseFloat(self.get('sAbstand')).toFixed(1));

              daemmungdata.set('NameDae1', self.get('daemmstoffart_1'));
              daemmungdata.set('ArtNr1', self.get('daemmstoffartnr_1'));
              daemmungdata.set('d_Dae1', parseFloat(self.get('daemmstoffdicken_1')).toFixed(1));
              daemmungdata.set('E_Dae', parseFloat(self.get('daemmstoffemod_1')).toFixed(1));
              daemmungdata.set('Sima10', parseFloat(self.get('daemmstoffs_10_1')).toFixed(1));
              daemmungdata.set('Dae_Druckfest', self.get('daemmstoffdruckfest_1'));
            }
          } else {
            self.set('displayErrors', true);
            application.set('daemmungInvalid', true);
          }
        });
      },

      dsDickeIsSelected: function dsDickeIsSelected(name, value) {
        var daemmstoffDickeAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('daemmstoffDickeAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('daemmstoffDickeAktiv', !daemmstoffDickeAktiv);
        if (this.get('daemmstoffdicken_1') !== "") {
          var daemmstoffdicken_1 = parseFloat(this.get('daemmstoffdicken_1').replace(",", "."));
          this.set('daemmstoffdicken_1', daemmstoffdicken_1.toFixed(1));
          this.send('validation');
        }
      },

      dsEmodIsSelected: function dsEmodIsSelected(name, value) {
        if (this.get('daemmstoffemod_1') !== "") {
          var daemmstoffemod_1 = parseFloat(this.get('daemmstoffemod_1').replace(",", "."));
          this.set('daemmstoffemod_1', daemmstoffemod_1.toFixed(1));
          this.send('validation');
        }
      },

      dsS10IsSelected: function dsS10IsSelected(name, value) {
        if (this.get('daemmstoffs_10_1') !== "") {
          var daemmstoffs_10_1 = parseFloat(this.get('daemmstoffs_10_1').replace(",", "."));
          this.set('daemmstoffs_10_1', daemmstoffs_10_1.toFixed(1));
          this.send('validation');
        }
      },

      dsNameIsSelected: function dsNameIsSelected(name, value) {},

      glLaengeIsSelected: function glLaengeIsSelected(name, value) {
        if (this.get('glLaenge') !== "") {
          var glLaenge = parseFloat(this.get('glLaenge').replace(",", "."));
          this.set('glLaenge', glLaenge.toFixed(1));
          this.send('validation');
        }
      },

      sAbstandIsSelected: function sAbstandIsSelected(name, value) {
        if (this.get('sAbstand') !== "") {
          var sAbstand = parseFloat(this.get('sAbstand').replace(",", "."));
          this.set('sAbstand', sAbstand.toFixed(1));
          this.send('validation');
        }
      },

      glHoeheIsSelected: function glHoeheIsSelected(name, value) {
        console.log(this.get('glHoehe'));
        var glhoeheAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('glhoeheAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('glhoeheAktiv', !glhoeheAktiv);
        if (this.get('glHoehe') !== "") {
          var glHoehe = parseFloat(this.get('glHoehe').replace(",", "."));
          this.set('glHoehe', glHoehe.toFixed(1));
          this.send('validation');
        }
      },

      glBreiteIsSelected: function glBreiteIsSelected(name, value) {
        console.log(this.get('glBreite'));
        var glbreiteAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('glbreiteAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('glbreiteAktiv', !glbreiteAktiv);
        if (this.get('glBreite') !== "") {
          var glBreite = parseFloat(this.get('glBreite').replace(",", "."));
          this.set('glBreite', glBreite.toFixed(1));
          this.send('validation');
        }
      }
    },

    setX3D: function setX3D(value, event) {

      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');
      var self = this;

      console.log(value + ' ' + event.target.name + ' ' + this.errors);

      switch (event.target.name) {
        case 'daemmstoffdicken_1':
          if (this.errors.daemmstoffdicken_1.length === 0) {
            value = parseFloat(value).toFixed(1);
            bauteile.findBy('id', 'daemmung').get('boxsizes').objectAt(0).set('y', value / 10);
            bauteile.findBy('id', 'traufkonstruktion1').get('boxsizes').objectAt(0).set('y', value / 10);
            bauteile.findBy('id', 'traufkonstruktion2').get('boxsizes').objectAt(0).set('y', value / 10);
            this.setHoeheUnterkonstruktion();
            this.setSchraubenEigenschaften();
            x3d.set('daemmstoffDickeEingetragen', true);
          } else {
            x3d.set('daemmstoffDickeEingetragen', false);
          }
          break;

        case 'glHoehe':
          if (this.errors.glHoehe.length === 0) {
            value = parseFloat(value).toFixed(1);
            bauteile.findBy('id', 'grundlatte1').get('boxsizes').objectAt(0).set('y', value);
            bauteile.findBy('id', 'grundlatte2').get('boxsizes').objectAt(0).set('y', value);
            this.setHoeheUnterkonstruktion();
            x3d.set('glHoeheEingetragen', true);
          } else {
            x3d.set('glHoeheEingetragen', false);
          }
          break;

        case 'glBreite':
          if (this.errors.glBreite.length === 0) {
            value = parseFloat(value).toFixed(1);
            bauteile.findBy('id', 'grundlatte1').get('boxsizes').objectAt(0).set('z', value);
            bauteile.findBy('id', 'grundlatte2').get('boxsizes').objectAt(0).set('z', value);
            x3d.set('glBreiteEingetragen', true);
          } else {
            x3d.set('glBreiteEingetragen', false);
          }
          break;
      }
      x3d.set('transformHelper', !x3d.get('transformHelper'));
    },

    setHoeheUnterkonstruktion: function setHoeheUnterkonstruktion() {
      var sparrenhoehe = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('bauteile').findBy('id', 'sparren1').get('boxsizes').objectAt(0).get('y');
      var grundlattenhoehe = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('bauteile').findBy('id', 'grundlatte1').get('boxsizes').objectAt(0).get('y');
      var schalungsdicke = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('bauteile').findBy('id', 'schalung').get('boxsizes').objectAt(0).get('y');
      var daemmungsdicke = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('bauteile').findBy('id', 'daemmung').get('boxsizes').objectAt(0).get('y');

      this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('bauteile').findBy('id', 'dachgeometrie').get('grundmasse').objectAt(0).set('hoeheUnterkonstruktion', Number(sparrenhoehe) / 2 + Number(grundlattenhoehe) + Number(schalungsdicke) + Number(daemmungsdicke));
    },

    setSchraubenEigenschaften: function setSchraubenEigenschaften() {

      var bauteile = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('bauteile');

      var sparrenhoehe = Number(bauteile.findBy('id', 'sparren1').get('boxsizes').objectAt(0).get('y'));
      var grundlattenhoehe = Number(bauteile.findBy('id', 'grundlatte1').get('boxsizes').objectAt(0).get('y'));
      var schalungsdicke = Number(bauteile.findBy('id', 'schalung').get('boxsizes').objectAt(0).get('y'));
      var daemmungsdicke = Number(bauteile.findBy('id', 'daemmung').get('boxsizes').objectAt(0).get('y'));

      var h = sparrenhoehe + schalungsdicke + daemmungsdicke + grundlattenhoehe;

      var l = daemmungsdicke / 2 + Number(bauteile.findBy('id', 'grundlatte1').get('boxsizes').objectAt(0).get('y'));
      var yPos = h - l;

      bauteile.findBy('id', 'schraubeStandard21').get('screwProperties').objectAt(0).set('length', l);
      bauteile.findBy('id', 'schraubeStandard21').get('translations').objectAt(0).set('y', yPos);

      bauteile.findBy('id', 'schraubeStandard25').get('screwProperties').objectAt(0).set('length', l);
      bauteile.findBy('id', 'schraubeStandard25').get('translations').objectAt(0).set('y', yPos);

      var l_traufk = Number(bauteile.findBy('id', 'traufkonstruktion1').get('boxsizes').objectAt(0).get('x'));
      var alpha = Math.atan(l_traufk / (daemmungsdicke + sparrenhoehe / 2));

      l = Math.sqrt(Math.pow(sparrenhoehe / 2 + daemmungsdicke, 2) + Math.pow(l_traufk, 2));

      bauteile.findBy('id', 'schraubeStandard22').get('screwProperties').objectAt(0).set('length', l);
      bauteile.findBy('id', 'schraubeStandard22').get('rotations').objectAt(0).set('z', alpha);

      bauteile.findBy('id', 'schraubeStandard26').get('screwProperties').objectAt(0).set('length', l);
      bauteile.findBy('id', 'schraubeStandard26').get('rotations').objectAt(0).set('z', alpha);
    }

  });

});