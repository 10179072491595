define('m0601/models/x3ddefault', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var X3D = DS['default'].Model.extend({
    modul: DS['default'].attr(''),
    transformHelper: DS['default'].attr('boolean', { defaultValue: false }),
    viewpointHelper: DS['default'].attr('boolean', { defaultValue: false }),
    farbeGeandert: DS['default'].attr('boolean', { defaultValue: false }),

    lastenTrigger: DS['default'].attr('boolean', { defaultValue: false }),
    systemwerteGeaendert: DS['default'].attr('boolean', { defaultValue: false }),
    lastInBearbeitung: DS['default'].attr('string', { defaultValue: 'last2' }),

    schraubenlisteAktualisiert: DS['default'].attr('boolean', { defaultValue: false }),
    aktuellerSchraubenTyp: DS['default'].attr('string', { defaultValue: 'standardSchraube' }),

    bauteile: DS['default'].hasMany('bauteil', { async: false }),

    dimensionen: DS['default'].hasMany('dimension', { async: false }),

    resetFieldOfView: DS['default'].attr('boolean', { defaultValue: false }),

    hWinkel: DS['default'].attr('number', { defaultValue: 90 }),
    vWinkel: DS['default'].attr('number', { defaultValue: 30 }),
    bemessungslast: DS['default'].attr('number', { defaultValue: 0 }),

    lastViewpoint: DS['default'].attr('string', { defaultValue: 'viewpointDefault' }),

    laengeF1Aktiv: DS['default'].attr('boolean', { defaultValue: false }),
    laengeF1Eingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    laengeF2Aktiv: DS['default'].attr('boolean', { defaultValue: false }),
    laengeF2Eingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    laengeF3Aktiv: DS['default'].attr('boolean', { defaultValue: false }),
    laengeF3Eingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    laengeF4Aktiv: DS['default'].attr('boolean', { defaultValue: false }),
    laengeF4Eingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    ueLinksAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    ueLinksEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    ueRechtsAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    ueRechtsEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    auflager1breiteAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    auflager1breiteEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    auflager2breiteAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    auflager2breiteEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    auflager3breiteAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    auflager3breiteEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    auflager4breiteAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    auflager4breiteEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    auflager5breiteAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    auflager5breiteEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    montageplatzAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    montageplatzEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    breiteAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    breiteEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    hoeheAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    hoeheEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    bAbstandAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    bAbstandEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    schalungAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    schalungEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    bDickeAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    bDickeEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    f1aAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    f1aEingetragen: DS['default'].attr('boolean', { defaultValue: true }),

    f1bAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    f1bEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    f1cAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    f1cEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    f1dAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    f1dEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    f2aAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    f2aEingetragen: DS['default'].attr('boolean', { defaultValue: true }),

    f2bAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    f2bEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    f2cAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    f2cEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    f2dAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    f2dEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    hWinkelAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    vWinkelAktiv: DS['default'].attr('boolean', { defaultValue: false }),

    skalierungsfaktor: DS['default'].attr('number', { defaultValue: function defaultValue() {
        return 2;
      } }),
    startDistance: DS['default'].attr('number', { defaultValue: 32 }),
    aktuelleDistance: DS['default'].attr('number', { defaultValue: 32 }),
    textRefresh: DS['default'].attr('number', { defaultValue: 0 }),

    abstaendeAnzeigen: DS['default'].attr('boolean', { defaultValue: true }),
    ergebnisGeladen: DS['default'].attr('boolean', { defaultValue: false }),
    istGitterModell: DS['default'].attr('boolean', { defaultValue: false })

  });

  exports['default'] = X3D;

});