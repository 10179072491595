define('m0601/models/schraubenvorauswahl', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	var schraubenvorauswahl = DS['default'].Model.extend({

		plattenstoss: DS['default'].attr('boolean'),
		maxSchubverbinder: DS['default'].attr('string')

	});

	schraubenvorauswahl.reopenClass({
		FIXTURES: [{
			id: 1,
			plattenstoss: false,
			maxSchubverbinder: "0"
		}]
	});

	exports['default'] = schraubenvorauswahl;

});