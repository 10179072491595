define('m0601/components/con-e', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Cone = Ember['default'].Component.extend({
    tagName: 'transform',
    layoutName: 'con-e',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: [''],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    translation: (function () {
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var name = this.get('name');

      return this.get('varibale');;
    }).property(''),

    rotation: (function () {
      return '1 0 0 3.14159';
    }).property(''),

    coneBottomradius: (function () {
      return '0.075';
    }).property(''),

    coneHeight: (function () {
      return '0.2';
    }).property(''),

    emissivecolor: (function () {
      var name = this.get('name');
      var bauteile = this.get('model').objectAt(0).get('bauteile');
      var emsvcolor;
      var item = bauteile.findBy('id', name);

      emsvcolor = bauteile.findBy('id', name).get('diffusecolors').objectAt(0).get('rot') + ' ' + bauteile.findBy('id', name).get('diffusecolors').objectAt(0).get('gruen') + ' ' + bauteile.findBy('id', name).get('diffusecolors').objectAt(0).get('blau');

      return emsvcolor;
    }).property('')

  });

  exports['default'] = Cone;

});