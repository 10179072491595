define('m0601/models/last-property', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    lastart: DS['default'].attr('string'),
    gespeichert: DS['default'].attr('boolean'),
    editieren: DS['default'].attr('boolean'),
    name: DS['default'].attr('string')
  });

});