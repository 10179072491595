define('m0601/controllers/materialien', ['exports', 'ember', 'ember-validations', 'ember-i18n'], function (exports, Ember, EmberValidations, ember_i18n) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {
    i18n: Ember['default'].inject.service(),

    schalungSetzbar: false,
    disableInput: false,
    breiteInputDisabled: false,
    abstandInputDisabled: false,

    openFromFile: false,

    validations: {
      breite: {
        numericality: {
          greaterThanOrEqualTo: 4.8,
          lessThanOrEqualTo: 999.9
        }
      },
      hoehe: {
        numericality: {
          greaterThanOrEqualTo: 10.0,
          lessThanOrEqualTo: 999.9
        }
      },
      bAbstand: {
        numericality: {
          greaterThanOrEqualTo: 4.8,
          lessThanOrEqualTo: 250.0
        }
      },
      schalung: {
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 10.0
        }
      },
      bDicke: {
        numericality: {
          greaterThanOrEqualTo: 7.0,
          lessThanOrEqualTo: 999.0
        }
      },
      f1a: {
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 999.0
        }
      },
      f1b: {
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 999.0
        }
      },
      f1c: {
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 999.0
        }
      },
      f1d: {
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 999.0
        }
      },
      f2a: {
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 999.0
        }
      },
      f2b: {
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 999.0
        }
      },
      f2c: {
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 999.0
        }
      },
      f2d: {
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 999.0
        }
      }
    },

    ttBreite: Ember['default'].computed('i18n.locale', {
      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 4.8 - 999.9 [cm]";
      },
      set: function set(key, value) {
        return value;
      }
    }),
    ttHoehe: Ember['default'].computed('i18n.locale', {
      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 10.0 - 999.9 [cm]";
      },
      set: function set(key, value) {
        return value;
      }
    }),
    ttBAbstand: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 4.8 - 250.0 [cm]";
    }),
    ttSchalung: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0.0 - 10.0 [cm]";
    }),
    ttBDicke: Ember['default'].computed('i18n.locale', {

      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 7.0 - 999.0 [cm]";
      },
      set: function set(key, value) {
        return value;
      }
    }),
    ttF1a: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0.0 - 999.0 [cm]";
    }),
    ttF1b: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0.0 - 999.0 [cm]";
    }),
    ttF1c: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0.0 - 999.0 [cm]";
    }),
    ttF1d: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0.0 - 999.0 [cm]";
    }),
    ttF2a: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0.0 - 999.0 [cm]";
    }),
    ttF2b: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0.0 - 999.0 [cm]";
    }),
    ttF2c: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0.0 - 999.0 [cm]";
    }),
    ttF2d: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0.0 - 999.0 [cm]";
    }),

    a2: true,
    a3: true,
    a4: true,
    a5: true,

    breite: "0",
    hoehe: "0",
    bAbstand: "0",
    schalung: "",
    bDicke: "",
    mindestbewehrung: "0.0",

    aufgehendeBauteile1: false,
    aufgehendeBauteile2: false,
    aufgehendeBauteile3: false,
    aufgehendeBauteile4: false,
    aufgehendeBauteile5: false,

    a1durchlaufend: false,
    a2durchlaufend: false,
    a3durchlaufend: false,
    a4durchlaufend: false,
    a5durchlaufend: false,

    f1a: "",
    f1b: "",
    f1c: "",
    f1d: "",
    f2a: "",
    f2b: "",
    f2c: "",
    f2d: "",

    fkl_vh: [],

    fkl_bsh: [],

    fkl_bsh_kombi: [],

    material: 0,
    ukmaterial: [],
    ukfkl: 5,
    ukfklarray: [],

    SchalungMaterial: 0,
    schalungMaterialArray: [],
    schalungFKL: 5,
    schalungfklarray: [],

    bGuete: 9120,
    bGueteArray: [],

    mindestbewehrungen: [],
    minBewehrung: "",

    init: function init() {
      this._super();
      var self = this;

      self.set('material', 0);
      self.set('schalungMaterial', 0);
      self.set('bGuete', 9120);
      // this.set('ukmaterial', self.getMaterials([0, 1, 13]));
      // this.set('ukfklarray', self.getFklassen(0));

      // this.set('ukfkl', parseInt(self.getDefaultValue(self.get('material'))));

      // this.set('schFKL', this.fkl_vh);
      self.set('mindestbewehrungen', betonstahlmattenDB.betonstahlmatten);
      // this.set('minBewehrung', betonstahlmattenDB.betonstahlmatten[0].data);
      self.set('minBewehrung', 0);
      // this.set('bGueteArray', fklDB.fkl_beton);
      // this.set('schalungsmaterial', self.getMaterials([0, 30, 20, 21]));
      // this.set('schalungMaterial', 0);
      // this.set('schalungfklarray', self.getFklassen(0));
      // this.set('schalungFKL', parseInt(self.getDefaultValue(self.get('schalungMaterial'))));

      self.set('f1a', "0.0");
      self.set('f1b', "0.0");
      self.set('f1c', "0.0");
      self.set('f1d', "0.0");
      self.set('f2a', "0.0");
      self.set('f2b', "0.0");
      self.set('f2c', "0.0");
      self.set('f2d', "0.0");

      self.set('schalung', "0.0");

      self.set('breite', "");
      self.set('hoehe', "");
      self.set('bAbstand', "");

      self.setSelectFieldsContent();

      this.send('validation', -1, { target: { name: "foobar" } });
    },

    setValues: function setValues(values) {

      var self = this;

      self.set('material', parseInt(values.MatKeyBeam));
      self.set('ukfkl', parseInt(values.FKLKeyBeam));
      self.set('breite', parseFloat(values.b).toFixed(1));
      this.setX3D(parseFloat(values.b).toFixed(1), { target: { name: "breite" } });

      self.set('hoehe', parseFloat(values.h).toFixed(1));
      this.setX3D(parseFloat(values.h).toFixed(1), { target: { name: "hoehe" } });

      self.set('bAbstand', parseFloat(values.e_Beam).toFixed(1));
      this.setX3D(parseFloat(values.e_Beam).toFixed(1), { target: { name: "bAbstand" } });

      self.set('schalung', parseFloat(values.schalung).toFixed(1));
      this.setX3D(parseFloat(values.schalung).toFixed(1), { target: { name: "schalung" } });

      self.set('schalungMaterial', parseInt(values.MatKeyInterlayer));
      self.set('schalungFKL', parseInt(values.FKLKeyInterlayer));
      self.set('bGuete', parseInt(values.FKLKeyConcrete));
      self.set('bDicke', parseFloat(values.betonDicke).toFixed(1));
      this.setX3D(parseFloat(values.betonDicke).toFixed(1), { target: { name: "bDicke" } });

      self.set('mindestbewehrung', parseFloat(values.mindestBewehrung).toFixed(1));
      self.set('minBewehrung', parseInt(values.mindestBewehrungOption));

      var plattenDurchlaufendSplit = values.PlatteDurchlaufend.split("##");
      self.set('a1durchlaufend', !!parseInt(plattenDurchlaufendSplit[0]));
      self.set('a2durchlaufend', !!parseInt(plattenDurchlaufendSplit[1]));
      self.set('a3durchlaufend', !!parseInt(plattenDurchlaufendSplit[2]));
      self.set('a4durchlaufend', !!parseInt(plattenDurchlaufendSplit[3]));
      self.set('a5durchlaufend', !!parseInt(plattenDurchlaufendSplit[4]));

      var dxSubLeftSplit = values.dX_Sub_left.replace(/,/g, '.').split("##");
      var dxSubRightSplit = values.dX_Sub_right.replace(/,/g, '.').split("##");

      self.set('f1a', parseFloat(dxSubLeftSplit[1]).toFixed(1));
      self.set('f1b', parseFloat(dxSubLeftSplit[2]).toFixed(1));
      self.set('f1c', parseFloat(dxSubLeftSplit[3]).toFixed(1));
      self.set('f1d', parseFloat(dxSubLeftSplit[4]).toFixed(1));

      self.set('f2a', parseFloat(dxSubRightSplit[0]).toFixed(1));
      self.set('f2b', parseFloat(dxSubRightSplit[1]).toFixed(1));
      self.set('f2c', parseFloat(dxSubRightSplit[2]).toFixed(1));
      self.set('f2d', parseFloat(dxSubRightSplit[3]).toFixed(1));

      var aufgehendeBauteileSplit = values.SubWithWall.split("##");
      self.set('aufgehendeBauteile1', !!parseInt(aufgehendeBauteileSplit[0]));
      self.set('aufgehendeBauteile2', !!parseInt(aufgehendeBauteileSplit[1]));
      self.set('aufgehendeBauteile3', !!parseInt(aufgehendeBauteileSplit[2]));
      self.set('aufgehendeBauteile4', !!parseInt(aufgehendeBauteileSplit[3]));
      self.set('aufgehendeBauteile5', !!parseInt(aufgehendeBauteileSplit[4]));

      self.set('openFromFile', true);
      self.send('validation');
      self.set('openFromFile', false);
    },

    setSelectFieldsContent: function setSelectFieldsContent() {

      var self = this;
      self.setUkMaterial();
      self.setUkFKLArray();
      self.setSchalungMaterialArray();
      self.setSchalungfklarray();
      self.setBGueteArray();
    },

    setUkMaterial: function setUkMaterial() {
      var self = this;
      var indices = [];

      var x3d = self.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var grundmasse = x3d.get('bauteile').findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0);

      if (grundmasse.get('deckenkonstruktion') === 'dippelbaum') {
        indices = [0];
      } else {
        indices = [0, 1, 13];
      }

      self.set('ukmaterial', self.getSelectFieldContent('materialienDB', indices));

      // console.log('----- setUkMaterial -----');
      // console.log('ukmaterial', self.get('ukmaterial'));
      // console.log(' ');
    },

    setUkFKLArray: function setUkFKLArray(indices) {
      var self = this;
      var indices = [];

      if (this.get('material') === 1) {
        indices = [20, 21, 24, 22];
        self.set('ukfklarray', self.getSelectFieldContent('fkl_bsh', indices));
        self.set('ukfkl', 20);
      } else if (self.get('material') === 13) {
        indices = [30, 31, 34, 32];
        self.set('ukfklarray', self.getSelectFieldContent('fkl_bsh_kombi', indices));
        self.set('ukfkl', 30);
      } else {
        indices = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
        self.set('ukfklarray', self.getSelectFieldContent('fkl_vh', indices));
        self.set('ukfkl', 5);
      }
    },

    setSchalungMaterialArray: function setSchalungMaterialArray() {
      var self = this;
      var indices = [0, 30, 20, 21];

      self.set('schalungMaterialArray', self.getSelectFieldContent('materialienDB', indices));
    },

    setSchalungfklarray: function setSchalungfklarray(indices) {
      var self = this;
      var indices = [];

      if (self.get('schalungMaterial') === 30) {
        indices = [301];
        self.set('schalungfklarray', self.getSelectFieldContent('fkl_baubuche', indices));
        self.set('schalungFKL', 301);
      } else if (self.get('schalungMaterial') === 20) {
        indices = [125, 126, 127];
        self.set('schalungfklarray', self.getSelectFieldContent('fkl_osb_platten', indices));
        self.set('schalungFKL', 125);
      } else if (self.get('schalungMaterial') === 21) {
        indices = [110, 111, 112, 113];
        self.set('schalungfklarray', self.getSelectFieldContent('fkl_baufurniersperrholz', indices));
        self.set('schalungFKL', 110);
      } else {
        indices = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
        self.set('schalungfklarray', self.getSelectFieldContent('fkl_vh', indices));
        self.set('schalungFKL', 5);
      }
    },

    setBGueteArray: function setBGueteArray() {
      var self = this;
      var indices = [9120, 9125, 9130, 9135, 9140, 9145];

      self.set('bGueteArray', self.getSelectFieldContent('fkl_beton', indices));
    },

    getSelectFieldContent: function getSelectFieldContent(db, indexes) {
      var self = this;
      var tmp = [];
      var dbContent = self.getDBContent(db);
      // console.log('----- dbContent -----');
      // console.log('db: '+ db +' ::: indexes: '+indexes);
      // console.log(dbContent);

      for (var i = 0; i < indexes.length; i++) {
        for (var k = 0; k < dbContent.length; k++) {

          if (dbContent[k].id === indexes[i]) {
            // console.log('dbContent[k].id: '+dbContent[k].id+ '==? indexes[i]: '+indexes[i]);
            // console.log('dbContent[k].name: '+dbContent[k].name);
            // console.log(this.get('i18n').t(dbContent[k].name).toString());
            tmp.push({ id: dbContent[k].id, name: this.get('i18n').t(dbContent[k].name).toString() });
          }
        }
      }

      // console.log('tmp');
      // console.log(tmp);

      return tmp;
    },

    getDBContent: function getDBContent(db) {
      var dbContent = [];
      switch (db) {
        case 'materialienDB':
          dbContent = materialienDB.materialien_de_m0601;
          break;
        case 'fkl_vh':
          dbContent = fklDB_new.fkl_vh.fklassen;
          break;
        case 'fkl_bsh':
          dbContent = fklDB_new.fkl_bsh.fklassen;
          break;
        case 'fkl_bsh_kombi':
          dbContent = fklDB_new.fkl_bsh_kombi.fklassen;
          break;
        case 'fkl_baubuche':
          dbContent = fklDB_new.fkl_baubuche.fklassen;
          break;
        case 'fkl_osb_platten':
          dbContent = fklDB_new.fkl_osb_platten.fklassen;
          break;
        case 'fkl_baufurniersperrholz':
          dbContent = fklDB_new.fkl_baufurniersperrholz.fklassen;
          break;
        case 'fkl_beton':
          dbContent = fklDB_new.fkl_beton.fklassen;
          break;
      }
      // console.log('----- db: '+db+' -----');
      // console.log(dbContent);
      // console.log(' ');
      return dbContent;
    },

    eigengewichtBerechnen: function eigengewichtBerechnen() {

      var self = this;

      var holzmaterial = Number(self.get('material'));
      var hHolz = Number(self.get('hoehe'));
      var bHolz = Number(self.get('breite'));
      var eHolz = Number(self.get('bAbstand'));
      var schalungsMaterial = self.get('schalungMaterial');
      var hSchalung = Number(self.get('schalung'));
      var hBeton = Number(self.get('bDicke'));
      var rohHolz = 0;
      var rohSchalung = 0;
      var rohBeton = 0;

      // console.log('material: '+self.get('material'));
      // console.log('hoehe: '+self.get('hoehe'));
      // console.log('breite: '+self.get('breite'));
      // console.log('bAbstand: '+self.get('bAbstand'));
      // console.log('schalungMaterial: '+self.get('schalungMaterial'));
      // console.log('schalung: '+self.get('schalung'));
      // console.log('bDicke: '+self.get('bDicke'));

      var materialDB = materialienDB.materialien_de_m0601;

      for (var k = 0; k < materialDB.length; k++) {
        if (materialDB[k].id == holzmaterial) {
          rohHolz = materialDB[k].roh_masse;
        }
      }

      for (var k = 0; k < materialDB.length; k++) {
        if (materialDB[k].id == schalungsMaterial) {
          rohSchalung = materialDB[k].roh_masse;
        }
      }

      for (var k = 0; k < materialDB.length; k++) {
        if (materialDB[k].id == 9100) {
          rohBeton = materialDB[k].roh_masse;
        }
      }

      var eigengewicht = 0;

      eigengewicht = (rohHolz * hHolz * bHolz / (eHolz * 100) + rohSchalung * hSchalung / 100 + rohBeton * hBeton / 100).toFixed(2);
      // console.log("######## " + eigengewicht);

      var einwirkung = self.controllerFor('einwirkung');
      Ember['default'].set(einwirkung.lasten[1], 'qLK', eigengewicht);
      Ember['default'].set(einwirkung.lasten[1], 'qRK', eigengewicht);

      var application = self.controllerFor('application');
      var einwirkungdata = application.get('model').einzelneeinwirkung.objectAt(0);
      einwirkungdata.set('val_li', eigengewicht);
      einwirkungdata.set('val_re', eigengewicht);

      var self = this;

      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');

      bauteile.findBy('id', 'last1').get('boxsizes').objectAt(0).set('y', eigengewicht);

      return eigengewicht;
    },

    watchMaterial: (function () {
      // var self = this;
      //
      // this.set('ukfklarray', self.getFklassen(self.get('material')));

      this.setUkFKLArray();
    }).observes('material'),

    watchSchalungsMaterial: (function () {

      // this.set('schalungfklarray', self.getFklassen(self.get('schalungMaterial')));
      // this.set('schalungFKL', parseInt(self.getDefaultValue(self.get('schalungMaterial'))));

      this.setSchalungfklarray();
    }).observes('schalungMaterial'),

    watchComboboxes: (function () {

      var self = this;

      self.controllerFor('supercontroller').resetcpplication();

      var materialien = this.controllerFor('application').get('model').materialien.objectAt(0);

      materialien.set('MatKeyBeam', self.get('material'));
      materialien.set('FKLKeyBeam', self.get('ukfkl'));
      materialien.set('betonGuete', self.get('bGuete'));
      materialien.set('MatKeyConcrete', '9100');
      materialien.set('FKLKeyConcrete', self.get('bGuete'));
      // materialien.set('mindestBewehrungOption', this.get('minBewehrung').name);
      materialien.set('mindestBewehrungOption', self.get('minBewehrung'));

      materialien.set('MatKeyInterlayer', self.get('schalungMaterial'));
      materialien.set('FKLKeyInterlayer', self.get('schalungFKL'));

      // console.log(this.get('minBewehrung').querschnitt1);
    }).observes('material', 'ukfkl', 'bGuete', 'minBewehrung', 'schalungMaterial', 'schalungFKL'),

    watchCheckboxes: (function () {
      var self = this;

      self.controllerFor('supercontroller').resetcpplication();

      var x3d = self.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var x3dGrundmasse = x3d.get('bauteile').findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0);

      var materialien = this.controllerFor('application').get('model').materialien.objectAt(0);

      var a1 = self.get('aufgehendeBauteile1') ? 1 : 0;
      var a2 = self.get('aufgehendeBauteile2') ? 1 : 0;
      var a3 = self.get('aufgehendeBauteile3') ? 1 : 0;
      var a4 = self.get('aufgehendeBauteile4') ? 1 : 0;
      var a5 = self.get('aufgehendeBauteile5') ? 1 : 0;

      x3dGrundmasse.set('aufgehendeBauteil1', self.get('aufgehendeBauteile1'));
      x3dGrundmasse.set('aufgehendeBauteil2', self.get('aufgehendeBauteile2'));
      x3dGrundmasse.set('aufgehendeBauteil3', self.get('aufgehendeBauteile3'));
      x3dGrundmasse.set('aufgehendeBauteil4', self.get('aufgehendeBauteile4'));
      x3dGrundmasse.set('aufgehendeBauteil5', self.get('aufgehendeBauteile5'));

      var pd1 = self.get('a1durchlaufend') ? 1 : 0;
      var pd2 = self.get('a2durchlaufend') ? 1 : 0;
      var pd3 = self.get('a3durchlaufend') ? 1 : 0;
      var pd4 = self.get('a4durchlaufend') ? 1 : 0;
      var pd5 = self.get('a5durchlaufend') ? 1 : 0;

      x3dGrundmasse.set('auflager1Durchlaufend', self.get('a1durchlaufend'));
      x3dGrundmasse.set('auflager2Durchlaufend', self.get('a2durchlaufend'));
      x3dGrundmasse.set('auflager3Durchlaufend', self.get('a3durchlaufend'));
      x3dGrundmasse.set('auflager4Durchlaufend', self.get('a4durchlaufend'));
      x3dGrundmasse.set('auflager5Durchlaufend', self.get('a5durchlaufend'));

      var SubWithWall = a1 + "##" + a2 + "##" + a3 + "##" + a4 + "##" + a5;
      var PlatteDurchlaufend = pd1 + "##" + pd2 + "##" + pd3 + "##" + pd4 + "##" + pd5;

      materialien.set('SubWithWall', SubWithWall);
      materialien.set('PlatteDurchlaufend', PlatteDurchlaufend);

      if (x3d.get('ergebnisGeladen') === true) {
        self.controllerFor('system').resetGrafikToDefault();
      }
      x3d.set('transformHelper', !x3d.get('transformHelper'));
    }).observes('aufgehendeBauteile1', 'aufgehendeBauteile2', 'aufgehendeBauteile3', 'aufgehendeBauteile4', 'aufgehendeBauteile5', 'a1durchlaufend', 'a2durchlaufend', 'a3durchlaufend', 'a4durchlaufend', 'a5durchlaufend'),

    actions: {

      validation: function validation(value, event) {

        var self = this;

        if (!self.openFromFile) {

          this.setX3D(value, event);
        }

        self.validate().then(function () {
          // all validati0ons pass

        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');
          if (validation === true) {
            self.set('displayErrors', false);
            application.set('materialienInvalid', false);

            self.controllerFor('supercontroller').resetcpplication();

            var materialiendata = self.controllerFor('application').get('model').materialien.objectAt(0);

            materialiendata.set('b', parseFloat(self.get('breite')).toFixed(1));
            materialiendata.set('h', parseFloat(self.get('hoehe')).toFixed(1));
            materialiendata.set('e_Beam', parseFloat(self.get('bAbstand')).toFixed(1));
            materialiendata.set('schalung', parseFloat(self.get('schalung')).toFixed(1));
            materialiendata.set('betonDicke', parseFloat(self.get('bDicke')).toFixed(1));
            materialiendata.set('mindestBewehrung', parseFloat(self.get('mindestbewehrung')).toFixed(1));

            var eConL = "0.00##" + parseFloat(self.get('f1a')).toFixed(2).replace('.', ',') + "##" + parseFloat(self.get('f1b')).toFixed(2).replace('.', ',') + "##" + parseFloat(self.get('f1c')).toFixed(2).replace('.', ',') + "##" + parseFloat(self.get('f1d')).toFixed(2).replace('.', ',');
            var eConR = parseFloat(self.get('f2a')).toFixed(2).replace('.', ',') + "##" + parseFloat(self.get('f2b')).toFixed(2).replace('.', ',') + "##" + parseFloat(self.get('f2c')).toFixed(2).replace('.', ',') + "##" + parseFloat(self.get('f2d')).toFixed(2).replace('.', ',') + "##0.00";

            materialiendata.set('dX_Sub_left', eConL);
            materialiendata.set('dX_Sub_right', eConR);

            self.eigengewichtBerechnen();
          } else {
            self.set('displayErrors', true);
            application.set('materialienInvalid', true);
          }
        });
      },

      hoeheIsSelected: function hoeheIsSelected() {
        var hoeheAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('hoeheAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('hoeheAktiv', !hoeheAktiv);
        if (this.get('hoehe') !== "") {
          var hoehework = parseFloat(this.get('hoehe').replace(",", "."));
          var hoeherounded = hoehework.toFixed(1);
          this.set('hoehe', hoeherounded);
          this.send('validation', hoeherounded, {
            target: {
              name: "hoehe"
            }
          });
        }

        if (hoeheAktiv === false) {
          document.getElementsByName('hoehe')[0].setSelectionRange(0, this.get('hoehe').length);
        }
      },

      breiteIsSelected: function breiteIsSelected() {
        var breiteAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('breiteAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('breiteAktiv', !breiteAktiv);
        if (this.get('breite') !== "") {
          var breitework = parseFloat(this.get('breite').replace(",", "."));
          var breiterounded = breitework.toFixed(1);
          this.set('breite', breiterounded);
          this.send('validation', breiterounded, {
            target: {
              name: "breite"
            }
          });
        }

        if (breiteAktiv === false) {
          document.getElementsByName('breite')[0].setSelectionRange(0, this.get('breite').length);
        }
      },

      bAbstandIsSelected: function bAbstandIsSelected() {
        var bAbstandAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('bAbstandAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('bAbstandAktiv', !bAbstandAktiv);
        if (this.get('bAbstand') !== "") {
          var bAbstandwork = parseFloat(this.get('bAbstand').replace(",", "."));
          var bAbstandrounded = bAbstandwork.toFixed(1);
          this.set('bAbstand', bAbstandrounded);
          this.send('validation', bAbstandrounded, {
            target: {
              name: "bAbstand"
            }
          });
        }

        if (bAbstandAktiv === false) {
          document.getElementsByName('bAbstand')[0].setSelectionRange(0, this.get('bAbstand').length);
        }
      },

      schalungIsSelected: function schalungIsSelected() {
        var schalungAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('schalungAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('schalungAktiv', !schalungAktiv);
        if (this.get('schalung') !== "") {
          var schalungwork = parseFloat(this.get('schalung').replace(",", "."));
          var schalungrounded = schalungwork.toFixed(1);
          this.set('schalung', schalungrounded);
          this.send('validation', schalungrounded, {
            target: {
              name: "schalung"
            }
          });
        }

        if (schalungAktiv === false) {
          document.getElementsByName('schalung')[0].setSelectionRange(0, this.get('schalung').length);
        }
      },

      bDickeIsSelected: function bDickeIsSelected() {
        var bDickeAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('bDickeAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('bDickeAktiv', !bDickeAktiv);
        if (this.get('bDicke') !== "") {
          var bDickework = parseFloat(this.get('bDicke').replace(",", "."));
          var bDickerounded = bDickework.toFixed(1);
          this.set('bDicke', bDickerounded);
          this.send('validation', bDickerounded, {
            target: {
              name: "bDicke"
            }
          });
        }

        if (bDickeAktiv === false) {
          document.getElementsByName('bDicke')[0].setSelectionRange(0, this.get('bDicke').length);
        }
      },

      f1aIsSelected: function f1aIsSelected() {
        var f1aAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('f1aAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('f1aAktiv', !f1aAktiv);
        if (this.get('f1a') !== "") {
          var f1awork = parseFloat(this.get('f1a').replace(",", "."));
          var f1arounded = f1awork.toFixed(1);
          this.set('f1a', f1arounded);
          this.send('validation', f1arounded, {
            target: {
              name: "f1a"
            }
          });
        }

        if (f1aAktiv === false) {
          document.getElementsByName('f1a')[0].setSelectionRange(0, this.get('f1a').length);
        }
      },

      f1bIsSelected: function f1bIsSelected() {
        var f1bAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('f1bAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('f1bAktiv', !f1bAktiv);
        if (this.get('f1b') !== "") {
          var f1bwork = parseFloat(this.get('f1b').replace(",", "."));
          var f1brounded = f1bwork.toFixed(1);
          this.set('f1b', f1brounded);
          this.send('validation', f1brounded, {
            target: {
              name: "f1b"
            }
          });
        }

        if (f1bAktiv === false) {
          document.getElementsByName('f1b')[0].setSelectionRange(0, this.get('f1b').length);
        }
      },

      f1cIsSelected: function f1cIsSelected() {
        var f1cAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('f1cAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('f1cAktiv', !f1cAktiv);
        if (this.get('f1c') !== "") {
          var f1cwork = parseFloat(this.get('f1c').replace(",", "."));
          var f1crounded = f1cwork.toFixed(1);
          this.set('f1c', f1crounded);
          this.send('validation', f1crounded, {
            target: {
              name: "f1c"
            }
          });
        }

        if (f1cAktiv === false) {
          document.getElementsByName('f1c')[0].setSelectionRange(0, this.get('f1c').length);
        }
      },

      f1dIsSelected: function f1dIsSelected() {
        var f1dAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('f1dAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('f1dAktiv', !f1dAktiv);
        if (this.get('f1d') !== "") {
          var f1dwork = parseFloat(this.get('f1d').replace(",", "."));
          var f1drounded = f1dwork.toFixed(1);
          this.set('f1d', f1drounded);
          this.send('validation', f1drounded, {
            target: {
              name: "f1d"
            }
          });
        }

        if (f1dAktiv === false) {
          document.getElementsByName('f1d')[0].setSelectionRange(0, this.get('f1d').length);
        }
      },

      f2aIsSelected: function f2aIsSelected() {
        var f2aAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('f2aAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('f2aAktiv', !f2aAktiv);
        if (this.get('f2a') !== "") {
          var f2awork = parseFloat(this.get('f2a').replace(",", "."));
          var f2arounded = f2awork.toFixed(1);
          this.set('f2a', f2arounded);
          this.send('validation', f2arounded, {
            target: {
              name: "f2a"
            }
          });
        }

        if (f2aAktiv === false) {
          document.getElementsByName('f2a')[0].setSelectionRange(0, this.get('f2a').length);
        }
      },

      f2bIsSelected: function f2bIsSelected() {
        var f2bAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('f2bAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('f2bAktiv', !f2bAktiv);
        if (this.get('f2b') !== "") {
          var f2bwork = parseFloat(this.get('f2b').replace(",", "."));
          var f2brounded = f2bwork.toFixed(1);
          this.set('f2b', f2brounded);
          this.send('validation', f2brounded, {
            target: {
              name: "f2b"
            }
          });
        }

        if (f2bAktiv === false) {
          document.getElementsByName('f2b')[0].setSelectionRange(0, this.get('f2b').length);
        }
      },

      f2cIsSelected: function f2cIsSelected() {
        var f2cAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('f2cAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('f2cAktiv', !f2cAktiv);
        if (this.get('f2c') !== "") {
          var f2cwork = parseFloat(this.get('f2c').replace(",", "."));
          var f2crounded = f2cwork.toFixed(1);
          this.set('f2c', f2crounded);
          this.send('validation', f2crounded, {
            target: {
              name: "f2c"
            }
          });
        }

        if (f2cAktiv === false) {
          document.getElementsByName('f2c')[0].setSelectionRange(0, this.get('f2c').length);
        }
      },

      f2dIsSelected: function f2dIsSelected() {
        var f2dAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('f2dAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('f2dAktiv', !f2dAktiv);
        if (this.get('f2d') !== "") {
          var f2dwork = parseFloat(this.get('f2d').replace(",", "."));
          var f2drounded = f2dwork.toFixed(1);
          this.set('f2d', f2drounded);
          this.send('validation', f2drounded, {
            target: {
              name: "f2d"
            }
          });
        }

        if (f2dAktiv === false) {
          document.getElementsByName('f2d')[0].setSelectionRange(0, this.get('f2d').length);
        }
      }

    },

    setX3D: function setX3D(value, event) {
      var self = this;
      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');

      var grundmasse = x3d.get('bauteile').findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0);

      application.set('verbindungsmittelInvalid', true);

      if (x3d.get('ergebnisGeladen') === true) {
        self.controllerFor('system').resetGrafikToDefault();
      }

      switch (event.target.name) {
        case 'breite':
          if (self.errors.breite.length === 0) {
            value = parseFloat(value).toFixed(1);
            bauteile.findBy('id', 'unterkonstruktion1').get('boxsizes').objectAt(0).set('z', value);
            bauteile.findBy('id', 'unterkonstruktion2').get('boxsizes').objectAt(0).set('z', value);

            if (grundmasse.get('deckenkonstruktion') === "dippelbaum") {

              self.set('bAbstand', value);
              bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).set('tiefeUnterkonstruktion', value);
              bauteile.findBy('id', 'beton').get('boxsizes').objectAt(0).set('z', Number(value) + Number(bauteile.findBy('id', 'unterkonstruktion1').get('boxsizes').objectAt(0).get('z')));
              bauteile.findBy('id', 'schalung').get('boxsizes').objectAt(0).set('z', Number(value) + Number(bauteile.findBy('id', 'unterkonstruktion1').get('boxsizes').objectAt(0).get('z')));

              self.set('validations.hoehe.numericality.greaterThanOrEqualTo', value / 2);
              self.set('ttHoehe', self.get('i18n').t('wertebereich') + " " + value / 2 + " - 999.0 [cm]");

              if (Number(self.get('hoehe')) < value / 2) {
                self.set('hoehe', value / 2);
              }

              x3d.set('bAbstandEingetragen', true);

              self.send('validation', -1, { target: { name: "foobar" } });
            } else {
              self.set('validations.hoehe.numericality.greaterThanOrEqualTo', 10);
              self.set('ttHoehe', self.get('i18n').t('wertebereich') + " 10.0 - 999.0 [cm]");
            }
            x3d.set('breiteEingetragen', true);
          } else {
            x3d.set('breiteEingetragen', false);
            if (grundmasse.get('deckenkonstruktion') === "dippelbaum") {
              x3d.set('bAbstandEingetragen', false);
            }
          }
          break;
        case 'hoehe':
          if (self.errors.hoehe.length === 0) {
            value = parseFloat(value).toFixed(1);
            bauteile.findBy('id', 'unterkonstruktion1').get('boxsizes').objectAt(0).set('y', value);
            bauteile.findBy('id', 'unterkonstruktion2').get('boxsizes').objectAt(0).set('y', value);
            x3d.set('hoeheEingetragen', true);
          } else {
            x3d.set('hoeheEingetragen', false);
          }
          break;
        case 'bAbstand':
          if (self.errors.bAbstand.length === 0) {
            value = parseFloat(value).toFixed(1);
            bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).set('tiefeUnterkonstruktion', value);
            bauteile.findBy('id', 'beton').get('boxsizes').objectAt(0).set('z', Number(value) + Number(bauteile.findBy('id', 'unterkonstruktion1').get('boxsizes').objectAt(0).get('z')));
            bauteile.findBy('id', 'schalung').get('boxsizes').objectAt(0).set('z', Number(value) + Number(bauteile.findBy('id', 'unterkonstruktion1').get('boxsizes').objectAt(0).get('z')));
            x3d.set('bAbstandEingetragen', true);
          } else {
            x3d.set('bAbstandEingetragen', false);
          }
          break;
        case 'schalung':
          if (self.errors.schalung.length === 0) {
            value = parseFloat(value).toFixed(1);
            bauteile.findBy('id', 'schalung').get('boxsizes').objectAt(0).set('y', value);
            x3d.set('schalungEingetragen', true);
          } else {
            x3d.set('schalungEingetragen', false);
          }
          break;
        case 'bDicke':
          if (self.errors.bDicke.length === 0) {
            value = parseFloat(value).toFixed(1);
            bauteile.findBy('id', 'beton').get('boxsizes').objectAt(0).set('y', value);
            x3d.set('bDickeEingetragen', true);
          } else {
            x3d.set('bDickeEingetragen', false);
          }
          break;
        case 'f1a':
          if (self.errors.f1a.length === 0) {
            value = parseFloat(value).toFixed(1);
            bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).set('auflager2RandLinks', value);
            x3d.set('f1aEingetragen', true);
          } else {
            x3d.set('f1aEingetragen', false);
          }
          break;
        case 'f1b':
          if (self.errors.f1b.length === 0) {
            value = parseFloat(value).toFixed(1);
            bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).set('auflager3RandLinks', value);
            x3d.set('f1bEingetragen', true);
          } else {
            x3d.set('f1bEingetragen', false);
          }
          break;
        case 'f1c':
          if (self.errors.f1c.length === 0) {
            value = parseFloat(value).toFixed(1);
            bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).set('auflager4RandLinks', value);
            x3d.set('f1cEingetragen', true);
          } else {
            x3d.set('f1cEingetragen', false);
          }
          break;
        case 'f1d':
          if (self.errors.f1d.length === 0) {
            value = parseFloat(value).toFixed(1);
            bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).set('auflager5RandLinks', value);
            x3d.set('f1dEingetragen', true);
          } else {
            x3d.set('f1dEingetragen', false);
          }
          break;
        case 'f2a':
          if (self.errors.f2a.length === 0) {
            value = parseFloat(value).toFixed(1);
            bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).set('auflager1RandRechts', value);
            x3d.set('f2aEingetragen', true);
          } else {
            x3d.set('f2aEingetragen', false);
          }
          break;
        case 'f2b':
          if (self.errors.f2b.length === 0) {
            value = parseFloat(value).toFixed(1);
            bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).set('auflager2RandRechts', value);
            x3d.set('f2bEingetragen', true);
          } else {
            x3d.set('f2bEingetragen', false);
          }
          break;
        case 'f2c':
          if (self.errors.f2c.length === 0) {
            value = parseFloat(value).toFixed(1);
            bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).set('auflager3RandRechts', value);
            x3d.set('f2cEingetragen', true);
          } else {
            x3d.set('f2cEingetragen', false);
          }
          break;
        case 'f2d':
          if (self.errors.f2d.length === 0) {
            value = parseFloat(value).toFixed(1);
            bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).set('auflager4RandRechts', value);
            x3d.set('f2dEingetragen', true);
          } else {
            x3d.set('f2dEingetragen', false);
          }
          break;
      }

      x3d.set('lastenTrigger', !x3d.get('lastenTrigger'));
      x3d.set('transformHelper', !x3d.get('transformHelper'));
      self.controllerFor('application').zentriertObjekt();
    }

  });
  // inputDisabled: function () {
  //   var self = this;
  //   var value = false;
  //
  //   var grundmasse = self.controllerFor('application').get('model').x3ddefault.objectAt(0).get('bauteile').findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0);
  //
  //   if(grundmasse.get('deckenkonstruktion') === "holzelement" ){
  //     value = true;
  //     self.set('breite', "100.0");
  //     self.set('bAbstand', "100.0");
  //   }
  //
  //   console.log("+ breite: "+self.get('breite'));
  //   console.log("+ bAbstand: "+self.get('bAbstand'));
  //
  //   return value;
  // }.property('disableInput')

});