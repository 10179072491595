define('m0601/router', ['exports', 'ember', 'm0601/config/environment'], function (exports, Ember, config) {

  'use strict';

  var Router = Ember['default'].Router.extend({
    location: config['default'].locationType
  });

  Router.reopen({
    location: 'history'
  });

  Router.map(function () {
    this.route('anschlussgeometrie');
    this.route('bauteile');
    this.route('ergebnis');
    this.route('lasteinwirkung');
    this.route('projektdaten');
    this.route('verbindungsmittel');

    // this.resource('projektdatens', {path: '/'}, function(){});
    this.route('impressum');
    this.route('printview');
    this.route('contact');
    this.route('dachgeometrie');
    this.route('unterkonstruktion');
    this.route('daemmung');
    this.route('downloadcenter');
    this.route('system');
    this.route('auflager');
    this.route('konstruktion');
    this.route('materialien');
    this.route('einwirkung');
    this.route('schwingungsnachweis');
    this.route('optionen');
    this.route('schraubenvorauswahl');
  });

  exports['default'] = Router;

});