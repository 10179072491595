define('m0601/components/appearanc-e0', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Appearance = Ember['default'].Component.extend({
    tagName: 'appearance',
    layoutName: 'appearanc-e0',
    attributeBindings: ['sorttype'],
    sorttype: "auto"
  });

  exports['default'] = Appearance;

});