define('m0601/models/optionen', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	var optionen = DS['default'].Model.extend({

		n90min: DS['default'].attr('string'),
		n90max: DS['default'].attr('string'),
		anzahlVmNebeneinanderGerade: DS['default'].attr('boolean'),
		mindestanzahlSchubverbinderLinks: DS['default'].attr('string'),
		mindestanzahlSchubverbinderRechts: DS['default'].attr('string')

	});

	optionen.reopenClass({
		FIXTURES: [{
			id: 1,
			n90min: '',
			n90max: '',
			anzahlVmNebeneinanderGerade: false,
			mindestanzahlSchubverbinderLinks: '',
			mindestanzahlSchubverbinderRechts: ''
		}]
	});

	exports['default'] = optionen;

});