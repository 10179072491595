define('m0601/components/kled-menu', ['exports', 'ember', 'ember-i18n'], function (exports, Ember, ember_i18n) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    i18n: Ember['default'].inject.service(),
    kled1: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t(this.get('dauer'));
    }),

    actions: {
      setKLED: function setKLED(last, kled, textid) {

        console.log("***** setKLED component *****");

        var self = this;

        var lastnrpara = this.get('lastnr');

        console.log(last);
        console.log(lastnrpara);
        console.log(kled);
        console.log("vor sendAction");
        this.sendAction('setKLED', lastnrpara, kled, textid, last);
        console.log("nach sendAction");
        self.set('kled1', self.get('i18n').t(kled));
      }
    }
  });

});