define('m0601/components/las-t', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Transform = Ember['default'].Component.extend({

    tagName: 'transform',
    layoutName: 'las-t',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    objektSkalierung: 1,

    translation: (function () {
      var name = this.get('name');
      var x3d = this.get('model');

      var item = x3d.objectAt(0).get('bauteile').findBy('id', name);

      var x = item.get('translations').objectAt(0).get('x');
      var y = item.get('translations').objectAt(0).get('y');
      var z = item.get('translations').objectAt(0).get('z');

      var cords = x + ' ' + y + ' ' + z;

      return cords;
    }).property('model.firstObject.transformHelper'),

    rotation: (function () {
      var name = this.get('name');
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var values = '0 0 0 0';

      return values;
    }).property('model.firstObject.transformHelper'),

    koordinaten: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      var x = 0;
      var y = 0;
      var z = 0;

      var cords;

      var item = x3d.get('bauteile').findBy('id', name);

      if (item.get('lastProperties').objectAt(0).get('lastart') === 'gleichlast') {
        cords = this.getGleichlastKoordinaten();
      } else if (item.get('lastProperties').objectAt(0).get('lastart') === 'trapezlast') {
        cords = this.getTrapezlastKoordinaten();
      } else if (item.get('lastProperties').objectAt(0).get('lastart') === 'einzellast') {
        cords = this.getEinzellastKoordinaten();
      }

      return cords;
    }).property('model.firstObject.transformHelper', 'model.firstObject.lastenTrigger'),

    koordinatenIndex: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var values = "0 6 -1 1 5 -1 10 16 -1 11 15 -1 0 1 11 10 0 -1 9 2 12 19 9 -1 8 3 13 18 8 -1 7 4 14 17 7 -1 6 5 15 16 6 -1";

      var item = x3d.objectAt(0).get('bauteile').findBy('id', name);

      if (item.get('lastProperties').objectAt(0).get('lastart') === 'trapezlast') {
        values = "0 5 -1 1 4 -1 8 13 -1 9 12 -1 0 1 9 8 0 -1 7 2 10 15 7 -1 6 3 11 14 6 -1 5 4 12 13 5";
      } else if (item.get('lastProperties').objectAt(0).get('lastart') === 'einzellast') {
        values = "0 1 2 3 0";
      }

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt'),

    emissivecolor: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var bauteile = this.get('model').objectAt(0).get('bauteile');

      var emsvcolor = bauteile.findBy('id', name).get('diffusecolors').objectAt(0).get('rot') + ' ' + bauteile.findBy('id', name).get('diffusecolors').objectAt(0).get('gruen') + ' ' + bauteile.findBy('id', name).get('diffusecolors').objectAt(0).get('blau');

      return emsvcolor;
    }).property(''),

    getGleichlastKoordinaten: function getGleichlastKoordinaten() {
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var name = this.get('name');

      // var x = bauteile.findBy('id',name).get('boxsizes').objectAt(0).get('x')/40;
      var x = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 40;
      var y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y');
      var z = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('tiefeUnterkonstruktion') / 10;

      var p0 = -x * 2 + ' ' + 0 + ' ' + -z / 2;
      var p1 = -x * 2 + ' ' + 0 + ' ' + z / 2;
      var p2 = -x + ' ' + 0 + ' ' + z / 2;
      var p3 = 0 + ' ' + 0 + ' ' + z / 2;
      var p4 = x + ' ' + 0 + ' ' + z / 2;
      var p5 = x * 2 + ' ' + 0 + ' ' + z / 2;
      var p6 = x * 2 + ' ' + 0 + ' ' + -z / 2;
      var p7 = x + ' ' + 0 + ' ' + -z / 2;
      var p8 = 0 + ' ' + 0 + ' ' + -z / 2;
      var p9 = -x + ' ' + 0 + ' ' + -z / 2;
      var p10 = -x * 2 + ' ' + y + ' ' + -z / 2;
      var p11 = -x * 2 + ' ' + y + ' ' + z / 2;
      var p12 = -x + ' ' + y + ' ' + z / 2;
      var p13 = 0 + ' ' + y + ' ' + z / 2;
      var p14 = x + ' ' + y + ' ' + z / 2;
      var p15 = x * 2 + ' ' + y + ' ' + z / 2;
      var p16 = x * 2 + ' ' + y + ' ' + -z / 2;
      var p17 = x + ' ' + y + ' ' + -z / 2;
      var p18 = 0 + ' ' + y + ' ' + -z / 2;
      var p19 = -x + ' ' + y + ' ' + -z / 2;

      return p0 + ' ' + p1 + ' ' + p2 + ' ' + p3 + ' ' + p4 + ' ' + p5 + ' ' + p6 + ' ' + p7 + ' ' + p8 + ' ' + p9 + ' ' + p10 + ' ' + p11 + ' ' + p12 + ' ' + p13 + ' ' + p14 + ' ' + p15 + ' ' + p16 + ' ' + p17 + ' ' + p18 + ' ' + p19;
    },

    getTrapezlastKoordinaten: function getTrapezlastKoordinaten() {
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var name = this.get('name');

      var x = Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x')) / 30;
      var y1 = Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y'));
      var y2 = Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z'));
      var z = Number(bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('tiefeUnterkonstruktion')) / 10;

      var yMin = y1;
      var xFaktor = -1;

      if (y2 < y1) {
        yMin = y2;
        xFaktor = 1;
      }

      var p0 = -x * 1.5 + ' ' + 0 + ' ' + -z / 2;
      var p1 = -x * 1.5 + ' ' + 0 + ' ' + z / 2;
      var p2 = -x * 0.5 * xFaktor + ' ' + 0 + ' ' + z / 2;
      var p3 = x * 0.5 * xFaktor + ' ' + 0 + ' ' + z / 2;
      var p4 = x * 1.5 + ' ' + 0 + ' ' + z / 2;
      var p5 = x * 1.5 + ' ' + 0 + ' ' + -z / 2;
      var p6 = x * 0.5 * xFaktor + ' ' + 0 + ' ' + -z / 2;
      var p7 = -x * 0.5 * xFaktor + ' ' + 0 + ' ' + -z / 2;

      var p8 = -x * 1.5 + ' ' + y1 + ' ' + -z / 2;
      var p9 = -x * 1.5 + ' ' + y1 + ' ' + z / 2;
      var p10 = -x * 0.5 * xFaktor + ' ' + (yMin + Math.abs(y2 - y1) / (3 * x) * 2 * x) + ' ' + z / 2;
      var p11 = x * 0.5 * xFaktor + ' ' + (yMin + Math.abs(y2 - y1) / (3 * x) * x) + ' ' + z / 2;
      var p12 = x * 1.5 + ' ' + y2 + ' ' + z / 2;
      var p13 = x * 1.5 + ' ' + y2 + ' ' + -z / 2;
      var p14 = x * 0.5 * xFaktor + ' ' + (yMin + Math.abs(y2 - y1) / (3 * x) * x) + ' ' + -z / 2;
      var p15 = -x * 0.5 * xFaktor + ' ' + (yMin + Math.abs(y2 - y1) / (3 * x) * 2 * x) + ' ' + -z / 2;

      return p0 + ' ' + p1 + ' ' + p2 + ' ' + p3 + ' ' + p4 + ' ' + p5 + ' ' + p6 + ' ' + p7 + ' ' + p8 + ' ' + p9 + ' ' + p10 + ' ' + p11 + ' ' + p12 + ' ' + p13 + ' ' + p14 + ' ' + p15;
    },

    getEinzellastKoordinaten: function getEinzellastKoordinaten() {
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var name = this.get('name');

      var x = 0;
      var y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y');
      var z = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('tiefeUnterkonstruktion') / 10;

      var p0 = x + ' ' + 0 + ' ' + -z / 2;
      var p1 = x + ' ' + 0 + ' ' + z / 2;
      var p2 = x + ' ' + y + ' ' + z / 2;
      var p3 = x + ' ' + y + ' ' + -z / 2;

      return p0 + ' ' + p1 + ' ' + p2 + ' ' + p3;
    },

    cones: (function () {
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var name = this.get('name');

      var item = bauteile.findBy('id', name);
      var cones = new Array();
      var c0, c1, c2, c3, c4, c5, c6, c7, c8, c9, x, z;
      var y = 0.1;

      if (item.get('lastProperties').objectAt(0).get('lastart') === 'gleichlast') {

        x = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 40;
        z = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('tiefeUnterkonstruktion') / 10;

        c0 = { id: 0, translation: -x * 2 + ' ' + y + ' ' + -z / 2 };
        c1 = { id: 1, translation: -x * 2 + ' ' + y + ' ' + z / 2 };
        c2 = { id: 2, translation: -x + ' ' + y + ' ' + z / 2 };
        c3 = { id: 3, translation: 0 + ' ' + y + ' ' + z / 2 };
        c4 = { id: 4, translation: x + ' ' + y + ' ' + z / 2 };
        c5 = { id: 5, translation: x * 2 + ' ' + y + ' ' + z / 2 };
        c6 = { id: 6, translation: x * 2 + ' ' + y + ' ' + -z / 2 };
        c7 = { id: 7, translation: x + ' ' + y + ' ' + -z / 2 };
        c8 = { id: 8, translation: 0 + ' ' + y + ' ' + -z / 2 };
        c9 = { id: 9, translation: -x + ' ' + y + ' ' + -z / 2 };

        cones.push(c0, c1, c2, c3, c4, c5, c6, c7, c8, c9);
      } else if (item.get('lastProperties').objectAt(0).get('lastart') === 'trapezlast') {

        x = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 30;
        z = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('tiefeUnterkonstruktion') / 10;

        c0 = { id: 0, translation: -x * 1.5 + ' ' + y + ' ' + -z / 2 };
        c1 = { id: 1, translation: -x * 1.5 + ' ' + y + ' ' + z / 2 };
        c2 = { id: 2, translation: -x * 0.5 + ' ' + y + ' ' + z / 2 };
        c3 = { id: 3, translation: x * 0.5 + ' ' + y + ' ' + z / 2 };
        c4 = { id: 4, translation: x * 1.5 + ' ' + y + ' ' + z / 2 };
        c5 = { id: 5, translation: x * 1.5 + ' ' + y + ' ' + -z / 2 };
        c6 = { id: 6, translation: x * 0.5 + ' ' + y + ' ' + -z / 2 };
        c7 = { id: 7, translation: -x * 0.5 + ' ' + y + ' ' + -z / 2 };

        cones.push(c0, c1, c2, c3, c4, c5, c6, c7);
      } else if (item.get('lastProperties').objectAt(0).get('lastart') === 'einzellast') {
        x = 0;
        z = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('tiefeUnterkonstruktion') / 10;

        c0 = { id: 0, translation: x + ' ' + y + ' ' + -z / 2 };
        c1 = { id: 1, translation: x + ' ' + y + ' ' + z / 2 };

        cones.push(c0, c1);
      }

      return cones;
    }).property('')

  });

  exports['default'] = Transform;

});