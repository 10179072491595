define('m0601/models/einzelneeinwirkung', ['exports', 'ember-data'], function (exports, DS) {

    'use strict';

    var einzelneeinwirkung = DS['default'].Model.extend({
        LastTypID: DS['default'].attr('string'),
        Beschreibung: DS['default'].attr('string'),
        KLEDGrpID: DS['default'].attr('string'),
        LastGrpID: DS['default'].attr('string'),
        x_li: DS['default'].attr('string'),
        x_re: DS['default'].attr('string'),
        val_li: DS['default'].attr('string'),
        val_re: DS['default'].attr('string'),
        Multiplikator: DS['default'].attr('string'), // gibts eigentlich nicht mehr
        EinwirkungID: DS['default'].attr('string')

    });

    einzelneeinwirkung.reopenClass({
        FIXTURES: [{
            id: 1,
            LastTypID: "1",
            Beschreibung: "Eigenlast",
            KLEDGrpID: "201",
            LastGrpID: 1,
            x_li: "0.0",
            x_re: "0.0",
            val_li: "0.0",
            val_re: "0.0",
            Multiplikator: "1",
            EinwirkungID: "201"
        }]
    });

    exports['default'] = einzelneeinwirkung;

});