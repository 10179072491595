define('m0601/routes/schwingungsnachweis', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    i18n: Ember['default'].inject.service(),
    hn: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('haupt_nebentraeger');
    }),

    model: function model() {
      return this.store.find('schwingungsnachweis');
    },

    setupController: function setupController() {
      this.controllerFor('application').miniertX3D();

      var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var lastid = x3d.get('lastInBearbeitung');
      x3d.get('bauteile').findBy('id', lastid).get('lastProperties').objectAt(0).set('editieren', false);
      x3d.set('lastenTrigger', !x3d.get('lastenTrigger'));
    }

  });

});