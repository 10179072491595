define('m0601/controllers/lasteinwirkung', ['exports', 'ember', 'ember-validations', 'ember-i18n'], function (exports, Ember, EmberValidations, ember_i18n) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {
    debug: true,
    openFromFile: false,
    displayErrors: true,
    loading: false,
    bmlast: "",
    verbindungsmittelliste: "",
    nkl: 1,
    led: 1,

    slz: 1,
    wlz: 1,
    dacheindeckung: 1,
    gelaendehoehe: "",
    geoDatenManuell: false,

    ndte: false,
    schneefanggitter: false,

    berechneteWindlast: "DYNAMISCH BERECHNETE WINDLAST",
    berechneteSchneelast: "DYNAMISCH BERECHNETE SCHNEELAST",

    g: 1,
    sk: 1,
    q: 1,

    manuelleDacheindeckung: false,
    manuelleSLZ: false,
    manuelleWLZ: false,

    ttBmLast: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0.01 - 999.0 [kN]";
    }),
    i18n: Ember['default'].inject.service(),

    validations: {
      g: {
        numericality: {
          greaterThan: 0,
          lessThanOrEqualTo: 9999.0
        }
      },
      sk: {
        numericality: {
          greaterThan: 0,
          lessThanOrEqualTo: 9999.0
        }
      },
      gelaendehoehe: {
        numericality: {
          greaterThan: 0,
          lessThanOrEqualTo: 2000.0
        }
      },
      q: {
        numericality: {
          greaterThan: 0,
          lessThanOrEqualTo: 9999.0
        }
      }
    },

    ttG: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0 - 9999.0 [N/m²]";
    }),
    ttSK: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0 - 9999.0 [kN/m²]";
    }),
    ttHuNN: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0 - 2000 [m]";
    }),
    ttQ: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0 - 9999.0 [kN/m²]";
    }),

    dacheindeckungcontent: [{
      id: 1,
      name: "Dfl. Metalldach/Schindeldach/Wellzement, 300 N/m²"
    }, {
      id: 2,
      name: "Dfl. Dachziegel/Dachsteine, Schiefer, 550 N/m²"
    }, {
      id: 3,
      name: "Dfl. Biberdoppeldeckung, 750 N/m²"
    }, {
      id: 4,
      name: "Manuelle Eingabe des Eigengewichts"
    }],

    slzarray: [{
      id: 1,
      name: "SLZ 1"
    }, {
      id: 2,
      name: "SLZ 1a"
    }, {
      id: 3,
      name: "SLZ 2"
    }, {
      id: 4,
      name: "SLZ 2a"
    }, {
      id: 5,
      name: "SLZ 3"
    }, {
      id: 5,
      name: "Manuelle Eingabe der charakteristischen Schneelast"
    }],

    wlzarray: [{
      id: 1,
      name: "WLZ 1"
    }, {
      id: 2,
      name: "WLZ 2"
    }, {
      id: 3,
      name: "WLZ 3"
    }, {
      id: 4,
      name: "WLZ 4"
    }, {
      id: 5,
      name: "Manuelle Eingabe des Böengeschwindigkeitsdrucks"
    }],

    setValues: function setValues(values) {
      if (self.debug) {
        console.log("values in lasteinwirkung: " + values);
      }

      this.set('openFromFile', true);

      this.set('nkl', parseInt(values.NKL_ID));
      this.set('led', parseInt(values.KLED_ID));
      this.set('bmlast', values.V_d);
      this.send('validation');

      this.set('openFromFile', false);
    },

    watchNumericalInputs: (function () {
      if (!this.openFromFile) {
        if (this.get('bmlast').indexOf(",") !== -1) {
          this.set('bmlast', this.get('bmlast').replace(",", "."));
        }
      }
    }).observes('bmlast'),

    watchGeodaten: (function () {
      var self = this;

      self.set('geoDatenManuell', true);
    }).observes('slz', 'wlz', 'gelaendehoehe', 'ndte', 'schneefanggitter', 'sk', 'q'),

    watchGeodatenManuell: (function () {
      var self = this;

      var lasteinwirkung = this.controllerFor('application').get('model').lasteinwirkung.objectAt(0);
      lasteinwirkung.set('geoDatenManuell', self.get('geoDatenManuell'));
    }).observes('geoDatenManuell'),

    watchComboboxes: (function () {

      var self = this;

      var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      applicationdata.set('treeLoaded', false);
      applicationdata.set('initialized', false);
      applicationdata.set('pdfErstellt', false);
      applicationdata.set('dxfErstellt', false);

      var application = self.controllerFor('application');
      application.set('verbindungsmittelInvalid', true);

      var lasteinwirkung = this.controllerFor('application').get('model').lasteinwirkung.objectAt(0);

      console.log(self.get('dacheindeckung'));

      if (this.get('dacheindeckung') == 4) {
        this.set('manuelleDacheindeckung', true);
      } else {
        this.set('manuelleDacheindeckung', false);
      }

      if (this.get('slz') == 5) {
        this.set('manuelleSLZ', true);
      } else {
        this.set('manuelleSLZ', false);
      }

      if (this.get('wlz') == 5) {
        this.set('manuelleWLZ', true);
      } else {
        this.set('manuelleWLZ', false);
      }

      // werte aus comboboxes ins model schreiben
      lasteinwirkung.set('NKL_ID', this.get('nkl'));

      lasteinwirkung.set('dacheindeckung', this.get('dacheindeckung'));
      lasteinwirkung.set('SLZ_ID', this.get('slz'));
      lasteinwirkung.set('WLZ_ID', this.get('wlz'));
    }).observes('dacheindeckung', 'slz', 'wlz'),

    watchCheckboxes: (function () {

      var self = this;

      var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      applicationdata.set('treeLoaded', false);
      applicationdata.set('initialized', false);
      applicationdata.set('pdfErstellt', false);
      applicationdata.set('dxfErstellt', false);

      var application = self.controllerFor('application');
      application.set('verbindungsmittelInvalid', true);

      var lasteinwirkung = this.controllerFor('application').get('model').lasteinwirkung.objectAt(0);

      lasteinwirkung.set('NDTE', this.get('ndte'));
      lasteinwirkung.set('Schneefanggitter', this.get('schneefanggitter'));
    }).observes('ndte', 'schneefanggitter'),

    setX3D: function setX3D(value, event) {

      var application = this.controllerFor('application');
      var x3ddefault = application.get('model').x3ddefault.objectAt(0);

      switch (event.target.name) {
        case 'bmlast':
          console.log('errors Anschlussgeometrie');
          console.log(this);
          console.log(this.errors);
          console.log(this.errors.get('rwinkelv'));
          if (this.errors.get('bmlast').length === 0) {
            x3ddefault.set('bemessungslast', value);
          }
          break;
      }

      application.get('model').x3ddefault.objectAt(0).set('transformHelper', !this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('transformHelper'));
    },

    actions: {
      proceedInput: function proceedInput(event) {

        var self = this;

        //var ledText = self.ledarray[self.get('led')].name;
        //var nklText = self.nklarray[self.get('nkl')].name;

        var validation = self.get('isValid');

        // if (validation === true) {
        //
        //   var lasteinwirkung = self.store.createRecord('lasteinwirkung', {
        //
        //     KLED_ID: self.get('led'),
        //     LED_Text: ledText,
        //     NKL_ID: self.get('nkl'),
        //     NKL_Text: nklText,
        //     V_d: self.get('bmlast').replace(",", ".")
        //
        //   });
        // }

        self.validate().then(function () {
          // all validations pass
          this.transitionToRoute('verbindungsmittel');
        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');
          if (validation === true) {

            var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
            applicationdata.set('treeLoaded', false);
            applicationdata.set('initialized', false);
            applicationdata.set('pdfErstellt', false);
            applicationdata.set('dxfErstellt', false);

            application.set('verbindungsmittelInvalid', true);

            self.set('displayErrors', false);
            application.set('lasteinwirkungInvalid', false);

            //var applicationdata = application.model.objectAt(0);

            //universal
            var applicationdata = application.get('model').application.objectAt(0);
            var applicationdataJSON = JSON.stringify(applicationdata);

            var projektdatendata = application.get('model').projektdaten.objectAt(0);
            var projektdatendataJSON = JSON.stringify(projektdatendata);

            //m06

            var bauteiledata = application.get('model').bauteile.objectAt(0);
            var bauteiledataJSON = JSON.stringify(bauteiledata);

            var anschlussgeometriedata = application.get('model').anschlussgeometrie.objectAt(0);
            var anschlussgeometriedataJSON = JSON.stringify(anschlussgeometriedata);

            //m01

            var dachgeometriedata = application.get('model').dachgeometrie.objectAt(0);
            var dachgeometriedataJSON = JSON.stringify(dachgeometriedata);

            var unterkonstruktiondata = application.get('model').unterkonstruktion.objectAt(0);
            var unterkonstruktiondataJSON = JSON.stringify(unterkonstruktiondata);

            var daemmungdata = application.get('model').daemmung.objectAt(0);
            var daemmungdataJSON = JSON.stringify(daemmungdata);

            //universal

            var lasteinwirkungdata = application.get('model').lasteinwirkung.objectAt(0);
            var lasteinwirkungdataJSON = JSON.stringify(lasteinwirkungdata);

            var uebergabedaten = $.extend(true, JSON.parse(dachgeometriedataJSON), JSON.parse(unterkonstruktiondataJSON), JSON.parse(daemmungdataJSON), JSON.parse(lasteinwirkungdataJSON));

            //Lastannahmen/Parameter_Strukt.vb

            var timberelementgl = {
              "Querschnittswerte": {
                "t_Fastener": 0, // -> gl hoehe
                "b": daemmungdata.get('glBreite'),
                "h": daemmungdata.get('glHoehe')
              }
            };
            var timberelementsp = {
              "Querschnittswerte": {
                "b": unterkonstruktiondata.get('TimberElementSp_b'),
                "h": unterkonstruktiondata.get('TimberElementSp_h')
              }
            };
            var schneelastparas = {
              "SLZ_ID": lasteinwirkungdata.get('SLZ_ID'),
              "SLZ_Text": self.slzarray[lasteinwirkungdata.get('SLZ_ID')].name, //inhalt der comobox
              "Region_ID": "Region ID", // nicht notwendig
              "RegionText": "Region Text", // nicht notwendig
              "WindgeschuetzteLage": "Windgeschuetzte Lage", // nicht notwendig
              "Fussnote": "Fussnote", // nicht notwendig
              "Zone": "Zone", // nur bei alten lastannahmen notwendig
              "A": lasteinwirkungdata.get('HuNN'), // HuNN
              "sk": lasteinwirkungdata.get('sk'),
              "DF_1": {
                "ALFA_Grad": dachgeometriedata.get('ALFA_Grad'),
                "Schneefanggitter": lasteinwirkungdata.get('Schneefanggitter')
              }
            };

            uebergabedaten.TimberElementGl = timberelementgl;
            uebergabedaten.TimberElementSp = timberelementsp;
            uebergabedaten.Schneelastparameter = schneelastparas;

            console.log(JSON.stringify(uebergabedaten));

            var JSONdata = JSON.stringify(uebergabedaten);

            if (self.debug) {
              console.log(JSONdata);
            }

            var vmittel = self.controllerFor('verbindungsmittel');
            vmittel.set('loading', true);

            self.set('loading', true);
            self.transitionToRoute('verbindungsmittel');

            var initialized = applicationdata.get('initialized');

            if (!initialized) {

              $.ajax({
                type: "POST",
                url: "http://136.243.4.143/berechnungsservice/m01/DE/initialisiereBerechnung/" + self.controllerFor('supercontroller').erzeugeAufrufzusatz(false),
                //url: "http://136.243.4.143/berechnungsservice/m06/DE/erzeugeSchraubenliste/",
                data: {
                  kennung: applicationdataJSON,
                  // projektdaten: projektdatendataJSON,
                  paras: JSONdata,
                  modul: "m01",
                  pfad: "D:/swgengineering/bin/"
                }
              }).done(function (data, statusText, xhr) {

                self.controllerFor('supercontroller').logaufruf("initialisiereBerechnung", xhr.status);

                var cid = data;
                applicationdata.set('Calculation_ID', cid);
                applicationdata.set('initialized', true);

                $.ajax({
                  type: "POST",
                  url: "http://136.243.4.143/berechnungsservice/m01/DE/erzeugeSchraubenliste/" + self.controllerFor('supercontroller').erzeugeAufrufzusatz(false),
                  data: {
                    cid: cid
                  }
                }).done(function (data, statusText, xhr) {

                  self.controllerFor('supercontroller').logaufruf("erzeugeSchraubenliste", xhr.status);

                  console.log(data);

                  if (Number(JSON.parse(data).Item1) === 0) {
                    var liste = JSON.parse(JSON.parse(data).Item2);
                    self.verbindungsmittelliste = liste;
                    var core = treetools.buildTree(liste, true);
                    vmittel.set('schraubenliste', core);
                    vmittel.set('keineSchraubenliste', false);
                  } else if (Number(JSON.parse(data).Item1) === 1) {
                    application.set('verbindungsmittelInvalid', false);
                    application.get('model').x3ddefault.objectAt(0).set('ergebnisGeladen', true);
                    vmittel.set('infoText', JSON.parse(data).Item3);
                    vmittel.set('keineSchraubenliste', true);
                  }

                  vmittel.set('calcid', cid);
                  vmittel.set('loading', false);
                  applicationdata.set('treeLoaded', true);

                  self.set('loading', false);
                });
              });
            } else {

              var cid = applicationdata.get('Calculation_ID');

              $.ajax({
                type: "POST",
                url: "http://136.243.4.143/berechnungsservice/m01/DE/erzeugeSchraubenliste/" + self.controllerFor('supercontroller').erzeugeAufrufzusatz(false),
                data: {
                  cid: cid
                }
              }).done(function (data, statusText, xhr) {

                self.controllerFor('supercontroller').logaufruf("erzeugeSchraubenliste", xhr.status);

                console.log('DATA ' + data);

                if (Number(JSON.parse(data).Item1) === 0) {
                  var liste = JSON.parse(JSON.parse(data).Item2);
                  self.verbindungsmittelliste = liste;
                  var core = treetools.buildTree(liste, true);
                  vmittel.set('schraubenliste', core);
                  vmittel.set('keineSchraubenliste', false);
                } else if (Number(JSON.parse(data).Item1) === 1) {
                  application.set('verbindungsmittelInvalid', false);
                  application.get('model').x3ddefault.objectAt(0).set('ergebnisGeladen', true);
                  vmittel.set('infoText', JSON.parse(data).Item3);
                  vmittel.set('keineSchraubenliste', true);
                }

                vmittel.set('calcid', cid);
                vmittel.set('loading', false);
                applicationdata.set('treeLoaded', true);

                self.set('loading', false);
              });
            }
          } else {
            self.set('displayErrors', true);
            application.set('lasteinwirkungInvalid', true);
          }
        });
      },

      validation: function validation(value, event) {

        var self = this;

        if (!self.openFromFile) {

          this.setX3D(value, event);
        }

        self.validate().then(function () {
          // all validations pass

        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');
          if (validation === true) {
            self.set('displayErrors', false);
            application.set('lasteinwirkungInvalid', false);

            var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
            applicationdata.set('treeLoaded', false);
            applicationdata.set('initialized', false);

            application.set('verbindungsmittelInvalid', true);

            var lasteinwirkung = self.controllerFor('application').get('model').lasteinwirkung.objectAt(0);

            lasteinwirkung.set('V_d', parseFloat(self.get('bmlast')).toFixed(1));
            // self.set('bmlast', parseFloat(self.get('bmlast').replace(",", ".")).toFixed(1));

            lasteinwirkung.set('beschreibung', self.get('beschreibung'));
            lasteinwirkung.set('g', parseFloat(self.get('g')).toFixed(1));
            lasteinwirkung.set('sk', parseFloat(self.get('sk')).toFixed(1));
            lasteinwirkung.set('HuNN', self.get('gelaendehoehe'));
            lasteinwirkung.set('q', parseFloat(self.get('q')).toFixed(1));

            self.set('displayErrors', false);
            application.set('lasteinwirkungInvalid', false);

            //universal
            var applicationdata = application.get('model').application.objectAt(0);
            var applicationdataJSON = JSON.stringify(applicationdata);

            var projektdatendata = application.get('model').projektdaten.objectAt(0);
            var projektdatendataJSON = JSON.stringify(projektdatendata);

            //m06

            var bauteiledata = application.get('model').bauteile.objectAt(0);
            var bauteiledataJSON = JSON.stringify(bauteiledata);

            var anschlussgeometriedata = application.get('model').anschlussgeometrie.objectAt(0);
            var anschlussgeometriedataJSON = JSON.stringify(anschlussgeometriedata);

            //m01

            var dachgeometriedata = application.get('model').dachgeometrie.objectAt(0);
            var dachgeometriedataJSON = JSON.stringify(dachgeometriedata);

            var unterkonstruktiondata = application.get('model').unterkonstruktion.objectAt(0);
            var unterkonstruktiondataJSON = JSON.stringify(unterkonstruktiondata);

            var daemmungdata = application.get('model').daemmung.objectAt(0);
            var daemmungdataJSON = JSON.stringify(daemmungdata);

            //universal

            var lasteinwirkung = self.controllerFor('lasteinwirkung');
            var lasteinwirkungdata = application.get('model').lasteinwirkung.objectAt(0);
            var lasteinwirkungdataJSON = JSON.stringify(lasteinwirkungdata);

            var uebergabedaten = $.extend(true, JSON.parse(dachgeometriedataJSON), JSON.parse(unterkonstruktiondataJSON), JSON.parse(daemmungdataJSON), JSON.parse(lasteinwirkungdataJSON));

            //Lastannahmen/Parameter_Strukt.vb

            var timberelementgl = {
              "Querschnittswerte": {
                "t_Fastener": 0, // -> gl hoehe
                "b": daemmungdata.get('glBreite'),
                "h": daemmungdata.get('glHoehe')
              }
            };
            var timberelementsp = {
              "Querschnittswerte": {
                "b": unterkonstruktiondata.get('TimberElementSp_b'),
                "h": unterkonstruktiondata.get('TimberElementSp_h')
              }
            };
            var schneelastparas = {
              "SLZ_ID": lasteinwirkungdata.get('SLZ_ID'),
              "SLZ_Text": lasteinwirkung.slzarray[lasteinwirkungdata.get('SLZ_ID')].name, //inhalt der comobox
              "Region_ID": "Region ID", // nicht notwendig
              "RegionText": "Region Text", // nicht notwendig
              "WindgeschuetzteLage": "Windgeschuetzte Lage", // nicht notwendig
              "Fussnote": "Fussnote", // nicht notwendig
              "Zone": "Zone", // nur bei alten lastannahmen notwendig
              "A": lasteinwirkungdata.get('HuNN'), // HuNN
              "sk": lasteinwirkungdata.get('sk'),
              "DF_1": {
                "ALFA_Grad": dachgeometriedata.get('ALFA_Grad'),
                "Schneefanggitter": lasteinwirkungdata.get('Schneefanggitter')
              }
            };

            uebergabedaten.TimberElementGl = timberelementgl;
            uebergabedaten.TimberElementSp = timberelementsp;
            uebergabedaten.Schneelastparameter = schneelastparas;

            console.log(JSON.stringify(uebergabedaten));

            var JSONdata = JSON.stringify(uebergabedaten);

            //var JSONdata = "{" + dachgeometriedataJSONcut + "," + unterkonstruktiondataJSONcut + "," + daemmungdataJSONcut + "," + lasteinwirkungdataJSONcut + "}";
            //var JSONdata = "{" + bauteileString + "," + timberelementstringht + timberelementstringnt + "," + anschlussgeometriedataJSONcut + "," + lasteinwirkungdataJSONcut + "}";

            // var JSONdata = "{" + projektdatendataJSONcut + "," + bauteiledataJSONcut + "," + timberelementstringht + timberelementstringnt + "," + anschlussgeometriedataJSONcut + "," + lasteinwirkungdataJSONcut + "}";
            if (self.debug) {
              console.log(JSONdata);
            }

            var initialized = applicationdata.get('initialized');
            console.log(initialized);

            if (!initialized) {

              $.ajax({
                type: "POST",
                url: "http://136.243.4.143/berechnungsservice/m01/DE/initialisiereBerechnung/" + self.controllerFor('supercontroller').erzeugeAufrufzusatz(false),
                //url: "http://136.243.4.143/berechnungsservice/m06/DE/erzeugeSchraubenliste/",
                data: {
                  kennung: applicationdataJSON,
                  // projektdaten: projektdatendataJSON,
                  paras: JSONdata,
                  modul: "m01",
                  pfad: "D:/swgengineering/bin/"
                }
              }).done(function (data, statusText, xhr) {

                self.controllerFor('supercontroller').logaufruf("initialisiereBerechnung", xhr.status);

                var cid = data;
                applicationdata.set('Calculation_ID', cid);
                applicationdata.set('initialized', true);

                $.ajax({
                  type: "POST",
                  url: "http://136.243.4.143/berechnungsservice/berechneWindlast/" + cid + "/",
                  data: {
                    cid: cid
                  }
                }).done(function (data) {

                  // rückgabewert als ergebnis in lasteinwirkung setzen

                });

                $.ajax({
                  type: "POST",
                  url: "http://136.243.4.143/berechnungsservice/berechneSchneelast/" + cid + "/",
                  data: {
                    cid: cid
                  }
                }).done(function (data) {

                  // rückgabewert als ergebnis in lasteinwirkung setzen

                });
              });
            } else {

                var cid = applicationdata.get('Calculation_ID');

                $.ajax({
                  type: "POST",
                  url: "http://136.243.4.143/berechnungsservice/berechneWindlast/" + cid + "/",
                  data: {
                    cid: cid
                  }
                }).done(function (data) {

                  // rückgabewert als ergebnis in lasteinwirkung setzen

                });

                $.ajax({
                  type: "POST",
                  url: "http://136.243.4.143/berechnungsservice/berechneSchneelast/" + cid + "/",
                  data: {
                    cid: cid
                  }
                }).done(function (data) {

                  // rückgabewert als ergebnis in lasteinwirkung setzen

                });
              }

            // hier endet das windlast zeug
          } else {
              self.set('displayErrors', true);
              application.set('lasteinwirkungInvalid', true);
            }
        });
      },

      bmlastIsSelected: function bmlastIsSelected() {
        var bmlastaktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('bemessungslastAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('bemessungslastAktiv', !bmlastaktiv);

        if (this.get('bmlast') !== "") {
          var bmlastwork = parseFloat(this.get('bmlast').replace(",", "."));
          var bmlastrounded = bmlastwork.toFixed(1);
          this.set('bmlast', bmlastrounded);
          this.send('validation');
        }
      }

    }
  });

});