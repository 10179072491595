define('m0601/components/transfor-m', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Transform = Ember['default'].Component.extend({

    tagName: 'transform',
    layoutName: 'transfor-m',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    // center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",
    test: 1337,

    center: (function () {
      var values = '0 0 0';

      return values;
    }).property(''),

    translation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = x3d.get('bauteile').findBy('id', name);
      var values = '0 0 0';
      var y;
      var xVerschiebung, yVerschiebung, zVerschiebung;

      if (item.get('typ') === 'box') {

        xVerschiebung = item.get('translations').objectAt(0).get('x') / 10;
        yVerschiebung = item.get('translations').objectAt(0).get('y') / 10;
        zVerschiebung = item.get('translations').objectAt(0).get('z') / 10;

        switch (name) {
          case 'lager1':
            xVerschiebung = -bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 20;
            if (bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('indirekteslager1')) {
              yVerschiebung = bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10 + bauteile.findBy('id', 'unterkonstruktion1').get('boxsizes').objectAt(0).get('y') / 10;
              if (bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('auflager1Durchlaufend')) {
                yVerschiebung = yVerschiebung + bauteile.findBy('id', 'schalung').get('boxsizes').objectAt(0).get('y') / 10 + bauteile.findBy('id', 'beton').get('boxsizes').objectAt(0).get('y') / 10;
              }
            }
            break;
          case 'lager2':
            xVerschiebung = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 20;
            if (bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('indirekteslager2')) {
              yVerschiebung = bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10 + bauteile.findBy('id', 'unterkonstruktion1').get('boxsizes').objectAt(0).get('y') / 10;
              if (bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('auflager2Durchlaufend')) {
                yVerschiebung = yVerschiebung + bauteile.findBy('id', 'schalung').get('boxsizes').objectAt(0).get('y') / 10 + bauteile.findBy('id', 'beton').get('boxsizes').objectAt(0).get('y') / 10;
              }
            }
            break;
          case 'unterkonstruktion1':
            yVerschiebung = bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10;
            zVerschiebung = -bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('tiefeUnterkonstruktion') / 20;
            // if(bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('deckenkonstruktion') === 'balken'){
            zVerschiebung = zVerschiebung;
            // }else if(bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('deckenkonstruktion') === 'holzelement'){
            //   zVerschiebung = zVerschiebung + bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('tiefeUnterkonstruktion')/40;
            // }
            break;
          case 'unterkonstruktion2':
            yVerschiebung = bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10;
            zVerschiebung = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('tiefeUnterkonstruktion') / 20;
            // if(bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('deckenkonstruktion') === 'balken'){
            zVerschiebung = zVerschiebung;
            // }else if(bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('deckenkonstruktion') === 'holzelement'){
            //   zVerschiebung = zVerschiebung - bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('tiefeUnterkonstruktion')/40;
            // }

            break;
          case 'schalung':
            yVerschiebung = bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10 + bauteile.findBy('id', 'unterkonstruktion1').get('boxsizes').objectAt(0).get('y') / 10;
            break;
          case 'beton':
            // if(bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('deckenkonstruktion') === "dippelbaum"){
            //   // y = y + bauteile.findBy('id', 'unterkonstruktion1').get('boxsizes').objectAt(0).get('y')/10;
            //   yVerschiebung = bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y')/10;
            // }else{
            yVerschiebung = bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10 + bauteile.findBy('id', 'unterkonstruktion1').get('boxsizes').objectAt(0).get('y') / 10 + bauteile.findBy('id', 'schalung').get('boxsizes').objectAt(0).get('y') / 10;
            // }
            break;
          case 'aufgehendesbauteil1':
            xVerschiebung = -bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 20;
            yVerschiebung = bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10 + bauteile.findBy('id', 'unterkonstruktion1').get('boxsizes').objectAt(0).get('y') / 10;
            if (bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('indirekteslager1')) {

              yVerschiebung = yVerschiebung + bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10;
            }
            if (bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('auflager1Durchlaufend')) {
              yVerschiebung = yVerschiebung + bauteile.findBy('id', 'beton').get('boxsizes').objectAt(0).get('y') / 10 + bauteile.findBy('id', 'schalung').get('boxsizes').objectAt(0).get('y') / 10;
            }
            break;
          case 'aufgehendesbauteil2':
            xVerschiebung = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 20;
            yVerschiebung = bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10 + bauteile.findBy('id', 'unterkonstruktion1').get('boxsizes').objectAt(0).get('y') / 10;
            if (bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('indirekteslager2')) {
              yVerschiebung = yVerschiebung + bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10;
            }
            if (bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('auflager2Durchlaufend')) {
              yVerschiebung = yVerschiebung + bauteile.findBy('id', 'beton').get('boxsizes').objectAt(0).get('y') / 10 + bauteile.findBy('id', 'schalung').get('boxsizes').objectAt(0).get('y') / 10;
            }
            break;
        }

        values = xVerschiebung + '  ' + yVerschiebung + '  ' + zVerschiebung;
      }

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt', 'model.firstObject.schraubenGekreuzt'),

    rotation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = this.get('model').objectAt(0).get('bauteile');
      var values;

      if (name.substring(0, 8) === 'schraube' || name === 'cylinder') {
        values = '0 0 0 0';
      } else if (name.substring(0, 12) === 'hauptTraeger' || name.substring(0, 18) === 'verstaerkungWerner') {
        var vwinkel = x3d.get('vWinkel') * Math.PI / 180;
        values = '0 0 1 ' + vwinkel;
      } else if (name.substring(0, 5) !== 'plate') {
        values = bauteile.findBy('id', name).get('rotations').objectAt(0).get('x') + ' ' + bauteile.findBy('id', name).get('rotations').objectAt(0).get('y') + ' ' + bauteile.findBy('id', name).get('rotations').objectAt(0).get('z') + ' ' + bauteile.findBy('id', name).get('rotations').objectAt(0).get('winkel');
      }

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.schraubenGekreuzt'),

    getOutline: (function () {
      var name = this.get('name');
      var bauteile = this.get('model').objectAt(0).get('bauteile');
      var value = false;

      if (bauteile.findBy('id', name).get('typ') === 'box') {
        value = true;
      }
      return value;
    }).property(''),

    masskette1: (function () {
      var value = false;
      var x3d = this.get('model').objectAt(0);
      var name = this.get('name');
      var deckenkonstruktion = this.get('model').objectAt(0).get('bauteile').findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('deckenkonstruktion');

      // console.log('----- masskette1 -----');
      // console.log('name: '+name+' ::: deckenkonstruktion: '+deckenkonstruktion);

      if (x3d.get('ergebnisGeladen') !== true && x3d.get('abstaendeAnzeigen') && (name === 'unterkonstruktion1' && deckenkonstruktion === 'balken' || name === 'beton')) {
        value = true;
      }

      // console.log('value: '+value);
      // console.log(' ');

      return value;
    }).property('model.firstObject.abstaendeAnzeigen', 'model.firstObject.transformHelper'),

    masskette2: (function () {
      var value = false;
      var x3d = this.get('model').objectAt(0);
      var name = this.get('name');

      if (x3d.get('ergebnisGeladen') !== true && x3d.get('abstaendeAnzeigen') && (name === 'beton' || name === 'schalung')) {
        value = true;
      }
      return value;
    }).property('model.firstObject.abstaendeAnzeigen'),

    masskette1Typ: (function () {
      var value = 'breite';
      var name = this.get('name');

      switch (name) {
        case 'XXX':
          value = 'schraege';
          break;
      }
      return value;
    }).property(''),

    masskette2Typ: (function () {
      var value = 'hoehe';
      var name = this.get('name');

      switch (name) {
        case 'lager1':
          value = 'tiefe';
          break;
      }
      return value;
    }).property('')
  });

  exports['default'] = Transform;

});