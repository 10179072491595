define('m0601/controllers/system', ['exports', 'ember', 'ember-validations', 'ember-i18n'], function (exports, Ember, EmberValidations, ember_i18n) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {
    i18n: Ember['default'].inject.service(),

    systemvariante: 1,

    ttF1: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0.1 - 12.0 [m]";
    }),
    ttF2: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0.1 - 12.0 [m]";
    }),
    ttF3: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0.1 - 12.0 [m]";
    }),
    ttF4: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0.1 - 12.0 [m]";
    }),
    ttUeLinks: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0.0 - 99.9 [cm]";
    }),
    ttUeRechts: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0.0 - 99.9 [cm]";
    }),

    validations: {
      f1: {
        numericality: {
          greaterThanOrEqualTo: 0.1,
          lessThanOrEqualTo: 12.0
        }
      },
      f2: {
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 12.0
        }
      },
      f3: {
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 12.0
        }
      },
      f4: {
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 12.0
        }
      },
      ueLinks: {
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 99.9
        }
      },
      ueRechts: {
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 99.9
        }
      }
    },

    ueLinks: "",
    ueRechts: "",

    f1: "",
    f2: "",
    f3: "",
    f4: "",

    gesamtlaenge: 0,

    f2disabled: true,
    f3disabled: true,
    f4disabled: true,

    ueLinksDisabled: true,
    ueRechtsDisabled: true,

    onoffswitch: true,

    systemvarianteGrafik: "assets/images/Feld_0",
    systemvarianteGrafikEndung: ".png",
    sysGrafik: "assets/images/Feld.png",

    init: function init() {
      this._super();

      this.set("ueLinks", "0.00");
      this.set("ueRechts", "0.00");
      this.set("f1", "0.00");
      this.set("f2", "0.00");
      this.set("f3", "0.00");
      this.set("f4", "0.00");

      this.send('validation', -1, { target: { name: "foobar" } });
    },

    setValues: function setValues(values) {

      var self = this;

      self.set('openFromFile', true);

      var LBeamsSplit = values.LBeams.replace(/,/g, '.').split("##");
      console.log(LBeamsSplit);
      self.set('ueLinks', parseFloat(LBeamsSplit[0]).toFixed(2));
      this.setX3D(parseFloat(LBeamsSplit[0]).toFixed(2), { target: { name: "ueLinks" } });

      self.set('f1', parseFloat(LBeamsSplit[1]).toFixed(2));
      this.setX3D(parseFloat(LBeamsSplit[1]).toFixed(2), { target: { name: "f1" } });

      self.set('f2', parseFloat(LBeamsSplit[2]).toFixed(2));
      this.setX3D(parseFloat(LBeamsSplit[2]).toFixed(2), { target: { name: "f2" } });

      self.set('f3', parseFloat(LBeamsSplit[3]).toFixed(2));
      this.setX3D(parseFloat(LBeamsSplit[3]).toFixed(2), { target: { name: "f3" } });

      self.set('f4', parseFloat(LBeamsSplit[4]).toFixed(2));
      this.setX3D(parseFloat(LBeamsSplit[4]).toFixed(2), { target: { name: "f4" } });

      self.set('ueRechts', parseFloat(LBeamsSplit[5]).toFixed(2));
      this.setX3D(parseFloat(LBeamsSplit[5]).toFixed(2), { target: { name: "ueRechts" } });

      self.send('setSystemvariante', values.SystemVariante);

      self.send('validation', parseFloat(self.get('ueRechts')).toFixed(2), { target: { name: "ueRechts" } });

      self.set('openFromFile', false);
    },

    watchNumericalInputs: (function () {

      if (!this.openFromFile) {

        if (this.get('ueLinks').indexOf(",") !== -1) {
          this.set('ueLinks', this.get('ueLinks').replace(",", "."));
        }

        if (this.get('f1').indexOf(",") !== -1) {
          this.set('f1', this.get('f1').replace(",", "."));
        }

        if (this.get('f2').indexOf(",") !== -1) {
          this.set('f2', this.get('f2').replace(",", "."));
        }

        if (this.get('f3').indexOf(",") !== -1) {
          this.set('f3', this.get('f3').replace(",", "."));
        }

        if (this.get('f4').indexOf(",") !== -1) {
          this.set('f4', this.get('f4').replace(",", "."));
        }

        if (this.get('ueRechts').indexOf(",") !== -1) {
          this.set('ueRechts', this.get('ueRechts').replace(",", "."));
        }
      }

      var self = this;

      self.set('gesamtlaenge', parseFloat(self.get('ueLinks')) + parseFloat(self.get('f1')) + parseFloat(self.get('f2')) + parseFloat(self.get('f3')) + parseFloat(self.get('f4')) + parseFloat(self.get('ueRechts')));
      var einwirkungen = self.controllerFor('einwirkung');
      einwirkungen.set('gesamtlaenge', self.get('gesamtlaenge'));
    }).observes('ueLinks', 'f1', 'f2', 'f3', 'f4', 'ueRechts'),

    actions: {
      setSystemvariante: function setSystemvariante(sysvar) {

        var self = this;
        self.set('systemvariante', sysvar);
        // self.set('sysGrafik', self.get('systemvarianteGrafik') + "_1" + self.get('systemvarianteGrafikEndung'));

        var auflager = self.controllerFor('auflager');

        var materialien = self.controllerFor('materialien');

        switch (sysvar) {
          case "1":
            console.log("case 1");
            self.set('f2disabled', true);
            self.set('f3disabled', true);
            self.set('f4disabled', true);

            self.set('validations.f2.numericality.greaterThanOrEqualTo', 0.0);
            //auflager.set('ttBreiteA', self.get('i18n').t('wertebereich') + self.get('htbreite') + " - 240 [mm]");
            self.set('validations.f3.numericality.greaterThanOrEqualTo', 0.0);
            //auflager.set('ttBreiteA', self.get('i18n').t('wertebereich') + self.get('htbreite') + " - 240 [mm]");
            self.set('validations.f4.numericality.greaterThanOrEqualTo', 0.0);
            //auflager.set('ttBreiteA', self.get('i18n').t('wertebereich') + self.get('htbreite') + " - 240 [mm]");

            auflager.set('a1', true);
            auflager.set('a2', true);
            auflager.set('a3', false);

            materialien.set('a1', false);
            materialien.set('a2', false);
            materialien.set('a3', true);
            materialien.set('a4', true);
            materialien.set('a5', true);

            break;
          case "2":
            console.log("case 2");
            self.set('f2disabled', false);
            self.set('f3disabled', true);
            self.set('f4disabled', true);

            self.set('validations.f2.numericality.greaterThanOrEqualTo', 0.1);
            //auflager.set('ttBreiteA', self.get('i18n').t('wertebereich') + self.get('htbreite') + " - 240 [mm]");
            self.set('validations.f3.numericality.greaterThanOrEqualTo', 0.0);
            //auflager.set('ttBreiteA', self.get('i18n').t('wertebereich') + self.get('htbreite') + " - 240 [mm]");
            self.set('validations.f4.numericality.greaterThanOrEqualTo', 0.0);
            //auflager.set('ttBreiteA', self.get('i18n').t('wertebereich') + self.get('htbreite') + " - 240 [mm]");

            auflager.set('a1', true);
            auflager.set('a2', true);
            auflager.set('a3', false);

            materialien.set('a1', false);
            materialien.set('a2', false);
            materialien.set('a3', false);
            materialien.set('a4', true);
            materialien.set('a5', true);
            break;
          case "3":
            console.log("case 3");
            self.set('f2disabled', false);
            self.set('f3disabled', false);
            self.set('f4disabled', true);

            self.set('validations.f2.numericality.greaterThanOrEqualTo', 0.1);
            //auflager.set('ttBreiteA', self.get('i18n').t('wertebereich') + self.get('htbreite') + " - 240 [mm]");
            self.set('validations.f3.numericality.greaterThanOrEqualTo', 0.1);
            //auflager.set('ttBreiteA', self.get('i18n').t('wertebereich') + self.get('htbreite') + " - 240 [mm]");
            self.set('validations.f4.numericality.greaterThanOrEqualTo', 0.0);
            //auflager.set('ttBreiteA', self.get('i18n').t('wertebereich') + self.get('htbreite') + " - 240 [mm]");

            auflager.set('a1', true);
            auflager.set('a2', true);
            auflager.set('a3', true);

            materialien.set('a1', false);
            materialien.set('a2', false);
            materialien.set('a3', false);
            materialien.set('a4', false);
            materialien.set('a5', true);
            break;
          default:
            console.log("Error bei der übergebenen Sysvar (Felder freischalten im System-Reiter)!");
        }

        var systemdata = self.controllerFor('application').get('model').system.objectAt(0);
        systemdata.set('SystemVariante', sysvar);
        //hier StaticSystem setzen!
      },

      validation: function validation(value, event) {

        var self = this;

        if (!self.openFromFile) {

          this.setX3D(value, event);
        }

        self.validate().then(function () {
          // all validati0ons pass

        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');
          if (validation === true) {
            self.set('displayErrors', false);
            application.set('systemInvalid', false);

            var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
            applicationdata.set('treeLoaded', false);
            applicationdata.set('initialized', false);
            applicationdata.set('pdfErstellt', false);
            applicationdata.set('dxfErstellt', false);

            var downloadcenter = self.controllerFor('downloadcenter');
            downloadcenter.set('pdfErstellt', false);
            downloadcenter.set('dxfErstellt', false);

            application.set('verbindungsmittelInvalid', true);

            var systemdata = self.controllerFor('application').get('model').system.objectAt(0);

            var LBeams = parseFloat(self.get('ueLinks')).toFixed(2).replace('.', ',') + "##" + parseFloat(self.get('f1')).toFixed(2).replace('.', ',') + "##" + parseFloat(self.get('f2')).toFixed(2) + "##" + parseFloat(self.get('f3')).toFixed(2).replace('.', ',') + "##" + parseFloat(self.get('f4')).toFixed(2).replace('.', ',') + "##" + parseFloat(self.get('ueRechts')).toFixed(2).replace('.', ',');

            systemdata.set('LBeams', LBeams);
            systemdata.set('f1', parseFloat(self.get('f1')).toFixed(2));
            systemdata.set('f2', parseFloat(self.get('f2')).toFixed(2));
            systemdata.set('f3', parseFloat(self.get('f3')).toFixed(2));
            systemdata.set('f4', parseFloat(self.get('f4')).toFixed(2));
          } else {
            self.set('displayErrors', true);
            application.set('bauteileInvalid', true);
          }
        });
      },

      f1IsSelected: function f1IsSelected() {
        var laengeF1Aktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('laengeF1Aktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('laengeF1Aktiv', !laengeF1Aktiv);
        if (this.get('f1') !== "") {
          var f1 = parseFloat(this.get('f1').replace(",", "."));
          this.set('f1', f1.toFixed(2));
          this.send('validation', f1.toFixed(2), { target: { name: "f1" } });
        }

        if (laengeF1Aktiv === false) {
          document.getElementsByName('f1')[0].setSelectionRange(0, this.get('f1').length);
        }
      },

      f2IsSelected: function f2IsSelected() {
        var laengeF2Aktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('laengeF2Aktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('laengeF2Aktiv', !laengeF2Aktiv);
        if (this.get('f2') !== "") {
          var f2 = parseFloat(this.get('f2').replace(",", "."));
          this.set('f2', f2.toFixed(2));
          this.send('validation', f2.toFixed(2), { target: { name: "f2" } });
        }

        if (laengeF2Aktiv === false) {
          document.getElementsByName('f2')[0].setSelectionRange(0, this.get('f2').length);
        }
      },

      f3IsSelected: function f3IsSelected() {
        var laengeF3Aktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('laengeF3Aktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('laengeF3Aktiv', !laengeF3Aktiv);
        if (this.get('f3') !== "") {
          var f3 = parseFloat(this.get('f3').replace(",", "."));
          this.set('f3', f3.toFixed(2));
          this.send('validation', f3.toFixed(2), { target: { name: "f3" } });
        }

        if (laengeF3Aktiv === false) {
          document.getElementsByName('f3')[0].setSelectionRange(0, this.get('f3').length);
        }
      },

      f4IsSelected: function f4IsSelected() {
        var laengeF4Aktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('laengeF3Aktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('laengeF3Aktiv', !laengeF3Aktiv);
        if (this.get('f4') !== "") {
          var f4 = parseFloat(this.get('f4').replace(",", "."));
          this.set('f4', f4.toFixed(2));
          this.send('validation', f4.toFixed(2), { target: { name: "f4" } });
        }

        if (laengeF4Aktiv === false) {
          document.getElementsByName('f4')[0].setSelectionRange(0, this.get('f4').length);
        }
      },

      ueLinksIsSelected: function ueLinksIsSelected() {
        var ueLinksAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('ueLinksAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('ueLinksAktiv', !ueLinksAktiv);
        if (this.get('ueLinks') !== "") {
          var ueLinks = parseFloat(this.get('ueLinks').replace(",", "."));
          this.set('ueLinks', ueLinks.toFixed(2));
          this.send('validation', ueLinks.toFixed(2), { target: { name: "ueLinks" } });
        }

        if (ueLinksAktiv === false) {
          document.getElementsByName('ueLinks')[0].setSelectionRange(0, this.get('ueLinks').length);
        }
      },

      ueRechtsIsSelected: function ueRechtsIsSelected() {
        var ueRechtsAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('ueRechtsAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('ueRechtsAktiv', !ueRechtsAktiv);
        if (this.get('ueRechts') !== "") {
          var ueRechts = parseFloat(this.get('ueRechts').replace(",", "."));
          this.set('ueRechts', ueRechts.toFixed(2));
          this.send('validation', ueRechts.toFixed(2), { target: { name: "ueRechts" } });
        }

        if (ueRechtsAktiv === false) {
          document.getElementsByName('ueRechts')[0].setSelectionRange(0, this.get('ueRechts').length);
        }
      }
    },

    setX3D: function setX3D(value, event) {
      var self = this;
      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');
      var self = this;

      if (x3d.get('ergebnisGeladen') === true) {
        self.resetGrafikToDefault();
      }

      switch (event.target.name) {
        case 'f1':
          if (this.errors.f1.length === 0) {
            value = parseFloat(value).toFixed(2);
            bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).set('laengeF1', value * 100);

            this.controllerFor('einwirkung').pruefeLasten(value);

            x3d.set('laengeF1Eingetragen', true);
          } else {
            x3d.set('laengeF1Eingetragen', false);
          }
          break;
        case 'f2':
          if (this.errors.f2.length === 0) {
            value = parseFloat(value).toFixed(2);
            bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).set('laengeF2', value * 100);
            x3d.set('laengeF2Eingetragen', true);
          } else {
            x3d.set('laengeF2Eingetragen', false);
          }
          break;
        case 'f3':
          if (this.errors.f3.length === 0) {
            value = parseFloat(value).toFixed(2);
            bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).set('laengeF3', value * 100);
            x3d.set('laengeF3Eingetragen', true);
          } else {
            x3d.set('laengeF3Eingetragen', false);
          }
          break;
        case 'f4':
          if (this.errors.f4.length === 0) {
            value = parseFloat(value).toFixed(2);
            bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).set('laengeF4', value * 100);
            x3d.set('laengeF4Eingetragen', true);
          } else {
            x3d.set('laengeF4Eingetragen', false);
          }
          break;
        case 'ueLinks':
          if (this.errors.ueLinks.length === 0) {
            value = parseFloat(value).toFixed(2);
            bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).set('ueberstandLinks', value * 100);
            x3d.set('ueLinksEingetragen', true);
          } else {
            x3d.set('ueLinksEingetragen', false);
          }
          break;
        case 'ueRechts':
          if (this.errors.ueRechts.length === 0) {
            value = parseFloat(value).toFixed(2);
            bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).set('ueberstandRechts', value * 100);
            x3d.set('ueRechtsEingetragen', true);
          } else {
            x3d.set('ueRechtsEingetragen', false);
          }
          break;
      }

      // if(self.get('openFromFile') === false){
      x3d.set('systemwerteGeaendert', true);
      // }

      x3d.set('lastenTrigger', !x3d.get('lastenTrigger'));
      x3d.set('transformHelper', !x3d.get('transformHelper'));
      self.controllerFor('application').zentriertObjekt();
    },

    resetGrafikToDefault: function resetGrafikToDefault() {
      var self = this;

      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = application.get('model').x3ddefault.objectAt(0).get('bauteile');

      x3d.set('aktuellerSchraubenTyp', 'standardSchraube');
      x3d.set('ergebnisGeladen', false);
      x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));
    }
  });

});