define('m0601/models/auflager', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var auflager = DS['default'].Model.extend({

    a1: DS['default'].attr('boolean'),
    a2: DS['default'].attr('boolean'),
    a3: DS['default'].attr('boolean'),
    a4: DS['default'].attr('boolean'),
    a5: DS['default'].attr('boolean'),

    a1breite: DS['default'].attr('string'),
    a2breite: DS['default'].attr('string'),
    a3breite: DS['default'].attr('string'),
    a4breite: DS['default'].attr('string'),
    a5breite: DS['default'].attr('string'),

    LSupport: DS['default'].attr('string'),
    SupportType: DS['default'].attr('string'),
    SubStiffness: DS['default'].attr('string')

  });

  auflager.reopenClass({
    FIXTURES: [{
      id: 1,
      a1: false,
      a2: false,
      a3: false,
      a4: false,
      a5: false,
      a1breite: "0.0",
      a2breite: "0.0",
      a3breite: "0.0",
      a4breite: "0.0",
      a5breite: "0.0",

      LSupport: "0,0##0,0##0,0##0,0##0,0",
      SupportType: "1##1##1##1##1",
      SubStiffness: "0,00##0,00##0,00##0,00##0,00"

    }]
  });

  exports['default'] = auflager;

});