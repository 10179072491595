define('m0601/models/schwingungsnachweis', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	var schwingungsnachweis = DS['default'].Model.extend({

		DIV_w_Schwingung: DS['default'].attr('string'),
		VarNachweisSchwingung: DS['default'].attr('string'),
		b_Deckenfeld: DS['default'].attr('string'),
		EI_quer: DS['default'].attr('string'),
		Daempfungsmass: DS['default'].attr('string'),
		MaxAnzahlIteration: DS['default'].attr('string'),
		brandschutznachweisFuehren: DS['default'].attr('boolean'),
		brandschutznachweis: DS['default'].attr('string'),
		abstuetzungWaehrendMontage: DS['default'].attr('boolean'),
		anzahlAbstuetzpunkte: DS['default'].attr('string'),
		dxKnoten: DS['default'].attr('string'),
		dxVerbinder: DS['default'].attr('string'),
		DIV_w_inst: DS['default'].attr('string'),
		DIV_w_fin: DS['default'].attr('string'),
		DIV_w_fin_net: DS['default'].attr('string'),
		fMin: DS['default'].attr('string')

	});

	schwingungsnachweis.reopenClass({
		FIXTURES: [{
			id: 1,
			DIV_w_Schwingung: "0",
			VarNachweisSchwingung: "0",
			b_Deckenfeld: "0.0",
			EI_quer: "0.0",
			Daempfungsmass: "0.01",
			MaxAnzahlIteration: "10",
			brandschutznachweisFuehren: false,
			brandschutznachweis: "0",
			abstuetzungWaehrendMontage: false,
			anzahlAbstuetzpunkte: "0",
			dxKnoten: "15",
			dxVerbinder: "15",
			DIV_w_inst: "300",
			DIV_w_fin: "200",
			DIV_w_fin_net: "200",
			fMin: ""
		}]
	});

	exports['default'] = schwingungsnachweis;

});