define('m0601/models/daemmung', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var daemmung = DS['default'].Model.extend({

    befestigungsVariante: DS['default'].attr('string'),
    NameDae1: DS['default'].attr('string'),
    d_Dae1: DS['default'].attr('string'),
    E_Dae: DS['default'].attr('string'),
    Dae_Druckfest: DS['default'].attr('boolean'),
    ArtNr1: DS['default'].attr('string'),
    NameDae2: DS['default'].attr('string'),
    d_Dae2: DS['default'].attr('string'),
    ArtNr2: DS['default'].attr('string'),
    MatKeyTimberElementGl: DS['default'].attr('string'),
    FKLKeyTimberElementGl: DS['default'].attr('string'),
    glBreite: DS['default'].attr('string'),
    glHoehe: DS['default'].attr('string'),
    glLaenge: DS['default'].attr('string'),
    schraubenabstaende: DS['default'].attr('string'),
    verklebt: DS['default'].attr('boolean'),
    Sima10: DS['default'].attr('String')

  });

  daemmung.reopenClass({
    FIXTURES: [{
      id: 1,
      befestigungsVariante: "0",
      NameDae1: "",
      d_Dae1: "",
      E_Dae: "",
      Dae_Druckfest: false,
      ArtNr1: "",
      NameDae2: "",
      d_Dae2: "",
      ArtNr2: "",
      MatKeyTimberElementGl: "0",
      FKLKeyTimberElementGl: "0",
      glBreite: "50",
      glHoehe: "30",
      glLaenge: "5",
      schraubenabstaende: "0",
      verklebt: false,
      Sima10: "0"
    }]
  });

  exports['default'] = daemmung;

});