define('m0601/models/unterkonstruktion', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	var unterkonstruktion = DS['default'].Model.extend({

		UK_ind: DS['default'].attr('string'),
		MatKeyTimberElementSp: DS['default'].attr('string'),
		FKLKeyTimberElementSp: DS['default'].attr('string'),
		TimberElementSp_b: DS['default'].attr('string'),
		TimberElementSp_h: DS['default'].attr('string'),
		e_Sp: DS['default'].attr('string'),
		schalung: DS['default'].attr('string')

	});

	unterkonstruktion.reopenClass({
		FIXTURES: [{
			id: 1,
			UK_ind: "0",
			MatKeyTimberElementSp: "5",
			FKLKeyTimberElementSp: "20",
			TimberElementSp_b: "20",
			TimberElementSp_h: "20",
			e_Sp: "20",
			schalung: "0"
		}]
	});

	exports['default'] = unterkonstruktion;

});