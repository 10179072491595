define('m0601/components/materia-l0', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Material = Ember['default'].Component.extend({
    tagName: 'material',
    attributeBindings: ['diffusecolor', 'transparency', 'ambientintensity', 'emissivecolor', 'shininess', 'specularcolor'],
    ambientintensity: "0.2",
    emissivecolor: "0,0,0",
    shininess: "0.2",
    specularcolor: "0,0,0",

    diffusecolor: (function () {

      var name = this.get('name');
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var item = bauteile.findBy('id', name);
      var values;

      values = item.get('diffusecolors').objectAt(0).get('rot') + " " + item.get('diffusecolors').objectAt(0).get('gruen') + " " + item.get('diffusecolors').objectAt(0).get('blau');
      return values;
    }).property('model.firstObject.transformHelper'),

    transparency: (function () {

      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var value;

      var item = bauteile.findBy('id', name);
      if ((item.get('typ') === 'box' || item.get('typ') === 'platte') && !x3d.get('istGitterModell')) {
        value = item.get('appearances').objectAt(0).get('transparency');
      } else if ((item.get('typ') === 'box' || item.get('typ') === 'platte') && x3d.get('istGitterModell')) {
        value = 1;
      } else {
        value = 0;
      }

      return value;
    }).property('model.x3d.bauteile', 'model.firstObject.istGitterModell')
  });

  exports['default'] = Material;

});