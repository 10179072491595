define('m0601/components/transfor-m0', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Transform = Ember['default'].Component.extend({

    tagName: 'transform',
    layoutName: 'transfor-m0',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    // center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",
    test: 1337,

    massstab: function massstab() {
      var value = this.get('model').objectAt(0).get('massstab');
      return value;
    },

    center: (function () {
      var values = '0 0 0';
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = bauteile.findBy('id', name);

      var massstab = this.massstab();

      if (name.substring(0, 12) === 'hauptTraeger' || name.substring(0, 18) === 'verstaerkungWerner') {

        values = '0 ' + Number(item.get('boxsizes').objectAt(0).get('y')) / (2 * massstab) + ' 0';
      }

      return values;
    }).property('model.firstObject.transformHelper'),

    translation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = bauteile.findBy('id', name);

      var values = '0 0 0';

      var xVerschiebung = 0,
          yVerschiebung = 0,
          zVerschiebung = 0,
          verstaerkung = 0,
          deltaVerstaerkung = 0;

      var massstab = this.massstab();

      if (x3d.get('verstaerkungBlass') === true && x3d.get('verstaerkungWerner') === false) {
        verstaerkung = Number(x3d.get('dickeBlass')) / massstab;
        deltaVerstaerkung = verstaerkung;
      } else if (x3d.get('verstaerkungBlass') === true && (x3d.get('verstaerkungWerner') === true && x3d.get('sFolgeHolzHolz') === true)) {
        verstaerkung = Number(x3d.get('dickeWerner')) / massstab;
        deltaVerstaerkung = 2 * verstaerkung;
      } else if (x3d.get('verstaerkungBlass') === true && (x3d.get('verstaerkungWerner') === true && x3d.get('sFolgeHolzHolz') === false)) {
        verstaerkung = Number(x3d.get('dickeWerner')) / massstab;
        deltaVerstaerkung = verstaerkung;
      }

      switch (name) {
        case 'nebenTraeger':
          xVerschiebung = item.get('translations').objectAt(0).get('x') / massstab;
          yVerschiebung = -(bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('y') / massstab - bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y') / massstab) / 2;
          zVerschiebung = item.get('translations').objectAt(0).get('z') / massstab;
          break;
        case 'hauptTraeger1':
          xVerschiebung = item.get('translations').objectAt(0).get('x') / massstab;
          yVerschiebung = item.get('translations').objectAt(0).get('y') / massstab;
          zVerschiebung = item.get('boxsizes').objectAt(0).get('z') / (2 * massstab) + bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / (2 * massstab) + deltaVerstaerkung;
          break;
        case 'hauptTraeger2':
          xVerschiebung = item.get('translations').objectAt(0).get('x') / massstab;
          yVerschiebung = item.get('translations').objectAt(0).get('y') / massstab;
          zVerschiebung = -(item.get('boxsizes').objectAt(0).get('z') / (2 * massstab) + bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / (2 * massstab) + deltaVerstaerkung);
          break;
        case 'verstaerkungBlass1':
          xVerschiebung = item.get('translations').objectAt(0).get('x') / massstab;
          yVerschiebung = -(bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('y') / massstab - bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y') / massstab) / 2;
          zVerschiebung = verstaerkung / 2 + bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / (2 * massstab);
          break;
        case 'verstaerkungBlass2':
          xVerschiebung = item.get('translations').objectAt(0).get('x') / massstab;
          yVerschiebung = -(bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('y') / massstab - bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y') / massstab) / 2;
          zVerschiebung = -(verstaerkung / 2 + bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / (2 * massstab));
          break;
        case 'verstaerkungWerner1':
          xVerschiebung = item.get('translations').objectAt(0).get('x') / massstab;
          yVerschiebung = item.get('translations').objectAt(0).get('y') / massstab;
          zVerschiebung = 1.5 * verstaerkung + bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / (2 * massstab);
          break;
        case 'verstaerkungWerner2':
          xVerschiebung = item.get('translations').objectAt(0).get('x') / massstab;
          yVerschiebung = item.get('translations').objectAt(0).get('y') / massstab;
          zVerschiebung = -(1.5 * verstaerkung + bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / (2 * massstab));
          break;
      }

      values = xVerschiebung + " " + yVerschiebung + " " + zVerschiebung;

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt', 'model.firstObject.schraubenGekreuzt'),

    rotation: (function () {

      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var values = '0 0 0 0';

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.schraubenGekreuzt'),

    getOutline: (function () {
      var name = this.get('name');
      var bauteile = this.get('model').objectAt(0).get('bauteile');
      var istVolumen = false;

      if (bauteile.findBy('id', name).get('typ') === 'box' || bauteile.findBy('id', name).get('typ') === 'platte') {
        istVolumen = true;
      }
      return istVolumen;
    }).property(''),

    getMassketteHoehe: (function () {
      var value = false;
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (x3d.get('ergebnisGeladen') !== true && (x3d.get('abstaendeAnzeigen') && (this.get('name') === 'nebenTraeger' || this.get('name') === 'hauptTraeger1' || this.get('name') === 'mittelbauteil' || this.get('name') === 'seitenbauteil0' && Number(x3d.get('vWinkel')) === 0) && bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') !== true)) {
        value = true;
      }

      return value;
    }).property('model.firstObject.lastViewpoint', 'model.firstObject.abstaendeAnzeigen'),

    getMassketteBreite: (function () {
      var value = false;
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (x3d.get('ergebnisGeladen') !== true && (x3d.get('abstaendeAnzeigen') && (this.get('name') === 'nebenTraeger' || this.get('name') === 'hauptTraeger1' || this.get('name') === 'mittelbauteil' || this.get('name') === 'seitenbauteil0' || this.get('name') === 'verstaerkungBlass1' || this.get('name') === 'verstaerkungWerner1' && x3d.get('sFolgeHolzHolz') === true) && bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind') !== true)) {
        value = true;
      }

      return value;
    }).property('model.firstObject.lastViewpoint', 'model.firstObject.abstaendeAnzeigen'),

    getMassketteSetztiefe: (function () {
      var value = false;
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (x3d.get('abstaendeAnzeigen') && (this.get('name') === 'hauptTraeger1' && Number(bauteile.findBy('id', 'schraubeStandard1').get('screwProperties').objectAt(0).get('setztiefe')) !== 0) && bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind') !== true) {
        value = true;
      }

      return value;
    }).property('model.firstObject.transformHelper', 'model.firstObject.lastViewpoint', 'model.firstObject.abstaendeAnzeigen'),

    getscherkraftPfeil: (function () {
      var value = false;
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if ((this.get('name') === 'hauptTraeger1' || name === 'seitenbauteil0') && x3d.get('scherkraftEingetragen') === true && bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind') !== true) {
        value = true;
      }

      return value;
    }).property('model.firstObject.transformHelper', 'model.firstObject.lastViewpoint'),

    istHolz: (function () {
      var value = true;
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (this.get('name') === 'hauptTraeger1' && (x3d.get('ergebnisGeladen') === true || x3d.get('sFolgeHolzHolz') === false)) {
        value = false;
      }

      return value;
    }).property('model.firstObject.transformHelper', 'model.firstObject.ergebnisGeladen')

  });

  exports['default'] = Transform;

});