define('m0601/models/system', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var system = DS['default'].Model.extend({

    SystemVariante: DS['default'].attr('string'),
    f1: DS['default'].attr('string'),
    f2: DS['default'].attr('string'),
    f3: DS['default'].attr('string'),
    f4: DS['default'].attr('string'),
    LBeams: DS['default'].attr('string'),
    StaticSystem: DS['default'].attr('string')

  });

  system.reopenClass({
    FIXTURES: [{
      id: 1,
      SystemVariante: "1",
      f1: "0.0",
      f2: "0.0",
      f3: "0.0",
      f4: "0.0",
      LBeams: "0,00##0,00##0,00##0,00##0,00##0,00",
      StaticSystem: "010000"
    }]
  });

  exports['default'] = system;

});