define('m0601/components/indexedfacese-t0', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Transform = Ember['default'].Component.extend({

    tagName: 'indexedFaceSet',
    layoutName: 'indexedfacese-t0',
    attributeBindings: ['coordIndex', 'colorindex', 'colorPerVertex', 'ccw'],
    classNames: ['testClassNameXXX'],
    colorPerVertex: "false",
    bboxsize: "-1,-1,-1",
    ccw: "true",

    objektSkalierung: 1,

    ueberstand: 2,

    yDeltaOben: 0,
    yDeltaUnten: 0,

    koordinaten: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      var item = x3d.get('bauteile').findBy('id', name);

      var p0 = '0 0 0',
          p1 = '0 0 0',
          p2 = '0 0 0',
          p3 = '0 0 0',
          p4 = '0 0 0',
          p5 = '0 0 0',
          p6 = '0 0 0',
          p7 = '0 0 0',
          p8 = '0 0 0',
          p9 = '0 0 0';

      var cords = "";

      if (item.get('typ') === "platte") {

        var x0 = 0;
        var y0 = 0;
        var z0 = 0;

        var rx = 0;
        var ry = 0;
        var rz = 0;

        var dicke = 0;

        for (var n = 0; n <= 1; n++) {
          for (var i = 0; i < item.get('punkt').length; i++) {

            x0 = Number(item.get('punkt').objectAt(i).get('x')) / 10;
            y0 = Number(item.get('punkt').objectAt(i).get('y')) / 10;
            z0 = Number(item.get('punkt').objectAt(i).get('z')) / 10;

            cords = cords + (x0 + rx * dicke) + ' ' + (y0 + ry * dicke) + ' ' + (z0 + rz * dicke) + ' ';
          }

          rx = Number(item.get('richtungsvektor').objectAt(0).get('x'));
          ry = Number(item.get('richtungsvektor').objectAt(0).get('y'));
          rz = Number(item.get('richtungsvektor').objectAt(0).get('z'));

          dicke = Number(item.get('platte').objectAt(0).get('thickness')) / 10;
        }

        // if(item.get('typ') === "platte"){
        //   var x0 = Number(item.get('punkt').objectAt(0).get('x'))/10;
        //   var y0 = Number(item.get('punkt').objectAt(0).get('y'))/10;
        //   var z0 = Number(item.get('punkt').objectAt(0).get('z'))/10;
        //   var x1 = Number(item.get('punkt').objectAt(1).get('x'))/10;
        //   var y1 = Number(item.get('punkt').objectAt(1).get('y'))/10;
        //   var z1 = Number(item.get('punkt').objectAt(1).get('z'))/10;
        //   var x2 = Number(item.get('punkt').objectAt(2).get('x'))/10;
        //   var y2 = Number(item.get('punkt').objectAt(2).get('y'))/10;
        //   var z2 = Number(item.get('punkt').objectAt(2).get('z'))/10;
        //   var x3 = Number(item.get('punkt').objectAt(3).get('x'))/10;
        //   var y3 = Number(item.get('punkt').objectAt(3).get('y'))/10;
        //   var z3 = Number(item.get('punkt').objectAt(3).get('z'))/10;
        //
        //   var rx = Number(item.get('richtungsvektor').objectAt(0).get('x'));
        //   var ry = Number(item.get('richtungsvektor').objectAt(0).get('y'));
        //   var rz = Number(item.get('richtungsvektor').objectAt(0).get('z'));
        //
        //   var dicke = Number(item.get('platte').objectAt(0).get('thickness'))/10;
        //
        //   //hintere Ebene
        //   p0 = x0 +' '+ y0 +' '+ z0;
        //   p1 = x1 +' '+ y1 +' '+ z1;
        //   p2 = x2 +' '+ y2 +' '+ z2;
        //   p3 = x3 +' '+ y3 +' '+ z3;
        //
        //   // fordere Ebene
        //   p4 = (x0+rx*dicke) +' '+ (y0+ry*dicke) +' '+ (z0+rz*dicke);
        //   p5 = (x1+rx*dicke) +' '+ (y1+ry*dicke) +' '+ (z1+rz*dicke);
        //   p6 = (x2+rx*dicke) +' '+ (y2+ry*dicke) +' '+ (z2+rz*dicke);
        //   p7 = (x3+rx*dicke) +' '+ (y3+ry*dicke) +' '+ (z3+rz*dicke);
        //
        //   var x4 = 0, y4 = 0, z4 = 0;
        //
        //   if(item.get('punkt').length > 4){
        //     x4 = Number(item.get('punkt').objectAt(4).get('x'))/10;
        //     y4 = Number(item.get('punkt').objectAt(4).get('y'))/10;
        //     z4 = Number(item.get('punkt').objectAt(4).get('z'))/10;
        //   }
        //
        //   p8 = x4 +' '+ y4 +' '+ z4;
        //   p9 = x4+' '+ y4+' '+ (z4+dicke);
      } else if (name.substring(0, 12) === 'hauptTraeger' || name.substring(0, 18) === 'verstaerkungWerner') {

          var x = item.get('boxsizes').objectAt(0).get('x') / 10;
          var y = item.get('boxsizes').objectAt(0).get('y') / 10;
          var z = 0;

          if (name.substring(0, 18) === 'verstaerkungWerner') {
            z = x3d.get('dickeWerner') / 10;
          } else {
            z = item.get('boxsizes').objectAt(0).get('z') / 10;
          }

          var deltaXht = 0;
          var deltaXnt = 0;
          var xMittelbauteil = 0;
          var xSeitenbauteilUnten = 0;
          var xPlusSeitenbauteilUnten = 0;
          var xSeitenbauteilOben = 0;

          var ueberstand = Number(this.get('ueberstand'));

          var x1 = 0,
              x2 = 0;

          switch (name) {
            case 'nebenTraeger':
              // x = 2.5*bauteile.findBy('id', 'queranschluss1').get('boxsizes').objectAt(0).get('x')/10;
              deltaXnt = Math.abs(x3d.get('xSeitenbauteil') / 10) + ueberstand;
              xMittelbauteil = Math.abs(x3d.get('xMittelbauteil') / 10);
              break;
            case 'hauptTraeger1':
              deltaXht = Math.abs(x3d.get('xMittelbauteil') / 10) + ueberstand;
              xSeitenbauteilOben = this.getXSeitenbauteilOben();
              xSeitenbauteilUnten = this.getXSeitenbauteilUnten();
              xPlusSeitenbauteilUnten = this.getXDeltaSeitenbauteilUnten(xSeitenbauteilUnten);
              break;
            case 'hauptTraeger2':
              deltaXht = Math.abs(x3d.get('xMittelbauteil') / 10) + ueberstand;
              xSeitenbauteilOben = this.getXSeitenbauteilOben();
              xSeitenbauteilUnten = this.getXSeitenbauteilUnten();
              xPlusSeitenbauteilUnten = this.getXDeltaSeitenbauteilUnten(xSeitenbauteilUnten);
              break;
            case 'verstaerkungWerner1':
              deltaXht = Math.abs(x3d.get('xMittelbauteil') / 10) + ueberstand;
              xSeitenbauteilOben = this.getXSeitenbauteilOben();
              xSeitenbauteilUnten = this.getXSeitenbauteilUnten();
              xPlusSeitenbauteilUnten = this.getXDeltaSeitenbauteilUnten(xSeitenbauteilUnten);
              break;
            case 'verstaerkungWerner2':
              deltaXht = Math.abs(x3d.get('xMittelbauteil') / 10) + ueberstand;
              xSeitenbauteilOben = this.getXSeitenbauteilOben();
              xSeitenbauteilUnten = this.getXSeitenbauteilUnten();
              xPlusSeitenbauteilUnten = this.getXDeltaSeitenbauteilUnten(xSeitenbauteilUnten);
              break;
          }

          var p0 = -deltaXnt - xPlusSeitenbauteilUnten + ' ' + (0 + Number(this.get('yDeltaUnten'))) + ' ' + -z / 2;
          var p1 = -deltaXnt - xPlusSeitenbauteilUnten + ' ' + (0 + Number(this.get('yDeltaUnten'))) + ' ' + z / 2;
          var p2 = deltaXht + xMittelbauteil + ' ' + (0 + Number(this.get('yDeltaUnten'))) + ' ' + z / 2;
          var p3 = deltaXht + xMittelbauteil + ' ' + (0 + Number(this.get('yDeltaUnten'))) + ' ' + -z / 2;
          var p4 = -deltaXnt - xSeitenbauteilOben + ' ' + (y - Number(this.get('yDeltaOben'))) + ' ' + -z / 2;
          var p5 = -deltaXnt - xSeitenbauteilOben + ' ' + (y - Number(this.get('yDeltaOben'))) + ' ' + z / 2;
          var p6 = deltaXht + xMittelbauteil + ' ' + (y - Number(this.get('yDeltaOben'))) + ' ' + z / 2;
          var p7 = deltaXht + xMittelbauteil + ' ' + (y - Number(this.get('yDeltaOben'))) + ' ' + -z / 2;
          var p8 = 0 + ' ' + 0 + ' ' + 0;
          var p9 = 0 + ' ' + 0 + ' ' + 0;

          if (x3d.get('ausmittigerstabanschluss') === true && (name.substring(0, 12) === 'hauptTraeger' || name.substring(0, 18) === 'verstaerkungWerner')) {
            p8 = -deltaXnt - xSeitenbauteilUnten - this.getXExzentrizitaet('x') + ' ' + this.getXExzentrizitaet('y') + ' ' + -z / 2;
            p9 = -deltaXnt - xSeitenbauteilUnten - this.getXExzentrizitaet('x') + ' ' + this.getXExzentrizitaet('y') + ' ' + z / 2;
          }

          cords = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + p8 + ', ' + p9;
        }

      return cords;
    }).property('model.firstObject.transformHelper', 'model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.istHt', 'model.firstObject.schraubenGekreuzt'),

    coordIndex: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = x3d.get('bauteile').findBy('id', name);
      var values = '';

      // Grundfläche
      for (var i = 0; i < item.get('punkt').length; i++) {

        values = values + " " + i;
      }

      values = values + " 0 -1 ";

      // n-1 Seitenflächen
      for (var i = 0; i < item.get('punkt').length - 1; i++) {

        values = values + i + " " + (i + item.get('punkt').length) + " " + (i + item.get('punkt').length + 1) + " " + (i + 1) + " " + i + " " + -1 + " ";
      }

      // n.-Seitenfläche
      values = values + 0 + " " + (item.get('punkt').length - 1) + " " + (item.get('punkt').length - 1 + item.get('punkt').length) + " " + item.get('punkt').length + " " + 0 + " " + -1 + " ";

      var arr = [];

      // Abschlussfläche
      for (var i = 0; i < item.get('punkt').length; i++) {

        arr.push(i + item.get('punkt').length);
      }

      var str = arr.reverse().toString();

      values = values + " " + item.get('punkt').length + " " + str.replace(/,/g, " ") + " -1 ";

      // values = "0 3 2 1 0 -1 1 2 6 5 1 -1 4 5 6 7 4 -1 0 4 7 3 0 -1 0 1 5 4 0 -1 2 3 7 6 2 -1";
      //
      // if(x3d.get('ausmittigerstabanschluss') === true && (name.substring(0, 12) === 'hauptTraeger' || name.substring(0, 18) === 'verstaerkungWerner')){
      //   values = "0 3 2 1 0 -1 1 2 6 5 9 1 -1 4 5 6 7 4 -1 0 8 4 7 3 0 -1 0 1 9 8 0 -1 4 8 9 5 4 -1 2 3 7 6 2 -1";
      // }else if(x3d.get('ausmittigerstabanschluss') === true && name.substring(0, 13) === 'seitenbauteil'){
      //   values = "0 8 3 2 1 0 -1 4 5 6 7 9 4 -1 0 4 9 8 0 -1 0 1 5 4 0 -1 1 2 6 5 1 -1 2 3 7 6 2 -1 8 9 7 3 8 -1";
      // }

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt'),

    colorindex: (function () {

      // var values = "0 0 0 0 0 0 0";

      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = x3d.get('bauteile').findBy('id', name);

      var values = "";

      for (var i = 0; i < item.get('punkt').length + 2; i++) {

        values = values + " 0";
      }

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt'),

    farbe: (function () {

      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      var item = x3d.get('bauteile').findBy('id', name);
      var values;
      var rot = 0,
          gruen = 0,
          blau = 0;

      if (x3d.get('ergebnisGeladen') !== true || item.get('typ') !== "platte") {

        rot = Number(item.get('diffusecolors').objectAt(0).get('rot'));
        gruen = Number(item.get('diffusecolors').objectAt(0).get('gruen'));
        blau = Number(item.get('diffusecolors').objectAt(0).get('blau'));
      } else {

        var materialtextur = Number(item.get('platte').objectAt(0).get('matid'));

        switch (materialtextur) {
          case 1:
            //Beton
            rot = 0;
            gruen = 0.52;
            blau = 0.88;
            break;
          case 10:
            //Holz
            rot = 0.52;
            gruen = 0.385;
            blau = 0.02;
            break;
          case 20:
            //Schalung
            rot = 0.8;
            gruen = 0.65;
            blau = 0;
            break;
        }
      }
      values = rot + ' ' + gruen + ' ' + blau;
      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt'),

    getXSeitenbauteilOben: function getXSeitenbauteilOben() {
      var name = this.get('name');

      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      var hDiag = bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y');
      var hGurt = bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('y');;

      var alfa = parseFloat(x3d.get('vWinkel'));
      var beta = parseFloat(x3d.get('winkelEndAbschnittDiagonaleGRAD'));
      var ueDiagVert = parseFloat(x3d.get('ueberstandDiagonaleVertikal'));

      var value = 0;

      if (alfa !== 0 && alfa !== 90) {
        var exzentrizitaet = 0;

        if (x3d.get('ausmittigerstabanschluss') === true) {
          exzentrizitaet = x3d.get('exzentrizitaetDiagonale');
        } else {
          exzentrizitaet = Number(hDiag / 2 / Math.sin(alfa * Math.PI / 180)) + Number(hGurt / 2 / Math.tan(alfa * Math.PI / 180));

          if (Number(x3d.get('winkelEndAbschnittGRAD')) === 1 && Number(ueDiagVert) > hDiag * Math.cos(alfa * Math.PI / 180)) {
            exzentrizitaet = exzentrizitaet + (ueDiagVert - hDiag * Math.cos(alfa * Math.PI / 180)) / Math.tan(alfa * Math.PI / 180);
          } else if (Number(x3d.get('winkelEndAbschnittGRAD')) === 2) {
            exzentrizitaet = exzentrizitaet + ueDiagVert / Math.tan(alfa * Math.PI / 180);
          }
        }

        value = (exzentrizitaet / Math.cos(alfa * Math.PI / 180) - hDiag / 2 * Math.tan(alfa * Math.PI / 180)) / 10;
      } else if (alfa === 90) {
        value = hGurt / 20;
      } else {
        value = Math.abs(x3d.get('xSeitenbauteil') / 10) + Math.sin(alfa * Math.PI / 180) * ueDiagVert / 10;
      }

      if (value < -(Math.abs(x3d.get('xMittelbauteil') / 10) + Number(this.get('ueberstand')))) {
        var xOben = Math.abs(value) - Math.abs(Math.abs(x3d.get('xMittelbauteil') / 10) + Number(this.get('ueberstand')));

        var yDeltaOben = Math.abs(xOben) / Math.tan(alfa * Math.PI / 180);

        this.set('yDeltaOben', yDeltaOben);

        value = -(Math.abs(x3d.get('xMittelbauteil') / 10) + Number(this.get('ueberstand')));
      } else {
        this.set('yDeltaOben', 0);
      }

      return value;
    },

    getXSeitenbauteilUnten: function getXSeitenbauteilUnten() {
      var name = this.get('name');

      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      var hDiag = bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y');
      var hGurt = bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('y');

      var alfa = parseFloat(x3d.get('vWinkel'));
      var beta = parseFloat(x3d.get('winkelEndAbschnittDiagonaleGRAD'));
      var ueDiagVert = parseFloat(x3d.get('ueberstandDiagonaleVertikal'));

      var exzentrizitaet = x3d.get('exzentrizitaetDiagonale');

      var v1 = 0,
          v2 = 0,
          l = 0,
          value = 0;

      v1 = hDiag / 2 / Math.sin(alfa * Math.PI / 180) + hGurt / 2 / Math.tan(alfa * Math.PI / 180) - exzentrizitaet;
      v2 = hDiag / Math.sin(alfa * Math.PI / 180);

      l = v2 - v1;

      if (alfa !== 0 && alfa !== 90) {

        value = hGurt / 2 / Math.sin(alfa * Math.PI / 180) - (v2 - hDiag / 2 / Math.sin(alfa * Math.PI / 180)) * Math.cos(alfa * Math.PI / 180);

        if (x3d.get('ausmittigerstabanschluss') === false) {

          if (Number(x3d.get('winkelEndAbschnittGRAD')) === 1 && Number(ueDiagVert) >= Number((hDiag * Math.cos(alfa * Math.PI / 180)).toFixed(1))) {
            value = value + hDiag / Math.tan(alfa * Math.PI / 180) + (ueDiagVert - hDiag * Math.cos(alfa * Math.PI / 180)) / Math.sin(alfa * Math.PI / 180);
          } else if (Number(x3d.get('winkelEndAbschnittGRAD')) === 2) {
            value = value + ueDiagVert / Math.sin(alfa * Math.PI / 180);
          }
        } else if (x3d.get('ausmittigerstabanschluss') === true) {

          if (Number(x3d.get('winkelEndAbschnittGRAD')) === 1) {
            // value = value + (l-ueDiagVert/Math.tan(alfa * Math.PI / 180)) * Math.cos(alfa * Math.PI / 180) + ueDiagVert/Math.sin(alfa * Math.PI / 180);
            value = value + ueDiagVert / Math.sin(alfa * Math.PI / 180);
          } else if (Number(x3d.get('winkelEndAbschnittGRAD')) === 2) {
            value = value + ueDiagVert / Math.sin(alfa * Math.PI / 180);
          }

          if (value >= Number(((hDiag / 2 + exzentrizitaet / Math.sin(alfa * Math.PI / 180)) * Math.tan(alfa * Math.PI / 180)).toFixed(1))) {
            value = (hDiag / 2 + exzentrizitaet / Math.sin(alfa * Math.PI / 180)) * Math.tan(alfa * Math.PI / 180);
          }
        }

        value = value / 10;
      } else if (alfa === 90) {
        value = hGurt / 20;
      } else {
        value = Math.abs(x3d.get('xSeitenbauteil') / 10) + Math.sin(alfa * Math.PI / 180) * ueDiagVert / 10;
      }

      if (value < -(Math.abs(x3d.get('xMittelbauteil') / 10) + Number(this.get('ueberstand')))) {
        var xUnten = Math.abs(value) - Math.abs(Math.abs(x3d.get('xMittelbauteil') / 10) + Number(this.get('ueberstand')));

        var yDeltaUnten = Math.abs(xUnten) * Math.tan(alfa * Math.PI / 180);

        this.set('yDeltaUnten', yDeltaUnten);
      } else {
        this.set('yDeltaUnten', 0);
      }

      return value;
    },

    getXDeltaSeitenbauteilUnten: function getXDeltaSeitenbauteilUnten(value) {

      var name = this.get('name');

      var x3d = this.get('model').objectAt(0);

      if (value < -(Math.abs(x3d.get('xMittelbauteil') / 10) + Number(this.get('ueberstand')))) {

        value = -(Math.abs(x3d.get('xMittelbauteil') / 10) + Number(this.get('ueberstand')));
      }

      return value;
    },

    getXExzentrizitaet: function getXExzentrizitaet(achse) {
      var name = this.get('name');

      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      var alfa = parseFloat(x3d.get('vWinkel'));
      var beta = parseFloat(x3d.get('winkelEndAbschnittDiagonaleGRAD'));

      var ueDiagVert = parseFloat(x3d.get('ueberstandDiagonaleVertikal'));

      var hDiag = bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y');
      var hGurt = bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('y');;
      var exzentrizitaet = Number(x3d.get('exzentrizitaetDiagonale'));

      var v1 = 0,
          v2 = 0,
          l = 0,
          value = 0;

      v1 = hDiag / 2 / Math.sin(alfa * Math.PI / 180) + hGurt / 2 / Math.tan(alfa * Math.PI / 180) - exzentrizitaet;
      v2 = hDiag / Math.sin(alfa * Math.PI / 180);

      l = v2 - v1;

      if (l - ueDiagVert / Math.tan(alfa * Math.PI / 180) >= 0) {
        if (achse === 'x') {

          if (Number(x3d.get('winkelEndAbschnittGRAD')) === 1) {
            value = 0;
          } else if (Number(x3d.get('winkelEndAbschnittGRAD')) === 2) {
            value = (l - ueDiagVert / Math.tan(alfa * Math.PI / 180)) * Math.cos(alfa * Math.PI / 180);
          }
        } else if (achse === 'y') {

          if (Number(x3d.get('winkelEndAbschnittGRAD')) === 1) {
            value = (l - ueDiagVert / Math.tan(alfa * Math.PI / 180)) / Math.sin(alfa * Math.PI / 180);
          } else if (Number(x3d.get('winkelEndAbschnittGRAD')) === 2) {
            value = (l - ueDiagVert / Math.tan(alfa * Math.PI / 180)) * Math.sin(alfa * Math.PI / 180);
          }
        }
      }

      value = value / 10;

      return value;
    }

  });

  exports['default'] = Transform;

});