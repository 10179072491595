define('m0601/models/dachgeometrie', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var dachgeometrie = DS['default'].Model.extend({

    DachFormID: DS['default'].attr('string'),
    ALFA_Grad: DS['default'].attr('string'),
    DN_Walm: DS['default'].attr('string'),
    DN_Grad: DS['default'].attr('string'),
    LTraufe: DS['default'].attr('string'),
    L_Sp: DS['default'].attr('string'),
    LFirst: DS['default'].attr('string'),
    A_Dach: DS['default'].attr('string'),
    L_Geb: DS['default'].attr('string'),
    B_Geb: DS['default'].attr('string'),
    Ue_Giebel: DS['default'].attr('string'),
    Ue_Traufe: DS['default'].attr('string'),
    Ue_First: DS['default'].attr('string'),
    hFirst: DS['default'].attr('string'),
    ueberdaemmteFlaeche: DS['default'].attr('string'),
    gebaeudeBreite: DS['default'].attr('string'),
    gebaeudeLaenge: DS['default'].attr('string')

  });

  dachgeometrie.reopenClass({
    FIXTURES: [{
      id: 1,
      DachFormID: "0",
      ALFA_Grad: "30",
      DN_Walm: "10",
      DN_Grad: "30",
      LTraufe: "20",
      L_Sp: "20",
      LFirst: "0",
      A_Dach: "5",
      L_Geb: "20",
      B_Geb: "20",
      Ue_Giebel: "1",
      Ue_Traufe: "1",
      Ue_First: "0",
      hFirst: "0",
      ueberdaemmteFlaeche: "0",
      gebaeudeBreite: "8",
      gebaeudeLaenge: "8"
    }]
  });

  exports['default'] = dachgeometrie;

});