define('m0601/models/dimension', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({

    name: DS['default'].attr('string'),
    bauteil: DS['default'].attr('string'),
    x: DS['default'].attr('number'),
    y: DS['default'].attr('number'),
    z: DS['default'].attr('number')

  });

});