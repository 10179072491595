define('m0601/controllers/auflager', ['exports', 'ember', 'ember-validations', 'ember-i18n'], function (exports, Ember, EmberValidations, ember_i18n) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {
    i18n: Ember['default'].inject.service(),

    ttAuflager1Breite: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0.0 - 100.0 [cm]";
    }),
    ttAuflager2Breite: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0.0 - 100.0 [cm]";
    }),
    ttAuflager3Breite: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0.0 - 100.0 [cm]";
    }),

    validations: {
      auflager1breite: {
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 100.0
        }
      },
      auflager2breite: {
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 100.0
        }
      },
      auflager3breite: {
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 100.0
        }
      }
    },

    displayErrors: true,
    auflager1cb: false,
    auflager2cb: false,
    auflager3cb: false,
    auflager4cb: false,
    auflager5cb: false,

    a1: true,
    a2: true,
    a3: false,
    a4: false,
    a5: false,

    auflager1breite: "",
    auflager2breite: "",
    auflager3breite: "",
    auflager4breite: "",
    auflager5breite: "",

    init: function init() {
      this._super();

      this.set('auflager1breite', "0.0");
      this.set('auflager2breite', "0.0");
      this.set('auflager3breite', "0.0");
      this.set('auflager4breite', "0.0");
      this.set('auflager5breite', "0.0");
    },

    setValues: function setValues(values) {

      var self = this;

      var LSupportSplit = values.LSupport.replace(/,/g, '.').split("##");
      self.set('auflager1breite', parseFloat(LSupportSplit[0]).toFixed(1));
      this.setX3D(parseFloat(LSupportSplit[0]).toFixed(1), { target: { name: "auflager1breite" } });

      self.set('auflager2breite', parseFloat(LSupportSplit[1]).toFixed(1));
      this.setX3D(parseFloat(LSupportSplit[1]).toFixed(1), { target: { name: "auflager2breite" } });

      self.set('auflager3breite', parseFloat(LSupportSplit[2]).toFixed(1));
      this.setX3D(parseFloat(LSupportSplit[2]).toFixed(1), { target: { name: "auflager3breite" } });

      self.set('auflager4breite', parseFloat(LSupportSplit[3]).toFixed(1));
      // this.setX3D(parseFloat(LSupportSplit[3]).toFixed(1), {target:{name: "auflager4breite"}});

      self.set('auflager5breite', parseFloat(LSupportSplit[4]).toFixed(1));
      // this.setX3D(parseFloat(LSupportSplit[4]).toFixed(1), {target:{name: "auflager5breite"}});

      var SupportTypeSplit = values.SupportType.replace(/,/g, '.').split("##");
      self.set('auflager1cb', !!!parseInt(SupportTypeSplit[0]));
      self.set('auflager2cb', !!!parseInt(SupportTypeSplit[1]));
      self.set('auflager3cb', !!!parseInt(SupportTypeSplit[2]));
      self.set('auflager4cb', !!!parseInt(SupportTypeSplit[3]));
      self.set('auflager5cb', !!!parseInt(SupportTypeSplit[4]));

      this.set('openFromFile', true);
      this.send('validation');
      this.set('openFromFile', false);
    },

    watchNumericalInputs: (function () {

      var self = this;

      if (!this.openFromFile) {

        if (this.get('auflager1breite').indexOf(",") !== -1 && self.gat('a1')) {
          this.set('auflager1breite', this.get('auflager1breite').replace(",", "."));
        }

        if (this.get('auflager2breite').indexOf(",") !== -1 && self.get('a2')) {
          this.set('auflager2breite', this.get('auflager2breite').replace(",", "."));
        }

        if (this.get('auflager3breite').indexOf(",") !== -1 && self.get('a3')) {
          this.set('auflager3breite', this.get('auflager3breite').replace(",", "."));
        }

        if (this.get('auflager4breite').indexOf(",") !== -1 && self.get('a4')) {
          this.set('auflager4breite', this.get('auflager4breite').replace(",", "."));
        }

        if (this.get('auflager5breite').indexOf(",") !== -1 && self.get('a5')) {
          this.set('auflager5breite', this.get('auflager5breite').replace(",", "."));
        }
      }
    }).observes('auflager1breite', 'auflager2breite', 'auflager3breite'),

    watchCheckboxes: (function () {
      var self = this;

      var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      applicationdata.set('treeLoaded', false);
      applicationdata.set('initialized', false);
      applicationdata.set('pdfErstellt', false);
      applicationdata.set('dxfErstellt', false);

      var application = self.controllerFor('application');
      application.set('verbindungsmittelInvalid', true);

      var downloadcenter = self.controllerFor('downloadcenter');
      downloadcenter.set('pdfErstellt', false);
      downloadcenter.set('dxfErstellt', false);

      var x3d = self.controllerFor('application').get('model').x3ddefault.objectAt(0);

      var auflagerdata = this.controllerFor('application').get('model').auflager.objectAt(0);

      var a1 = self.get('auflager1cb') ? 0 : 1;
      var a2 = self.get('auflager2cb') ? 0 : 1;
      var a3 = self.get('auflager3cb') ? 0 : 1;
      var a4 = self.get('auflager4cb') ? 0 : 1;
      var a5 = self.get('auflager5cb') ? 0 : 1;

      x3d.get('bauteile').findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).set('indirekteslager1', self.get('auflager1cb'));
      x3d.get('bauteile').findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).set('indirekteslager2', self.get('auflager2cb'));
      x3d.get('bauteile').findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).set('indirekteslager3', self.get('auflager3cb'));
      x3d.get('bauteile').findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).set('indirekteslager3', self.get('auflager4cb'));
      x3d.get('bauteile').findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).set('indirekteslager3', self.get('auflager5cb'));

      var SupportType = a1 + "##" + a2 + "##" + a3 + "##" + a4 + "##" + a5;
      auflagerdata.set('SupportType', SupportType);

      if (x3d.get('ergebnisGeladen') === true) {
        self.controllerFor('system').resetGrafikToDefault();
      }
      x3d.set('transformHelper', !x3d.get('transformHelper'));
    }).observes('auflager1cb', 'auflager2cb', 'auflager3cb', 'auflager4cb', 'auflager5cb'),

    actions: {

      validation: function validation(value, event) {

        var self = this;

        if (!self.openFromFile) {

          this.setX3D(value, event);
        }

        self.validate().then(function () {
          // all validati0ons pass

        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');
          if (validation === true) {
            self.set('displayErrors', false);
            application.set('auflagerInvalid', false);

            var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
            applicationdata.set('treeLoaded', false);
            applicationdata.set('initialized', false);
            applicationdata.set('pdfErstellt', false);
            applicationdata.set('dxfErstellt', false);

            var downloadcenter = self.controllerFor('downloadcenter');
            downloadcenter.set('pdfErstellt', false);
            downloadcenter.set('dxfErstellt', false);

            // application.set('verbindungsmittelInvalid', true);

            var auflagerdata = self.controllerFor('application').get('model').auflager.objectAt(0);

            var LSupport = parseFloat(self.get('auflager1breite')).toFixed(1).replace('.', ',') + "##" + parseFloat(self.get('auflager2breite')).toFixed(1).replace('.', ',') + "##" + parseFloat(self.get('auflager3breite')).toFixed(1).replace('.', ',') + "##" + parseFloat(self.get('auflager4breite')).toFixed(1).replace('.', ',') + "##" + parseFloat(self.get('auflager5breite')).toFixed(1).replace('.', ',');

            auflagerdata.set('LSupport', LSupport);

            auflagerdata.set('a1breite', self.get('auflager1breite'));
            auflagerdata.set('a2breite', self.get('auflager2breite'));
            auflagerdata.set('a3breite', self.get('auflager3breite'));
            auflagerdata.set('a4breite', self.get('auflager4breite'));
            auflagerdata.set('a5breite', self.get('auflager5breite'));
          } else {
            self.set('displayErrors', true);
            application.set('auflagerInvalid', true);
          }
        });
      },

      auflager1breiteIsSelected: function auflager1breiteIsSelected() {
        var auflager1breiteAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('auflager1breiteAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('auflager1breiteAktiv', !auflager1breiteAktiv);
        if (this.get('auflager1breite') !== "") {
          var auflager1breite = parseFloat(this.get('auflager1breite').replace(",", "."));
          this.set('auflager1breite', auflager1breite.toFixed(1));
          this.send('validation', auflager1breite.toFixed(1), { target: { name: "auflager1breite" } });
        }

        if (auflager1breiteAktiv === false) {
          document.getElementsByName('auflager1breite')[0].setSelectionRange(0, this.get('auflager1breite').length);
        }
      },

      auflager2breiteIsSelected: function auflager2breiteIsSelected() {
        var auflager2breiteAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('auflager2breiteAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('auflager2breiteAktiv', !auflager2breiteAktiv);
        if (this.get('auflager2breite') !== "") {
          var auflager2breite = parseFloat(this.get('auflager2breite').replace(",", "."));
          this.set('auflager2breite', auflager2breite.toFixed(1));
          this.send('validation', auflager2breite.toFixed(1), { target: { name: "auflager2breite" } });
        }

        if (auflager2breite === false) {
          document.getElementsByName('auflager2breite')[0].setSelectionRange(0, this.get('auflager2breite').length);
        }
      },

      auflager3breiteIsSelected: function auflager3breiteIsSelected() {
        var auflager3breiteAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('auflager3breiteAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('auflager3breiteAktiv', !auflager3breiteAktiv);
        if (this.get('auflager3breite') !== "") {
          var auflager3breite = parseFloat(this.get('auflager3breite').replace(",", "."));
          this.set('auflager3breite', auflager3breite.toFixed(1));
          this.send('validation', auflager3breite.toFixed(1), { target: { name: "auflager3breite" } });
        }

        if (auflager3breiteAktiv === false) {
          document.getElementsByName('auflager3breite')[0].setSelectionRange(0, this.get('auflager3breite').length);
        }
      },

      auflager4breiteIsSelected: function auflager4breiteIsSelected() {
        var auflager4breiteAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('auflager4breiteAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('auflager4breiteAktiv', !auflager4breiteAktiv);
        if (this.get('auflager4breite') !== "") {
          var auflager4breite = parseFloat(this.get('auflager4breite').replace(",", "."));
          this.set('auflager4breite', auflager4breite.toFixed(1));
          this.send('validation', auflager4breite.toFixed(1), { target: { name: "auflager4breite" } });
        }

        if (auflager4breiteAktiv === false) {
          document.getElementsByName('auflager4breite')[0].setSelectionRange(0, this.get('auflager4breite').length);
        }
      },

      auflager5breiteIsSelected: function auflager5breiteIsSelected() {
        var auflager5breiteAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('auflager5breiteAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('auflager5breiteAktiv', !auflager5breiteAktiv);
        if (this.get('auflager5breite') !== "") {
          var auflager5breite = parseFloat(this.get('auflager5breite').replace(",", "."));
          this.set('auflager5breite', auflager5breite.toFixed(1));
          this.send('validation', auflager5breite.toFixed(1), { target: { name: "auflager5breite" } });
        }

        if (auflager5breiteAktiv === false) {
          document.getElementsByName('auflager5breite')[0].setSelectionRange(0, this.get('auflager5breite').length);
        }
      }
    },

    setX3D: function setX3D(value, event) {
      var self = this;
      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');
      var self = this;

      if (x3d.get('ergebnisGeladen') === true) {
        self.controllerFor('system').resetGrafikToDefault();
      }

      switch (event.target.name) {
        case 'auflager1breite':
          if (this.errors.auflager1breite.length === 0) {
            value = parseFloat(value).toFixed(2);
            bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).set('x', value);
            x3d.set('auflager1breiteEingetragen', true);
          } else {
            x3d.set('auflager1breiteEingetragen', false);
          }
          break;
        case 'auflager2breite':
          if (this.errors.auflager2breite.length === 0) {
            value = parseFloat(value).toFixed(2);
            bauteile.findBy('id', 'lager2').get('boxsizes').objectAt(0).set('x', value);
            x3d.set('auflager2breiteEingetragen', true);
          } else {
            x3d.set('auflager2breiteEingetragen', false);
          }
          break;
        case 'auflager3breite':
          if (this.errors.auflager3breite.length === 0) {
            value = parseFloat(value).toFixed(2);
            bauteile.findBy('id', 'lager3').get('boxsizes').objectAt(0).set('x', value);
            x3d.set('auflager3breiteEingetragen', true);
          } else {
            x3d.set('auflager3breiteEingetragen', false);
          }
          break;
        case 'auflager4breite':
          if (this.errors.auflager4breite.length === 0) {
            value = parseFloat(value).toFixed(2);
            bauteile.findBy('id', 'lager4').get('boxsizes').objectAt(0).set('x', value);
            x3d.set('auflager4breiteEingetragen', true);
          } else {
            x3d.set('auflager4breiteEingetragen', false);
          }
          break;
        case 'auflager5breite':
          if (this.errors.auflager5breite.length === 0) {
            value = parseFloat(value).toFixed(2);
            bauteile.findBy('id', 'lager5').get('boxsizes').objectAt(0).set('x', value);
            x3d.set('auflager5breiteEingetragen', true);
          } else {
            x3d.set('auflager5breiteEingetragen', false);
          }
          break;
      }
      x3d.set('transformHelper', !x3d.get('transformHelper'));
      self.controllerFor('application').zentriertObjekt();
    }
  });

});