define('m0601/components/typ-menu', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    typ1: "1",

    actions: {
      setTyp: function setTyp(lastnr, lasttyp) {

        var self = this;
        var lastnrpara = this.get('lastnr');

        this.sendAction('setTyp', lastnrpara, lasttyp);

        //var einwirkung = this.get('model').objectAt(0);
        //einwirkung.set('typ1', lasttyp);
        self.set('typ1', lasttyp);
      },

      setKLED: function setKLED(last, kled) {

        var self = this;
        self.lasten[lastnr]['kled'] = kled;
      }
    }
  });

});