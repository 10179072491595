define('m0601/controllers/dachgeometrie', ['exports', 'ember', 'ember-validations', 'ember-i18n'], function (exports, Ember, EmberValidations, ember_i18n) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {

    validations: {
      fhoehe: {
        numericality: {
          greaterThanOrEqualTo: 0.1,
          lessThanOrEqualTo: 25
        }
      },
      dneigung: {
        numericality: {
          greaterThanOrEqualTo: 5,
          lessThanOrEqualTo: 80
        }
      },
      dneigung2: {
        numericality: {
          greaterThanOrEqualTo: 5,
          lessThanOrEqualTo: 80
        }
      },
      gbreite: {
        numericality: {
          greaterThanOrEqualTo: 1,
          lessThanOrEqualTo: 999.0
        }
      },
      glaenge: {
        numericality: {
          greaterThanOrEqualTo: 1,
          lessThanOrEqualTo: 999.9
        }
      },
      uegiebel: {
        numericality: {
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 5
        }
      },
      uetraufe: {
        numericality: {
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 5
        }
      },
      uefirst: {
        numericality: {
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 5
        }
      }
    },

    eingabenForLastannahmen: true,

    dgeometrie: [{
      name: "Satteldach",
      ind: 0
    }, {
      name: "Pultdach",
      ind: 1
    }, {
      name: "Walmdach",
      ind: 2
    }],

    ttHoeheFirst: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0.1 - 25 [m]";
    }),
    ttDachneigung: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 5 - 80 [°]";
    }),
    ttDachneigungWalm: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 5 - 80 [°]";
    }),
    ttGebaeudebreite: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 1 - 999.9 [m]";
    }),
    ttGebaeudelaenge: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 1 - 999.9 [m]";
    }),
    ttUeGiebel: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0 - 5 [m]";
    }),
    ttUeFirst: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0 - 5 [m]";
    }),
    ttUeTraufe: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0 - 5 [m]";
    }),
    ttUeberdaemmteflaeche: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " > Dachfläche [m²]";
    }),
    i18n: Ember['default'].inject.service(),

    // Default Werte fuer Comboboxes
    dachgeometrie: 0,

    fhoehe: "8.0",
    dneigung: "30.0",
    dneigung2: "40.0",
    gbreite: "8.0",
    glaenge: "8.0",
    uegiebel: "0.0",
    uetraufe: "0.0",
    uefirst: "0.0",
    reduktionsmass: "0.0",
    dachflaeche: "0.0",
    ueberdaemmteflaeche: "0.0",

    walmdach: false,
    pultdach: false,
    openFromFile: false,
    displayErrors: true,

    watchNumericalInputs: (function () {

      if (!this.openFromFile) {

        if (this.get('fhoehe').indexOf(",") !== -1) {
          this.set('fhoehe', this.get('fhoehe').replace(",", "."));
        }

        if (this.get('dneigung').indexOf(",") !== -1) {
          this.set('dneigung', this.get('dneigung').replace(",", "."));
        }

        if (this.get('dneigung2').indexOf(",") !== -1) {
          this.set('dneigung2', this.get('dneigung2').replace(",", "."));
        }

        if (this.get('gbreite').indexOf(",") !== -1) {
          this.set('gbreite', this.get('gbreite').replace(",", "."));
        }

        if (this.get('glaenge').indexOf(",") !== -1) {
          this.set('glaenge', this.get('glaenge').replace(",", "."));
        }

        if (this.get('uegiebel').indexOf(",") !== -1) {
          this.set('giebel', this.get('giebel').replace(",", "."));
        }

        if (this.get('uetraufe').indexOf(",") !== -1) {
          this.set('traufe', this.get('traufe').replace(",", "."));
        }

        if (this.get('uefirst').indexOf(",") !== -1) {
          this.set('uefirst', this.get('uefirst').replace(",", "."));
        }

        if (this.get('reduktionsmass').indexOf(",") !== -1) {
          this.set('reduktionsmass', this.get('reduktionsmass').replace(",", "."));
        }

        var gbreite = this.get('gbreite');
        var glaenge = this.get('glaenge');
        var uegiebel = this.get('uegiebel');
        var uetraufe = this.get('uetraufe');
        var dneigung = this.get('dneigung');
        var dneigung2 = this.get('dneigung2');
        var uefirst = this.get('uefirst');

        if (this.get('dachgeometrie') == 0) {
          this.set('dachflaeche', rooftools.satteldachflaecheBerechnen(parseFloat(gbreite), parseFloat(glaenge), parseFloat(uegiebel), parseFloat(uetraufe), parseFloat(dneigung)));
        } else if (this.get('dachgeometrie') == 1) {
          this.set('dachflaeche', rooftools.pultdachflaecheBerechnen(parseFloat(gbreite), parseFloat(glaenge), parseFloat(uegiebel), parseFloat(uetraufe), parseFloat(dneigung), parseFloat(uefirst)));
        } else if (this.get('dachgeometrie') == 2) {
          this.set('dachflaeche', rooftools.walmdachflaecheBerechnen(gbreite, glaenge, uegiebel, uetraufe, dneigung, uefirst));
        }

        this.set('ueberdaemmteflaeche', rooftools.dachflaecheReduzieren(this.get('dachflaeche'), this.get('reduktionsmass')));
      }
    }).observes('fhoehe', 'dneigung', 'dneigung2', 'gbreite', 'glaenge', 'uegiebel', 'uetraufe', 'uefirst', 'reduktionsmass'),

    watchDachgeometrie: (function () {

      if (!this.openFromFile) {

        var gbreite = this.get('gbreite');
        var glaenge = this.get('glaenge');
        var uegiebel = this.get('uegiebel');
        var uetraufe = this.get('uetraufe');
        var dneigung = this.get('dneigung');
        var dneigung2 = this.get('dneigung2');
        var uefirst = this.get('uefirst');

        var self = this;

        var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
        applicationdata.set('treeLoaded', false);
        applicationdata.set('initialized', false);
        applicationdata.set('pdfErstellt', false);
        applicationdata.set('dxfErstellt', false);

        var application = self.controllerFor('application');
        application.set('dachgeometrieInvalid', true);

        var dachgeometrie = self.controllerFor('application').get('model').dachgeometrie.objectAt(0);
        var x3d = self.controllerFor('application').get('model').x3ddefault.objectAt(0);

        if (this.get('dachgeometrie') == 2) {
          this.set('dachflaeche', rooftools.walmdachflaecheBerechnen(gbreite, glaenge, uegiebel, uetraufe, dneigung, uefirst));
          this.set('walmdach', true);
          this.set('pultdach', false);
          dachgeometrie.set('DachFormID', this.get('dachgeometrie'));
          x3d.set('dachgeometrieGeandert', !x3d.get('dachgeometrieGeandert'));
          x3d.get('bauteile').findBy('id', 'dachgeometrie').get('grundmassee').objectAt(0).set('ausfuehrung', 'walmdach');
        } else if (this.get('dachgeometrie') == 1) {
          this.set('dachflaeche', rooftools.pultdachflaecheBerechnen(gbreite, glaenge, uegiebel, uetraufe, dneigung, uefirst));
          this.set('pultdach', true);
          this.set('walmdach', false);
          dachgeometrie.set('DachFormID', this.get('dachgeometrie'));
          x3d.set('dachgeometrieGeandert', !x3d.get('dachgeometrieGeandert'));
          x3d.get('bauteile').findBy('id', 'dachgeometrie').get('grundmassee').objectAt(0).set('ausfuehrung', 'pultdach');
        } else {
          this.set('dachflaeche', rooftools.satteldachflaecheBerechnen(gbreite, glaenge, uegiebel, uetraufe, dneigung));
          this.set('pultdach', false);
          this.set('walmdach', false);
          dachgeometrie.set('DachFormID', this.get('dachgeometrie'));
          x3d.set('dachgeometrieGeandert', !x3d.get('dachgeometrieGeandert'));
          x3d.get('bauteile').findBy('id', 'dachgeometrie').get('grundmassee').objectAt(0).set('ausfuehrung', 'satteldach');
        }
      }
    }).observes('dachgeometrie'),

    watchEingabenFuerLastannahmen: (function () {

      var self = this;
    }).observes('dachgeometrie', 'fhoehe', 'dneigung', 'dneigung2', 'gbreite', 'glaenge', 'uegiebel', 'uetraufe', 'uefirst'),

    actions: {

      validation: function validation(value, event) {

        var self = this;

        if (!self.openFromFile) {
          this.setX3D(value, event);
        }

        self.validate().then(function () {
          // all validations pass

        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');
          if (validation === true) {
            self.set('displayErrors', false);
            application.set('dachgeometrieInvalid', false);

            var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
            applicationdata.set('treeLoaded', false);
            applicationdata.set('initialized', false);
            applicationdata.set('pdfErstellt', false);
            applicationdata.set('dxfErstellt', false);

            application.set('verbindungsmittelInvalid', true);

            var dachgeometriedata = self.controllerFor('application').get('model').dachgeometrie.objectAt(0);

            dachgeometriedata.set('ALFA_Grad', parseFloat(self.get('dneigung')).toFixed(1));
            dachgeometriedata.set('DN_Walm', parseFloat(self.get('dneigung2')).toFixed(1));
            dachgeometriedata.set('hFirst', parseFloat(self.get('fhoehe')).toFixed(1));
            dachgeometriedata.set('LTraufe', parseFloat(self.get('dneigung')).toFixed(1));
            dachgeometriedata.set('gebaeudeBreite', parseFloat(self.get('gbreite')).toFixed(1));
            dachgeometriedata.set('gebaeudeLaenge', parseFloat(self.get('glaenge')).toFixed(1));
            dachgeometriedata.set('Ue_Giebel', parseFloat(self.get('uegiebel')).toFixed(1));
            dachgeometriedata.set('Ue_First', parseFloat(self.get('uefirst')).toFixed(1));
            dachgeometriedata.set('Ue_Traufe', parseFloat(self.get('uetraufe')).toFixed(1));
            dachgeometriedata.set('ueberdaemmteFlaeche', parseFloat(self.get('ueberdaemmteflaeche')).toFixed(1));
            dachgeometriedata.set('A_Dach', parseFloat(self.get('dachflaeche')).toFixed(1));

            // hier gehts los mit windlasten usw.

            self.set('displayErrors', false);
            application.set('lasteinwirkungInvalid', false);

            //universal
            var applicationdata = application.get('model').application.objectAt(0);
            var applicationdataJSON = JSON.stringify(applicationdata);

            var projektdatendata = application.get('model').projektdaten.objectAt(0);
            var projektdatendataJSON = JSON.stringify(projektdatendata);

            //m06

            var bauteiledata = application.get('model').bauteile.objectAt(0);
            var bauteiledataJSON = JSON.stringify(bauteiledata);

            var anschlussgeometriedata = application.get('model').anschlussgeometrie.objectAt(0);
            var anschlussgeometriedataJSON = JSON.stringify(anschlussgeometriedata);

            //m01

            var dachgeometriedata = application.get('model').dachgeometrie.objectAt(0);
            var dachgeometriedataJSON = JSON.stringify(dachgeometriedata);

            var unterkonstruktiondata = application.get('model').unterkonstruktion.objectAt(0);
            var unterkonstruktiondataJSON = JSON.stringify(unterkonstruktiondata);

            var daemmungdata = application.get('model').daemmung.objectAt(0);
            var daemmungdataJSON = JSON.stringify(daemmungdata);

            //universal

            var lasteinwirkung = self.controllerFor('lasteinwirkung');
            var lasteinwirkungdata = application.get('model').lasteinwirkung.objectAt(0);
            var lasteinwirkungdataJSON = JSON.stringify(lasteinwirkungdata);

            var uebergabedaten = $.extend(true, JSON.parse(dachgeometriedataJSON), JSON.parse(unterkonstruktiondataJSON), JSON.parse(daemmungdataJSON), JSON.parse(lasteinwirkungdataJSON));

            //Lastannahmen/Parameter_Strukt.vb

            var timberelementgl = {
              "Querschnittswerte": {
                "t_Fastener": 0, // -> gl hoehe
                "b": daemmungdata.get('glBreite'),
                "h": daemmungdata.get('glHoehe')
              }
            };
            var timberelementsp = {
              "Querschnittswerte": {
                "b": unterkonstruktiondata.get('TimberElementSp_b'),
                "h": unterkonstruktiondata.get('TimberElementSp_h')
              }
            };
            var schneelastparas = {
              "SLZ_ID": lasteinwirkungdata.get('SLZ_ID'),
              "SLZ_Text": lasteinwirkung.slzarray[lasteinwirkungdata.get('SLZ_ID')].name, //inhalt der comobox
              "Region_ID": "Region ID", // nicht notwendig
              "RegionText": "Region Text", // nicht notwendig
              "WindgeschuetzteLage": "Windgeschuetzte Lage", // nicht notwendig
              "Fussnote": "Fussnote", // nicht notwendig
              "Zone": "Zone", // nur bei alten lastannahmen notwendig
              "A": lasteinwirkungdata.get('HuNN'), // HuNN
              "sk": lasteinwirkungdata.get('sk'),
              "DF_1": {
                "ALFA_Grad": dachgeometriedata.get('ALFA_Grad'),
                "Schneefanggitter": lasteinwirkungdata.get('Schneefanggitter')
              }
            };

            uebergabedaten.TimberElementGl = timberelementgl;
            uebergabedaten.TimberElementSp = timberelementsp;
            uebergabedaten.Schneelastparameter = schneelastparas;

            console.log(JSON.stringify(uebergabedaten));

            var JSONdata = JSON.stringify(uebergabedaten);

            //var JSONdata = "{" + dachgeometriedataJSONcut + "," + unterkonstruktiondataJSONcut + "," + daemmungdataJSONcut + "," + lasteinwirkungdataJSONcut + "}";
            //var JSONdata = "{" + bauteileString + "," + timberelementstringht + timberelementstringnt + "," + anschlussgeometriedataJSONcut + "," + lasteinwirkungdataJSONcut + "}";

            // var JSONdata = "{" + projektdatendataJSONcut + "," + bauteiledataJSONcut + "," + timberelementstringht + timberelementstringnt + "," + anschlussgeometriedataJSONcut + "," + lasteinwirkungdataJSONcut + "}";
            if (self.debug) {
              console.log(JSONdata);
            }

            var initialized = applicationdata.get('initialized');
            console.log(initialized);

            if (!initialized) {

              $.ajax({
                type: "POST",
                url: "http://136.243.4.143/berechnungsservice/m01/DE/initialisiereBerechnung/",
                //url: "http://136.243.4.143/berechnungsservice/m06/DE/erzeugeSchraubenliste/",
                data: {
                  kennung: applicationdataJSON,
                  // projektdaten: projektdatendataJSON,
                  paras: JSONdata,
                  modul: "m01",
                  pfad: "D:/swgengineering/bin/"
                }
              }).done(function (data) {

                var cid = data;
                applicationdata.set('Calculation_ID', cid);
                applicationdata.set('initialized', true);

                $.ajax({
                  type: "POST",
                  url: "http://136.243.4.143/berechnungsservice/berechneWindlast/" + cid + "/",
                  data: {
                    cid: cid
                  }
                }).done(function (data) {

                  // rückgabewert als ergebnis in lasteinwirkung setzen

                });

                $.ajax({
                  type: "POST",
                  url: "http://136.243.4.143/berechnungsservice/berechneSchneelast/" + cid + "/",
                  data: {
                    cid: cid
                  }
                }).done(function (data) {

                  // rückgabewert als ergebnis in lasteinwirkung setzen

                });
              });
            } else {

                var cid = applicationdata.get('Calculation_ID');

                $.ajax({
                  type: "POST",
                  url: "http://136.243.4.143/berechnungsservice/berechneWindlast/" + cid + "/",
                  data: {
                    cid: cid
                  }
                }).done(function (data) {

                  // rückgabewert als ergebnis in lasteinwirkung setzen

                });

                $.ajax({
                  type: "POST",
                  url: "http://136.243.4.143/berechnungsservice/berechneSchneelast/" + cid + "/",
                  data: {
                    cid: cid
                  }
                }).done(function (data) {

                  // rückgabewert als ergebnis in lasteinwirkung setzen

                });
              }

            // hier endet das windlast zeug
          } else {
              self.set('displayErrors', true);
              application.set('dachgeometrieInvalid', true);
            }
        });
      },

      fhoeheIsSelected: function fhoeheIsSelected() {
        var firsthoeheAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('firsthoeheAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('firsthoeheAktiv', !firsthoeheAktiv);
        if (this.get('fhoehe') !== "") {
          var fhoehe = parseFloat(this.get('fhoehe').replace(",", "."));
          this.set('fhoehe', fhoehe.toFixed(1));
          this.send('validation');
        }
      },

      dneigungIsSelected: function dneigungIsSelected() {
        var dneigungAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('dneigungAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('dneigungAktiv', !dneigungAktiv);
        if (this.get('dneigung') !== "") {
          var dneigung = parseFloat(this.get('dneigung').replace(",", "."));
          this.set('dneigung', dneigung.toFixed(1));
          this.send('validation');
        }
      },

      dneigung2IsSelected: function dneigung2IsSelected() {
        var dneigung2Aktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('dneigung2Aktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('dneigung2Aktiv', !dneigung2Aktiv);
        if (this.get('dneigung2') !== "") {
          var dneigung = parseFloat(this.get('dneigung').replace(",", "."));
          this.set('dneigung', dneigung.toFixed(1));
          this.send('validation');
        }
      },

      gbreiteIsSelected: function gbreiteIsSelected(name, value) {
        var gbreiteAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('gbreiteAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('gbreiteAktiv', !gbreiteAktiv);
        if (this.get('gbreite') !== "") {
          var gbreite = parseFloat(this.get('gbreite').replace(",", "."));
          this.set('gbreite', gbreite.toFixed(1));
          this.send('validation');
        }
      },

      glaengeIsSelected: function glaengeIsSelected(name, value) {
        var glaengeAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('glaengeAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('glaengeAktiv', !glaengeAktiv);
        if (this.get('glaenge') !== "") {
          var gbreite = parseFloat(this.get('glaenge').replace(",", "."));
          this.set('glaenge', glaenge.toFixed(1));
          this.send('validation');
        }
      },

      uegiebelIsSelected: function uegiebelIsSelected(name, value) {
        var uegiebelAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('uegiebelAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('uegiebelAktiv', !uegiebelAktiv);
        if (this.get('uegiebel') !== "") {
          var uegiebel = parseFloat(this.get('uegiebel').replace(",", "."));
          this.set('uegiebel', uegiebel.toFixed(1));
          this.send('validation');
        }
      },

      uetraufeIsSelected: function uetraufeIsSelected(name, value) {
        var uetraufeAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('uetraufeAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('uetraufeAktiv', !uetraufeAktiv);
        if (this.get('uetraufe') !== "") {
          var uetraufe = parseFloat(this.get('uetraufe').replace(",", "."));
          this.set('uetraufe', uetraufe.toFixed(1));
          this.send('validation');
        }
      },

      uefirstIsSelected: function uefirstIsSelected(name, value) {
        var uefirstAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('uefirstAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('uefirstAktiv', !uefirstAktiv);
        if (this.get('uefirst') !== "") {
          var uefirst = parseFloat(this.get('uefirst').replace(",", "."));
          this.set('uefirst', uetraufe.toFixed(1));
          this.send('validation');
        }
      }
    },

    setX3D: function setX3D(value, event) {

      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');
      var self = this;

      switch (event.target.name) {
        case 'fhoehe':
          if (this.errors.fhoehe.length === 0) {
            value = parseFloat(value).toFixed(2);
            bauteile.findBy('id', 'dachgeometrie').get('grundmassee').objectAt(0).set('firsthoehe', value);
            x3d.set('firsthoeheEingetragen', true);
          } else {
            x3d.set('firsthoeheEingetragen', false);
          }
          break;

        case 'dneigung':
          if (this.errors.dneigung.length === 0) {
            value = parseFloat(value).toFixed(1);
            bauteile.findBy('id', 'dachgeometrie').get('grundmassee').objectAt(0).set('dachneigung', value);
            x3d.set('dneigungEingetragen', true);

            var gebLaenge = x3d.get('bauteile').findBy('id', 'dachgeometrie').get('grundmassee').objectAt(0).get('gebaeudelaenge');
            var gebBreite = x3d.get('bauteile').findBy('id', 'dachgeometrie').get('grundmassee').objectAt(0).get('gebaeudebreite');
            var dachhoehe = gebBreite / 2 * Math.tan(value * Math.PI / 180);
            var minDneigung2 = Math.atan(dachhoehe / (gebLaenge / 2)) * 180 / Math.PI;

            this.set('validations.dneigung2.numericality.greaterThanOrEqualTo', minDneigung2);
            this.set('dneigung2', minDneigung2.toFixed(1));
          } else {
            x3d.set('dneigungEingetragen', false);
          }
          break;

        case 'dneigung2':
          if (this.errors.dneigung2.length === 0) {
            value = parseFloat(value).toFixed(1);

            var gebLaenge = x3d.get('bauteile').findBy('id', 'dachgeometrie').get('grundmassee').objectAt(0).get('gebaeudelaenge');
            var gebBreite = x3d.get('bauteile').findBy('id', 'dachgeometrie').get('grundmassee').objectAt(0).get('gebaeudebreite');
            var dn1 = x3d.get('bauteile').findBy('id', 'dachgeometrie').get('grundmassee').objectAt(0).get('dachneigung');
            var dachhoehe = gebBreite / 2 * Math.tan(dn1 * Math.PI / 180);

            if (gebLaenge - 2 * (dachhoehe / Math.tan(value * Math.PI / 180)) > 0) {
              bauteile.findBy('id', 'dachgeometrie').get('grundmassee').objectAt(0).set('dachneigung2', value);
              x3d.set('dneigung2Eingetragen', true);
            }
          } else {
            x3d.set('dneigung2Eingetragen', false);
          }
          break;
        case 'gbreite':
          if (this.errors.gbreite.length === 0) {
            value = parseFloat(value).toFixed(1);
            bauteile.findBy('id', 'dachgeometrie').get('grundmassee').objectAt(0).set('gebaeudebreite', value);
            x3d.set('gbreiteEingetragen', true);
          } else {
            x3d.set('gbreiteEingetragen', false);
          }
          break;
        case 'glaenge':
          if (this.errors.glaenge.length === 0) {
            value = parseFloat(value).toFixed(1);
            bauteile.findBy('id', 'dachgeometrie').get('grundmassee').objectAt(0).set('gebaeudelaenge', value);
            x3d.set('glaengeEingetragen', true);
          } else {
            x3d.set('glaengeEingetragen', false);
          }
          break;
        case 'uegiebel':
          if (this.errors.uegiebel.length === 0) {
            value = parseFloat(value).toFixed(1);
            bauteile.findBy('id', 'dachgeometrie').get('grundmassee').objectAt(0).set('giebelueberstand', value);
            x3d.set('uegiebelEingetragen', true);
          } else {
            x3d.set('uegiebelEingetragen', false);
          }
          break;
        case 'uetraufe':
          if (this.errors.uetraufe.length === 0) {
            value = parseFloat(value).toFixed(1);
            bauteile.findBy('id', 'dachgeometrie').get('grundmassee').objectAt(0).set('traufeueberstand', value);
            x3d.set('uetraufeEingetragen', true);
          } else {
            x3d.set('uetraufeEingetragen', false);
          }
          break;
        case 'uefirst':
          if (this.errors.uefirst.length === 0) {
            value = parseFloat(value).toFixed(1);
            bauteile.findBy('id', 'dachgeometrie').get('grundmassee').objectAt(0).set('firstueberstand', value);
            x3d.set('uefirstEingetragen', true);
          } else {
            x3d.set('uefirstEingetragen', false);
          }
          break;
      }
      x3d.set('transformHelper', !x3d.get('transformHelper'));
    }
  });

});