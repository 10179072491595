define('m0601/routes/application', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({

    model: function model() {
      return Ember['default'].RSVP.hash({
        application: this.store.findAll('application'),
        projektdaten: this.store.findAll('projektdaten'),
        bauteile: this.store.findAll('bauteile'),
        anschlussgeometrie: this.store.findAll('anschlussgeometrie'),
        lasteinwirkung: this.store.findAll('lasteinwirkung'),
        x3ddefault: this.store.findAll('x3ddefault'),

        dachgeometrie: this.store.findAll('dachgeometrie'),
        unterkonstruktion: this.store.findAll('unterkonstruktion'),
        daemmung: this.store.findAll('daemmung'),

        system: this.store.findAll('system'),
        auflager: this.store.findAll('auflager'),
        konstruktion: this.store.findAll('konstruktion'),
        einwirkung: this.store.findAll('einwirkung'),
        materialien: this.store.findAll('materialien'),
        schwingungsnachweis: this.store.findAll('schwingungsnachweis'),
        optionen: this.store.findAll('optionen'),
        schraubenvorauswahl: this.store.findAll('schraubenvorauswahl'),
        einzelneeinwirkung: this.store.findAll('einzelneeinwirkung'),
        verbindungsmittel: this.store.findAll('verbindungsmittel')

      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.watchingTextSize(controller, false);
    },

    watchingTextSize: function watchingTextSize(controller, loaded) {
      var self = this;

      loaded = document.getElementById('x3d') !== null ? true : loaded;

      if (loaded) {
        (function () {
          var setX3DSize = function setX3DSize() {
            var x3dGrafik = document.getElementById("threedee");
            var x3d = document.getElementById("x3d");

            x3d.setAttribute('width', Number($(window).width()) - Number(x3dGrafik.offsetLeft) - 20);
            x3d.setAttribute('height', Number($(window).height()) - 300);
          };

          // Ember.run.debounce( function(){
          //   document.getElementById('x3d').runtime.enterFrame = function(){
          //     let vp = controller.model.x3ddefault.objectAt(0).get('lastViewpoint');
          //     let skalfak = Math.abs(document.getElementById(vp).getFieldValue('fieldOfView')[0])/4;

          //       controller.model.x3ddefault.objectAt(0).set('skalierungsfaktor', skalfak);
          //   };
          // }, 500);

          $(document).ready(function () {
            setX3DSize();
          });

          $(window).resize(function () {
            setX3DSize();
          });
        })();
      } else {
        (function () {
          var debounce = Ember['default'].run.debounce(function () {

            try {

              document.getElementById('x3d').runtime.enterFrame = function () {

                Ember['default'].run.cancel(debounce);
              };
            } catch (error) {
              console.log('/routes/application.js: x3dom need reload');
              x3dom.reload();
              self.watchingTextSize(controller, false);
            }
          }, 500);
        })();
      }
    }

  });

});