define('m0601/components/indexedlinese-t', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Transform = Ember['default'].Component.extend({

    tagName: 'transform',
    layoutName: 'indexedlinese-t',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    objektSkalierung: 1,

    translation: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var bezug = this.get('typ');

      var x = 0;
      var y = 0;
      var z = 0;

      var cords;

      var item = x3d.objectAt(0).get('bauteile').findBy('id', name);

      if (name === 'lastenPfeil') {
        x = item.get('translations').objectAt(0).get('x');
        y = item.get('translations').objectAt(0).get('y');
        z = item.get('translations').objectAt(0).get('z');
      }

      cords = x + ' ' + y + ' ' + z;

      return cords;
    }).property('model.firstObject.transformHelper'),

    rotation: (function () {
      var name = this.get('name');
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var values;

      var item = x3d.objectAt(0).get('bauteile').findBy('id', name);

      if (name === 'lastenPfeil') {
        values = item.get('rotations').objectAt(0).get('x') + " " + item.get('rotations').objectAt(0).get('y') + " " + item.get('rotations').objectAt(0).get('z') + " " + item.get('rotations').objectAt(0).get('winkel');
      }

      if (name === 'hWinkel') {
        values = '1 0 0 -1.5707';
      }

      return values;
    }).property('model.firstObject.transformHelper'),

    koordinaten: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      var hwinkel = Number(x3d.get('hWinkel')) * Math.PI / 180;
      var vwinkel = Number(x3d.get('vWinkel')) * Math.PI / 180;

      var x = 0;
      var y = 0;
      var z = 0;

      // var skalierungsfaktor = x3d.get('skalierungsfaktor');
      var skalierungsfaktor = 1;

      var cords;

      var p1 = new Object();
      var p2 = new Object();

      var drehachsen = new Object();
      var drehwinkel = new Object();

      var w90Grad = 89 * Math.PI / 180;
      var w180Grad = 180 * Math.PI / 180;

      var hoeheMasskette = 0.5;
      var abstandVomBauteil = 0.15;
      var korrektur = 0.05;

      var item = x3d.get('bauteile').findBy('id', name);

      if (item.get('typ') === 'box') {

        if (name === 'lager1' || name === 'lager2' || name === 'aufgehendesbauteil1' || name === 'aufgehendesbauteil2') {
          x = item.get('boxsizes').objectAt(0).get('x') / 10;
          z = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('tiefeUnterkonstruktion') / 10 + bauteile.findBy('id', 'unterkonstruktion2').get('boxsizes').objectAt(0).get('z') / 10;
        } else if (name.substring(0, 17) === 'unterkonstruktion') {
          x = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 10;

          // if (bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('deckenkonstruktion')  === 'balken'){
          z = this.get('model').objectAt(0).get('bauteile').findBy('id', name).get('boxsizes').objectAt(0).get('z') / 10;
          // }else if (bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('deckenkonstruktion')  === 'holzelement'){
          //   z = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('tiefeUnterkonstruktion')/20;
          // }
        } else {
            x = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 10;
            z = item.get('boxsizes').objectAt(0).get('z') / 10;
          }

        y = item.get('boxsizes').objectAt(0).get('y') / 10;

        // if(name === "beton" && bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('deckenkonstruktion') === "dippelbaum"){
        //   y = y + bauteile.findBy('id', 'unterkonstruktion1').get('boxsizes').objectAt(0).get('y')/10;
        // }

        if (typ === 'kontur') {
          cords = this.getKonturKoordinaten(x, y, z);
        }

        if (typ === 'faserrichtung') {
          if (item.id.substring(0, 7) === 'sparren' || item.id.substring(0, 10) === 'grundlatte' || item.id.substring(0, 17) === 'traufkonstruktion') {

            skalierungsfaktor = 1;
            var delta = 0.15;

            x = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20;
            if (item.get('boxsizes').objectAt(0).get('y') / 10 > 0.45) {
              y = 0.15;
            } else {
              delta = item.get('boxsizes').objectAt(0).get('y') / 25;
              y = item.get('boxsizes').objectAt(0).get('y') / 20 - delta;
            }
            z = item.get('boxsizes').objectAt(0).get('z') / 20;

            cords = -x + 0.15 + delta + ' ' + (y + delta * 2) + ' ' + z + ', ' + (-x + 0.15) + ' ' + (y + delta) + ' ' + z + ', ' + (-x + 1) + ' ' + (y + delta) + ' ' + z + ', ' + (-x + 0.85) + ' ' + y + ' ' + z;
          }
        }

        if (typ === 'breite') {
          switch (name) {
            case 'lager2':
              p1.x = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 20 + bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20;
              p1.y = 0;
              p1.z = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('tiefeUnterkonstruktion') / 20 + bauteile.findBy('id', 'unterkonstruktion2').get('boxsizes').objectAt(0).get('z') / 20;
              p2.x = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 20 - bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20;
              p2.y = 0;
              p2.z = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('tiefeUnterkonstruktion') / 20 + bauteile.findBy('id', 'unterkonstruktion2').get('boxsizes').objectAt(0).get('z') / 20;

              if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
                drehwinkel.x = w90Grad;
                drehwinkel.y = 0;
                drehwinkel.z = 0;
                p1.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20 + bauteile.findBy('id', 'beton').get('boxsizes').objectAt(0).get('z') / 20 - bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;
                p2.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20 + bauteile.findBy('id', 'beton').get('boxsizes').objectAt(0).get('z') / 20 - bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;
              } else {
                drehwinkel.x = w180Grad;
                drehwinkel.y = 0;
                drehwinkel.z = 0;
              }
              cords = this.getMassketteKoordinaten(p1, p2, drehachsen, drehwinkel);
              break;
            case 'lager1':
              p1.x = -bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 20 + bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20;
              p1.y = 0;
              p1.z = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('tiefeUnterkonstruktion') / 20 + bauteile.findBy('id', 'unterkonstruktion2').get('boxsizes').objectAt(0).get('z') / 20;
              p2.x = -bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 20 - bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20;
              p2.y = 0;
              p2.z = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('tiefeUnterkonstruktion') / 20 + bauteile.findBy('id', 'unterkonstruktion2').get('boxsizes').objectAt(0).get('z') / 20;

              if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
                drehwinkel.x = w90Grad;
                drehwinkel.y = 0;
                drehwinkel.z = 0;
                p1.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20 + bauteile.findBy('id', 'beton').get('boxsizes').objectAt(0).get('z') / 20 - bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;
                p2.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20 + bauteile.findBy('id', 'beton').get('boxsizes').objectAt(0).get('z') / 20 - bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;
              } else {
                drehwinkel.x = w180Grad;
                drehwinkel.y = 0;
                drehwinkel.z = 0;
              }
              cords = this.getMassketteKoordinaten(p1, p2, drehachsen, drehwinkel);
              break;
            case 'unterkonstruktion1':
              p1.x = -(bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 20) - bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('x') / 20;
              p1.y = -bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10;
              p1.z = -bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;

              p2.x = -(bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 20) - bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('x') / 20;
              p2.y = -bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10;
              p2.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;

              if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
                drehwinkel.x = -w90Grad;
                drehwinkel.y = w90Grad;
                drehwinkel.z = 0;
              } else {
                drehwinkel.x = w180Grad;
                drehwinkel.y = w90Grad;
                drehwinkel.z = 0;
              }
              cords = this.getMassketteKoordinaten(p1, p2, drehachsen, drehwinkel);
              break;
            case 'beton':
              p1.x = -(bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 20);
              p1.y = -bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10 - bauteile.findBy('id', 'unterkonstruktion1').get('boxsizes').objectAt(0).get('y') / 10 - bauteile.findBy('id', 'schalung').get('boxsizes').objectAt(0).get('y') / 10 - hoeheMasskette - abstandVomBauteil;
              p1.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;

              p2.x = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 20;
              p2.y = -bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10 - bauteile.findBy('id', 'unterkonstruktion1').get('boxsizes').objectAt(0).get('y') / 10 - bauteile.findBy('id', 'schalung').get('boxsizes').objectAt(0).get('y') / 10 - hoeheMasskette - abstandVomBauteil;
              p2.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;

              if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
                drehwinkel.x = w90Grad;
                drehwinkel.y = 0;
                drehwinkel.z = 0;
                p1.y = -bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10 - bauteile.findBy('id', 'unterkonstruktion1').get('boxsizes').objectAt(0).get('y') / 10 - bauteile.findBy('id', 'schalung').get('boxsizes').objectAt(0).get('y') / 10;
                p2.y = -bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10 - bauteile.findBy('id', 'unterkonstruktion1').get('boxsizes').objectAt(0).get('y') / 10 - bauteile.findBy('id', 'schalung').get('boxsizes').objectAt(0).get('y') / 10;
                p1.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20 + abstandVomBauteil + hoeheMasskette;
                p2.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20 + abstandVomBauteil + hoeheMasskette;
              } else {
                drehwinkel.x = w180Grad;
                drehwinkel.y = 0;
                drehwinkel.z = 0;
              }
              cords = this.getMassketteKoordinaten(p2, p1, drehachsen, drehwinkel);
              break;
          }
        }

        if (typ === 'tiefe') {
          switch (name) {
            case 'lager1':
              p1.x = -bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 20 - bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20;
              p1.y = -hoeheMasskette - abstandVomBauteil;
              p1.z = -(bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('tiefeUnterkonstruktion') / 20);

              p2.x = -bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 20 - bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20;
              p2.y = -hoeheMasskette - abstandVomBauteil;
              p2.z = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('tiefeUnterkonstruktion') / 20;

              if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
                drehwinkel.x = 0;
                drehwinkel.y = w90Grad;
                drehwinkel.z = w90Grad;
                p1.x = p1.x - hoeheMasskette - abstandVomBauteil;
                p2.x = p2.x - hoeheMasskette - abstandVomBauteil;
                p1.y = 0;
                p2.y = 0;
              } else {
                drehwinkel.x = 0;
                drehwinkel.y = w90Grad;
                drehwinkel.z = w180Grad;
              }
              cords = this.getMassketteKoordinaten(p1, p2, drehachsen, drehwinkel);
              break;
          }
        }

        if (typ === 'hoehe') {
          switch (name) {
            case 'beton':
              var xVerschiebung = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 20 + bauteile.findBy('id', 'lager2').get('boxsizes').objectAt(0).get('x') / 20;

              p1.x = xVerschiebung;
              p1.y = 0;
              p1.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;

              p2.x = xVerschiebung;
              p2.y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10;
              p2.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;

              if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
                drehwinkel.x = w90Grad;
                drehwinkel.y = 0;
                drehwinkel.z = -w90Grad;
              } else {
                drehwinkel.x = 0;
                drehwinkel.y = 0;
                drehwinkel.z = -w90Grad;
              }
              cords = this.getMassketteKoordinaten(p1, p2, drehachsen, drehwinkel);
              break;
            case 'schalung':
              var xVerschiebung = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 20 + bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('x') / 20;

              p1.x = -xVerschiebung;
              p1.y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10;
              p1.z = bauteile.findBy('id', 'beton').get('boxsizes').objectAt(0).get('z') / 20;

              p2.x = -xVerschiebung;
              p2.y = 0;
              p2.z = bauteile.findBy('id', 'beton').get('boxsizes').objectAt(0).get('z') / 20;
              if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
                drehwinkel.x = -w90Grad;
                drehwinkel.y = 0;
                drehwinkel.z = -w90Grad;
                p1.y = 0;
                p2.y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10;
                p1.z = -p1.z;
                p2.z = -p2.z;
              } else {
                drehwinkel.x = 0;
                drehwinkel.y = 0;
                drehwinkel.z = w90Grad;
              }
              cords = this.getMassketteKoordinaten(p1, p2, drehachsen, drehwinkel);
              break;
            case 'unterkonstruktion2':
              var xVerschiebung = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 20 + bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('x') / 20;

              p1.x = xVerschiebung;
              p1.y = bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10;
              p1.z = bauteile.findBy('id', 'beton').get('boxsizes').objectAt(0).get('z') / 20;

              p2.x = xVerschiebung;
              p2.y = bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10 + bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10;
              p2.z = bauteile.findBy('id', 'beton').get('boxsizes').objectAt(0).get('z') / 20;

              if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
                drehwinkel.x = w90Grad;
                drehwinkel.y = 0;
                drehwinkel.z = -w90Grad;
              } else {
                drehwinkel.x = 0;
                drehwinkel.y = 0;
                drehwinkel.z = -w90Grad;
              }
              cords = this.getMassketteKoordinaten(p1, p2, drehachsen, drehwinkel);
              break;
          }
        }
      }

      if (typ === 'randabstandlinks') {
        p1.x = -(bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 20);
        p1.y = -2 * hoeheMasskette - 2 * abstandVomBauteil;
        p1.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;

        p2.x = -(bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 20) + bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('auflager1RandRechts') / 10;
        p2.y = -2 * hoeheMasskette - 2 * abstandVomBauteil;
        p2.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;

        if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
          drehwinkel.x = w90Grad;
          drehwinkel.y = 0;
          drehwinkel.z = 0;
          p1.y = 0;
          p2.y = 0;
          p1.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20 + 2 * hoeheMasskette + 2 * abstandVomBauteil;
          p2.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20 + 2 * hoeheMasskette + 2 * abstandVomBauteil;
        } else {
          drehwinkel.x = w180Grad;
          drehwinkel.y = 0;
          drehwinkel.z = 0;
        }
        cords = this.getMassketteKoordinaten(p2, p1, drehachsen, drehwinkel);
      }

      if (typ === 'randabstandrechts') {
        p1.x = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 20 - bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('auflager2RandLinks') / 10;
        p1.y = -2 * hoeheMasskette - 2 * abstandVomBauteil;
        p1.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;

        p2.x = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 20;
        p2.y = -2 * hoeheMasskette - 2 * abstandVomBauteil;
        p2.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;

        if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
          drehwinkel.x = w90Grad;
          drehwinkel.y = 0;
          drehwinkel.z = 0;
          p1.y = 0;
          p2.y = 0;
          p1.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20 + 2 * hoeheMasskette + 2 * abstandVomBauteil;
          p2.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20 + 2 * hoeheMasskette + 2 * abstandVomBauteil;
        } else {
          drehwinkel.x = w180Grad;
          drehwinkel.y = 0;
          drehwinkel.z = 0;
        }
        cords = this.getMassketteKoordinaten(p2, p1, drehachsen, drehwinkel);
      }

      if (name === 'grundkonstruktion') {
        var skalierung = this.objektSkalierung;

        var laengeF1 = item.get('grundmasse').objectAt(0).get('gebaeudebreite') / skalierung;
        var tiefeUnterkonstruktion = item.get('grundmasse').objectAt(0).get('gebaeudelaenge') / skalierung;

        switch (typ) {
          case 'laenge':
            p1.x = item.get('grundmasse').objectAt(0).get('gebaeudelaenge') / 2;
            p1.y = 0;
            p1.z = item.get('grundmasse').objectAt(0).get('gebaeudebreite') / 2;

            p2.x = -(item.get('grundmasse').objectAt(0).get('gebaeudelaenge') / 2);
            p2.y = 0;
            p2.z = item.get('grundmasse').objectAt(0).get('gebaeudebreite') / 2;

            drehwinkel.x = w180Grad;
            drehwinkel.y = 0;
            drehwinkel.z = 0;

            cords = this.getMassketteKoordinaten(p1, p2, drehachsen, drehwinkel);
            break;
        }
      }

      if (name.substring(0, 8) === 'schraube' && x3d.get('ergebnisGeladen')) {

        var p0, p3, p4, p5, p6, p7, p8, p9;

        var b05 = 0.05 * skalierungsfaktor;
        var b1 = 0.1 * skalierungsfaktor;
        var b5 = 0.5 * skalierungsfaktor;
        var b45 = b5 - b05;
        var b55 = b5 + b05;
        var b6 = 0.6 * skalierungsfaktor;

        var xVerschiebung = item.get('translations').objectAt(0).get('x') / 10;
        var yVerschiebung = item.get('translations').objectAt(0).get('y') / 10;
        var zVerschiebung = item.get('translations').objectAt(0).get('z') / 10;

        var schraubenlaenge = (item.get('screwProperties').objectAt(0).get('length') + item.get('screwProperties').objectAt(0).get('headheight')) / 10;
        var alpha = item.get('rotations').objectAt(0).get('z');
        var beta = item.get('rotations').objectAt(0).get('y');

        if (alpha > 1.5707 && !x3d.get('schraubenGekreuzt')) {
          alpha = 1.5707 - (alpha - 1.5707);
          xVerschiebung = xVerschiebung * -1;
        }

        var delta = item.get('screwProperties').objectAt(0).get('headdiameter') / 20 * Math.cos(alpha);

        var posFaktor = 1;
        if (alpha > 1.5707) {
          posFaktor = -1;
        }

        var xKopfPos = Math.cos(beta) * (Math.cos(alpha) * schraubenlaenge) + xVerschiebung;
        var yKopfPos = -(Math.sin(alpha) * schraubenlaenge) - yVerschiebung;
        var zKopfPos = Math.sin(beta) * (Math.cos(alpha) * schraubenlaenge) - zVerschiebung;

        var xBezugspunkt = 0;
        var yBezugspunkt = 0;
        var zBezugspunkt = 0;

        if (x3d.get('istHt')) {
          zBezugspunkt = x3d.get('bauteile').findBy('id', 'sparren2').get('boxsizes').objectAt(0).get('z') / 20 + 0.25;
        } else {
          zBezugspunkt = x3d.get('bauteile').findBy('id', 'sparren2').get('boxsizes').objectAt(0).get('y') / 20 + 0.25;
        }

        b5 = 0.2 * skalierungsfaktor;
        b45 = b5 - b05;
        b55 = b5 + b05;
        b6 = 0.3 * skalierungsfaktor;

        if (typ === 'xPos') {
          p0 = xKopfPos + ' ' + yBezugspunkt + ' ' + -zBezugspunkt;
          p1 = xBezugspunkt + ' ' + yBezugspunkt + ' ' + -zBezugspunkt;
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') || bauteile.findBy('id', 'viewpointDefault').get('cameraProperties').objectAt(0).get('setBind')) {
            p2 = xBezugspunkt + ' ' + yBezugspunkt + ' ' + -(zBezugspunkt + b6);
            p3 = xKopfPos + ' ' + yBezugspunkt + ' ' + -(zBezugspunkt + b6);
            p4 = xKopfPos + posFaktor * b1 + ' ' + yBezugspunkt + ' ' + -(zBezugspunkt + b5);
            p5 = xBezugspunkt - posFaktor * b1 + ' ' + yBezugspunkt + ' ' + -(zBezugspunkt + b5);
            p6 = xBezugspunkt - b05 + ' ' + yBezugspunkt + ' ' + -(zBezugspunkt + b45);
            p7 = xBezugspunkt + b05 + ' ' + yBezugspunkt + ' ' + -(zBezugspunkt + b55);
            p8 = xKopfPos - b05 + ' ' + yBezugspunkt + ' ' + -(zBezugspunkt + b45);
            p9 = xKopfPos + b05 + ' ' + yBezugspunkt + ' ' + -(zBezugspunkt + b55);
          } else if (bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind')) {
            p2 = xBezugspunkt + ' ' + (yBezugspunkt + b6) + ' ' + -zBezugspunkt;
            p3 = xKopfPos + ' ' + (yBezugspunkt + b6) + ' ' + -zBezugspunkt;
            p4 = xKopfPos + posFaktor * b1 + ' ' + (yBezugspunkt + b5) + ' ' + -zBezugspunkt;
            p5 = xBezugspunkt - posFaktor * b1 + ' ' + (yBezugspunkt + b5) + ' ' + -zBezugspunkt;
            p6 = xBezugspunkt - b05 + ' ' + (yBezugspunkt + b45) + ' ' + -zBezugspunkt;
            p7 = xBezugspunkt + b05 + ' ' + (yBezugspunkt + b55) + ' ' + -zBezugspunkt;
            p8 = xKopfPos - b05 + ' ' + (yBezugspunkt + b45) + ' ' + -zBezugspunkt;
            p9 = xKopfPos + b05 + ' ' + (yBezugspunkt + b55) + ' ' + -zBezugspunkt;
          }
        }

        if (typ === 'zPos') {

          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') && !x3d.objectAt(0).get('istHt')) {
            xBezugspunkt = x3d.get('bauteile').findBy('id', 'sparren2').get('boxsizes').objectAt(0).get('x') / 10 + 0.75;
          } else {
            xBezugspunkt = x3d.get('bauteile').findBy('id', 'sparren2').get('boxsizes').objectAt(0).get('x') / 10 + 0.3;
          }

          if (zKopfPos > 0) {
            posFaktor = 1;
            zBezugspunkt = -x3d.get('bauteile').findBy('id', 'sparren2').get('boxsizes').objectAt(0).get('z') / 20;
          } else {
            posFaktor = -1;
            zBezugspunkt = x3d.get('bauteile').findBy('id', 'sparren2').get('boxsizes').objectAt(0).get('z') / 20;
          }

          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') || bauteile.findBy('id', 'viewpointDefault').get('cameraProperties').objectAt(0).get('setBind')) {
            if (zKopfPos > 0) {
              xBezugspunkt = xBezugspunkt + 0.4;
            }
            p0 = xBezugspunkt + ' ' + yBezugspunkt + ' ' + -zKopfPos;
            p1 = xBezugspunkt + ' ' + yBezugspunkt + ' ' + zBezugspunkt;
            p2 = xBezugspunkt + b6 + ' ' + yBezugspunkt + ' ' + zBezugspunkt;
            p3 = xBezugspunkt + b6 + ' ' + yBezugspunkt + ' ' + -zKopfPos;
            p4 = xBezugspunkt + b5 + ' ' + yBezugspunkt + ' ' + (-zKopfPos + b1 * posFaktor);
            p5 = xBezugspunkt + b5 + ' ' + yBezugspunkt + ' ' + (zBezugspunkt - b1 * posFaktor);
            p6 = xBezugspunkt + b45 + ' ' + yBezugspunkt + ' ' + (zBezugspunkt - b05);
            p7 = xBezugspunkt + b55 + ' ' + yBezugspunkt + ' ' + (zBezugspunkt + b05);
            p8 = xBezugspunkt + b45 + ' ' + yBezugspunkt + ' ' + (-zKopfPos - b05);
            p9 = xBezugspunkt + b55 + ' ' + yBezugspunkt + ' ' + (-zKopfPos + b05);
          } else if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            if (zKopfPos > 0) {
              yBezugspunkt = yBezugspunkt + 0.4;
            }
            p0 = xBezugspunkt + ' ' + yBezugspunkt + ' ' + -zKopfPos;
            p1 = xBezugspunkt + ' ' + yBezugspunkt + ' ' + zBezugspunkt;
            p2 = xBezugspunkt + ' ' + (yBezugspunkt + b6) + ' ' + zBezugspunkt;
            p3 = xBezugspunkt + ' ' + (yBezugspunkt + b6) + ' ' + -zKopfPos;
            p4 = xBezugspunkt + ' ' + (yBezugspunkt + b5) + ' ' + (-zKopfPos + b1 * posFaktor);
            p5 = xBezugspunkt + ' ' + (yBezugspunkt + b5) + ' ' + (zBezugspunkt - b1 * posFaktor);
            p6 = xBezugspunkt + ' ' + (yBezugspunkt + b45) + ' ' + (zBezugspunkt - b05);
            p7 = xBezugspunkt + ' ' + (yBezugspunkt + b55) + ' ' + (zBezugspunkt + b05);
            p8 = xBezugspunkt + ' ' + (yBezugspunkt + b45) + ' ' + (-zKopfPos - b05);
            p9 = xBezugspunkt + ' ' + (yBezugspunkt + b55) + ' ' + (-zKopfPos + b05);
          }
        }
        cords = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + p8 + ', ' + p9;
      }

      if (typ === 'lastenPfeil') {
        cords = '0 0 0 0 1 0';
      }

      return cords;
    }).property('model.firstObject.transformHelper', 'model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.istHt', 'model.firstObject.skalierungsfaktor', 'model.firstObject.schraubenGekreuzt', 'model.firstObject.viewpointHelper'),

    koordinatenIndex: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var values = "1 2 -1 0 3 -1 4 5 -1 6 7 -1 8 9 -1";

      var item = x3d.objectAt(0).get('bauteile').findBy('id', name);

      if (item.get('typ') === 'box') {

        if (typ === 'kontur') {
          values = "0 1 2 3 0 -1 4 5 6 7 4 -1 0 4 -1 1 5 -1 2 6 -1 3 7";
        }

        if (typ === 'faserrichtung') {
          values = "0 1 -1 1 2 -1 2 3 -1";
        }
      } else {
        if (typ === 'lastenPfeil') {
          values = "0 1 -1";
        }

        if (typ === 'hWinkel') {
          values = "0 1 -1 0 2 -1";
        }
        if (typ === 'vWinkel') {
          values = "0 1 -1 0 2 -1";
        }

        if (name.substring(0, 8) === 'schraube' && x3d.objectAt(0).get('ergebnisGeladen')) {
          if (typ === 'xPos' && bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            values = "0 0";
          } else if (typ === 'zPos' && bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind')) {
            values = "0 0";
          } else {
            values = "1 2 -1 0 3 -1 4 5 -1 6 7 -1 8 9 -1";
          }
        }
      }

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt'),

    istLastenpfeil: (function () {
      var istLastenpfeil = false;

      if (this.get('name') === 'lastenPfeil') {
        istLastenpfeil = true;
      }
      return istLastenpfeil;
    }).property(''),

    emissivecolor: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);

      var emsvcolor = '0 0 0';

      // if(typ !== 'kontur'){
      //   console.log('name: '+name+ ' ::: typ: '+typ);
      // }

      if (x3d.get('hoeheAktiv') === true && name === 'unterkonstruktion2' && typ === 'hoehe') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('breiteAktiv') === true && name === 'unterkonstruktion1' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('bAbstandAktiv') === true && name === 'lager1' && typ === 'tiefe') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('auflager1breiteAktiv') === true && name === 'lager1' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('auflager2breiteAktiv') === true && name === 'lager2' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('laengeF1Aktiv') === true && name === 'beton' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('f1aAktiv') === true && name === 'schalung' && typ === 'randabstandrechts') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('f2aAktiv') === true && name === 'schalung' && typ === 'randabstandlinks') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('laengeF1Aktiv') === true && name === 'beton' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('schalungAktiv') === true && name === 'schalung' && typ === 'hoehe') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('bDickeAktiv') === true && name === 'beton' && typ === 'hoehe') {
        emsvcolor = '1 0 0';
      }

      return emsvcolor;
    }).property('model.firstObject.breiteAktiv', 'model.firstObject.hoeheAktiv', 'model.firstObject.auflager1breiteAktiv', 'model.firstObject.auflager2breiteAktiv', 'model.firstObject.bAbstandAktiv', 'model.firstObject.f1aAktiv', 'model.firstObject.f2aAktiv', 'model.firstObject.laengeF1Aktiv', 'model.firstObject.schalungAktiv', 'model.firstObject.bDickeAktiv'),

    getUrsprungMasskette: function getUrsprungMasskette(ursprung, richtung, verschiebung) {

      ursprung.x = ursprung.x + richtung.x * verschiebung;
      ursprung.y = ursprung.y + richtung.y * verschiebung;
      ursprung.z = ursprung.z + richtung.z * verschiebung;

      return ursprung;
    },

    getKonturKoordinaten: function getKonturKoordinaten(x, y, z) {

      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var name = this.get('name');
      var randAbstandLinks = 0;
      var randAbstandRechts = 0;

      if (name === 'schalung' || name === 'beton') {
        if (bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('auflager1Durchlaufend')) {
          randAbstandLinks = -bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('x') / 20;
        } else {
          randAbstandLinks = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('auflager1RandRechts') / 10;
        }

        if (bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('auflager2Durchlaufend')) {
          randAbstandRechts = -bauteile.findBy('id', 'lager2').get('boxsizes').objectAt(0).get('x') / 20;
        } else {
          randAbstandRechts = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('auflager2RandLinks') / 10;
        }
      }

      if (name.substring(0, 17) === 'unterkonstruktion') {
        randAbstandLinks = -bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('x') / 20;
        randAbstandRechts = -bauteile.findBy('id', 'lager2').get('boxsizes').objectAt(0).get('x') / 20;
      }

      var p0 = -x / 2 + randAbstandLinks + ' ' + 0 + ' ' + -z / 2;
      var p1 = -x / 2 + randAbstandLinks + ' ' + 0 + ' ' + z / 2;
      var p2 = x / 2 - randAbstandRechts + ' 0 ' + z / 2;
      var p3 = x / 2 - randAbstandRechts + ' 0 ' + -z / 2;
      var p4 = -x / 2 + randAbstandLinks + ' ' + y + ' ' + -z / 2;
      var p5 = -x / 2 + randAbstandLinks + ' ' + y + ' ' + z / 2;
      var p6 = x / 2 - randAbstandRechts + ' ' + y + ' ' + z / 2;
      var p7 = x / 2 - randAbstandRechts + ' ' + y + ' ' + -z / 2;
      var p8 = 0;
      var p9 = 0;

      var koordinaten = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + p8 + ', ' + p9;

      return koordinaten;
    },

    getMassketteKoordinaten: function getMassketteKoordinaten(punkt1, punkt2, drehachsen, drehwinkel) {

      var name = this.get('name');
      var typ = this.get('typ');

      // var skalierungsfaktor = this.get('model').objectAt(0).get('skalierungsfaktor')+1;
      var skalierungsfaktor = 1;

      var b05 = 0.05 * skalierungsfaktor;
      var b1 = 0.1 * skalierungsfaktor;
      var b5 = 0.5 * skalierungsfaktor;
      var b45 = b5 - b05;
      var b55 = b5 + b05;
      var b6 = 0.6 * skalierungsfaktor;

      var hwinkel = Number(this.get('model').objectAt(0).get('hWinkel')) * Math.PI / 180;
      var vwinkel = Number(this.get('model').objectAt(0).get('vWinkel')) * Math.PI / 180;

      var dp = new Object();
      var deltaX = 0;
      var deltaY = 0;

      if (name === 'grundkonstruktion' && typ === 'hoehe') {
        var item = this.get('model').objectAt(0).get('bauteile').findBy('id', name);
        deltaX = -item.get('grundmasse').objectAt(0).get('giebelueberstand');
      }

      if (name === 'grundkonstruktion' && typ === 'traufeueberstand') {
        var item = this.get('model').objectAt(0).get('bauteile').findBy('id', name);
        deltaY = -Number(item.get('grundmasse').objectAt(0).get('traufeueberstand')) * Math.tan(item.get('grundmasse').objectAt(0).get('dachneigung') * Math.PI / 180);
      }

      if (name === 'grundkonstruktion' && typ === 'firstueberstand') {
        var item = this.get('model').objectAt(0).get('bauteile').findBy('id', name);
        deltaY = -Number(item.get('grundmasse').objectAt(0).get('traufeueberstand')) * Math.tan(item.get('grundmasse').objectAt(0).get('dachneigung') * Math.PI / 180);
      }

      if (name === 'grundkonstruktion' && typ === 'giebelueberstand' && this.get('model').objectAt(0).get('bauteile').findBy('id', name).get('grundmasse').objectAt(0).get('ausfuehrung') === 'walmdach') {
        var item = this.get('model').objectAt(0).get('bauteile').findBy('id', name);
        deltaY = -(item.get('grundmasse').objectAt(0).get('giebelueberstand') / 2) * Math.tan(item.get('grundmasse').objectAt(0).get('dachneigung2') * Math.PI / 180) - 0.1;
      }

      var p0 = Number(punkt1.x) + ' ' + punkt1.y + ' ' + punkt1.z;
      var p1 = Number(punkt2.x) + ' ' + (Number(punkt2.y) + Number(deltaY)) + ' ' + punkt2.z;

      dp = this.drehungXAchse({ x: 0, y: b6, z: 0 }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p2 = punkt2.x + dp.x + deltaX + ' ' + (punkt2.y + dp.y) + ' ' + (punkt2.z + dp.z);
      var p3 = punkt1.x + dp.x + deltaX + ' ' + (punkt1.y + dp.y) + ' ' + (punkt1.z + dp.z);

      dp = this.drehungXAchse({ x: b1, y: b5, z: 0 }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p4 = punkt1.x + dp.x + deltaX + ' ' + (punkt1.y + dp.y) + ' ' + (punkt1.z + dp.z);

      dp = this.drehungXAchse({ x: -b1, y: b5, z: 0 }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p5 = punkt2.x + dp.x + deltaX + ' ' + (punkt2.y + dp.y) + ' ' + (punkt2.z + dp.z);

      dp = this.drehungXAchse({ x: -b05, y: b45, z: 0 }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p6 = punkt1.x + dp.x + deltaX + ' ' + (punkt1.y + dp.y) + ' ' + (punkt1.z + dp.z);
      var p8 = punkt2.x + dp.x + deltaX + ' ' + (punkt2.y + dp.y) + ' ' + (punkt2.z + dp.z);

      dp = this.drehungXAchse({ x: b05, y: b55, z: 0 }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p7 = punkt1.x + dp.x + deltaX + ' ' + (punkt1.y + dp.y) + ' ' + (punkt1.z + dp.z);
      var p9 = punkt2.x + dp.x + deltaX + ' ' + (punkt2.y + dp.y) + ' ' + (punkt2.z + dp.z);

      var koordinaten = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + p8 + ', ' + p9;

      return koordinaten;
    },

    delta: function delta(l, winkel) {
      var value = l * Math.cos(winkel);
      return value;
    },

    vektorLaenge: function vektorLaenge(x, y, z) {
      var skalierungsfaktor = this.get('model').objectAt(0).get('skalierungsfaktor');
      return skalierungsfaktor * Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2) + Math.pow(z, 2));
    },

    winkel: function winkel(x, y) {
      return Math.atan(x / y);
    },

    vektorenWinkel: function vektorenWinkel(v1, v2) {
      var zaehler = v1.x * v2.x + v1.y * v2.y + v1.z * v2.z;
      // console.log('zaehler: '+zaehler);
      var n1 = Math.sqrt(Math.pow(v1.x, 2) + Math.pow(v1.y, 2) + Math.pow(v1.z, 2));
      // console.log('n1: '+n1);
      var n2 = Math.sqrt(Math.pow(v2.x, 2) + Math.pow(v2.y, 2) + Math.pow(v2.z, 2));
      // console.log('n2: '+n2);
      var nenner = n1 * n2;
      // console.log('nenner: '+nenner);
      // console.log('zaehler/nenner: '+zaehler/nenner);
      var alpha = Math.acos((zaehler / nenner).toFixed(6));
      console.log(alpha * 180 / Math.PI);
      return alpha;
    },

    differenzVektor: function differenzVektor(v2, v1) {
      var vResult = new Object();
      vResult.x = v2.x - v1.x;
      vResult.y = v2.y - v1.y;
      vResult.z = v2.z - v1.z;
      return vResult;
    },

    drehungXAchse: function drehungXAchse(v, omega) {
      var r = new Object();
      r.x = v.x;
      r.y = v.y * Math.cos(omega) - v.z * Math.sin(omega);
      r.z = v.y * Math.sin(omega) + v.z * Math.cos(omega);
      return r;
    },

    drehungYAchse: function drehungYAchse(v, omega) {
      var r = new Object();
      r.x = v.x * Math.cos(omega) + v.z * Math.sin(omega);
      r.y = v.y;
      r.z = -v.x * Math.sin(omega) + v.z * Math.cos(omega);
      return r;
    },

    drehungZAchse: function drehungZAchse(v, omega) {
      var r = new Object();
      r.x = v.x * Math.cos(omega) - v.y * Math.sin(omega);
      r.y = v.x * Math.sin(omega) + v.y * Math.cos(omega);
      r.z = v.z;
      return r;
    }

  });

  exports['default'] = Transform;

});