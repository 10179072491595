define('m0601/components/schraub-e', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Schraube = Ember['default'].Component.extend({

    tagName: 'transform',
    layoutName: 'schraub-e',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['schraubenKlasse'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    center: (function () {
      var values = '0 0 0';
      return values;
    }).property(''),

    translation: (function () {
      var values = '0 0 0';

      var x = 0,
          y = 0,
          z = 0;

      var name = this.get('name');

      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (x3d.get('ergebnisGeladen') === true) {
        var item = bauteile.findBy('id', name);

        x = item.get('punkt').objectAt(0).get('x') / 10;
        y = item.get('punkt').objectAt(0).get('y') / 10;
        z = item.get('punkt').objectAt(0).get('z') / 10;
      } else {
        var deltaEntfernung = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 60;

        if (bauteile.findBy('id', name).get('translations').objectAt(0).get('x') > 0 && !bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('auflager2Durchlaufend')) {
          deltaEntfernung = deltaEntfernung - bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('auflager2RandLinks') / 30;
        } else if (bauteile.findBy('id', name).get('translations').objectAt(0).get('x') < 0 && !bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('auflager1Durchlaufend')) {
          deltaEntfernung = deltaEntfernung - bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('auflager1RandRechts') / 30;
        }

        x = bauteile.findBy('id', name).get('translations').objectAt(0).get('x') * deltaEntfernung;
        y = bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10 + bauteile.findBy('id', 'unterkonstruktion1').get('boxsizes').objectAt(0).get('y') / 20;
        var zrel = bauteile.findBy('id', name).get('translations').objectAt(0).get('z') / 10;

        if (bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('hbvSystem') === 'ftNass') {
          // var xVerschiebung = (Math.tan(1.0472)) * bauteile.findBy('id', 'beton').get('boxsizes').objectAt(0).get('y')/20;

          Number(bauteile.findBy('id', name).get('screwProperties').objectAt(0).set('length', 23));

          var obererAbstandAmFTVerbinder = 0.658;
          var hoeheUntererTeilAmFTVerbinder = 0.446;
          var laengeUntererTeilAmFTVerbinder = 1.113 - 0.29;
          var dickeStahlplatteAmFTVerbinder = 0.06;

          var xVerschiebung = Math.cos(0.5236) * (Number(this.get('model').objectAt(0).get('bauteile').findBy('id', name).get('screwProperties').objectAt(0).get('length')) / 10 + dickeStahlplatteAmFTVerbinder) - laengeUntererTeilAmFTVerbinder;
          var yVerschiebung = Math.sin(0.5236) * Number(this.get('model').objectAt(0).get('bauteile').findBy('id', name).get('screwProperties').objectAt(0).get('length')) / 10 - hoeheUntererTeilAmFTVerbinder;
          y = y + bauteile.findBy('id', 'unterkonstruktion1').get('boxsizes').objectAt(0).get('y') / 20 + bauteile.findBy('id', 'schalung').get('boxsizes').objectAt(0).get('y') / 10 - yVerschiebung;
          if (x < 0) {
            x = x + xVerschiebung;
          } else {
            x = x - xVerschiebung;
          }
        }

        if (bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('hbvSystem') === '45Grad') {
          var l = (Number(bauteile.findBy('id', 'unterkonstruktion1').get('boxsizes').objectAt(0).get('y') / 2) + Number(bauteile.findBy('id', 'schalung').get('boxsizes').objectAt(0).get('y')) + Number(bauteile.findBy('id', 'beton').get('boxsizes').objectAt(0).get('y')) / 2) / Math.cos(0.7854);
          Number(bauteile.findBy('id', name).get('screwProperties').objectAt(0).set('length', l));
        }

        if (bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('hbvSystem') === '90Grad') {
          var l = Number(bauteile.findBy('id', 'unterkonstruktion1').get('boxsizes').objectAt(0).get('y') / 2) + Number(bauteile.findBy('id', 'schalung').get('boxsizes').objectAt(0).get('y')) + Number(bauteile.findBy('id', 'beton').get('boxsizes').objectAt(0).get('y')) / 2;
          Number(bauteile.findBy('id', name).get('screwProperties').objectAt(0).set('length', l));
        }

        if (bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('hbvSystem') === 'ftTrocken') {
          y = bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10 + bauteile.findBy('id', 'unterkonstruktion1').get('boxsizes').objectAt(0).get('y') / 10 + bauteile.findBy('id', 'schalung').get('boxsizes').objectAt(0).get('y') / 10;
          Number(bauteile.findBy('id', name).get('screwProperties').objectAt(0).set('length', 23));
        }

        // if(bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('deckenkonstruktion')  === 'holzelement'){
        //   z = (bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('tiefeUnterkonstruktion')/40);
        // }else{
        z = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('tiefeUnterkonstruktion') / 20;
        // }

        if (zrel < 0) {
          z = -z;
        }
      }

      values = x + ' ' + y + ' ' + z;

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt', 'model.firstObject.schraubenGekreuzt'),

    // Rotation in der XY-Ebene, um die z-Achse
    rotation: (function () {
      var self = this;

      var name = this.get('name');

      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      var item = bauteile.findBy('id', name);

      var drehwinckelZAchse = 0;
      var drehwinckelYAchse = 0;
      var values = '0 0 0 0';

      var x = 0,
          y = 0,
          z = 0;

      if (x3d.get('ergebnisGeladen') === true) {

        x = bauteile.findBy('id', name).get('richtungsvektor').objectAt(0).get('x');
        y = bauteile.findBy('id', name).get('richtungsvektor').objectAt(0).get('y');
        z = bauteile.findBy('id', name).get('richtungsvektor').objectAt(0).get('z');

        if (Math.abs(y) !== 1) {
          drehwinckelYAchse = self.getWinkel({ x: x, y: 0, z: z }, { x: 0, y: 0, z: 1 });
        } else {
          drehwinckelYAchse = self.getWinkel({ x: x, y: y, z: z }, { x: 0, y: 0, z: 1 });
        }

        drehwinckelZAchse = self.getWinkel({ x: x, y: y, z: z }, { x: 0, y: 1, z: 0 });

        var vorzeichen = 1;

        if (x !== 0) {
          vorzeichen = x / Math.abs(x);
        }

        var xWertRotationsachse = Math.cos(drehwinckelYAchse);
        var zWertRotationsachse = vorzeichen * -Math.sin(drehwinckelYAchse);

        values = xWertRotationsachse + ' 0 ' + zWertRotationsachse + ' ' + drehwinckelZAchse;
      } else {

        drehwinckelZAchse = item.get('rotations').objectAt(0).get('z');
        drehwinckelYAchse = item.get('rotations').objectAt(0).get('y');

        if (bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('hbvSystem') === '90Grad') {
          drehwinckelZAchse = 0;
        } else if (bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('hbvSystem') === 'ftTrocken' || bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('hbvSystem') === 'ftNass') {
          drehwinckelZAchse = drehwinckelZAchse / Math.abs(drehwinckelZAchse) * 1.0472;
        }
        values = '0 0 1 ' + drehwinckelZAchse;
      }

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.schraubenGekreuzt'),

    getWinkel: function getWinkel(vektor, bezugsVektor) {
      var self = this;

      var xx = vektor.x * bezugsVektor.x;
      var yy = vektor.y * bezugsVektor.y;
      var zz = vektor.z * bezugsVektor.z;

      var l_vektor = self.vektorLaenge(vektor);
      var l_bezugsVektor = self.vektorLaenge(bezugsVektor);

      var winkel = Math.acos((xx + yy + zz) / (l_vektor * l_bezugsVektor));

      return winkel;
    },

    vektorLaenge: function vektorLaenge(v) {
      return Math.sqrt(Math.pow(v.x, 2) + Math.pow(v.y, 2) + Math.pow(v.z, 2));
    }

  });

  exports['default'] = Schraube;

});