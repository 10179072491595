define('m0601/initializers/t', ['exports', 'ember', 'ember-cli-i18n/utils/t', 'm0601/helpers/t', 'ember-cli-i18n/utils/stream'], function (exports, Ember, T, tHelper, Stream) {

  'use strict';

  exports.initialize = initialize;

  function initialize(container, application) {
    Ember['default'].HTMLBars._registerHelper('t', tHelper['default']);

    application.localeStream = new Stream['default'](function () {
      return application.get('locale');
    });

    Ember['default'].addObserver(application, 'locale', function () {
      application.localeStream.notify();
    });

    application.register('utils:t', T['default']);
    application.inject('route', 't', 'utils:t');
    application.inject('model', 't', 'utils:t');
    application.inject('component', 't', 'utils:t');
    application.inject('controller', 't', 'utils:t');
  }

  ;

  exports['default'] = {
    name: 't',
    initialize: initialize
  };

});