define('m0601/controllers/projektdaten', ['exports', 'ember', 'ember-validations'], function (exports, Ember, EmberValidations) {

  'use strict';

  /**
   * Controller für den Projektdaten-Reiter.
   *
   * @class projektdaten
   */

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {

    ndte: "",

    debug: true,
    plzservice: true,
    displayErrors: true,
    displayTooltips: false,
    bvort: "",
    vort: "",
    orte: "",
    bvname: "",
    bvplz: "",
    bvstrasse: "",
    vname: "",
    vstrasse: "",
    vplz: "",
    vtel: "",
    vfax: "",
    vemail: "",
    bezeichnung: "",
    beschreibung: "",
    kdnum: "",
    bemerkungstext: "",

    validations: {
      bvname: {
        length: {
          minimum: 3
        }
      },
      bvstrasse: {
        length: {
          minimum: 3
        }
      },
      bvplz: {
        length: 5,
        format: {
          'with': /^[0-9]/
        }
      },
      bvort: {
        length: {
          minimum: 2
        }
      },
      vname: {
        length: {
          minimum: 3
        }
      },
      vstrasse: {
        length: {
          minimum: 3
        }
      },
      vplz: {
        length: 5,
        format: {
          'with': /^[0-9]/
        }
      },
      vort: {
        length: {
          minimum: 2
        }
      },
      vfax: {
        format: {
          'with': /^[0-9]{7,25}/
        }
      },
      vtel: {
        format: {
          'with': /^[0-9]{7,25}/
        }
      },
      vemail: {
        format: {
          'with': /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i
        }
      },
      bezeichnung: {
        length: {
          minimum: 2
        }
      },
      beschreibung: {
        length: {
          minimum: 2
        }
      },
      kdnum: {
        format: {
          'with': /^[0-9]{5,25}/
        }
      }
    },

    setValues: function setValues(values) {

      this.set('plzservice', false);

      if (this.debug) {
        console.log("values in projektdaten: ");
      }

      this.set('bvname', values.bvname);
      this.set('bvplz', values.bvplz);
      this.set('bvort', values.bvort);
      this.set('bvstrasse', values.bvstrasse);
      this.set('vname', values.vname);
      this.set('vstrasse', values.vstrasse);
      this.set('vort', values.vort);
      this.set('vplz', values.vplz);
      this.set('vtel', values.vtel);
      this.set('vfax', values.vfax);
      this.set('vemail', values.vemail);
      this.set('bezeichnung', values.bezeichnung);
      this.set('beschreibung', values.beschreibung);
      this.set('kdnum', values.kdnum);
      this.set('bemerkungstext', values.bemerkungstext);

      this.send('storeValues');
    },

    /**
     * Observes the Bvplz input. If the input length is 5 it triggers a webservice to get the city name to the zip code.
     * The city name is set as city in the input field.
     *
     * @method watchBvplz
     */
    watchBvplz: (function () {

      if (this.plzservice === true) {
        var self = this;
        console.log(self.get('bvplz'));
        var bvplz = self.get('bvplz');

        var application = this.controllerFor('application');
        var environment = externalConfig.environments[application.get('environment')];
        var url = externalConfig[environment].uriPLZservice;

        if (bvplz.length === 5) {

          $.get(url + bvplz, function (data) {

            var orte = [];
            var ortelength = data.length;

            var ortsdaten = data[0];
            var ort = ortsdaten.split(";")[1];
            var slz = ortsdaten.split(";")[3];
            var wlz = ortsdaten.split(";")[5];
            var hunn = ortsdaten.split(";")[7];

            var lasteinwirkung = self.controllerFor('lasteinwirkung');
            lasteinwirkung.set('gelaendehoehe', hunn);
            lasteinwirkung.set('slz', parseInt(slz));
            lasteinwirkung.set('wlz', parseInt(wlz));
            lasteinwirkung.set('geoDatenManuell', false);

            self.set('bvort', ort);
          });
        }
      }
    }).observes('bvplz'),

    /**
     * Observes the vplz input. If the input length is 5 it triggers a webservice to get the city name to the zip code.
     * The city name is set as city in the input field.
     *
     * @method watchVplz
     */
    watchVplz: (function () {

      if (this.plzservice === true) {
        var self = this;
        console.log(self.get('vplz'));
        var vplz = self.get('vplz');

        var application = this.controllerFor('application');
        var environment = externalConfig.environments[application.get('environment')];
        var url = externalConfig[environment].uriPLZservice;

        if (vplz.length === 5) {

          $.get(url + vplz, function (data) {

            var orte = [];
            var ortelength = data.length;

            var ortsdaten = data[0];
            console.log(ortsdaten);
            var ort = ortsdaten.split(";")[1];
            var slz = ortsdaten.split(";")[3];
            var wlz = ortsdaten.split(";")[5];
            var hunn = ortsdaten.split(";")[7];
            console.log(ort);
            console.log(slz);
            console.log(wlz);
            console.log(hunn);
            self.set('vort', ort);
          });
        }
      }
    }).observes('vplz'),

    actions: {

      /**
       * Stores all values from the input forms in the projektdaten store.
       *
       * @method ACTION: storeValues
       */

      storeValues: function storeValues() {

        var self = this;
        var projektdaten = this.controllerFor('application').get('model').projektdaten.objectAt(0);

        // var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
        // applicationdata.set('treeLoaded', false);
        // applicationdata.set('initialized', false);
        //
        // var application = self.controllerFor('application');
        // application.set('verbindungsmittelInvalid', true);

        self.controllerFor('projektdaten').set('bname', 'Horst');
        projektdaten.set('bvname', self.get('bvname'));
        projektdaten.set('bvplz', self.get('bvplz'));
        projektdaten.set('bvstrasse', self.get('bvstrasse'));
        projektdaten.set('bvort', self.get('bvort'));
        projektdaten.set('vname', self.get('vname'));
        projektdaten.set('vstrasse', self.get('vstrasse'));
        projektdaten.set('vplz', self.get('vplz'));
        projektdaten.set('vort', self.get('vort'));
        projektdaten.set('vtel', self.get('vtel'));
        projektdaten.set('vfax', self.get('vfax'));
        projektdaten.set('vemail', self.get('vemail'));
        projektdaten.set('bezeichnung', self.get('bezeichnung'));
        projektdaten.set('beschreibung', self.get('beschreibung'));
        projektdaten.set('kdnum', self.get('kdnum'));
        projektdaten.set('bemerkungstext', self.get('bemerkungstext'));
      },

      bvnameIsSelected: function bvnameIsSelected() {
        document.getElementsByName('bvname')[0].setSelectionRange(0, this.get('bvname').length);
      },

      bvstrasseIsSelected: function bvstrasseIsSelected() {
        document.getElementsByName('bvstrasse')[0].setSelectionRange(0, this.get('bvstrasse').length);
      },

      bvplzIsSelected: function bvplzIsSelected() {
        document.getElementsByName('bvplz')[0].setSelectionRange(0, this.get('bvplz').length);
      },

      bvortIsSelected: function bvortIsSelected() {
        document.getElementsByName('bvort')[0].setSelectionRange(0, this.get('bvort').length);
      },

      bezeichnungIsSelected: function bezeichnungIsSelected() {
        document.getElementsByName('bezeichnung')[0].setSelectionRange(0, this.get('bezeichnung').length);
      },

      beschreibungIsSelected: function beschreibungIsSelected() {
        document.getElementsByName('beschreibung')[0].setSelectionRange(0, this.get('beschreibung').length);
      },

      vnameIsSelected: function vnameIsSelected() {
        document.getElementsByName('vname')[0].setSelectionRange(0, this.get('vname').length);
      },

      kdnumIsSelected: function kdnumIsSelected() {
        document.getElementsByName('kdnum')[0].setSelectionRange(0, this.get('kdnum').length);
      },

      vstrasseIsSelected: function vstrasseIsSelected() {
        document.getElementsByName('vstrasse')[0].setSelectionRange(0, this.get('vstrasse').length);
      },

      vplzIsSelected: function vplzIsSelected() {
        document.getElementsByName('vplz')[0].setSelectionRange(0, this.get('vplz').length);
      },

      vortIsSelected: function vortIsSelected() {
        document.getElementsByName('vort')[0].setSelectionRange(0, this.get('vort').length);
      },

      vtelIsSelected: function vtelIsSelected() {
        document.getElementsByName('vtel')[0].setSelectionRange(0, this.get('vtel').length);
      },

      vfaxIsSelected: function vfaxIsSelected() {
        document.getElementsByName('vfax')[0].setSelectionRange(0, this.get('vfax').length);
      },

      vemailIsSelected: function vemailIsSelected() {
        document.getElementsByName('vemail')[0].setSelectionRange(0, this.get('vemail').length);
      },

      bemerkungstextIsSelected: function bemerkungstextIsSelected() {
        document.getElementsByName('bemerkungstext')[0].setSelectionRange(0, this.get('bemerkungstext').length);
      },

      toggleTextArea: function toggleTextArea() {
        $('#bemerkungenTextAreaID').toggleClass('fullsize');

        if ($('#bemerkungenTextAreaID').offset().top < $('#threedee').offset().top + $('#x3d').height() + 20) {
          var newMargin = $('#threedee').offset().top + $('#x3d').height() + 20 - $('#bemerkungenTextAreaID').offset().top;
          $('#bemerkungenArea').css('margin-top', newMargin);
        }
      },

      testAction: function testAction(index) {
        var self = this;
        console.log(index);
      }

    }
  });

});