define('m0601/components/dippelbau-m', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Transform = Ember['default'].Component.extend({

    tagName: 'transform',
    layoutName: 'dippelbau-m',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    punkterDerRundung: 32,

    translation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = x3d.get('bauteile').findBy('id', name);
      var values = '0 0 0';
      var y = 0;
      var xVerschiebung, yVerschiebung, zVerschiebung;

      xVerschiebung = item.get('translations').objectAt(0).get('x') / 10;
      yVerschiebung = item.get('translations').objectAt(0).get('y') / 10;
      zVerschiebung = item.get('translations').objectAt(0).get('z') / 10;

      switch (name) {
        case 'unterkonstruktion1':
          yVerschiebung = bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10;
          zVerschiebung = -bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('tiefeUnterkonstruktion') / 20;
          if (bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('deckenkonstruktion') === 'balken') {
            zVerschiebung = zVerschiebung;
          } else if (bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('deckenkonstruktion') === 'holzelement') {
            zVerschiebung = zVerschiebung + bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('tiefeUnterkonstruktion') / 40;
          }
          break;
        case 'unterkonstruktion2':
          yVerschiebung = bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10;
          zVerschiebung = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('tiefeUnterkonstruktion') / 20;
          if (bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('deckenkonstruktion') === 'balken') {
            zVerschiebung = zVerschiebung;
          } else if (bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('deckenkonstruktion') === 'holzelement') {
            zVerschiebung = zVerschiebung - bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('tiefeUnterkonstruktion') / 40;
          }
          break;
      }

      values = xVerschiebung + '  ' + yVerschiebung + '  ' + zVerschiebung;

      return values;
    }).property('model.firstObject.transformHelper'),

    rotation: (function () {
      var name = this.get('name');
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var values = '0 0 0 0';
      var item = x3d.objectAt(0).get('bauteile').findBy('id', name);

      return values;
    }).property('model.firstObject.transformHelper'),

    koordinaten: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var item = x3d.objectAt(0).get('bauteile').findBy('id', name);

      var koordinaten = "";

      //var x = bauteile.findBy('id', 'unterkonstruktion2').get('boxsizes').objectAt(0).get('x')/10;
      var x = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 10;
      var y = bauteile.findBy('id', 'unterkonstruktion2').get('boxsizes').objectAt(0).get('y') / 10;
      var z = bauteile.findBy('id', 'unterkonstruktion2').get('boxsizes').objectAt(0).get('z') / 10;

      var randAbstandLinks = bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('x') / 20;
      var randAbstandRechts = bauteile.findBy('id', 'lager2').get('boxsizes').objectAt(0).get('x') / 20;

      var eckenradius = Math.pow(z, 2) / (8 * y) + y / 2;

      var p0 = -x / 2 - randAbstandLinks + ' ' + 0 + ' ' + -z / 2;
      var p1 = -x / 2 - randAbstandLinks + ' ' + 0 + ' ' + z / 2;
      var p2 = x / 2 + randAbstandRechts + ' ' + 0 + ' ' + z / 2;
      var p3 = x / 2 + randAbstandRechts + ' ' + 0 + ' ' + -z / 2;

      koordinaten = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ';

      var dy = 0;
      var dz = 0;

      var punkterDerRundung = Number(this.get('punkterDerRundung'));

      // console.log('z: '+z);
      // console.log("eckenradius: "+eckenradius);
      //
      // var winkel = Math.asin((z/2)/eckenradius);
      //
      // console.log('winkel: '+winkel+' ::: '+winkel*180/Math.PI);
      //
      // var dWinkel = winkel/punkterDerRundung;
      var dWinkel = Math.PI / (2 * punkterDerRundung);

      for (var p = 0; p < punkterDerRundung; p++) {

        dy = eckenradius - eckenradius * Math.sin(p * dWinkel);

        if (z / 2 >= Math.abs(eckenradius * Math.cos(p * dWinkel))) {
          dz = z / 2 - eckenradius * Math.cos(p * dWinkel);
        }

        var dp0 = -x / 2 - randAbstandLinks + ' ' + (y - dy) + ' ' + -(z / 2 - dz);
        var dp1 = -x / 2 - randAbstandLinks + ' ' + (y - dy) + ' ' + (z / 2 - dz);
        var dp2 = x / 2 + randAbstandRechts + ' ' + (y - dy) + ' ' + (z / 2 - dz);
        var dp3 = x / 2 + randAbstandRechts + ' ' + (y - dy) + ' ' + -(z / 2 - dz);

        koordinaten = koordinaten + dp0 + ', ' + dp1 + ', ' + dp2 + ', ' + dp3 + ', ';
      }

      return koordinaten;
    }).property('model.firstObject.transformHelper', 'model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.istHt', 'model.firstObject.skalierungsfaktor', 'model.firstObject.schraubenGekreuzt'),

    koordinatenIndex: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var item = x3d.objectAt(0).get('bauteile').findBy('id', name);

      var values = "0 3 2 1 0 -1 ";
      var punkterDerRundung = Number(this.get('punkterDerRundung'));

      for (var p = 0; p < punkterDerRundung; p++) {

        values = values + p * 4 + ' ' + (p * 4 + 1) + ' ' + (p * 4 + 5) + ' ' + (p * 4 + 4) + ' ' + p * 4 + ' -1 '; // linke Seite
        values = values + (p * 4 + 1) + ' ' + (p * 4 + 2) + ' ' + (p * 4 + 6) + ' ' + (p * 4 + 5) + ' ' + (p * 4 + 1) + ' -1 '; // Vorderseite
        values = values + (p * 4 + 2) + ' ' + (p * 4 + 3) + ' ' + (p * 4 + 7) + ' ' + (p * 4 + 6) + ' ' + (p * 4 + 2) + ' -1 '; // rechte Seite
        values = values + p * 4 + ' ' + (p * 4 + 4) + ' ' + (p * 4 + 7) + ' ' + (p * 4 + 3) + ' ' + p * 4 + ' -1 '; // Rückseite
      }

      values = values + punkterDerRundung * 4 + ' ' + (punkterDerRundung * 4 + 1) + ' ' + (punkterDerRundung * 4 + 2) + ' ' + (punkterDerRundung * 4 + 3) + ' ' + punkterDerRundung * 4 + ' -1 '; // Oberseite

      return values;
    }).property('model.firstObject.transformHelper'),

    farbIndex: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var values = '0 0 0 0 ';

      var item = x3d.objectAt(0).get('bauteile').findBy('id', name);

      var punkterDerRundung = Number(this.get('punkterDerRundung'));

      for (var p = 0; p < punkterDerRundung; p++) {

        values = values + '0 0 0 0 ';
      }

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt'),

    farbe: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = x3d.get('bauteile').findBy('id', name);

      var values = item.get('diffusecolors').objectAt(0).get('rot') + " " + item.get('diffusecolors').objectAt(0).get('gruen') + " " + item.get('diffusecolors').objectAt(0).get('blau');

      return values;
    }).property('model.firstObject.transformHelper'),

    koordinatenIndexLinset: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var item = x3d.objectAt(0).get('bauteile').findBy('id', name);

      var values = "0 3 2 1 0 -1 ";
      var punkterDerRundung = Number(this.get('punkterDerRundung'));

      for (var p = 0; p < punkterDerRundung; p++) {

        values = values + p * 4 + ' ' + (p * 4 + 4) + ' -1 '; // hinten links
        values = values + (p * 4 + 1) + ' ' + (p * 4 + 5) + ' -1 '; // vorn links
        values = values + (p * 4 + 2) + ' ' + (p * 4 + 6) + ' -1 '; // vorn rechts
        values = values + (p * 4 + 3) + ' ' + (p * 4 + 7) + ' -1 '; // hinten rechts
      }

      values = values + punkterDerRundung * 4 + ' ' + (punkterDerRundung * 4 + 1) + ' -1 ' + (punkterDerRundung * 4 + 2) + ' ' + (punkterDerRundung * 4 + 3) + ' -1 '; // Oberseite

      return values;
    }).property('model.firstObject.transformHelper'),

    emissivecolor: (function () {
      return "0 0 0";
    }).property('model.firstObject.transformHelper')

  });

  exports['default'] = Transform;

});