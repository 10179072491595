define('m0601/controllers/supercontroller', ['exports', 'ember', 'ember-validations', 'ember-i18n'], function (exports, Ember, EmberValidations, ember_i18n) {

  'use strict';

  Ember['default'].deprecate = function () {};

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;
  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {
    i18n: Ember['default'].inject.service(),

    erzeugeAufrufzusatz: function erzeugeAufrufzusatz(zweiterzusatz) {

      // Aufrufe	Zusatz1	Zusatz2
      // initialisiereBerechnung	"?"+kennungland_loadsNA_timberNA
      // getKompaktergebnis	"?"+kennungland_loadsNA_timberNA	"_"+artikelnummer.replace(' ','+')
      // erzeugeSchraubenliste	"?"+kennungland_loadsNA_timberNA
      // erzeugeAusgabe	"?"+kennungland_loadsNA_timberNA	"_"+artikelnummer.replace(' ','+')
      // erzeugeDXF	"?"+kennungland_loadsNA_timberNA	"_"+artikelnummer.replace(' ','+')
      // speichereBerechnung	"?"+kennungland_loadsNA_timberNA
      //
      // Beispiel
      // erzeugeAusgabe/?CH_CH_DE_0170+350+45

      var self = this;

      var application = self.controllerFor('application');
      var applicationdata = application.get('model').application.objectAt(0);
      var verbindungsmittel = self.controllerFor('verbindungsmittel');

      var zusatzString = "?" + applicationdata.get("kennungland") + "_" + applicationdata.get("loadsNA") + "_" + applicationdata.get("timberNA");

      if (zweiterzusatz === true) {

        var artikelnummer;

        if (typeof verbindungsmittel.get('selectedNodes')[0] === 'undefined') {
          artikelnummer = "Keine Artikel ausgewaehlt";
        } else {
          artikelnummer = verbindungsmittel.get('selectedNodes')[0].data.ArtNr;
        }

        zusatzString = zusatzString + "_" + artikelnummer.replace(/ /g, '+');
      }

      return zusatzString;
    },

    resetcpplication: function resetcpplication() {
      var self = this;

      var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      applicationdata.set('treeLoaded', false);
      applicationdata.set('initialized', false);
      applicationdata.set('pdfErstellt', false);
      applicationdata.set('dxfErstellt', false);

      var application = self.controllerFor('application');
      application.set('verbindungsmittelInvalid', true);

      var downloadcenter = self.controllerFor('downloadcenter');
      downloadcenter.set('pdfErstellt', false);
      downloadcenter.set('dxfErstellt', false);
    },

    setDefinitionsbereich: function setDefinitionsbereich(controllerBezeichnung, variable, minWert, maxWert, tooltipBezeichnung, einheit) {
      var self = this;
      var materialien = self.controllerFor(controllerBezeichnung);

      materialien.set('validations.' + variable + '.numericality.greaterThanOrEqualTo', minWert);
      materialien.set('validations.' + variable + '.numericality.lessThanOrEqualTo', maxWert);
      materialien.set(tooltipBezeichnung, self.get('i18n').t('wertebereich') + " " + Number(minWert).toFixed(2) + " - " + Number(maxWert).toFixed(2) + " [" + einheit + "]");
    },

    logaufruf: function logaufruf(_aufruf, _status, _showOutput, _logAnServerSendenErzwingen) {
      var self = this;
      var application = self.controllerFor('application');
      var applicationdata = application.get('model').application.objectAt(0);

      var date = new Date();
      var datuminticks = date.getTime() * 10000 + 621355968000000000;

      if (_showOutput) {
        console.log("***** logaufruf von *****");
        console.log(_aufruf);
      }

      var tc = application.get('model').application.objectAt(0).get('timberCode').replace(" ", "").substring(0, 3);
      var lc = application.get('model').application.objectAt(0).get('loadsCode').replace(" ", "").substring(0, 3);

      var guid = "00000000-0000-0000-0000-000000000000";
      var guidlength = guid.length;
      var cidlength = application.get('model').application.objectAt(0).get('Calculation_ID').length;
      guid = guid.substring(0, guidlength - cidlength) + application.get('model').application.objectAt(0).get('Calculation_ID');

      var logdaten = {
        "datum": datuminticks,
        "bid": guid,
        "request": 0,
        "firma": enums.Firma.findBy("bezeichnung", application.get('model').application.objectAt(0).get('firm')).id,
        "modul": enums.Modul.findBy("bezeichnung", "m0601").id,
        "bemessung": enums.NormBemessung.findBy("bezeichnung", tc).id,
        "bemessungNAD": enums.Sprache.findBy("bezeichnung", application.get('model').application.objectAt(0).get('timberNA').toLowerCase()).id,
        "lastannahmen": enums.NormLastannahmen.findBy("bezeichnung", lc).id,
        "lastannahmenNAD": enums.Sprache.findBy("bezeichnung", application.get('model').application.objectAt(0).get('loadsNA').toLowerCase()).id,
        "eingabeSprache": enums.Sprache.findBy("bezeichnung", application.get('model').application.objectAt(0).get('spracheinput').toLowerCase()).id,
        "ausgabeSprache": enums.Sprache.findBy("bezeichnung", application.get('model').application.objectAt(0).get('spracheoutput').toLowerCase()).id,
        "kennungLand": enums.Sprache.findBy("bezeichnung", application.get('model').application.objectAt(0).get('kennungland').toLowerCase()).id,
        "katalog": enums.Sprache.findBy("bezeichnung", application.get('model').application.objectAt(0).get('katalog').toLowerCase()).id,
        "methode": enums.Methode.findBy("bezeichnung", _aufruf).id,
        "userIp": null,
        "userName": application.get('model').application.objectAt(0).get('userName'),
        "url": null,
        "status": _status,
        "artikelnummer": application.get('model').application.objectAt(0).get('selectedScrew')
      };

      if (_showOutput) {
        console.log("zu lockende Daten:");
        console.log(logdaten);
      }

      var form = new FormData();
      form.append("_aufruf", JSON.stringify(logdaten));

      var environment = externalConfig.environments[application.get('environment')];
      var url = externalConfig[environment].uriLogservice;

      var liveVersion = application.get('environment').search('dev') !== -1 ? false : true;

      if (liveVersion === true || _logAnServerSendenErzwingen === true) {

        $.ajax({
          "type": "POST",
          "url": url,
          "processData": false,
          "contentType": false,
          data: form

        }).done(function (data, statusText, xhr) {
          if (_showOutput) {
            console.log("Daten gelockt: " + data);
            // var status = xhr.status;                //200
            // console.log("statusText");
            // console.log(statusText);
            // console.log("status");
            // console.log(status);
            console.log(" ");
          }
        });
      }
      // testen von der Browserkonsole --> M0601.__container__.lookup('controller:supercontroller').logaufruf('erzeugeDXF', 4444, true, true);
    }

  });

});