define('m0601/controllers/verbindungsmittel', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    schraubenliste: [],
    calcid: "",
    tooltiptext: "",
    loading: true,
    showinvalidscrews: false,
    validScrewSelected: false,
    jstreeStateOptionHash: {
      'key': ''
    },
    plugins: 'state',

    getInfo: false,
    berechnungsInfo: "",

    selectedNodes: "",

    ergPdfErstellt: false,
    ergDxfErstellt: false,

    e1value: "e1 Value",
    e2value: "e2 Value",
    e3value: "e3 Value",

    e1visible: true,
    e2visible: true,
    e3visible: true,

    plattenstoss: false,
    mindestanzahlDisabled: false,
    plattenstossDisabled: false,
    mindestanzahl: 25,
    ma: 25,
    mindestanzahlFromScrew: 1,

    mindestanzahlContent: [],

    infoText: "",
    keineSchraubenliste: true,

    berechnungErfolgreich: false,

    setValues: function setValues(values) {

      var self = this;

      self.set('plattenstoss', values.plattenstoss);
      self.set('mindestanzahl', parseInt(values.maxSchubverbinder));
    },

    getBerechnungsinfo: function getBerechnungsinfo() {
      var self = this;
      self.set('getInfo', true);
      self.set('berechnungsInfo', "");

      var application = self.controllerFor('application');

      var server = application.get('server');
      var pfad = application.get('pfad');

      var windowsRefreshInterval = setInterval(function () {
        $.ajax({
          type: "POST",
          url: server + "getBerechnungsInfo/",
          data: {
            cid: self.calcid,
            index: 0
          }
        }).done(function (data) {

          if (self.get('getInfo') === false) {
            clearInterval(windowsRefreshInterval);
          }

          console.log(data);

          var htmlString = data.replace(/\r\n/g, '<br />').replace(/[\r\n]/g, '<br />');
          self.set('berechnungsInfo', htmlString);

          if (self.get('getInfo') === true) {
            var objDiv = document.getElementById("infopanel");
            objDiv.scrollTop = objDiv.scrollHeight;
          }
        });
      }, 2000);
    },

    watchPlattenstoss: (function () {
      var self = this;
      var schraubenvorauswahldata = self.controllerFor('application').get('model').schraubenvorauswahl.objectAt(0);

      if (self.get('plattenstoss')) {
        self.filterMindestanzahl(0);
        schraubenvorauswahldata.set('plattenstoss', true);
      } else {
        self.filterMindestanzahl(1);
        schraubenvorauswahldata.set('plattenstoss', false);
      }

      schraubenvorauswahldata.set('maxSchubverbinder', self.get('mindestanzahl'));
    }).observes('plattenstoss', 'mindestanzahl'),

    filterMindestanzahl: function filterMindestanzahl(evenUneven) {
      var self = this;

      if (evenUneven == 0) {
        var i = 0;
        var tmp = [];
        var ma = self.get('ma');
        for (i = 2; i <= ma; i = i + 2) {
          tmp.push({
            value: i,
            name: i
          });
        }

        self.set('mindestanzahlContent', tmp);
      } else if (evenUneven == 1) {
        var i = 0;
        var tmp = [];
        var ma = self.get('ma');
        for (i = 1; i <= ma; i++) {
          tmp.push({
            value: i,
            name: i
          });
        }

        self.set('mindestanzahlContent', tmp);
      }
    },

    init: function init() {

      this._super();

      var lasteinwirkung = this.controllerFor('lasteinwirkung');
      var bauteile = this.controllerFor('bauteile');
      if (!lasteinwirkung.get('lasteinwirkungInvalid') || !bauteile.get('bauteileInvalid')) {
        this.transitionToRoute('projektdaten');
      } else {
        console.log("lasteinwirkung ist valid");
      }

      var kon = this.controllerFor('konstruktion');
      var hbvSys = kon.get('hbvSystem');

      switch (hbvSys) {
        case "1":
          // grundmasse.set('hbvSystem', '90Grad');
          this.set('plattenstossDisabled', true);
          this.set('mindestanzahlDisabled', true);
          break;
        case "2":
          // grundmasse.set('hbvSystem', '45Grad');
          this.set('plattenstossDisabled', true);
          this.set('mindestanzahlDisabled', true);
          break;
        case "3":
          // grundmasse.set('hbvSystem', 'ftNass');
          this.set('plattenstossDisabled', true);
          this.set('mindestanzahlDisabled', true);
          break;
        case "4":
          // grundmasse.set('hbvSystem', 'ftTrocken');
          this.set('plattenstossDisabled', false);
          this.set('mindestanzahlDisabled', false);
          break;

        default:
      }
    },

    watchInvalidScrewsCombo: (function () {

      var self = this;

      if (self.showinvalidscrews) {

        console.log("hier werden die unzulässigen schrauben eingeblendet.");
        var schraubenliste = self.controllerFor('lasteinwirkung').get('schraubenliste');
        var core = treetools.buildTree(schraubenliste, true);
        self.set('schraubenliste', core);
      } else {
        console.log("und hier wieder aus.");
        var schraubenliste = self.controllerFor('lasteinwirkung').get('schraubenliste');
        var core = treetools.buildTree(schraubenliste, false);
        self.set('schraubenliste', core);
      }
    }).observes('showinvalidscrews'),

    watchSelectedScrew: (function () {

      var self = this;
      var application = self.controllerFor('application');
      var downloadcenter = self.controllerFor('downloadcenter');

      var cid = self.calcid;
      var selectedScrew = self.selectedNodes[0].data.ArtNr;

      console.log(self.jstreeStateOptionHash);

      if (self.selectedNodes.length > 0) {

        if (self.selectedNodes[0].data.VM_Okay === "True") {
          //self.set('getInfo', true);

          application.set('verbindungsmittelInvalid', false);
          application.set('schraubenInfo', self.selectedNodes[0].data.ProductInfo);
          application.set('schraubenCAD', self.selectedNodes[0].data.CAD);
          self.set('validScrewSelected', true);
          self.set('mindestanzahlFromScrew', self.selectedNodes[0].data.max_n90);
          // self.set('mindestanzahlFromScrew', 1);
          console.log(self.get('mindestanzahlFromScrew'));
          self.send('getMindestanzahl');

          downloadcenter.set('schraubenInfo', self.selectedNodes[0].data.ProductInfo);
          downloadcenter.set('schraubenCAD', self.selectedNodes[0].data.CAD);
          downloadcenter.set('validScrewSelected', true);

          var ergebnis = self.controllerFor('ergebnis');
          ergebnis.set('pdfErstellt', false);
          ergebnis.set('dxfErstellt', false);
        } else {

          application.set('verbindungsmittelInvalid', true);
          self.set('validScrewSelected', false);
          downloadcenter.set('validScrewSelected', false);
        }
      }
    }).observes('selectedNodes'),

    ergebnisgrafik: function ergebnisgrafik() {

      console.log();

      var self = this;

      var selectedScrew = self.selectedNodes[0].data.ArtNr;
      var cidGrafik = self.calcid;

      var application = self.controllerFor('application');
      var store = application.get('store');
      var arrayHelper = [];

      var x3d = application.get('model').x3ddefault.objectAt(0);

      // alte Schrauben "Verbindung (has-many)" aus x3ddefault.get('bauteile') löschen
      application.get('model').x3ddefault.objectAt(0).get('bauteile').forEach(function (item) {
        if (item.get('typ') === 'verbindungsmittel' || item.get('typ') === 'masskette' || item.get('typ') === 'platte') {
          arrayHelper.pushObject(item);
        }
      });
      arrayHelper.forEach(function (item) {
        application.get('model').x3ddefault.objectAt(0).get('bauteile').removeObject(item);
      });

      console.log('cidGrafik: ' + cidGrafik);
      console.log('selectedScrew: ' + selectedScrew);

      var server = application.get('server');
      var pfad = application.get('pfad');

      $.ajax({
        type: "POST",
        url: server + "getGrafik/",
        data: {
          cid: cidGrafik,
          artnr: selectedScrew
        }
      }).done(function (data) {

        console.log('Daten der ausgewählten Schraube');
        console.log(data);

        var dataObject = JSON.parse(data);

        console.log("dataObject");

        dataObject.bauteile.forEach(function (item) {

          var newID = item.id;
          var newID = item.id;
          if (item.typ === "verbindungsmittel") {
            newID = "schraubeStandard1";
          } else if (item.typ === "platte") {
            if (item.id.substring(0, 13) === 'seitenbauteil' && x3d.get('sFolgeHolzHolz') === false && x3d.get('zweiSchnittig') === false) {
              newID = "metall";
            } else if (item.id.substring(0, 13) === 'mittelbauteil' && x3d.get('sFolgeHolzHolz') === false && x3d.get('zweiSchnittig') === true && x3d.get('zweiSchaerflaechen') === true) {
              newID = "metall";
            } else if (item.id.substring(0, 16) === 'verstaerkunggurt' || item.id.substring(0, 15) === 'zwischenschicht') {
              newID = "verstaerkungBlass1";
            } else if (item.id.substring(0, 21) === 'verstaerkungdiagonale') {
              newID = "verstaerkungWerner1";
            } else {
              newID = "holz";
            }
          }

          if (item.typ === "verbindungsmittel") {

            console.log("Verbindungsmittel");

            var bauteil = store.push('bauteil', {
              id: item.id,
              typ: item.typ,
              punkt: item.point,
              translations: ["null"],
              rotations: ["null"],
              appearances: [newID],
              diffusecolors: [newID],
              screwProperties: [item.verbindungsmittel],
              richtungsvektor: [item.richtungsvektor],
              lagewinkel: [item.lagewinkel]
            });

            bauteil.save();
            x3d.get('bauteile').pushObject(bauteil);
          } else if (item.typ === "platte") {

            console.log("Platte");

            var bauteil = store.push('bauteil', {
              id: item.id,
              typ: item.typ,
              punkt: item.point,
              translations: ["null"],
              rotations: ["null"],
              appearances: [newID],
              diffusecolors: [newID],
              richtungsvektor: [item.richtungsvektor],
              orthovektor: [item.orthovektor],
              platte: [item.id]
            });

            bauteil.save();
            x3d.get('bauteile').pushObject(bauteil);
          } else if (item.typ === "masskette") {

            console.log("Masskette");

            var bauteil = store.push('bauteil', {
              id: item.id,
              typ: item.typ,
              punkt: item.point,
              translations: ["null"],
              rotations: ["null"],
              appearances: [newID],
              diffusecolors: [newID],
              richtungsvektor: [item.richtungsvektor],
              // orthovektor: [item.orthovektor],
              masskette: [item.id],
              ansichtsvektoren: [item.offsetVektorAnsichtXY, item.offsetVektorAnsichtXZ, item.offsetVektorAnsichtYZ]

            });

            bauteil.save();
            x3d.get('bauteile').pushObject(bauteil);
          }
        }); //Bauteile

        var xWerte = [];
        var yWerte = [];
        var zWerte = [];

        console.log('punkte');
        if (typeof dataObject.punkte === "undefined") {
          console.log('PUNKTE in der geladenen Dateie nicht vorhanden');
        } else {
          dataObject.punkte.forEach(function (item) {

            xWerte.push(Number(item.x));
            yWerte.push(Number(item.y));
            zWerte.push(Number(item.z));

            var p = store.push('punkt', item);
            p.save();
          });

          self.setMinMaxWerte("x", xWerte);
          self.setMinMaxWerte("y", yWerte);
          self.setMinMaxWerte("z", zWerte);
        }

        console.log('platten');
        if (typeof dataObject.platte === "undefined") {
          console.log('PLATTE in der geladenen Dateie nicht vorhanden');
        } else {
          dataObject.platte.forEach(function (item) {
            var p = store.push('platte', item);
            p.save();
          });
        }

        console.log('richtungsvektoren');
        if (typeof dataObject.richtungsvektoren === "undefined") {
          console.log('RICHTUNGSVEKTOREN in der geladenen Dateie nicht vorhanden');
        } else {
          dataObject.richtungsvektoren.forEach(function (item) {
            var r = store.push('richtungsvektor', item);
            r.save();
          });
        }

        console.log('ansichtsvektoren');
        if (typeof dataObject.ansichtsvektoren === "undefined") {
          console.log('ANSICHTSVEKTOREN in der geladenen Dateie nicht vorhanden');
        } else {
          dataObject.ansichtsvektoren.forEach(function (item) {
            var r = store.push('ansichtsvektoren', item);
            r.save();
          });
        }

        console.log('orthovektoren');
        if (typeof dataObject.orthovektoren === "undefined") {
          console.log('ORTHOVEKTOREN in der geladenen Dateie nicht vorhanden');
        } else {
          dataObject.orthovektoren.forEach(function (item) {
            var r = store.push('orthovektor', item);
            r.save();
          });
        }

        console.log('screw-properties');
        if (typeof dataObject.verbindungsmittel === "undefined") {
          console.log('VERBINDUNGSMITTEL in der geladenen Dateie nicht vorhanden');
        } else {
          dataObject.verbindungsmittel.forEach(function (item) {
            var screwProp = store.push('screw-property', item);
            screwProp.save();
          });
        }

        console.log('massketten');
        if (typeof dataObject.masskette === "undefined") {
          console.log('MASSKETTEN in der geladenen Dateie nicht vorhanden');
        } else {
          x3d.set('ebene', 1);
          dataObject.masskette.forEach(function (item) {
            var massk = store.push('masskette', item);
            var textEbene = x3d.get('ebene');
            massk.save();
          });
        }

        console.log('lagewinkel');
        if (typeof dataObject.lagewinkel === "undefined") {
          console.log('LAGEWINKEL in der geladenen Dateie nicht vorhanden');
        } else {
          dataObject.lagewinkel.forEach(function (item) {
            var lagewinkel = store.push('lagewinkel', item);
            lagewinkel.save();
          });
        }

        console.log('LADEN ERFOLGREICH');

        x3d.set('ergebnisGeladen', true);
        x3d.set('aktuellerSchraubenTyp', 'verbindungsmittel');
        x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));
        x3d.set('transformHelper', !x3d.get('transformHelper'));
        self.controllerFor('application').zentriertObjekt();
      });
    },

    actions: {

      showInfoDialog: function showInfoDialog() {

        $(".overlay, .overlay-message").show();
      },

      hideInfoDialog: function hideInfoDialog() {
        this.$("#popup2").style.display = 'none';
      },

      cancelCalculation: function cancelCalculation() {
        var self = this;
        self.set('getInfo', false);
        self.set('loading', false);

        var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
        applicationdata.set('initialized', false);

        self.controllerFor('schwingungsnachweis').send('proceedInput');
      },

      getMindestanzahl: function getMindestanzahl() {
        var self = this;

        self.set('ma', self.get('mindestanzahlFromScrew'));

        var i = 0;
        var tmp = [];

        for (i = 1; i <= self.get('ma'); i++) {
          tmp.push({
            value: i,
            name: i
          });
        }

        self.set('mindestanzahlContent', tmp);
        console.log(self.mindestanzahlContent);
      },

      proceedInput: function proceedInput(event) {

        var self = this;

        var selectedScrew = self.selectedNodes[0].data.ArtNr;
        var application = self.controllerFor('application');
        var applicationdata = application.get('model').application.objectAt(0);
        var cid = applicationdata.get('Calculation_ID');

        applicationdata.set('selectedScrew', selectedScrew);

        var tmp = { "Item1": self.get('plattenstoss'), "Item2": 1 };
        // var tmp = {"Item1": self.get('plattenstoss'), "Item2": self.get('mindestanzahl')};

        console.log('cid: ' + cid + ' ::: artnr: ' + selectedScrew);
        console.log('parameter');
        console.log(tmp);

        var server = application.get('server');
        var pfad = application.get('pfad');

        // var berechnungErfolgreich = false;

        $.ajax({
          type: "POST",
          url: server + "berechneVerbindungsmittel/",
          data: {
            cid: self.calcid,
            artnr: selectedScrew,
            parameter: tmp
          }
        }).done(function (data) {

          console.log('##### Verbindungsmittel erfolgreich: #####');
          console.log(data);

          var berechnungErfolgreich = data === 'true';

          self.set('berechnungErfolgreich', berechnungErfolgreich);

          self.set('getInfo', false);

          $.ajax({
            type: "POST",
            url: server + "getKompaktergebnis/",
            data: {
              cid: self.calcid,
              artnr: selectedScrew
            }
          }).done(function (data, statusText, xhr) {

            self.controllerFor('supercontroller').logaufruf("getKompaktergebnis", xhr.status);

            var ergebnis = self.controllerFor('ergebnis');
            var downloadcenter = self.controllerFor('downloadcenter');
            downloadcenter.set('pdfErstellt', false);
            downloadcenter.set('dxfErstellt', false);

            var html = data;
            var bEnd, bStart;
            bStart = html.indexOf("<div class='headline'");

            var schraubeFunktioniert = true;

            console.log(data);
            console.log('bStart = ' + bStart);
            console.log("***** berechnungErfolgreich: " + self.get('berechnungErfolgreich') + " *****");
            console.log(html.indexOf("<div class='headline'>"));

            if (html.indexOf("<div class='headline'>") === -1) {

              console.log("#### Berechnung Erfolglos");

              schraubeFunktioniert = false;
              self.controllerFor('ergebnis').set('schraubeFunktioniert', false);
              bStart = html.indexOf("<div class='content'>");
            } else {
              bStart = html.indexOf("<div class='headline'>");
              console.log("#### Berechnung Erfolgreich");
              schraubeFunktioniert = true;
              self.controllerFor('ergebnis').set('schraubeFunktioniert', true);
            }

            bEnd = html.indexOf("</body");

            var body = html.slice(bStart, bEnd);

            ergebnis.set('kompaktergebnis', body.toString());
            ergebnis.set('pdfErstellt', false);
            ergebnis.set('dxfErstellt', false);
            ergebnis.send('setKompaktergebnis');
            self.transitionToRoute('ergebnis');
            self.set('loading', false);

            self.controllerFor('ergebnis').setKompaktergebnisHeight();

            console.log('schraubeFunktioniert: ' + self.controllerFor('ergebnis').get('schraubeFunktioniert'));

            if (schraubeFunktioniert === true) {
              self.ergebnisgrafik();
            }
          });
        });
        self.getBerechnungsinfo();
        self.set('loading', true);
      },

      logNode: function logNode(event) {
        var self = this;
        console.log(self.selectedNodes);
        console.log(self.selectedNodes[0].text);
      }
    },

    setMinMaxWerte: function setMinMaxWerte(bezeichner, array) {
      var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var dimensionen = x3d.get('dimensionen');

      array.sort(function (a, b) {
        return a - b;
      });

      dimensionen.findBy('name', 'ergebnisgrafikMinialWerte').set(bezeichner, Number(array[0]));
      dimensionen.findBy('name', 'ergebnisgrafikMaximalWerte').set(bezeichner, Number(array[array.length - 1]));
    },

    arrayAbsteigendSortieren: function arrayAbsteigendSortieren(arr) {
      var ergebnis = arr.sort(function (a, b) {
        return b - a;
      });

      return ergebnis;
    },

    getAbstandPunkte: function getAbstandPunkte(x1, x2) {
      return Math.abs(x1 - x2);
    },

    getSumme: function getSumme(x1, x2) {
      return Math.abs(x1 + x2);
    }
  });

});