define('m0601/tests/unit/mixins/tools-test', ['ember', 'm0601/mixins/tools', 'qunit'], function (Ember, ToolsMixin, qunit) {

  'use strict';

  qunit.module('Unit | Mixin | tools');

  // Replace this with your real tests.
  qunit.test('it works', function (assert) {
    var ToolsObject = Ember['default'].Object.extend(ToolsMixin['default']);
    var subject = ToolsObject.create();
    assert.ok(subject);
  });

});