define('m0601/controllers/unterkonstruktion', ['exports', 'ember', 'ember-validations'], function (exports, Ember, EmberValidations) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {

    openFromFile: false,
    displayErrors: true,

    validations: {
      schalung: {
        numericality: {
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 5
        }
      },
      spAbstand: {
        numericality: {
          greaterThanOrEqualTo: 25,
          lessThanOrEqualTo: 250
        }
      },
      spHoehe: {
        numericality: {
          greaterThanOrEqualTo: 5,
          lessThanOrEqualTo: 9999.9
        }
      },
      spBreite: {
        numericality: {
          greaterThanOrEqualTo: 3.6,
          lessThanOrEqualTo: 250
        }
      }
    },

    ttSchalung: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0 - 5 [cm]";
    }),
    ttSpAbstand: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 25 - 250 [cm]";
    }),
    ttSpHoehe: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 5 - 9999.9 [cm]";
    }),
    ttSpBreite: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 3.6 - 250 [cm]";
    }),
    i18n: Ember['default'].inject.service(),

    schalungNotSelected: true,

    uk: [{
      name: "direkt auf Sparren",
      ind: 0
    }, {
      name: "auf Schalung",
      ind: 1
    }, {
      name: "auf Beton",
      ind: 2
    }, {
      name: "auf Porenbeton",
      ind: 3
    }],

    spmaterial: [{
      name: "Vollholz Nadelholz",
      ind: 0
    }, {
      name: "Brettschichtholz homogen",
      ind: 1
    }, {
      name: "Brettschichtholz kombiniert",
      ind: 13
    }],

    fkl_vh: [{
      fklind: 0,
      fkl: "C14"
    }, {
      fklind: 1,
      fkl: "C16"
    }, {
      fklind: 2,
      fkl: "C18"
    }, {
      fklind: 3,
      fkl: "C20"
    }, {
      fklind: 4,
      fkl: "C22"
    }, {
      fklind: 5,
      fkl: "C24"
    }, {
      fklind: 6,
      fkl: "C27"
    }, {
      fklind: 7,
      fkl: "C30"
    }],

    fkl_bsh: [{
      fklind: 20,
      fkl: "GL24h"
    }, {
      fklind: 21,
      fkl: "GL28h"
    }, {
      fklind: 22,
      fkl: "GL32h"
    }, {
      fklind: 23,
      fkl: "GL36h"
    }],

    fkl_bsh_kombi: [{
      fklind: 30,
      fkl: "GL24c"
    }, {
      fklind: 31,
      fkl: "GL28c"
    }, {
      fklind: 32,
      fkl: "GL32c"
    }, {
      fklind: 33,
      fkl: "GL36c"
    }],

    spfklarray: [],
    fpfkl: 5,

    schalung: "0.0",
    spAbstand: "0.0",
    spBreite: "0.0",
    spHoehe: "0.0",

    init: function init() {
      this._super();
      this.set('spfklarray', this.fkl_vh);
    },

    watchNumericalInputs: (function () {

      if (!this.openFromFile) {

        if (this.get('schalung').indexOf(",") !== -1) {
          this.set('schalung', this.get('schalung').replace(",", "."));
        }

        if (this.get('spAbstand').indexOf(",") !== -1) {
          this.set('spAbstand', this.get('spAbstand').replace(",", "."));
        }

        if (this.get('spHoehe').indexOf(",") !== -1) {
          this.set('spHoehe', this.get('spHoehe').replace(",", "."));
        }

        if (this.get('spBreite').indexOf(",") !== -1) {
          this.set('spBreite', this.get('spBreite').replace(",", "."));
        }
      }
    }).observes('schalung', 'spAbstand', 'spHoehe', 'spBreite'),

    watchSpMaterial: (function () {
      console.log(this.title);
      if (this.get('sparrenmaterial') === 1) {
        this.set('spfklarray', this.fkl_bsh);
        this.set('spfkl', 20);
      } else if (this.get('sparrenmaterial') === 13) {
        this.set('spfklarray', this.fkl_bsh_kombi);
        this.set('spfkl', 30);
      } else {
        this.set('spfklarray', this.fkl_vh);
        this.set('spfkl', 5);
      }
    }).observes('sparrenmaterial'),

    watchSchalung: (function () {

      if (!this.openFromFile) {

        if (this.get('unterkonstruktion') === 1) {
          this.set('schalungNotSelected', false);
        } else {
          this.set('schalungNotSelected', true);
        }
      }
    }).observes('unterkonstruktion'),

    watchComboBoxes: (function () {

      var self = this;

      if (!this.openFromFile) {

        var unterkonstruktion = self.controllerFor('application').get('model').unterkonstruktion.objectAt(0);

        unterkonstruktion.set('UK_ind', this.get('unterkonstruktion'));
        unterkonstruktion.set('MatKeyTimberElementSp', this.get('sparrenmaterial'));
        unterkonstruktion.set('FKLKeyTimberElementSp', this.get('spfkl'));

        var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);
        var grundmasse = x3d.get('bauteile').findBy('id', 'dachgeometrie').get('grundmasse').objectAt(0);

        if (this.get('unterkonstruktion') === 0) {
          this.set('schalungNotSelected', true);
          grundmasse.set('schalung', '');
        } else if (this.get('unterkonstruktion') === 1) {
          this.set('schalungNotSelected', false);
          grundmasse.set('schalung', 'holz');
        } else if (this.get('unterkonstruktion') === 2) {
          this.set('schalungNotSelected', false);
          grundmasse.set('schalung', 'beton');
        } else if (this.get('unterkonstruktion') === 3) {
          this.set('schalungNotSelected', false);
          grundmasse.set('schalung', 'porenbeton');
        }
        x3d.set('transformHelper', !x3d.get('transformHelper'));
        x3d.set('farbeGeandert', !x3d.get('farbeGeandert'));
      }
    }).observes('unterkonstruktion', 'sparrenmaterial', 'spfkl'),

    actions: {

      validation: function validation(value, event) {

        var self = this;

        if (!self.openFromFile) {
          this.setX3D(value, event);
        }

        self.validate().then(function () {
          // all validations pass

        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');
          if (validation === true) {
            self.set('displayErrors', false);
            application.set('unterkonstruktionInvalid', false);

            var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
            applicationdata.set('treeLoaded', false);
            applicationdata.set('initialized', false);
            applicationdata.set('pdfErstellt', false);
            applicationdata.set('dxfErstellt', false);

            application.set('verbindungsmittelInvalid', true); // das muss immer so bleibeb, in jedem Reiter.

            var unterkonstruktiondata = self.controllerFor('application').get('model').unterkonstruktion.objectAt(0);

            unterkonstruktiondata.set('schalung', parseFloat(self.get('schalung')).toFixed(1));
            unterkonstruktiondata.set('e_Sp', parseFloat(self.get('spAbstand')).toFixed(1));
            unterkonstruktiondata.set('TimberElementSp_b', parseFloat(self.get('spBreite')).toFixed(1));
            unterkonstruktiondata.set('TimberElementSp_h', parseFloat(self.get('spHoehe')).toFixed(1));
          } else {
            self.set('displayErrors', true);
            application.set('unterkonstruktionInvalid', true);
          }
        });
      },

      schalungIsSelected: function schalungIsSelected(name, value) {
        var schalungshoeheAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('schalungshoeheAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('schalungshoeheAktiv', !schalungshoeheAktiv);
        if (this.get('schalung') !== "") {
          var schalung = parseFloat(this.get('schalung').replace(",", "."));
          this.set('schalung', schalung.toFixed(1));
          this.send('validation');
        }
      },

      spAbstandIsSelected: function spAbstandIsSelected(name, value) {
        var spabstandAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('spabstandAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('spabstandAktiv', !spabstandAktiv);
        if (this.get('spAbstand') !== "") {
          var spAbstand = parseFloat(this.get('spAbstand').replace(",", "."));
          this.set('spAbstand', spAbstand.toFixed(1));
          this.send('validation');
        }
      },

      spHoeheIsSelected: function spHoeheIsSelected(name, value) {
        var sphoeheAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('sphoeheAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('sphoeheAktiv', !sphoeheAktiv);
        if (this.get('spHoehe') !== "") {
          var spHoehe = parseFloat(this.get('spHoehe').replace(",", "."));
          this.set('spHoehe', spHoehe.toFixed(1));
          this.send('validation');
        }
      },

      spBreiteIsSelected: function spBreiteIsSelected(name, value) {
        var spbreiteAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('spbreiteAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('spbreiteAktiv', !spbreiteAktiv);
        if (this.get('spBreite') !== "") {
          var spBreite = parseFloat(this.get('spBreite').replace(",", "."));
          this.set('spBreite', spBreite.toFixed(1));
          this.send('validation');
        }
      }
    },

    setX3D: function setX3D(value, event) {

      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');
      var self = this;

      switch (event.target.name) {
        case 'schalung':
          if (this.errors.schalung.length === 0) {
            value = parseFloat(value).toFixed(1);
            bauteile.findBy('id', 'schalung').get('boxsizes').objectAt(0).set('y', value);
            x3d.set('schalungshoeheEingetragen', true);
            this.setHoeheUnterkonstruktion();
            this.setSchraubenEigenschaften();
          } else {
            x3d.set('schalungshoeheEingetragen', false);
          }
          break;

        case 'spAbstand':
          if (this.errors.spAbstand.length === 0) {
            value = parseFloat(value).toFixed(1);
            bauteile.findBy('id', 'dachgeometrie').get('grundmasse').objectAt(0).set('sparrenabstand', value);
            bauteile.findBy('id', 'daemmung').get('boxsizes').objectAt(0).set('z', Number(value) + 30);
            bauteile.findBy('id', 'schalung').get('boxsizes').objectAt(0).set('z', Number(value) + 30);
            x3d.set('spAbstandEingetragen', true);
          } else {
            x3d.set('spAbstandEingetragen', false);
          }
          break;

        case 'spHoehe':
          if (this.errors.spHoehe.length === 0) {
            value = parseFloat(value).toFixed(1);
            bauteile.findBy('id', 'sparren1').get('boxsizes').objectAt(0).set('y', value);
            bauteile.findBy('id', 'sparren2').get('boxsizes').objectAt(0).set('y', value);
            x3d.set('spHoeheEingetragen', true);
            this.setHoeheUnterkonstruktion();
            this.setSchraubenEigenschaften();
          } else {
            x3d.set('spHoeheEingetragen', false);
          }
          break;
        case 'spBreite':
          if (this.errors.spBreite.length === 0) {
            value = parseFloat(value).toFixed(1);
            bauteile.findBy('id', 'sparren1').get('boxsizes').objectAt(0).set('z', value);
            bauteile.findBy('id', 'sparren2').get('boxsizes').objectAt(0).set('z', value);
            x3d.set('spBreiteEingetragen', true);
          } else {
            x3d.set('spBreiteEingetragen', false);
          }
          break;
      }
      x3d.set('transformHelper', !x3d.get('transformHelper'));
    },

    setHoeheUnterkonstruktion: function setHoeheUnterkonstruktion() {
      var sparrenhoehe = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('bauteile').findBy('id', 'sparren1').get('boxsizes').objectAt(0).get('y');
      var grundlattenhoehe = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('bauteile').findBy('id', 'grundlatte1').get('boxsizes').objectAt(0).get('y');
      var schalungsdicke = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('bauteile').findBy('id', 'schalung').get('boxsizes').objectAt(0).get('y');
      var daemmungsdicke = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('bauteile').findBy('id', 'daemmung').get('boxsizes').objectAt(0).get('y');

      this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('bauteile').findBy('id', 'dachgeometrie').get('grundmasse').objectAt(0).set('hoeheUnterkonstruktion', Number(sparrenhoehe) / 2 + Number(grundlattenhoehe) + Number(schalungsdicke) + Number(daemmungsdicke));
    },

    setSchraubenEigenschaften: function setSchraubenEigenschaften() {

      var bauteile = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('bauteile');

      var sparrenhoehe = Number(bauteile.findBy('id', 'sparren1').get('boxsizes').objectAt(0).get('y'));
      var grundlattenhoehe = Number(bauteile.findBy('id', 'grundlatte1').get('boxsizes').objectAt(0).get('y'));
      var schalungsdicke = Number(bauteile.findBy('id', 'schalung').get('boxsizes').objectAt(0).get('y'));
      var daemmungsdicke = Number(bauteile.findBy('id', 'daemmung').get('boxsizes').objectAt(0).get('y'));

      var h = sparrenhoehe + schalungsdicke + daemmungsdicke + grundlattenhoehe;

      var l = daemmungsdicke / 2 + Number(bauteile.findBy('id', 'grundlatte1').get('boxsizes').objectAt(0).get('y'));
      var yPos = h - l;

      bauteile.findBy('id', 'schraubeStandard21').get('screwProperties').objectAt(0).set('length', l);
      bauteile.findBy('id', 'schraubeStandard21').get('translations').objectAt(0).set('y', yPos);

      bauteile.findBy('id', 'schraubeStandard25').get('screwProperties').objectAt(0).set('length', l);
      bauteile.findBy('id', 'schraubeStandard25').get('translations').objectAt(0).set('y', yPos);

      var l_traufk = Number(bauteile.findBy('id', 'traufkonstruktion1').get('boxsizes').objectAt(0).get('x'));
      var alpha = Math.atan(l_traufk / (daemmungsdicke + schalungsdicke + sparrenhoehe / 2));

      l = Math.sqrt(Math.pow(sparrenhoehe / 2 + schalungsdicke + daemmungsdicke, 2) + Math.pow(l_traufk, 2));

      bauteile.findBy('id', 'schraubeStandard22').get('screwProperties').objectAt(0).set('length', l);
      bauteile.findBy('id', 'schraubeStandard22').get('rotations').objectAt(0).set('z', alpha);

      bauteile.findBy('id', 'schraubeStandard26').get('screwProperties').objectAt(0).set('length', l);
      bauteile.findBy('id', 'schraubeStandard26').get('rotations').objectAt(0).set('z', alpha);
    }

  });

});