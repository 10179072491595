define('m0601/templates/dippelbau-m', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.0",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("shape");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("appearance");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("material");
        dom.setAttribute(el3,"transparency","0.5");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("indexedFaceSet");
        dom.setAttribute(el2,"colorPerVertex","false");
        dom.setAttribute(el2,"ccw","true");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("coordinate");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("color");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("transform");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("shape");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("appearance");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("material");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("indexedLineSet");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("coordinate");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment(" Maßkette Breite ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, get = hooks.get, attribute = hooks.attribute, inline = hooks.inline;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element0 = dom.childAt(fragment, [0, 3]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element0, [3]);
        var element3 = dom.childAt(fragment, [2, 1]);
        var element4 = dom.childAt(element3, [1, 1]);
        var element5 = dom.childAt(element3, [3]);
        var element6 = dom.childAt(element5, [1]);
        var attrMorph0 = dom.createAttrMorph(element0, 'coordIndex');
        var attrMorph1 = dom.createAttrMorph(element0, 'colorindex');
        var attrMorph2 = dom.createAttrMorph(element1, 'point');
        var attrMorph3 = dom.createAttrMorph(element2, 'color');
        var attrMorph4 = dom.createAttrMorph(element4, 'emissivecolor');
        var attrMorph5 = dom.createAttrMorph(element5, 'coordIndex');
        var attrMorph6 = dom.createAttrMorph(element6, 'point');
        var morph0 = dom.createMorphAt(fragment,6,6,contextualElement);
        var morph1 = dom.createMorphAt(fragment,8,8,contextualElement);
        attribute(env, attrMorph0, element0, "coordIndex", get(env, context, "koordinatenIndex"));
        attribute(env, attrMorph1, element0, "colorindex", get(env, context, "farbIndex"));
        attribute(env, attrMorph2, element1, "point", get(env, context, "koordinaten"));
        attribute(env, attrMorph3, element2, "color", get(env, context, "farbe"));
        attribute(env, attrMorph4, element4, "emissivecolor", get(env, context, "emissivecolor"));
        attribute(env, attrMorph5, element5, "coordIndex", get(env, context, "koordinatenIndexLinset"));
        attribute(env, attrMorph6, element6, "point", get(env, context, "koordinaten"));
        inline(env, morph0, context, "indexedlinese-t", [], {"name": get(env, context, "view.name"), "model": get(env, context, "view.model"), "typ": "breite"});
        inline(env, morph1, context, "tex-t", [], {"name": get(env, context, "view.name"), "model": get(env, context, "view.model"), "typ": "breite"});
        return fragment;
      }
    };
  }()));

});