define('m0601/controllers/einwirkung', ['exports', 'ember', 'ember-validations', 'ember-i18n'], function (exports, Ember, EmberValidations, ember_i18n) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {
    i18n: Ember['default'].inject.service(),

    openFromFile: false,

    zusaetzlicheLasten: false,
    eigengewichtBerechnen: false,

    last: "",
    xl: "",
    qlk: "",
    qrk: "",
    xr: "",
    kled: "",
    typ: 1,
    dauer: "",

    gesamtlaenge: 0,

    xlDisabled: true,
    qlkDisabled: false,
    qrkDisabled: true,
    xrDisabled: true,

    buttonDisabled: false,
    lastTypenButtonsDisabled: false,

    displayErrors: true,

    lastEditieren: false,
    zuEditierendeLast: 0,

    glastValue: true,
    tlastValue: false,
    llastValue: false,

    lastIsAktiv: false,
    xrIsAktiv: false,
    xlIsAktiv: false,
    qrkIsAktiv: false,
    qlkIsAktiv: false,

    kledBez: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('eigenlastStaendig');
    }),

    gLK1: 0.0,

    qLK1: 0.00,
    qLK2: 0.00,
    qLK3: 0.00,
    qLK4: 0.00,
    qLK5: 0.00,

    lasten: {
      1: {
        typ: 1,
        last: "Eigenlast",
        kled: 201,
        x_L: "0.0",
        qLK: "0.0",
        qRK: "0.0",
        x_R: "0.0",
        faktor: 0,
        dauer: "staendig",
        added: true,
        edit: false,
        xleingetragen: true,
        xreingetragen: true
      },

      2: {
        typ: 0,
        last: "",
        kled: 0,
        x_L: 0.000,
        qLK: 0.0,
        qRK: 0.0,
        x_R: 0.000,
        faktor: 0,
        dauer: "kurz",
        added: false,
        edit: false,
        xleingetragen: true,
        xreingetragen: true
      },

      3: {
        typ: 0,
        last: "",
        kled: 0,
        x_L: 0.000,
        qLK: 0.0,
        qRK: 0.0,
        x_R: 0.000,
        faktor: 0,
        dauer: "kurz",
        added: false,
        edit: false,
        xleingetragen: true,
        xreingetragen: true
      },

      4: {
        typ: 0,
        last: "",
        kled: 0,
        x_L: 0.000,
        qLK: 0.0,
        qRK: 0.0,
        x_R: 0.000,
        faktor: 0,
        dauer: "kurz",
        added: false,
        edit: false,
        xleingetragen: true,
        xreingetragen: true
      },

      5: {
        typ: 0,
        last: "",
        kled: 0,
        x_L: 0.000,
        qLK: 0.0,
        qRK: 0.0,
        x_R: 0.000,
        faktor: 0,
        dauer: "kurz",
        added: false,
        edit: false,
        xleingetragen: true,
        xreingetragen: true
      },

      6: {
        typ: 0,
        last: "",
        kled: 0,
        x_L: 0.000,
        qLK: 0.0,
        qRK: 0.0,
        x_R: 0.000,
        faktor: 0,
        dauer: "kurz",
        added: false,
        edit: false,
        xleingetragen: true,
        xreingetragen: true
      },

      7: {
        typ: 0,
        last: "",
        kled: 0,
        x_L: 0.000,
        qLK: 0.0,
        qRK: 0.0,
        x_R: 0.000,
        faktor: 0,
        dauer: "kurz",
        added: false,
        edit: false,
        xleingetragen: true,
        xreingetragen: true
      },

      8: {
        typ: 0,
        last: "",
        kled: 0,
        x_L: 0.000,
        qLK: 0.0,
        qRK: 0.0,
        x_R: 0.000,
        faktor: 0,
        dauer: "kurz",
        added: false,
        edit: false,
        xleingetragen: true,
        xreingetragen: true
      },

      9: {
        typ: 0,
        last: "",
        kled: 0,
        x_L: 0.000,
        qLK: 0.0,
        qRK: 0.0,
        x_R: 0.000,
        faktor: 0,
        dauer: "kurz",
        added: false,
        edit: false,
        xleingetragen: true,
        xreingetragen: true
      },

      10: {
        typ: 0,
        last: "",
        kled: 0,
        x_L: 0.000,
        qLK: 0.0,
        qRK: 0.0,
        x_R: 0.000,
        faktor: 0,
        dauer: "kurz",
        added: false,
        edit: false,
        xleingetragen: true,
        xreingetragen: true
      },

      11: {
        typ: 0,
        last: "",
        kled: 0,
        x_L: 0.000,
        qLK: 0.0,
        qRK: 0.0,
        x_R: 0.000,
        faktor: 0,
        dauer: "kurz",
        added: false,
        edit: false,
        xleingetragen: true,
        xreingetragen: true
      }
    },

    validations: {
      xl: {
        numericality: {
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 9.99
        }
      },
      qlk: {
        numericality: {
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 999.99
        }
      },
      qrk: {
        numericality: {
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 999.99
        }
      },
      xr: {
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 9.99
        }
      }
    },

    ttXl: Ember['default'].computed('i18n.locale', {
      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 0 - 9.99 [m]";
      },
      set: function set(key, value) {
        return value;
      }
    }),

    ttQlk: Ember['default'].computed('i18n.locale', {
      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 0 - 999.99 [kN/m²]";
      },
      set: function set(key, value) {
        return value;
      }
    }),

    ttQrk: Ember['default'].computed('i18n.locale', {
      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 0 - 999.99 [kN/m²]";
      },
      set: function set(key, value) {
        return value;
      }
    }),

    ttXr: Ember['default'].computed('i18n.locale', {
      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 0 - 9.99 [cm]";
      },
      set: function set(key, value) {
        return value;
      }
    }),

    typ1: "",

    init: function init() {
      this._super();
      this.set('qlk', "0.00");
      this.set('qrk', '0.00');
      this.set('xl', '0.00');
      this.set('xr', '0.00');
      this.set('kled', '201');
      this.set('dauer', 'staendig');
      this.set('typ1', '1');
    },

    setValues: function setValues(values) {

      var self = this;

      // self.logValues();

      var application = self.controllerFor('application');
      var store = application.get('store');
      var x3d = application.get('model').x3ddefault.objectAt(0);

      // bereits vorhandene Lasten im store löschen
      var tmp = application.get('model').einzelneeinwirkung.content.length;
      var bauteile = x3d.get('bauteile');
      var arrayHelper = [];

      for (var i = 1; i <= tmp; i++) {

        bauteile.findBy('id', 'last' + i).get('lastProperties').objectAt(0).set('gespeichert', false);
        bauteile.findBy('id', 'last' + i).get('lastProperties').objectAt(0).set('editieren', false);
      }

      application.get('model').einzelneeinwirkung.content.forEach(function (item) {

        arrayHelper.pushObject(item);

        store.unloadRecord(item);
      });

      arrayHelper.forEach(function (item) {
        application.get('model').einzelneeinwirkung.removeObject(item);
      });

      // bereits vorhandene Lasten im controller überschreiben
      var lasten = self.get('lasten');
      for (var i = 1; i <= Object.keys(lasten).length; i++) {
        Ember['default'].set(self.lasten[i], 'added', false);
        Ember['default'].set(self.lasten[i], 'edit', false);
      };

      var loadlist = values.LoadList;

      self.set('openFromFile', true);

      console.log('Loadlist');
      console.log(loadlist);

      for (var i = 0; i <= loadlist.length - 1; i++) {

        var loadprop = loadlist[i].LoadProperties;

        var lastid = i + 1;

        var einzeleinwirkung = store.push('einzelneeinwirkung', {
          id: lastid,
          LastTypID: loadprop.LastTypID,
          Beschreibung: loadprop.Beschreibung,
          KLEDGrpID: loadprop.KLEDGrpID, // zahl aus kled menü, zb 306
          LastGrpID: loadprop.LastGrpID, // button oben drüber, art der last
          x_li: loadprop.x_li,
          x_re: loadprop.x_re,
          val_li: loadprop.val_li,
          val_re: loadprop.val_re,
          Multiplikator: "1",
          EinwirkungID: loadprop.KLEDGrpID
        });
        einzeleinwirkung.save();

        x3d.set('lastInBearbeitung', 'last' + lastid);

        console.log('last', lastid);

        if (Number(loadprop.LastTypID) === 1) {
          bauteile.findBy('id', 'last' + lastid).get('diffusecolors').objectAt(0).set('rot', 0.2);
          bauteile.findBy('id', 'last' + lastid).get('diffusecolors').objectAt(0).set('gruen', 1);
          bauteile.findBy('id', 'last' + lastid).get('diffusecolors').objectAt(0).set('blau', 0.4);
          bauteile.findBy('id', 'last' + lastid).get('lastProperties').objectAt(0).set('lastart', 'gleichlast');
        } else if (Number(loadprop.LastTypID) === 2) {
          bauteile.findBy('id', 'last' + lastid).get('diffusecolors').objectAt(0).set('rot', 0);
          bauteile.findBy('id', 'last' + lastid).get('diffusecolors').objectAt(0).set('gruen', 0);
          bauteile.findBy('id', 'last' + lastid).get('diffusecolors').objectAt(0).set('blau', 1);
          bauteile.findBy('id', 'last' + lastid).get('lastProperties').objectAt(0).set('lastart', 'trapezlast');
        } else if (Number(loadprop.LastTypID) === 3) {
          bauteile.findBy('id', 'last' + lastid).get('diffusecolors').objectAt(0).set('rot', 0.8);
          bauteile.findBy('id', 'last' + lastid).get('diffusecolors').objectAt(0).set('gruen', 0);
          bauteile.findBy('id', 'last' + lastid).get('diffusecolors').objectAt(0).set('blau', 0.8);
          bauteile.findBy('id', 'last' + lastid).get('lastProperties').objectAt(0).set('lastart', 'einzellast');
        }

        bauteile.findBy('id', x3d.get('lastInBearbeitung')).get('boxsizes').objectAt(0).set('y', loadprop.val_li);
        bauteile.findBy('id', x3d.get('lastInBearbeitung')).get('boxsizes').objectAt(0).set('z', loadprop.val_re);
        bauteile.findBy('id', x3d.get('lastInBearbeitung')).get('lastProperties').objectAt(0).set('name', self.get('last'));
        bauteile.findBy('id', x3d.get('lastInBearbeitung')).get('lastProperties').objectAt(0).set('gespeichert', true);

        var xSize = Number(bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1')) / 10;
        var newTranslation = 0;
        var xl = 0;

        if (Number(loadprop.LastTypID) === 3) {
          xl = Number(loadprop.x_li);
          newTranslation = -xSize / 2 + xl * 10;

          bauteile.findBy('id', x3d.get('lastInBearbeitung')).get('translations').objectAt(0).set('x', newTranslation);
        } else if (Number(loadprop.LastTypID) === 1) {
          bauteile.findBy('id', x3d.get('lastInBearbeitung')).get('translations').objectAt(0).set('x', 0);
        } else {
          xl = Number(loadprop.x_li);
          var xr = Number(loadprop.x_re);

          xSize = xSize - xl * 10 - xr * 10;

          newTranslation = (xl - xr) / 2;

          bauteile.findBy('id', x3d.get('lastInBearbeitung')).get('boxsizes').objectAt(0).set('x', xSize * 10);
          bauteile.findBy('id', x3d.get('lastInBearbeitung')).get('translations').objectAt(0).set('x', newTranslation * 10);
        }

        self.send('setKLED', 1, kledMenuDB[loadprop.KLEDGrpID].kled, kledMenuDB[loadprop.KLEDGrpID].textid, loadprop.KLEDGrpID);

        Ember['default'].set(self.lasten[lastid], 'typ', loadprop.LastTypID);
        Ember['default'].set(self.lasten[lastid], 'last', loadprop.Beschreibung);
        Ember['default'].set(self.lasten[lastid], 'kled', self.get('kled'));
        Ember['default'].set(self.lasten[lastid], 'x_L', loadprop.x_li);
        Ember['default'].set(self.lasten[lastid], 'qLK', loadprop.val_li);
        Ember['default'].set(self.lasten[lastid], 'qRK', loadprop.val_re);
        Ember['default'].set(self.lasten[lastid], 'x_R', loadprop.x_re);
        Ember['default'].set(self.lasten[lastid], 'dauer', self.get('dauer'));
        Ember['default'].set(self.lasten[lastid], 'added', true);
        Ember['default'].set(self.lasten[lastid], 'edit', false);

        bauteile.findBy('id', x3d.get('lastInBearbeitung')).get('lastProperties').objectAt(0).set('editieren', false);

        self.set('lastTypenButtonsDisabled', false);
        self.controllerFor('application').set('lastInBearbeitung', false);
      }

      x3d.set('systemwerteGeaendert', false);

      self.set('openFromFile', false);

      self.set('typ', 1);
      self.set('last', "");
      self.set('kled', "201");
      self.set('xl', "0.00");
      self.set('qlk', "0.00");
      self.set('qrk', "0.00");
      self.set('xr', "0.00");
      self.set('dauer', "staendig");

      self.set('lastEditieren', false);
      self.set('zuEditierendeLast', -1);

      self.set('lastTypenButtonsDisabled', false);
      self.controllerFor('application').set('lastInBearbeitung', false);
    },

    watchNumericalInputs: (function () {

      if (!this.openFromFile) {

        if (this.get('xl').indexOf(",") !== -1) {
          this.set('xl', this.get('xl').replace(",", "."));
        }

        if (this.get('qlk').indexOf(",") !== -1) {
          this.set('qlk', this.get('qlk').replace(",", "."));
        }
        if (this.get('qrk').indexOf(",") !== -1) {
          this.set('qrk', this.get('qrk').replace(",", "."));
        }
        if (this.get('xr').indexOf(",") !== -1) {
          this.set('xr', this.get('xr').replace(",", "."));
        }
      }
    }).observes('xl', 'xr', 'qlk', 'qrk'),

    watchQlk: (function () {

      var self = this;

      if (self.get('typ') === 1) {
        self.set('qrk', self.get('qlk'));
      }
    }).observes('qlk'),

    logValues: function logValues() {
      var self = this;

      console.log('***** self *****');
      console.log('lastEditieren: ' + self.get('lastEditieren'));
      console.log('xl: ' + self.get('xl'));
      console.log('xr: ' + self.get('xr'));
      console.log('qlk: ' + self.get('qlk'));
      console.log('qrk: ' + self.get('qrk'));
      console.log('EinwirkungID: ' + self.get('kled'));
      console.log(' ');

      var lasten = self.get('lasten');
      console.log('***** lasten *****');
      console.log(lasten);
      console.log(Object.keys(lasten).length);
      for (var i = 1; i <= Object.keys(lasten).length; i++) {
        console.log(lasten[i]);
        console.log('Beschreibung: ' + lasten[i].last);
        console.log('xl: ' + lasten[i].x_L);
        console.log('xr: ' + lasten[i].x_R);
        console.log('qlk: ' + lasten[i].qLK);
        console.log('qrk: ' + lasten[i].qRK);
        console.log('EinwirkungID: ' + lasten[i].kled);
      };
      console.log(' ');

      var application = self.controllerFor('application');
      var einzeleinwirkung = application.get('model').einzelneeinwirkung;
      var anzahlLasten = application.get('model').einzelneeinwirkung.content.length;

      console.log('***** einzeleinwirkungen *****');
      console.log(einzeleinwirkung);
      console.log(anzahlLasten);

      application.get('model').einzelneeinwirkung.content.forEach(function (item) {
        console.log(item);
      });

      // for (var i = 0; i <= anzahlLasten - 1; i++) {
      //   console.log(einzeleinwirkung.objectAt(i));
      //   console.log('Beschreibung: '+einzeleinwirkung.objectAt(i).get('Beschreibung'));
      //   console.log('xl: '+einzeleinwirkung.objectAt(i).get('x_li'));
      //   console.log('xr: '+einzeleinwirkung.objectAt(i).get('x_re'));
      //   console.log('qlk: '+einzeleinwirkung.objectAt(i).get('val_li'));
      //   console.log('qrk: '+einzeleinwirkung.objectAt(i).get('val_re'));
      //   console.log('EinwirkungID: '+einzeleinwirkung.objectAt(i).get('EinwirkungID'));
      // };
      console.log(' ');
    },

    lastInStore: function lastInStore(lastid) {
      var value = false;

      if (self.get('lasten')[i].added === true) {
        value = true;
      }

      return value;
    },

    neueEinzeleinwirkung: function neueEinzeleinwirkung(lastid) {
      var self = this;
      var application = self.controllerFor('application');
      var store = self.controllerFor('application').get('store');
      var einzeleinwirkung = store.push('einzelneeinwirkung', {
        id: lastid,
        LastTypID: self.get('typ'),
        Beschreibung: self.get('last'),
        KLEDGrpID: self.get('kled'), // zahl aus kled menü, zb 306
        LastGrpID: 1, // button oben drüber, art der last
        x_li: self.get('xl'),
        x_re: self.get('xr'),
        val_li: self.get('qlk'),
        val_re: self.get('qrk'),
        Multiplikator: "1",
        EinwirkungID: self.get('kled')
      });
      einzeleinwirkung.save();
      // application.get('model').einzelneeinwirkung.pushObject(einzeleinwirkung);

      // var einwirkungdata = application.get('model').einwirkung.objectAt(0);
      // einwirkungdata.get('einwirkungen').pushObject(einzeleinwirkung);
    },

    getObjectByID: function getObjectByID(id) {
      var self = this;
      var application = self.controllerFor('application');
      var einwirkung;

      application.get('model').einzelneeinwirkung.content.forEach(function (item) {
        if (parseInt(id) === parseInt(item.id)) {
          einwirkung = item;
        }
        return;
      });

      return einwirkung;
    },

    speicherLastwerteImStore: function speicherLastwerteImStore(lastid) {
      var self = this;

      console.log("speicherLastwerteImStore ---> lastid: " + lastid + " ::: zuEditierendeLast: " + self.get('zuEditierendeLast'));

      lastid = typeof lastid !== 'undefined' ? lastid : parseInt(self.get('zuEditierendeLast'));

      var application = self.controllerFor('application');
      // var einwirkung = application.get('model').einzelneeinwirkung.objectAt(lastid-1);
      var einwirkung = self.getObjectByID(lastid);
      var anzahlLasten = application.get('model').einzelneeinwirkung.content.length;

      console.log(einwirkung);

      einwirkung.set('Beschreibung', self.get('last'));
      einwirkung.set('KLEDGrpID', self.get('kled'));
      einwirkung.set('LastGrpID', 1);
      einwirkung.set('LastTypID', self.get('typ'));
      einwirkung.set('x_li', self.get('xl'));
      einwirkung.set('x_re', self.get('xr'));
      einwirkung.set('val_li', self.get('qlk'));
      einwirkung.set('val_re', self.get('qrk'));
      einwirkung.set('EinwirkungID', self.get('kled'));

      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');
      bauteile.findBy('id', 'last' + lastid).get('lastProperties').objectAt(0).set('name', self.get('last'));
      bauteile.findBy('id', 'last' + lastid).get('lastProperties').objectAt(0).set('gespeichert', true);
      bauteile.findBy('id', 'last' + lastid).get('lastProperties').objectAt(0).set('editieren', false);
    },

    speicherLastwerteImController: function speicherLastwerteImController(lastid) {
      var self = this;

      console.log("speicherLastwerteImStore ---> lastid: " + lastid + " ::: zuEditierendeLast: " + self.get('zuEditierendeLast'));

      lastid = typeof lastid !== 'undefined' ? lastid : parseInt(self.get('zuEditierendeLast'));

      Ember['default'].set(self.lasten[lastid], 'typ', self.get('typ'));
      Ember['default'].set(self.lasten[lastid], 'last', self.get('last'));
      Ember['default'].set(self.lasten[lastid], 'kled', self.get('kled'));
      Ember['default'].set(self.lasten[lastid], 'x_L', self.get('xl'));
      Ember['default'].set(self.lasten[lastid], 'qLK', self.get('qlk'));
      Ember['default'].set(self.lasten[lastid], 'qRK', self.get('qrk'));
      Ember['default'].set(self.lasten[lastid], 'x_R', self.get('xr'));
      Ember['default'].set(self.lasten[lastid], 'dauer', self.get('dauer'));
      Ember['default'].set(self.lasten[lastid], 'added', true);
      Ember['default'].set(self.lasten[lastid], 'edit', false);
    },

    // setLastStartwerte: function(lastid){
    //   var self = this;
    //
    //   lastid = (typeof lastid !== 'undefined') ?  lastid : parseInt(self.get('zuEditierendeLast'));
    //
    //   self.set('typ', 1);
    //   self.set('last', "");
    //   self.set('kled', "201");
    //   self.set('xl', "0.0");
    //   self.set('qlk', "0.0");
    //   self.set('qrk', "0.0");
    //   self.set('xr', "0.0");
    //   self.set('dauer', "staendig");
    //
    //   self.set('lastEditieren', false);
    //   self.set('zuEditierendeLast', 0);
    //
    //   self.set('editLast' + lastnr, false);
    //
    // },

    getFreieLastID: function getFreieLastID() {
      var self = this;

      var lastid = -1;

      for (var i = 1; i <= Object.keys(self.get('lasten')).length; i++) {
        if (self.get('lasten')[i].added === false && lastid === -1) {
          lastid = i;
        }
      }
      return lastid;
    },

    berechneDefinitionsbereich: function berechneDefinitionsbereich(lastTyp) {
      var self = this;
      var supercontroller = self.controllerFor('supercontroller');
      var systemlaengeL1 = Number(self.controllerFor('system').get('f1'));
      var xrvalue = lastTyp !== 2 ? systemlaengeL1 : systemlaengeL1 > 0 ? systemlaengeL1 - Number(self.get('xl')) : 9.99;
      var xlvalue = lastTyp !== 2 ? systemlaengeL1 : systemlaengeL1 > 0 ? systemlaengeL1 - Number(self.get('xr')) : 9.99;
      supercontroller.setDefinitionsbereich("einwirkung", "xl", 0, xlvalue - 0.01, "ttXl", "m");
      supercontroller.setDefinitionsbereich("einwirkung", "xr", 0, xrvalue - 0.01, "ttXr", "m");
    },

    actions: {

      neueLastAnlegen: function neueLastAnlegen() {
        var self = this;

        var lastid = self.getFreieLastID();

        var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);
        var bauteile = x3d.get('bauteile');

        console.log('neue LAST anlegen mit ID: ' + lastid);

        // if(lastid > 1){
        self.neueEinzeleinwirkung(lastid);
        // }

        self.berechneDefinitionsbereich(1);

        self.set('lastEditieren', true);
        self.set('zuEditierendeLast', lastid);
        Ember['default'].set(self.lasten[lastid], 'added', true);
        Ember['default'].set(self.lasten[lastid], 'edit', true);

        x3d.set('lastInBearbeitung', 'last' + lastid);
        bauteile.findBy('id', x3d.get('lastInBearbeitung')).get('lastProperties').objectAt(0).set('editieren', true);
        self.send('typSetzen', self.typ);

        // self.set('last', self.lasten[lastid]['last']);

        self.send('setKLED', 1, kledMenuDB[201].kled, kledMenuDB[201].textid, 201);

        self.send('validation', true, {
          target: {
            name: "lastEditieren"
          }
        });
      },

      lastSpeichern: function lastSpeichern() {
        var self = this;
        var lastid = self.get('zuEditierendeLast');

        self.speicherLastwerteImStore();
        self.speicherLastwerteImController();

        self.set('typ', 1);
        self.set('last', "");
        self.set('kled', "201");
        self.set('xl', "0.00");
        self.set('qlk', "0.00");
        self.set('qrk', "0.00");
        self.set('xr', "0.00");
        self.set('dauer', "staendig");

        self.set('lastEditieren', false);
        self.set('zuEditierendeLast', -1);

        self.set('lastTypenButtonsDisabled', false);
        self.controllerFor('application').set('lastInBearbeitung', false);
      },

      // lastHinzufuegen: function () {
      //
      //   console.log('Last hinzufügen');
      //
      //   var self = this;
      //   var application = self.controllerFor('application');
      //   var einwirkungdata = application.get('model').einwirkung.objectAt(0);
      //   var store = application.get('store');
      //
      //   var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);
      //   var bauteile = x3d.get('bauteile');
      //   var tempTyp = self.typ;
      //
      //   if (!self.lastEditieren) {
      //
      //     console.log('last editieren false');
      //
      //     // finde frei ID der nächsten freien last
      //     var lastid = 11;
      //
      //     bauteile.forEach(function (item) {
      //       if (item.get('typ') === 'last' && item.get('lastProperties').objectAt(0).get('gespeichert') === false && lastid === 11 ) {
      //         lastid = Number(item.id.substring(4, 5))+1;
      //       }
      //     });
      //
      //     if (lastid <= 10) {
      //       var einzeleinwirkung = store.push('einzelneeinwirkung', {
      //         id: lastid,
      //         // id: Number(einwirkungdata.get('einwirkungen').length) + 1,
      //         LastTypID: self.get('typ'),
      //         Beschreibung: self.get('last'),
      //         KLEDGrpID: self.get('kled'), // zahl aus kled menü, zb 306
      //         LastGrpID: self.get('typ'), // button oben drüber, art der last
      //         x_li: self.get('xl'),
      //         x_re: self.get('xr'),
      //         val_li: self.get('getqlk'),
      //         val_re: self.get('getqrk'),
      //         Multiplikator: "1",
      //         EinwirkungID: self.get('kled')
      //       });
      //       einzeleinwirkung.save();
      //       // einwirkungdata.get('einwirkungen').pushObject(einzeleinwirkung);
      //
      //       Ember.set(self.lasten[lastid], 'added', true);
      //       Ember.set(self.lasten[lastid], 'edit', true);
      //
      //       x3d.set('lastInBearbeitung', 'last' + lastid);
      //       bauteile.findBy('id', x3d.get('lastInBearbeitung')).get('lastProperties').objectAt(0).set('editieren', true);
      //       self.send('typSetzen', tempTyp);
      //     //
      //     }
      //
      //     // console.log('LAST ANLEGEN');
      //     self.set('lastEditieren', true);
      //     self.set('zuEditierendeLast', lastid);
      //     self.set('last', self.lasten[lastid]['last']);
      //
      //
      //   } else {
      //     var lastnr = parseInt(self.get('zuEditierendeLast')-1);
      //     var einwirkung = application.get('model').einzelneeinwirkung.objectAt(lastnr);
      //
      //     bauteile.findBy('id', ('last' + (lastnr+1))).get('lastProperties').objectAt(0).set('name', self.get('last'));
      //     einwirkung.set('Beschreibung', self.get('last'));
      //     einwirkung.set('KLEDGrpID', self.get('kled'));
      //     einwirkung.set('LastGrpID', self.get('typ'));
      //     einwirkung.set('x_li', self.get('xl'));
      //     einwirkung.set('x_re', self.get('xr'));
      //     einwirkung.set('val_li', self.get('qlk'));
      //     einwirkung.set('val_re', self.get('qrk'));
      //     einwirkung.set('EinwirkungID', self.get('kled'))
      //
      //     lastnr = lastnr + 1;
      //
      //     Ember.set(self.lasten[lastnr], 'typ', self.get('typ'));
      //     Ember.set(self.lasten[lastnr], 'last', self.get('last'));
      //     Ember.set(self.lasten[lastnr], 'kled', self.get('kled'));
      //     Ember.set(self.lasten[lastnr], 'x_L', self.get('xl'));
      //     Ember.set(self.lasten[lastnr], 'qLK', self.get('qlk'));
      //     Ember.set(self.lasten[lastnr], 'qRK', self.get('qrk'));
      //     Ember.set(self.lasten[lastnr], 'x_R', self.get('xr'));
      //     Ember.set(self.lasten[lastnr], 'dauer', self.get('dauer'));
      //
      //     self.set('typ', 1);
      //     self.set('last', "");
      //     self.set('kled', "201");
      //     self.set('xl', "0.0");
      //     self.set('qlk', "0.0");
      //     self.set('qrk', "0.0");
      //     self.set('xr', "0.0");
      //     self.set('dauer', "staendig");
      //
      //     self.set('lastEditieren', false);
      //     self.set('zuEditierendeLast', 0);
      //
      //     // self.set('editLast' + lastnr, false);
      //     Ember.set(self.lasten[lastnr], 'edit', false);
      //
      //
      //     bauteile.findBy('id', ('last' + lastnr)).get('lastProperties').objectAt(0).set('gespeichert', true);
      //     bauteile.findBy('id', ('last' + lastnr)).get('lastProperties').objectAt(0).set('editieren', false);
      //
      //     self.set('lastTypenButtonsDisabled', false);
      //     self.controllerFor('application').set('lastInBearbeitung', false);
      //
      //   }
      //
      //   self.send('validation', true, {
      //     target: {
      //       name: "lastEditieren"
      //     }
      //   });
      //
      // },

      editLast: function editLast(lastid) {

        var self = this;

        var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);
        x3d.set('lastInBearbeitung', 'last' + lastid);

        if (self.get('lastEditieren') !== true) {
          self.set('dauer', self.lasten[lastid]['dauer']);
          self.set('last', self.lasten[lastid]['last']);

          self.set('kled', self.lasten[lastid]['kled']);
          self.set('typ', self.lasten[lastid]['typ']);
          self.set('xl', self.lasten[lastid]['x_L']);
          self.set('qlk', self.lasten[lastid]['qLK']);
          self.set('qrk', self.lasten[lastid]['qRK']);
          self.set('xr', self.lasten[lastid]['x_R']);

          self.set('lastEditieren', true);
          self.set('zuEditierendeLast', lastid);
          Ember['default'].set(self.lasten[lastid], 'edit', true);

          self.set('buttonDisabled', false);
          self.set('lastTypenButtonsDisabled', true);

          self.send('setKLED', 1, kledMenuDB[parseInt(self.get('kled'))].kled, kledMenuDB[parseInt(self.get('kled'))].textid, parseInt(self.get('kled')));

          self.controllerFor('application').set('lastInBearbeitung', true);

          self.send('typSetzen', self.lasten[lastid]['typ']);
        }
      },

      lastLoeschen: function lastLoeschen() {

        var self = this;
        var application = self.controllerFor('application');
        var store = application.get('store');

        var lastnr = parseInt(self.get('zuEditierendeLast'));

        // Zu löschende Last aus Model 'einzelneeinwirkung' entfernen

        store.find('einzelneeinwirkung', lastnr).then(function (eintrag) {
          eintrag.deleteRecord();
          eintrag.get('isDeleted'); // => true
          eintrag.save(); // => DELETE to /posts/1

          application.get('model').einzelneeinwirkung.removeObject(eintrag);
        });

        // Werte in den Eingabefeldern zurücksetzen

        self.set('typ', 1);
        self.set('last', "");
        self.set('kled', "201");
        self.set('xl', "0.00");
        self.set('qlk', "0.00");
        self.set('qrk', "0.00");
        self.set('xr', "0.00");
        self.set('dauer', "staendig");

        // Werte zu löschender Last in lasten im controller zurücksetzen

        Ember['default'].set(self.lasten[lastnr], 'typ', self.get('typ'));
        Ember['default'].set(self.lasten[lastnr], 'last', self.get('last'));
        Ember['default'].set(self.lasten[lastnr], 'kled', self.get('kled'));
        Ember['default'].set(self.lasten[lastnr], 'x_L', self.get('xl'));
        Ember['default'].set(self.lasten[lastnr], 'qLK', self.get('qlk'));
        Ember['default'].set(self.lasten[lastnr], 'qRK', self.get('qrk'));
        Ember['default'].set(self.lasten[lastnr], 'x_R', self.get('xr'));
        Ember['default'].set(self.lasten[lastnr], 'dauer', self.get('dauer'));

        // Zu löschende Last aus lasten im controller "löschen"

        Ember['default'].set(self.lasten[lastnr], 'added', false);
        Ember['default'].set(self.lasten[lastnr], 'edit', false);

        // Zu löschende Last aus für Grafik zurücksetzen

        var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);
        var bauteile = x3d.get('bauteile');

        bauteile.findBy('id', 'last' + lastnr).get('lastProperties').objectAt(0).set('gespeichert', false);
        bauteile.findBy('id', 'last' + lastnr).get('lastProperties').objectAt(0).set('editieren', false);

        self.resetX3dLast('last' + lastnr);

        // Werte in controllern für das Ende der Berabeitung setzen

        self.set('lastEditieren', false);
        self.set('zuEditierendeLast', 0);
        self.controllerFor('application').set('lastInBearbeitung', false);
        self.set('lastTypenButtonsDisabled', false);
      },

      setKLED: function setKLED(lastnr, kled, textid, last) {

        var self = this;
        var einwirkungdata = self.controllerFor('application').get('model').einwirkung.objectAt(0);

        // console.log("***** setKLED controller *****");
        // console.log('lastnr: '+lastnr+' kled: '+kled+' textid: '+textid+' last: '+last);

        self.set('kled', last);
        self.set('dauer', kled);
        self.set('kledBez', self.get('i18n').t(textid));

        einwirkungdata.set('kled' + lastnr, kled);
        einwirkungdata.get('einwirkungen').set('KLEDGrpID', last);
      },

      typSetzen: function typSetzen(typ) {

        console.log('zu setzender Typ: ' + typ);

        typ = parseInt(typ);

        var self = this;

        self.set('typ', typ);

        if (self.get('lastEditieren') !== true) {
          self.set('last', "");
          self.set('kled', "201");
          self.set('xl', "0.00");
          self.set('qlk', "0.00");
          self.set('qrk', "0.00");
          self.set('xr', "0.00");
          self.set('dauer', "staendig");
        }

        if (typ === 1) {

          console.log('Typ 1 setzen');

          self.set('xlDisabled', true);
          self.set('qlkDisabled', false);
          self.set('qrkDisabled', true);
          self.set('xrDisabled', true);

          self.set('glastValue', true);
          self.set('tlastValue', false);
          self.set('llastValue', false);

          // self.set('validations.xl.numericality.greaterThanOrEqualTo', 0.0);
          // self.set('validations.qlk.numericality.greaterThanOrEqualTo', 0.1);
          // self.set('validations.qrk.numericality.greaterThanOrEqualTo', 0.0);
          // self.set('validations.xr.numericality.greaterThanOrEqualTo', 0.0);

          self.x3dFarbenSetzen('gleichlast', 0.2, 1, 0.4);
        } else if (typ === 2) {

          console.log('Typ 2 setzen');

          self.set('xlDisabled', false);
          self.set('qlkDisabled', false);
          self.set('qrkDisabled', false);
          self.set('xrDisabled', false);

          self.set('glastValue', false);
          self.set('tlastValue', true);
          self.set('llastValue', false);

          // self.set('validations.xl.numericality.greaterThanOrEqualTo', 0.1);
          // self.set('validations.qlk.numericality.greaterThanOrEqualTo', 0.1);
          // self.set('validations.qrk.numericality.greaterThanOrEqualTo', 0.1);
          // self.set('validations.xr.numericality.greaterThanOrEqualTo', 0.1);

          self.x3dFarbenSetzen('trapezlast', 0, 0, 1);
        } else if (typ === 3) {

          console.log('Typ 3 setzen');

          self.set('xlDisabled', false);
          self.set('qlkDisabled', false);
          self.set('qrkDisabled', true);
          self.set('xrDisabled', true);

          self.set('glastValue', false);
          self.set('tlastValue', false);
          self.set('llastValue', true);

          // self.set('validations.xl.numericality.greaterThanOrEqualTo', 0.1);
          // self.set('validations.qlk.numericality.greaterThanOrEqualTo', 0.1);
          // self.set('validations.qrk.numericality.greaterThanOrEqualTo', 0.0);
          // self.set('validations.xr.numericality.greaterThanOrEqualTo', 0.0);

          self.x3dFarbenSetzen('einzellast', 0.8, 0, 0.8);
        }

        self.berechneDefinitionsbereich(typ);

        self.send('validation', typ, {
          target: {
            name: "lastInBearbeitung"
          }
        });

        self.setTranslations();
      },

      validation: function validation(value, event) {

        var self = this;

        if (!self.openFromFile) {

          this.setX3D(value, event);
        }

        self.validate().then(function () {
          // all validati0ons pass

        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');
          if (validation === true) {
            console.log("validation true");
            self.set('displayErrors', false);
            application.set('einwirkungInvalid', false);

            var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
            applicationdata.set('treeLoaded', false);
            applicationdata.set('initialized', false);
            applicationdata.set('pdfErstellt', false);
            applicationdata.set('dxfErstellt', false);

            var downloadcenter = self.controllerFor('downloadcenter');
            downloadcenter.set('pdfErstellt', false);
            downloadcenter.set('dxfErstellt', false);

            self.set('buttonDisabled', false);

            var lasten = self.get('lasten');

            console.log("Anzahl der Lasten: ");
            console.log(Object.keys(lasten).length);

            for (var i = 0; i < Object.keys(lasten).length; i++) {

              console.log(" ");
              console.log("lasten[" + (i + 1) + "].added: " + lasten[i + 1].added);
              console.log("lasten[" + (i + 1) + "].xleingetragen: " + lasten[i + 1].xleingetragen);
              console.log("lasten[" + (i + 1) + "].xreingetragen: " + lasten[i + 1].xreingetragen);

              if (lasten[i + 1].added == true && (lasten[i + 1].xleingetragen === false || lasten[i + 1].xreingetragen === false)) {
                application.set('einwirkungInvalid', true);

                console.log("***** einwirkungInvalid " + application.get('einwirkungInvalid') + " *****");
              }
            }
          } else {
            console.log("validation false");
            self.set('displayErrors', true);
            application.set('einwirkungInvalid', true);
            self.set('buttonDisabled', true);
          }
        });
      },

      qlkIsSelected: function qlkIsSelected() {

        var qlkIsAktiv = this.get('qlkIsAktiv');
        this.set('qlkIsAktiv', !qlkIsAktiv);

        if (this.get('qlk') !== "") {
          var wertwork = parseFloat(this.get('qlk').replace(",", "."));
          var wertrounded = wertwork.toFixed(2);
          this.set('qlk', wertrounded);
          // this.send('validation');
        }

        if (qlkIsAktiv === false) {
          document.getElementsByName('qlk')[0].setSelectionRange(0, this.get('qlk').length);
        }
      },

      qrkIsSelected: function qrkIsSelected() {

        var qrkIsAktiv = this.get('qrkIsAktiv');
        this.set('qrkIsAktiv', !qrkIsAktiv);

        if (this.get('qrk') !== "") {
          var wertwork = parseFloat(this.get('qrk').replace(",", "."));
          var wertrounded = wertwork.toFixed(2);
          this.set('qrk', wertrounded);
          // this.send('validation');
        }

        if (qrkIsAktiv === false) {
          document.getElementsByName('qrk')[0].setSelectionRange(0, this.get('qrk').length);
        }
      },

      xlIsSelected: function xlIsSelected() {

        var xlIsAktiv = this.get('xlIsAktiv');
        this.set('xlIsAktiv', !xlIsAktiv);

        if (this.get('xl') !== "") {
          var wertwork = parseFloat(this.get('xl').replace(",", "."));
          var wertrounded = wertwork.toFixed(2);
          this.set('xl', wertrounded);
          // this.send('validation');
        }

        if (xlIsAktiv === false) {
          document.getElementsByName('xl')[0].setSelectionRange(0, this.get('xl').length);
        }
      },

      xrIsSelected: function xrIsSelected() {

        var xrIsAktiv = this.get('xrIsAktiv');
        this.set('xrIsAktiv', !xrIsAktiv);

        if (this.get('xr') !== "") {
          var wertwork = parseFloat(this.get('xr').replace(",", "."));
          var wertrounded = wertwork.toFixed(2);
          this.set('xr', wertrounded);
          // this.send('validation');
        }

        if (xrIsAktiv === false) {
          document.getElementsByName('xr')[0].setSelectionRange(0, this.get('xr').length);
        }
      },

      lastIsSelected: function lastIsSelected() {

        var lastIsAktiv = this.get('lastIsAktiv');
        this.set('lastIsAktiv', !lastIsAktiv);

        if (lastIsAktiv === false) {
          document.getElementsByName('last')[0].setSelectionRange(0, this.get('last').length);
        }
      }
    },

    x3dFarbenSetzen: function x3dFarbenSetzen(typ, rot, gruen, blau) {

      var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');
      var lastid = x3d.get('lastInBearbeitung');

      bauteile.findBy('id', lastid).get('diffusecolors').objectAt(0).set('rot', rot);
      bauteile.findBy('id', lastid).get('diffusecolors').objectAt(0).set('gruen', gruen);
      bauteile.findBy('id', lastid).get('diffusecolors').objectAt(0).set('blau', blau);

      bauteile.findBy('id', lastid).get('lastProperties').objectAt(0).set('lastart', typ);

      x3d.set('lastenTrigger', !x3d.get('lastenTrigger'));
    },

    setX3D: function setX3D(value, event) {
      var self = this;

      var application = this.controllerFor('application');
      var supercontroller = this.controllerFor('supercontroller');
      var systemlaengeL1 = Number(this.controllerFor('system').get('f1'));
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');

      var lastid = parseInt(self.get('zuEditierendeLast'));

      if (x3d.get('ergebnisGeladen') === true) {
        self.controllerFor('system').resetGrafikToDefault();
      }

      value = Number(value);

      console.log("+++++ Blub Blub +++++");

      switch (event.target.name) {
        case 'xl':
          if (this.errors.xl.length === 0) {
            // var xAlt = Number(bauteile.findBy('id', x3d.get('lastInBearbeitung')).get('boxsizes').objectAt(0).get('x'));
            // bauteile.findBy('id', x3d.get('lastInBearbeitung')).get('boxsizes').objectAt(0).set('x', (xAlt-Number(value)*100));

            var xrvalue = systemlaengeL1 > 0 ? systemlaengeL1 - value : 9.99;
            supercontroller.setDefinitionsbereich("einwirkung", "xr", 0, xrvalue - 0.01, "ttXr", "m");

            Ember['default'].set(self.lasten[lastid], 'xleingetragen', true);

            self.setTranslations();
          }
          break;
        case 'qlk':
          if (this.errors.qlk.length === 0) {
            bauteile.findBy('id', x3d.get('lastInBearbeitung')).get('boxsizes').objectAt(0).set('y', value);
          }
          break;
        case 'qrk':
          if (this.errors.qrk.length === 0) {
            bauteile.findBy('id', x3d.get('lastInBearbeitung')).get('boxsizes').objectAt(0).set('z', value);
          }
          break;
        case 'xr':
          if (this.errors.xr.length === 0) {
            // var xAlt = Number(bauteile.findBy('id', x3d.get('lastInBearbeitung')).get('boxsizes').objectAt(0).get('x'));
            // bauteile.findBy('id', x3d.get('lastInBearbeitung')).get('boxsizes').objectAt(0).set('x', (xAlt-Number(value)*100));

            var xlvalue = systemlaengeL1 > 0 ? systemlaengeL1 - value : 9.99;
            supercontroller.setDefinitionsbereich("einwirkung", "xl", 0, xlvalue - 0.01, "ttXl", "m");

            Ember['default'].set(self.lasten[lastid], 'xreingetragen', true);

            self.setTranslations();
          }
          break;
      }

      x3d.set('lastenTrigger', !x3d.get('lastenTrigger'));
      x3d.set('transformHelper', !x3d.get('transformHelper'));
      self.controllerFor('application').zentriertObjekt();
    },

    setTranslations: function setTranslations() {
      var self = this;

      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');

      var lastart = bauteile.findBy('id', x3d.get('lastInBearbeitung')).get('lastProperties').objectAt(0).get('lastart');
      var newTranslation = 0;
      var xl = 0;

      if (lastart === 'einzellast') {
        var xSize = Number(bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1')) / 10;
        xl = Number(self.get('xl'));
        newTranslation = -xSize / 2 + xl * 10;
        bauteile.findBy('id', x3d.get('lastInBearbeitung')).get('translations').objectAt(0).set('x', newTranslation);
      } else if (lastart === 'gleichlast') {
        bauteile.findBy('id', x3d.get('lastInBearbeitung')).get('translations').objectAt(0).set('x', 0);
      } else {
        var xSize = Number(bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 10);
        xl = Number(self.get('xl'));
        var xr = Number(self.get('xr'));

        xSize = xSize - xl * 10 - xr * 10;

        newTranslation = (xl - xr) / 2;
        bauteile.findBy('id', x3d.get('lastInBearbeitung')).get('boxsizes').objectAt(0).set('x', xSize * 10);
        bauteile.findBy('id', x3d.get('lastInBearbeitung')).get('translations').objectAt(0).set('x', newTranslation * 10);
      }
    },

    resetX3dLast: function resetX3dLast(lastid) {
      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');
      var xSize = Number(bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1')) / 10;

      var zuLoeschendeLast = x3d.get('lastInBearbeitung');

      if (zuLoeschendeLast !== 'last1') {
        bauteile.findBy('id', lastid).get('boxsizes').objectAt(0).set('x', xSize);
        bauteile.findBy('id', lastid).get('boxsizes').objectAt(0).set('y', 0.5);
        bauteile.findBy('id', lastid).get('boxsizes').objectAt(0).set('z', 0.5);

        bauteile.findBy('id', lastid).get('translations').objectAt(0).set('x', 0);
        bauteile.findBy('id', lastid).get('translations').objectAt(0).set('y', 0);
        bauteile.findBy('id', lastid).get('translations').objectAt(0).set('z', 0);

        bauteile.findBy('id', lastid).get('lastProperties').objectAt(0).set('gespeichert', false);
        bauteile.findBy('id', lastid).get('lastProperties').objectAt(0).set('editieren', false);

        // var lastNr = Number(zuLoeschendeLast.substring(4, 6)) - 1;
        // x3d.set('lastInBearbeitung', 'last' + lastNr);
      }

      x3d.set('lastenTrigger', !x3d.get('lastenTrigger'));
      x3d.set('transformHelper', !x3d.get('transformHelper'));
    },

    glast: (function () {
      var self = this;
      return self.get('glastValue');
    }).property('glastValue'),

    tlast: (function () {
      var self = this;
      return self.get('tlastValue');
    }).property('tlastValue'),

    llast: (function () {
      var self = this;
      return self.get('llastValue');
    }).property('llastValue'),

    pruefeLasten: function pruefeLasten(systemlaenge) {
      var self = this;

      var application = self.controllerFor('application');
      var lasten = application.get('model').einzelneeinwirkung;

      for (var i = 0; i < lasten.content.length; i++) {

        if (self.get('lasten')[i + 1].typ === 2) {
          lasten.objectAt(i).set('x_li', "");
          lasten.objectAt(i).set('x_re', "");

          self.get('lasten')[i + 1].x_L = "";
          self.get('lasten')[i + 1].x_R = "";

          self.get('lasten')[i + 1].xleingetragen = false;
          self.get('lasten')[i + 1].xreingetragen = false;

          application.set('einwirkungInvalid', true);
        } else if (self.get('lasten')[i + 1].typ === 3) {
          lasten.objectAt(i).set('x_li', "");

          self.get('lasten')[i + 1].x_L = "";

          self.get('lasten')[i + 1].xleingetragen = false;
        }
      }
    }

  });

});