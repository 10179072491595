define('m0601/models/verbindungsmittel', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	var verbindungsmittel = DS['default'].Model.extend({

		// plattenstoss: DS.attr('boolean'),
		// maxSchubverbinder: DS.attr('string')

	});

	verbindungsmittel.reopenClass({
		FIXTURES: [{
			id: 1
		}]
	});

	// plattenstoss: false,
	// maxSchubverbinder: "0"
	exports['default'] = verbindungsmittel;

});