define('m0601/components/extrusio-n', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Extrusion = Ember['default'].Component.extend({
    tagName: 'extrusion',
    attributeBindings: ['endCap', 'solid', 'creaseAngle', 'crosssection', 'orientation', 'scale', 'spine'],
    endCap: true,
    solid: true,
    ispickable: "0",
    creaseAngle: 7,

    //crossection beschreibt die zu extrodierende Grundform in der xz-Ebene
    crosssection: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      var values = false;
      var x;
      var y;
      var z;

      var item = this.get('model').objectAt(0).get('bauteile').findBy('id', name);

      if (item.get('typ') === "box") {

        var randAbstandLinks = 0;
        var randAbstandRechts = 0;

        if (name === 'lager1' || name === 'lager2' || name === 'aufgehendesbauteil1' || name === 'aufgehendesbauteil2') {
          x = item.get('boxsizes').objectAt(0).get('x') / 10;
          z = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('tiefeUnterkonstruktion') / 10 + bauteile.findBy('id', 'unterkonstruktion2').get('boxsizes').objectAt(0).get('z') / 10;
        } else if (name.substring(0, 17) === 'unterkonstruktion') {
          x = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 10;
          randAbstandLinks = -bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('x') / 20;
          randAbstandRechts = -bauteile.findBy('id', 'lager2').get('boxsizes').objectAt(0).get('x') / 20;
          // if (bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('deckenkonstruktion')  === 'balken'){
          z = this.get('model').objectAt(0).get('bauteile').findBy('id', name).get('boxsizes').objectAt(0).get('z') / 10;
          // }else if (bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('deckenkonstruktion')  === 'holzelement'){
          //   z = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('tiefeUnterkonstruktion')/10;
          // }
        } else if (name === 'schalung' || name === 'beton') {
            x = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 10;

            if (bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('auflager1Durchlaufend')) {
              randAbstandLinks = -bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('x') / 20;
            } else {
              randAbstandLinks = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('auflager1RandRechts') / 10;
            }

            if (bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('auflager2Durchlaufend')) {
              randAbstandRechts = -bauteile.findBy('id', 'lager2').get('boxsizes').objectAt(0).get('x') / 20;
            } else {
              randAbstandRechts = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('auflager2RandLinks') / 10;
            }

            z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 10;
          }

        values = '[(' + (-x / 2 + randAbstandLinks) + ',' + -z / 2 + '),(' + (-x / 2 + randAbstandLinks) + ',' + z / 2 + '),(' + (x / 2 - randAbstandRechts) + ',' + z / 2 + '),(' + (x / 2 - randAbstandRechts) + ',' + -z / 2 + '),(' + (-x / 2 + randAbstandLinks) + ',' + -z / 2 + ')]';
      }

      return values;
    }).property('model.firstObject.transformHelper'),

    //orientation beschreibt die Richtung in der die Extrusion erfolgt
    //Wird die Extrusion in mehreren Schritten/Stufen durchgeführt, ist die Richtung für jede Stufe anzugeben.
    orientation: (function () {
      var name = this.get('name');
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');

      var values = '0 1 0 0, 0 1 0 0';

      var item = this.get('model').objectAt(0).get('bauteile').findBy('id', name);

      if (item.get('typ') === "box") {
        values = '[(0,0,0,1)]';
      }

      return values;
    }).property('model.firstObject.transformHelper'),

    //scale beschreibt die Skalierung der Grundfläche während eines Extrusionschritts
    //Wird die Extrusion in mehreren Schritten/Stufen durchgeführt, ist die Skalierung für jeden Schritt/Stufe anzugeben.
    scale: (function () {
      var name = this.get('name');
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');

      var values = '0 0, 0.025 0.025, 0.05 0.05, 0.07 0.07';

      var item = this.get('model').objectAt(0).get('bauteile').findBy('id', name);

      if (item.get('typ') === "box") {
        values = '[(1,1)]';
      }

      return values;
    }).property('model.firstObject.transformHelper'),

    //spine beschreibt die Länge der Extrusion in y-Richtung
    //Die Extrusion kann in mehreren Schritten/Stufen erfolgen
    spine: (function () {
      var name = this.get('name');
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var values = '0 0 0';

      var item = this.get('model').objectAt(0).get('bauteile').findBy('id', name);

      if (item.get('typ') === "box") {
        var y = item.get('boxsizes').objectAt(0).get('y') / 10;

        // if(name === "beton" && bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('deckenkonstruktion') === "dippelbaum"){
        //   y = y + bauteile.findBy('id', 'unterkonstruktion1').get('boxsizes').objectAt(0).get('y')/10;
        // }

        values = '[(0,0,0), (0 ' + y + ' 0)]';
      }

      return values;
    }).property('model.firstObject.transformHelper')
  });

  exports['default'] = Extrusion;

});