define('m0601/templates/ft-verbinder', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.12.0",
        blockParams: 1,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("transform");
          dom.setAttribute(el1,"class","ember-view testClassNameXXX");
          dom.setAttribute(el1,"bboxcenter","0 0 0");
          dom.setAttribute(el1,"bboxsize","-1,-1,-1");
          dom.setAttribute(el1,"center","0 0 0");
          dom.setAttribute(el1,"scale","1,1,1");
          dom.setAttribute(el1,"scaleorientation","0,0,0,0");
          dom.setAttribute(el1,"render","true");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("transform");
          dom.setAttribute(el2,"class","ember-view testClassNameXXX");
          dom.setAttribute(el2,"translation","0 0 0");
          dom.setAttribute(el2,"rotation","0 0 0 0");
          dom.setAttribute(el2,"bboxcenter","0 0 0");
          dom.setAttribute(el2,"bboxsize","-1,-1,-1");
          dom.setAttribute(el2,"center","0 0 0");
          dom.setAttribute(el2,"scale","1,1,1");
          dom.setAttribute(el2,"scaleorientation","0,0,0,0");
          dom.setAttribute(el2,"render","true");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("shape");
          dom.setAttribute(el3,"class","ember-view");
          dom.setAttribute(el3,"bboxcenter","0,0,0");
          dom.setAttribute(el3,"bboxsize","-1,-1,-1");
          dom.setAttribute(el3,"ispickable","0");
          dom.setAttribute(el3,"def","");
          dom.setAttribute(el3,"render","true");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("appearance");
          dom.setAttribute(el4,"class","ember-view");
          dom.setAttribute(el4,"sorttype","auto");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("material");
          dom.setAttribute(el5,"class","ember-view");
          dom.setAttribute(el5,"diffusecolor","0.8 0.8 0.8");
          dom.setAttribute(el5,"transparency","0");
          dom.setAttribute(el5,"ambientintensity","0.2");
          dom.setAttribute(el5,"emissivecolor","0,0,0");
          dom.setAttribute(el5,"shininess","0.2");
          dom.setAttribute(el5,"specularcolor","0,0,0");
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("extrusion");
          dom.setAttribute(el4,"class","ember-view");
          dom.setAttribute(el4,"endcap","false");
          dom.setAttribute(el4,"solid","false");
          dom.setAttribute(el4,"creaseangle","7");
          dom.setAttribute(el4,"crosssection","1 0 0.9 -0.35 0.6 -0.6 0.3 -0.7 0 -0.75 -0.3 -0.7 -0.6 -0.6 -0.9 -0.35 -1 0 -0.9 0.35 -0.6 0.6 -0.3 0.7 0 0.75 0.3 0.7 0.6 0.6 0.9 0.35 1 0");
          dom.setAttribute(el4,"orientation","0 0 1 1.1, 0 0 1 0, 0 0 1 0, 0 0 1 1.06");
          dom.setAttribute(el4,"scale","0.15 0.1 0.07 0.1 0.09 0.18 0.18 0.18");
          dom.setAttribute(el4,"spine","0 0 0 , 0.8 0.46 0, 0.8 0.46 0, 1.25 0.7 0");
          dom.setAttribute(el4,"ccw","true");
          dom.setAttribute(el4,"usegeocache","true");
          dom.setAttribute(el4,"lit","true");
          dom.setAttribute(el4,"begincap","true");
          dom.setAttribute(el4,"convex","true");
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("transform");
          dom.setAttribute(el2,"class","ember-view testClassNameXXX");
          dom.setAttribute(el2,"translation","0.8 0.45 0");
          dom.setAttribute(el2,"rotation","0 0 1 0.5236");
          dom.setAttribute(el2,"bboxcenter","0 0 0");
          dom.setAttribute(el2,"bboxsize","-1,-1,-1");
          dom.setAttribute(el2,"center","0 0 0");
          dom.setAttribute(el2,"scale","1,1,1");
          dom.setAttribute(el2,"scaleorientation","0,0,0,0");
          dom.setAttribute(el2,"render","true");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("shape");
          dom.setAttribute(el3,"class","ember-view");
          dom.setAttribute(el3,"bboxcenter","0,0,0");
          dom.setAttribute(el3,"bboxsize","-1,-1,-1");
          dom.setAttribute(el3,"ispickable","0");
          dom.setAttribute(el3,"def","");
          dom.setAttribute(el3,"render","true");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("appearance");
          dom.setAttribute(el4,"class","ember-view");
          dom.setAttribute(el4,"sorttype","auto");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("material");
          dom.setAttribute(el5,"class","ember-view");
          dom.setAttribute(el5,"diffusecolor","0.9 0.9 0.9");
          dom.setAttribute(el5,"transparency","0");
          dom.setAttribute(el5,"ambientintensity","0.2");
          dom.setAttribute(el5,"emissivecolor","0,0,0");
          dom.setAttribute(el5,"shininess","0.2");
          dom.setAttribute(el5,"specularcolor","0,0,0");
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("box");
          dom.setAttribute(el4,"size","0.06 0.5 0.5");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("transform");
          dom.setAttribute(el2,"class","ember-view testClassNameXXX");
          dom.setAttribute(el2,"translation","0 0 -0.03");
          dom.setAttribute(el2,"rotation","1 0 0 1.5707");
          dom.setAttribute(el2,"bboxcenter","0 0 0");
          dom.setAttribute(el2,"bboxsize","-1,-1,-1");
          dom.setAttribute(el2,"center","0 0 0");
          dom.setAttribute(el2,"scale","1,1,1");
          dom.setAttribute(el2,"scaleorientation","0,0,0,0");
          dom.setAttribute(el2,"render","true");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("shape");
          dom.setAttribute(el3,"class","ember-view");
          dom.setAttribute(el3,"bboxcenter","0,0,0");
          dom.setAttribute(el3,"bboxsize","-1,-1,-1");
          dom.setAttribute(el3,"ispickable","0");
          dom.setAttribute(el3,"def","");
          dom.setAttribute(el3,"render","true");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("appearance");
          dom.setAttribute(el4,"class","ember-view");
          dom.setAttribute(el4,"sorttype","auto");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("material");
          dom.setAttribute(el5,"class","ember-view");
          dom.setAttribute(el5,"diffusecolor","0.8 0.8 0.8");
          dom.setAttribute(el5,"transparency","0");
          dom.setAttribute(el5,"ambientintensity","0.2");
          dom.setAttribute(el5,"emissivecolor","0,0,0");
          dom.setAttribute(el5,"shininess","0.2");
          dom.setAttribute(el5,"specularcolor","0,0,0");
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("extrusion");
          dom.setAttribute(el4,"class","ember-view");
          dom.setAttribute(el4,"endcap","true");
          dom.setAttribute(el4,"solid","true");
          dom.setAttribute(el4,"creaseangle","7");
          dom.setAttribute(el4,"crosssection","0 0 0.8 0 0.8 -0.5 0 0");
          dom.setAttribute(el4,"orientation","0 1 0 0, 0 1 0 0");
          dom.setAttribute(el4,"scale","1 1");
          dom.setAttribute(el4,"spine","0 0 0, 0 0.07 0");
          dom.setAttribute(el4,"ccw","true");
          dom.setAttribute(el4,"usegeocache","true");
          dom.setAttribute(el4,"lit","true");
          dom.setAttribute(el4,"begincap","true");
          dom.setAttribute(el4,"convex","true");
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement, blockArguments) {
          var dom = env.dom;
          var hooks = env.hooks, set = hooks.set, get = hooks.get, attribute = hooks.attribute;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element0 = dom.childAt(fragment, [1]);
          var attrMorph0 = dom.createAttrMorph(element0, 'translation');
          var attrMorph1 = dom.createAttrMorph(element0, 'rotation');
          set(env, context, "ftVerbinder", blockArguments[0]);
          attribute(env, attrMorph0, element0, "translation", get(env, context, "ftVerbinder.pos"));
          attribute(env, attrMorph1, element0, "rotation", get(env, context, "ftVerbinder.rot"));
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.0",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, get = hooks.get, block = hooks.block;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
        dom.insertBoundary(fragment, null);
        dom.insertBoundary(fragment, 0);
        block(env, morph0, context, "each", [get(env, context, "view.ftvListe")], {}, child0, null);
        return fragment;
      }
    };
  }()));

});