define('m0601/models/materialien', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	var materialien = DS['default'].Model.extend({

		MatKeyBeam: DS['default'].attr('string'),
		FKLKeyBeam: DS['default'].attr('string'),
		b: DS['default'].attr('string'),
		h: DS['default'].attr('string'),
		e_Beam: DS['default'].attr('string'),
		vorgebohrtBeam: DS['default'].attr('string'),
		schalung: DS['default'].attr('string'),
		betonGuete: DS['default'].attr('string'),
		betonDicke: DS['default'].attr('string'),
		dX_Sub_right: DS['default'].attr('string'),
		dX_Sub_left: DS['default'].attr('string'),
		SubWithWall: DS['default'].attr('string'),
		mindestBewehrung: DS['default'].attr('string'),
		mindestBewehrungOption: DS['default'].attr('string'),
		PlatteDurchlaufend: DS['default'].attr('string'),
		MatKeyConcrete: DS['default'].attr('string'),
		FKLKeyConcrete: DS['default'].attr('string'),
		MatKeyInterlayer: DS['default'].attr('string'),
		FKLKeyInterlayer: DS['default'].attr('string'),
		vorgebohrtInterlayer: DS['default'].attr('string')

	});

	materialien.reopenClass({
		FIXTURES: [{
			id: 1,
			MatKeyBeam: "0",
			FKLKeyBeam: "5",
			b: "0.0",
			h: "0.0",
			e_Beam: "0.0",
			schalung: "0.0",
			betonGuete: "9120",
			betonDicke: "0.0",
			dX_Sub_left: "0,00##0,00##0,00##0,00##0,00",
			dX_Sub_right: "0,00##0,00##0,00##0,00##0,00",
			SubWithWall: "0##0##0##0##0",
			mindestBewehrung: "0.0",
			mindestBewehrungOption: "0",
			PlatteDurchlaufend: "0##0##0##0##0",
			MatKeyConcrete: "9100",
			FKLKeyConcrete: "9120",
			MatKeyInterlayer: "0",
			FKLKeyInterlayer: "5",
			vorgebohrtBeam: false,
			vorgebohrtInterlayer: false
		}]
	});

	exports['default'] = materialien;

});