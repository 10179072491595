define('m0601/controllers/contact', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    debug: false,
    message: "",
    sname: "",
    email: "",
    testemail: "",

    sendingMail: false,

    nachrichtVerfassen: true,

    actions: {

      sendMessage: function sendMessage() {

        var self = this;

        if (self.get('testemail') === "") {

          console.log('sendMessage()');

          self.set('sendingMail', true);

          var messageToSend = self.get('message');
          var nameOfSender = self.get('sname');
          var email = self.get('email');

          var application = self.controllerFor('application');

          var applicationdata = application.get('model').application.objectAt(0);
          var applicationdataJSON = JSON.stringify(applicationdata);

          var projektdatendata = application.get('model').projektdaten.objectAt(0);
          var projektdatendataJSON = JSON.stringify(projektdatendata);

          //m0601

          var systemdata = application.get('model').system.objectAt(0);
          var systemdataJSON = JSON.stringify(systemdata);

          var auflagerdata = application.get('model').auflager.objectAt(0);
          var auflagerdataJSON = JSON.stringify(auflagerdata);

          var konstruktiondata = application.get('model').konstruktion.objectAt(0);
          var konstruktiondataJSON = JSON.stringify(konstruktiondata);

          var materialiendata = application.get('model').materialien.objectAt(0);
          var materialiendataJSON = JSON.stringify(materialiendata);

          var einwirkungdata = application.get('model').einwirkung.objectAt(0);
          var einwirkungdataJSON = JSON.stringify(einwirkungdata);

          var nachweisedata = application.get('model').schwingungsnachweis.objectAt(0);
          var nachweisedataJSON = JSON.stringify(nachweisedata);

          var schraubenvorauswahldata = application.get('model').verbindungsmittel.objectAt(0);
          var schraubenvorauswahldataJSON = JSON.stringify(schraubenvorauswahldata);

          var uebergabedaten = $.extend(true, JSON.parse(projektdatendataJSON), JSON.parse(systemdataJSON), JSON.parse(auflagerdataJSON), JSON.parse(konstruktiondataJSON), JSON.parse(materialiendataJSON), JSON.parse(einwirkungdataJSON), JSON.parse(nachweisedataJSON), JSON.parse(schraubenvorauswahldataJSON));

          //Lastannahmen/Parameter_Strukt.vb

          var timberelement = {
            "Querschnittswerte": {
              "b": materialiendata.get('b'),
              "h": materialiendata.get('h'),
              "vorgebohrt": false,
              "QuerschnittsID": "1"
            }

          };

          var interlayerelement = {
            "Querschnittswerte": {
              "b": materialiendata.get('e_Beam'),
              "h": materialiendata.get('schalung'),
              "vorgebohrt": false,
              "QuerschnittsID": "1"
            }
          };

          var concreteelement = {
            "Querschnittswerte": {
              "b": materialiendata.get('e_Beam'),
              "h": materialiendata.get('betonDicke'),
              "QuerschnittsID": "1"
            }
          };

          var einwirkungen = [];

          uebergabedaten.TimberElement = timberelement;
          uebergabedaten.InterlayerElement = interlayerelement;
          uebergabedaten.ConcreteElement = concreteelement;

          console.log(JSON.stringify(uebergabedaten));

          var anzahlLasten = application.get('model').einzelneeinwirkung.content.length;

          console.log("######");
          console.log(anzahlLasten);

          for (var i = 0; i <= anzahlLasten - 1; i++) {
            var last = {
              'LoadProperties': application.get('model').einzelneeinwirkung.objectAt(i),
              'LoadEffect': {
                'MyID': 0,
                'MyText': 'foo',
                'MyKLED': 2,
                'MyTextKLED': 'feucht',
                'MyXi0': 1.0,
                'MyXi1': 2.0,
                'MyXi2': 3.0,
                'MyOriginID': 23
              }
            };
            einwirkungen.push(last);
          }

          console.log("!!!");
          console.log(einwirkungen);

          uebergabedaten.LoadList = einwirkungen;

          var JSONdata = JSON.stringify(uebergabedaten);

          var application = self.controllerFor('application');
          var server = application.get('server');
          var pfad = application.get('pfad');
          var cid = applicationdata.get('Calculation_ID');

          if (self.debug) {
            console.log('cid: ' + cid);
            console.log('server: ' + server);
            console.log('pfad: ' + pfad);
            console.log('nameOfSender: ' + nameOfSender);
            console.log('messageToSend: ');
            console.log(messageToSend);
            console.log('kennung: ');
            console.log(applicationdataJSON);
            console.log('paras:');
            console.log(JSONdata);
          }

          $.ajax({
            type: "POST",
            url: server + "sendeEmail/",
            data: {
              kennung: applicationdataJSON,
              paras: JSONdata,
              suffix: "wu0601",
              cid: cid,
              body: messageToSend,
              name: nameOfSender,
              subj: 'M0601 HTML CLIENT - Kontaktformular / Anfrage',
              sender: email,
              receiver: "support@swg-engineering.de"
            }
          }).done(function (data) {
            self.set('sendingMail', false);
            console.log(data);
            self.set('nachrichtVerfassen', false);
            // datei öffnen, bzw. link anzeigen
          });
        }
      },

      neueNachrichtVerfassen: function neueNachrichtVerfassen() {
        var self = this;
        self.set('message', "");
        this.transitionToRoute('projektdaten');
        self.set('nachrichtVerfassen', true);
      }

    }

  });

});