define('m0601/models/konstruktion', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	var konstruktion = DS['default'].Model.extend({

		dxWall: DS['default'].attr('string'),
		dxWall_Input: DS['default'].attr('boolean'),
		VarDecken: DS['default'].attr('string'),
		VarVerbund: DS['default'].attr('string')

	});

	konstruktion.reopenClass({
		FIXTURES: [{
			id: 1,
			dxWall: "0.0",
			dxWall_Input: false,
			VarDecken: "0",
			VarVerbund: "2"
		}]
	});

	exports['default'] = konstruktion;

});