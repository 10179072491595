define('m0601/models/grundmass', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    tiefeUnterkonstruktion: DS['default'].attr('number'),
    laengeF1: DS['default'].attr('number'),
    laengeF2: DS['default'].attr('number'),
    laengeF3: DS['default'].attr('number'),
    laengeF4: DS['default'].attr('number'),
    ueberstandLinks: DS['default'].attr('number'),
    ueberstandRechts: DS['default'].attr('number'),
    randabstandlinks: DS['default'].attr('number'),
    randabstandrechts: DS['default'].attr('number'),
    montageplatz: DS['default'].attr('number'),
    deckenkonstruktion: DS['default'].attr('string', { defaultValue: 'balken' }),
    indirekteslager1: DS['default'].attr('boolean'),
    indirekteslager2: DS['default'].attr('boolean'),
    indirekteslager3: DS['default'].attr('boolean'),
    indirekteslager4: DS['default'].attr('boolean'),
    indirekteslager5: DS['default'].attr('boolean'),
    aufgehendeBauteil1: DS['default'].attr('boolean'),
    aufgehendeBauteil2: DS['default'].attr('boolean'),
    aufgehendeBauteil3: DS['default'].attr('boolean'),
    aufgehendeBauteil4: DS['default'].attr('boolean'),
    aufgehendeBauteil5: DS['default'].attr('boolean'),
    auflager1Durchlaufend: DS['default'].attr('boolean'),
    auflager2Durchlaufend: DS['default'].attr('boolean'),
    auflager3Durchlaufend: DS['default'].attr('boolean'),
    auflager4Durchlaufend: DS['default'].attr('boolean'),
    auflager5Durchlaufend: DS['default'].attr('boolean'),
    auflager1RandLinks: DS['default'].attr('number'),
    auflager1RandRechts: DS['default'].attr('number'),
    auflager2RandLinks: DS['default'].attr('number'),
    auflager2RandRechts: DS['default'].attr('number'),
    auflager3RandLinks: DS['default'].attr('number'),
    auflager3RandRechts: DS['default'].attr('number'),
    auflager4RandLinks: DS['default'].attr('number'),
    auflager4RandRechts: DS['default'].attr('number'),
    auflager5RandLinks: DS['default'].attr('number'),
    auflager5RandRechts: DS['default'].attr('number'),
    hbvSystem: DS['default'].attr('string')

  });

});