define('m0601/components/indexedfacese-t', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Transform = Ember['default'].Component.extend({

    tagName: 'transform',
    layoutName: 'indexedfacese-t',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    objektSkalierung: 1,

    translation: (function () {
      var name = this.get('name');
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');

      var x = 0;
      var y = 0;
      var z = 0;

      var cords;

      var item = x3d.objectAt(0).get('bauteile').findBy('id', name);

      if (name === 'grundkonstruktion') {
        x = 0;
        y = -item.get('grundmasse').objectAt(0).get('firsthoehe') / (this.objektSkalierung * 3);
        z = 0;
        cords = x + ' ' + y + ' ' + z;
      }

      return cords;
    }).property('model.firstObject.transformHelper'),

    rotation: (function () {
      var name = this.get('name');
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var values = '0 0 0 0';
      var item = x3d.objectAt(0).get('bauteile').findBy('id', name);

      return values;
    }).property('model.firstObject.transformHelper'),

    koordinaten: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');

      var hwinkel = Number(x3d.objectAt(0).get('hWinkel'));
      var vwinkel = Number(x3d.objectAt(0).get('vWinkel'));

      var x = 0;
      var y = 0;
      var z = 0;

      var skalierungsfaktor = x3d.objectAt(0).get('skalierungsfaktor');

      var cords;

      var item = x3d.objectAt(0).get('bauteile').findBy('id', name);

      if (name === 'grundkonstruktion') {

        var skalierung = this.objektSkalierung;

        var gebaeudebreite = item.get('grundmasse').objectAt(0).get('gebaeudebreite') / skalierung;
        var gebaeudelaenge = item.get('grundmasse').objectAt(0).get('gebaeudelaenge') / skalierung;
        var firsthoehe = item.get('grundmasse').objectAt(0).get('firsthoehe') / skalierung;
        var dachneigung = item.get('grundmasse').objectAt(0).get('dachneigung') * Math.PI / 180;
        var dachneigung2 = item.get('grundmasse').objectAt(0).get('dachneigung2') * Math.PI / 180;
        var firstueberstand = item.get('grundmasse').objectAt(0).get('firstueberstand') / skalierung;
        var traufeueberstand = item.get('grundmasse').objectAt(0).get('traufeueberstand') / skalierung;
        var giebelueberstand = item.get('grundmasse').objectAt(0).get('giebelueberstand') / skalierung;

        if (typ === 'satteldach') {
          cords = this.getSatteldachKoordinaten(gebaeudebreite, gebaeudelaenge, firsthoehe, dachneigung, firstueberstand, traufeueberstand, giebelueberstand);
        }

        if (typ === 'pultdach') {
          cords = this.getPultdachKoordinaten(gebaeudebreite, gebaeudelaenge, firsthoehe, dachneigung, firstueberstand, traufeueberstand, giebelueberstand);
        }

        if (typ === 'walmdach') {
          cords = this.getWalmdachKoordinaten(gebaeudebreite, gebaeudelaenge, firsthoehe, dachneigung, dachneigung2, firstueberstand, traufeueberstand, giebelueberstand);
        }
      }
      return cords;
    }).property('model.firstObject.transformHelper', 'model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.istHt', 'model.firstObject.skalierungsfaktor', 'model.firstObject.schraubenGekreuzt'),

    koordinatenIndex: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var values = '';

      var item = x3d.objectAt(0).get('bauteile').findBy('id', name);

      if (name === 'grundkonstruktion') {
        if (typ === 'satteldach') {
          values = "0 3 2 1 0 -1 0 1 5 20 4 0 -1 1 2 6 5 1 -1 2 3 7 21 6 -1 3 0 4 7 0 -1 8 13 12 11 10 9 8 -1 14 15 16 17 18 19 14 -1 8 9 15 14 19 13 8 -1 9 10 16 15 9 -1 11 12 18 17 16 10 11 -1 12 13 19 18 12 -1 22 23 24 25 26 27 22 -1";
        }
        if (typ === 'pultdach') {
          values = "0 3 2 1 0 -1 0 1 5 4 0 -1 1 2 6 5 1 -1 2 3 7 6 -1 3 0 4 7 0 -1 11 10 9 8 11 -1 12 13 14 15 12 -1  8 9 13 12 8 -1 9 10 14 13 9 -1 10 11 15 14 10 -1 11 8 12 15 11 -1 16 17 18 19 -1";
        }
        if (typ === 'walmdach') {
          values = "0 3 2 1 0 -1 0 1 5 4 0 -1 1 2 6 5 1 -1 2 3 7 6 -1 3 0 4 7 0 -1 8 9 13 8 -1 8 11 10 9 8 -1 11 12 10 11 -1 8 13 12 11 8 -1 18 17 14 18 -1 9 14 17 13 9 -1 14 15 19 18 14 -1 9 10 15 14 9 -1 15 16 19 15 -1 10 12 16 15 10 -1 18 19 16 17 18 -1 12 13 17 16 12 -1";
        }
      }
      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt'),

    farbIndex: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var values = '';

      var item = x3d.objectAt(0).get('bauteile').findBy('id', name);

      if (name === 'grundkonstruktion') {
        if (typ === 'satteldach') {
          values = "2 2 2 2 2 0 0 0 0 0 0 1";
        }
        if (typ === 'pultdach') {
          values = "2 2 2 2 2 0 0 0 0 0 0 1";
        }
        if (typ === 'walmdach') {
          values = "2 2 2 2 2 0 0 0 0 0 0 0 0 0 0 0 0";
        }
      }
      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt'),

    farbe: (function () {
      var values = '1 0 0, 0.66 0 0.07, 0.85 0.85 0.85';

      return values;
    }).property('model.firstObject.transformHelper'),

    getSatteldachKoordinaten: function getSatteldachKoordinaten(gebaeudebreite, gebaeudelaenge, firsthoehe, dachneigung, firstueberstand, traufeueberstand, giebelueberstand) {
      var p0, p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11, p12, p13, p14, p15, p16, p17, p18, p19, p20, p21, p22, p23, p24, p25, p26, p27;
      var x, y, deltaYTraufe, z;

      y = firsthoehe - Math.tan(dachneigung) * gebaeudebreite / 2;
      deltaYTraufe = Math.tan(dachneigung) * traufeueberstand;

      p0 = -(gebaeudelaenge / 2) + ' ' + 0 + ' ' + -(gebaeudebreite / 2);
      p1 = -(gebaeudelaenge / 2) + ' ' + 0 + ' ' + gebaeudebreite / 2;
      p2 = gebaeudelaenge / 2 + ' ' + 0 + ' ' + gebaeudebreite / 2;
      p3 = gebaeudelaenge / 2 + ' ' + 0 + ' ' + -(gebaeudebreite / 2);
      p4 = -(gebaeudelaenge / 2) + ' ' + y + ' ' + -(gebaeudebreite / 2);
      p5 = -(gebaeudelaenge / 2) + ' ' + y + ' ' + gebaeudebreite / 2;
      p6 = gebaeudelaenge / 2 + ' ' + y + ' ' + gebaeudebreite / 2;
      p7 = gebaeudelaenge / 2 + ' ' + y + ' ' + -(gebaeudebreite / 2);
      p8 = -(gebaeudelaenge / 2 + giebelueberstand) + ' ' + firsthoehe + ' ' + 0;
      p9 = -(gebaeudelaenge / 2 + giebelueberstand) + ' ' + (y - deltaYTraufe) + ' ' + (gebaeudebreite / 2 + traufeueberstand);
      p10 = gebaeudelaenge / 2 + giebelueberstand + ' ' + (y - deltaYTraufe) + ' ' + (gebaeudebreite / 2 + traufeueberstand);
      p11 = gebaeudelaenge / 2 + giebelueberstand + ' ' + firsthoehe + ' ' + 0;
      p12 = gebaeudelaenge / 2 + giebelueberstand + ' ' + (y - deltaYTraufe) + ' ' + -(gebaeudebreite / 2 + traufeueberstand);
      p13 = -(gebaeudelaenge / 2 + giebelueberstand) + ' ' + (y - deltaYTraufe) + ' ' + -(gebaeudebreite / 2 + traufeueberstand);
      p14 = -(gebaeudelaenge / 2 + giebelueberstand) + ' ' + (firsthoehe + 0.2) + ' ' + 0;
      p15 = -(gebaeudelaenge / 2 + giebelueberstand) + ' ' + (y - deltaYTraufe + 0.2) + ' ' + (gebaeudebreite / 2 + traufeueberstand);
      p16 = gebaeudelaenge / 2 + giebelueberstand + ' ' + (y - deltaYTraufe + 0.2) + ' ' + (gebaeudebreite / 2 + traufeueberstand);
      p17 = gebaeudelaenge / 2 + giebelueberstand + ' ' + (firsthoehe + 0.2) + ' ' + 0;
      p18 = gebaeudelaenge / 2 + giebelueberstand + ' ' + (y - deltaYTraufe + 0.2) + ' ' + -(gebaeudebreite / 2 + traufeueberstand);
      p19 = -(gebaeudelaenge / 2 + giebelueberstand) + ' ' + (y - deltaYTraufe + 0.2) + ' ' + -(gebaeudebreite / 2 + traufeueberstand);

      p20 = -(gebaeudelaenge / 2) + ' ' + firsthoehe + ' ' + 0;
      p21 = gebaeudelaenge / 2 + ' ' + firsthoehe + ' ' + 0;

      p22 = -(gebaeudelaenge / 2) + ' ' + (firsthoehe + 0.21) + ' ' + 0;
      p23 = -(gebaeudelaenge / 2) + ' ' + (y + 0.21) + ' ' + gebaeudebreite / 2;
      p24 = gebaeudelaenge / 2 + ' ' + (y + 0.21) + ' ' + gebaeudebreite / 2;
      p25 = gebaeudelaenge / 2 + ' ' + (firsthoehe + 0.21) + ' ' + 0;
      p26 = gebaeudelaenge / 2 + ' ' + (y + 0.21) + ' ' + -(gebaeudebreite / 2);
      p27 = -(gebaeudelaenge / 2) + ' ' + (y + 0.21) + ' ' + -(gebaeudebreite / 2);

      var koordinaten = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + p8 + ', ' + p9 + ', ' + p10 + ', ' + p11 + ', ' + p12 + ', ' + p13 + ', ' + p14 + ', ' + p15 + ', ' + p16 + ', ' + p17 + ', ' + p18 + ', ' + p19 + ', ' + p20 + ', ' + p21 + ', ' + p22 + ', ' + p23 + ', ' + p24 + ', ' + p25 + ', ' + p26 + ', ' + p27;
      return koordinaten;
    },

    getPultdachKoordinaten: function getPultdachKoordinaten(gebaeudebreite, gebaeudelaenge, firsthoehe, dachneigung, firstueberstand, traufeueberstand, giebelueberstand) {
      var p0, p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11, p12, p13, p14, p15, p16, p17, p18, p19;
      var x, y, deltaYTraufe, deltaYFirst, z;

      y = firsthoehe - Math.tan(dachneigung) * gebaeudebreite;
      deltaYTraufe = Math.tan(dachneigung) * traufeueberstand;
      deltaYFirst = Math.tan(dachneigung) * firstueberstand;

      p0 = -(gebaeudelaenge / 2) + ' ' + 0 + ' ' + -(gebaeudebreite / 2);
      p1 = -(gebaeudelaenge / 2) + ' ' + 0 + ' ' + gebaeudebreite / 2;
      p2 = gebaeudelaenge / 2 + ' ' + 0 + ' ' + gebaeudebreite / 2;
      p3 = gebaeudelaenge / 2 + ' ' + 0 + ' ' + -(gebaeudebreite / 2);

      p4 = -(gebaeudelaenge / 2) + ' ' + firsthoehe + ' ' + -(gebaeudebreite / 2);
      p5 = -(gebaeudelaenge / 2) + ' ' + y + ' ' + gebaeudebreite / 2;
      p6 = gebaeudelaenge / 2 + ' ' + y + ' ' + gebaeudebreite / 2;
      p7 = gebaeudelaenge / 2 + ' ' + firsthoehe + ' ' + -(gebaeudebreite / 2);

      p8 = -(gebaeudelaenge / 2 + giebelueberstand) + ' ' + (firsthoehe + deltaYFirst) + ' ' + -(gebaeudebreite / 2 + firstueberstand);
      p9 = -(gebaeudelaenge / 2 + giebelueberstand) + ' ' + (y - deltaYTraufe) + ' ' + (gebaeudebreite / 2 + traufeueberstand);
      p10 = gebaeudelaenge / 2 + giebelueberstand + ' ' + (y - deltaYTraufe) + ' ' + (gebaeudebreite / 2 + traufeueberstand);
      p11 = gebaeudelaenge / 2 + giebelueberstand + ' ' + (firsthoehe + deltaYFirst) + ' ' + -(gebaeudebreite / 2 + firstueberstand);

      p12 = -(gebaeudelaenge / 2 + giebelueberstand) + ' ' + (firsthoehe + deltaYFirst + 0.2) + ' ' + -(gebaeudebreite / 2 + firstueberstand);
      p13 = -(gebaeudelaenge / 2 + giebelueberstand) + ' ' + (y - deltaYTraufe + 0.2) + ' ' + (gebaeudebreite / 2 + traufeueberstand);
      p14 = gebaeudelaenge / 2 + giebelueberstand + ' ' + (y - deltaYTraufe + 0.2) + ' ' + (gebaeudebreite / 2 + traufeueberstand);
      p15 = gebaeudelaenge / 2 + giebelueberstand + ' ' + (firsthoehe + deltaYFirst + 0.2) + ' ' + -(gebaeudebreite / 2 + firstueberstand);

      p16 = -(gebaeudelaenge / 2) + ' ' + (firsthoehe + 0.21) + ' ' + -(gebaeudebreite / 2);
      p17 = -(gebaeudelaenge / 2) + ' ' + (y + 0.21) + ' ' + gebaeudebreite / 2;
      p18 = gebaeudelaenge / 2 + ' ' + (y + 0.21) + ' ' + gebaeudebreite / 2;
      p19 = gebaeudelaenge / 2 + ' ' + (firsthoehe + 0.21) + ' ' + -(gebaeudebreite / 2);

      var koordinaten = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + p8 + ', ' + p9 + ', ' + p10 + ', ' + p11 + ', ' + p12 + ', ' + p13 + ', ' + p14 + ', ' + p15 + ', ' + p16 + ', ' + p17 + ', ' + p18 + ', ' + p19;
      return koordinaten;
    },

    getWalmdachKoordinaten: function getWalmdachKoordinaten(gebaeudebreite, gebaeudelaenge, firsthoehe, dachneigung, dachneigung2, firstueberstand, traufeueberstand, giebelueberstand) {
      var p0, p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11, p12, p13, p14, p15, p16, p17, p18, p19;
      var x, y, deltaYTraufe, z;

      y = firsthoehe - Math.tan(dachneigung) * gebaeudebreite / 2;
      deltaYTraufe = Math.tan(dachneigung) * traufeueberstand;
      var deltaX = (firsthoehe - y) / Math.tan(dachneigung2);

      p0 = -(gebaeudelaenge / 2) + ' ' + 0 + ' ' + -(gebaeudebreite / 2);
      p1 = -(gebaeudelaenge / 2) + ' ' + 0 + ' ' + gebaeudebreite / 2;
      p2 = gebaeudelaenge / 2 + ' ' + 0 + ' ' + gebaeudebreite / 2;
      p3 = gebaeudelaenge / 2 + ' ' + 0 + ' ' + -(gebaeudebreite / 2);
      p4 = -(gebaeudelaenge / 2) + ' ' + y + ' ' + -(gebaeudebreite / 2);
      p5 = -(gebaeudelaenge / 2) + ' ' + y + ' ' + gebaeudebreite / 2;
      p6 = gebaeudelaenge / 2 + ' ' + y + ' ' + gebaeudebreite / 2;
      p7 = gebaeudelaenge / 2 + ' ' + y + ' ' + -(gebaeudebreite / 2);

      p8 = -gebaeudelaenge / 2 + deltaX + ' ' + firsthoehe + ' ' + 0;
      p9 = -(gebaeudelaenge / 2 + giebelueberstand) + ' ' + (y - deltaYTraufe) + ' ' + (gebaeudebreite / 2 + traufeueberstand);
      p10 = gebaeudelaenge / 2 + giebelueberstand + ' ' + (y - deltaYTraufe) + ' ' + (gebaeudebreite / 2 + traufeueberstand);
      p11 = gebaeudelaenge / 2 - deltaX + ' ' + firsthoehe + ' ' + 0;

      p12 = gebaeudelaenge / 2 + giebelueberstand + ' ' + (y - deltaYTraufe) + ' ' + -(gebaeudebreite / 2 + traufeueberstand);
      p13 = -(gebaeudelaenge / 2 + giebelueberstand) + ' ' + (y - deltaYTraufe) + ' ' + -(gebaeudebreite / 2 + traufeueberstand);
      p14 = -(gebaeudelaenge / 2 + giebelueberstand) + ' ' + (y - deltaYTraufe + 0.2) + ' ' + (gebaeudebreite / 2 + traufeueberstand);
      p15 = gebaeudelaenge / 2 + giebelueberstand + ' ' + (y - deltaYTraufe + 0.2) + ' ' + (gebaeudebreite / 2 + traufeueberstand);
      p16 = gebaeudelaenge / 2 + giebelueberstand + ' ' + (y - deltaYTraufe + 0.2) + ' ' + -(gebaeudebreite / 2 + traufeueberstand);
      p17 = -(gebaeudelaenge / 2 + giebelueberstand) + ' ' + (y - deltaYTraufe + 0.2) + ' ' + -(gebaeudebreite / 2 + traufeueberstand);

      p18 = -(gebaeudelaenge / 2 - deltaX) + ' ' + (firsthoehe + 0.2) + ' ' + 0;
      p19 = gebaeudelaenge / 2 - deltaX + ' ' + (firsthoehe + 0.2) + ' ' + 0;

      var koordinaten = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + p8 + ', ' + p9 + ', ' + p10 + ', ' + p11 + ', ' + p12 + ', ' + p13 + ', ' + p14 + ', ' + p15 + ', ' + p16 + ', ' + p17 + ', ' + p18 + ', ' + p19;

      return koordinaten;
    }

  });

  exports['default'] = Transform;

});