define('m0601/components/ft-verbinder', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Transform = Ember['default'].Component.extend({

    tagName: 'transform',
    layoutName: 'ft-verbinder',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    // center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",
    test: 1337,

    center: (function () {
      var values = '0 0 0';

      return values;
    }).property(''),

    translation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = this.get('model').objectAt(0).get('bauteile');

      // var xVerschiebung = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('tiefeUnterkonstruktion')/10 - bauteile.findBy('id', 'unterkonstruktion1').get('boxsizes').objectAt(0).get('z')/20;
      // var yVerschiebung = bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y')/10 + bauteile.findBy('id', 'unterkonstruktion1').get('boxsizes').objectAt(0).get('y')/10;
      // var zVerschiebung = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('tiefeUnterkonstruktion')/20 - bauteile.findBy('id', 'unterkonstruktion1').get('boxsizes').objectAt(0).get('z')/20;

      var values = 0 + ' ' + 0 + ' ' + 0;

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt', 'model.firstObject.schraubenGekreuzt'),

    rotation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = this.get('model').objectAt(0).get('bauteile');
      var values = '0 0 0 0';

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.schraubenGekreuzt'),

    ftvListe: (function () {
      var x3d = this.get('model').objectAt(0);
      var bauteile = this.get('model').objectAt(0).get('bauteile');
      // var ftvliste = Ember.Set.create();
      var ftvliste = new Array();

      if (x3d.get('ergebnisGeladen') === false) {
        bauteile.forEach(function (item) {
          if (item.get('typ') === x3d.get('aktuellerSchraubenTyp')) {
            var newFT = { pos: "", rot: "0 0 0 0" };

            var deltaEntfernung = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 60;

            if (item.get('translations').objectAt(0).get('x') > 0 && !bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('auflager2Durchlaufend')) {
              deltaEntfernung = deltaEntfernung - bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('auflager2RandLinks') / 30;
            } else if (item.get('translations').objectAt(0).get('x') < 0 && !bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('auflager1Durchlaufend')) {
              deltaEntfernung = deltaEntfernung - bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('auflager1RandRechts') / 30;
            }

            var x = item.get('translations').objectAt(0).get('x') * deltaEntfernung - 0.04;
            var y = bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10 + bauteile.findBy('id', 'unterkonstruktion1').get('boxsizes').objectAt(0).get('y') / 10 + bauteile.findBy('id', 'schalung').get('boxsizes').objectAt(0).get('y') / 10;
            var zrel = item.get('translations').objectAt(0).get('z') / 10;
            var z;

            if (x < 0) {
              newFT.rot = "0 1 0 " + Math.PI;
              x = x + 0.08;
            }

            // if(bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('deckenkonstruktion')  === 'holzelement'){
            //   z = (bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('tiefeUnterkonstruktion')/40);
            // }else{
            z = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('tiefeUnterkonstruktion') / 20;
            // }

            if (zrel < 0) {
              z = -z;
            }

            newFT.pos = x + ' ' + y + ' ' + z;

            ftvliste.push(newFT);
          }
        });
      } else {

        bauteile.forEach(function (item) {

          if (item.get('typ') === x3d.get('aktuellerSchraubenTyp')) {
            var newFT = { pos: "", rot: "0 0 0 0" };

            var deltaX = 0;
            var deltaY = 0;

            // if(bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('hbvSystem') === 'ftNass'){
            //   deltaX = (Number(item.get('screwProperties').objectAt(0).get('length'))-9) * Math.cos(0.5236);
            //   deltaY = (Number(item.get('screwProperties').objectAt(0).get('length'))-9) * Math.cos(0.5236);
            // }

            var xMax = Number(item.get('punkt').objectAt(0).get('x')) + Number(item.get('richtungsvektor').objectAt(0).get('x')) * item.get('screwProperties').objectAt(0).get('length');

            var g = -Number(item.get('punkt').objectAt(0).get('y')) / Number(item.get('richtungsvektor').objectAt(0).get('y'));

            // var x = Number(item.get('punkt').objectAt(0).get('x'))/10-0.04+(Number(item.get('punkt').objectAt(0).get('x'))/Math.abs(Number(item.get('punkt').objectAt(0).get('x'))))*deltaX/10;

            // var x = Number(item.get('punkt').objectAt(0).get('x'))/10;
            // var x = Number(item.get('punkt').objectAt(0).get('x'))/Math.abs(Number(item.get('punkt').objectAt(0).get('x'))) * xSchnittpunktt/10;

            var x = (Number(item.get('punkt').objectAt(0).get('x')) + g * Number(item.get('richtungsvektor').objectAt(0).get('x'))) / 10 - 0.04;

            // var y = Number(item.get('punkt').objectAt(0).get('y'))/10;
            var y = 0;
            var z = Number(item.get('punkt').objectAt(0).get('z')) / 10;

            if (x < 0) {
              newFT.rot = "0 1 0 " + Math.PI;
              x = x + 0.08;
            }

            newFT.pos = x + ' ' + y + ' ' + z;

            ftvliste.push(newFT);
          }
        });
      }

      return ftvliste;
    }).property('model.firstObject.schraubenlisteAktualisiert', 'model.firstObject.transformHelper')
  });

  exports['default'] = Transform;

});